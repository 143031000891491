import React from 'react';

const k = 5.9115 / 7.9375;

export const IconFileTypeMedia: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 30, fill = 'black', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    <svg {...rest} width={aspectWidth} height={height} fill={fill} viewBox="0 0 5.9115 7.9375" xmlns="http://www.w3.org/2000/svg">
      <path d="m5.4467 2.2667h-0.49809v-1.7935h0.49809zm-0.33206 4.6746h-4.3177v-2.5904h4.3175v2.5904zm-3.4874-6.0361h0.49828v1.3615h-0.49828zm0.83052-0.43179h0.49809v1.7935h-0.49809zm0.83034 0h0.49809v1.7935h-0.49809zm0.82996 0h0.49828v1.7935h-0.49828zm1.2623-0.47344h-3.5061c-0.62509 0.6249-1.2498 1.2496-1.8743 1.8745v5.5614c0 0.27603 0.23905 0.50164 0.53133 0.50164h4.849c0.29191 0 0.53115-0.22561 0.53115-0.50164v-6.9338c0-0.27603-0.23924-0.50201-0.53115-0.50201" />
    </svg>
  );
};
