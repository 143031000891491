import React from 'react';
import BarItem from '../../Component/BarItem/BarItem';
import image from './image_HitConfigure.svg';

const HitConfigure = () => (
    <BarItem
        number={3}
        text={(
            <>
                Configure the app to
                <br />
                store media locally or in
                <br />
                the cloud or both!
            </>
        )}
        image={image}
    />
);

export default HitConfigure;
