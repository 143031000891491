import { findFilesSync, findFolderWithIdSync, getDateFiler, scanDirectory } from './fileSystem';
import { getProjectsRootPathSync } from './userSearch';
import { isWeb } from '../../constants/modules';

const findProjectFiles = (projectId, filter) => (dispatch) => {
    if (isWeb) {
        return [];
    }
    const projects = findFolderWithIdSync(dispatch(getProjectsRootPathSync()), projectId);
    if (projects && projects.length) {
        const files = findFilesSync(projects[0], filter, true, false);
        if (files && files.length) {
            return files;
        }
    }

    return [];
};
export const findProjectFile = (projectId, fileId) => (dispatch) => {
    const files = dispatch(findProjectFiles(projectId, fileId));

    if (files && files.length) {
        return files[0];
    }

    return '';
};


// look only for YYYYMMDD folders
export const findDatedFolders = async fileTypeFolder => scanDirectory(
    fileTypeFolder, getDateFiler(), true, false
);
// const findDatedFoldersSync = fileTypeFolder => scanDirectorySync(
//     fileTypeFolder, getDateFiler(), true, false
// );

// export const findFilesOnDrive = docs => (dispatch) => {
//     const paths = {};
//     const prPaths = {};
//
//     // Look for paths for files '.../{..._projectId}/{docTypeFolder}/{dateFolder}/'
//     docs.forEach((doc) => {
//         if (!paths[doc.projectId]) {
//             const prPath = dispatch(findProjectFolderSync(doc.projectId));
//             if (prPath && fsExistsSync(prPath)) {
//                 prPaths[doc.projectId] = prPath;
//                 paths[doc.projectId] = {};
//             }
//         }
//
//         if (prPaths[doc.projectId]) {
//           DOCUMENT_DESCRIPTIONS[doc.type].folderNames
//             .map(f => `${prPaths[doc.projectId]}/${f}`)
//             .forEach((typeFolder) => {
//                 if (typeFolder && fsExistsSync(typeFolder)) {
//                     if (!paths[doc.projectId][doc.type]) {
//                         paths[doc.projectId][doc.type] = [];
//                     }
//                     Array.prototype.push.apply(paths[doc.projectId][doc.type], findDatedFoldersSync(typeFolder));
//                 }
//             });
//         }
//     });
//
//     // Search for files
//     return docs.reduce((acc, doc) => {
//         const fileFolders = paths[doc.projectId] && paths[doc.projectId][doc.type];
//         if (fileFolders) {
//             const res = {};
//             if (fileFolders.some((folder) => {
//                 const files = findFilesSync(folder, doc._id, false);
//                 if (files && files.length) {
//                     const [file] = files;
//                     res[doc._id] = file;
//                     return true;
//                 }
//                 return false;
//             })) {
//                 return { ...acc, ...res };
//             }
//         }
//         return acc;
//     }, {});
// };
//
// export const findNewFiles = project => async (dispatch) => {
//     const fileDocs = await dispatch(fetchProjectFiles(project._id, ''));
//     console.log('find new files: fileDocs', fileDocs);
//
//     const filesInProject = Object.values(dispatch(findFilesOnDrive(fileDocs)));
//     console.log('find new files: filesInProject', { filesInProject });
//     // const filesInProject = fileDocs
//     //     .map(file => dispatch(findProjectFile(file.projectId, file._id)))
//     //     .filter(path => !!path);
//
//     return Object.keys(DOC_TYPES.file)
//         .reduce((acc, fileType) => {
//             const fileTypeFolder = dispatch(getFileNewFolderName(DOC_TYPES.file[fileType], project, null, false));
//             const folders = findDatedFoldersSync(fileTypeFolder);
//             const files = folders
//                 .reduce((accFiles, folder) => {
//                     const fls = findFilesSync(folder, '', false)
//                         .filter(filepath => !filesInProject.includes(filepath));
//                     return [...accFiles, ...fls];
//                 }, []);
//             return files.length > 0
//                 ? {
//                     ...acc,
//                     [DOC_TYPES.file[fileType]]: files.map(path => FileFS.from(path))
//                 }
//                 : acc;
//         }, {});
// };
