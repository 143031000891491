import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { isWeb } from '../../../constants/modules';
import { TStore } from '../../../helpers/types-store';
import { apiDeactivateUser, updateUserPassword, updateUserProperties } from '../../../modules/user/actions';
import { doFetchUserProperties } from '../../../new/authUtils';
import { UserContext } from '../../../Providers/User';
import { prettyDate } from '../../../utils/format';
import LocalSettings from '../../Desktop/LocalSettings/LocalSettings';
import { PageContainer } from '../PageContainer';
import styles from './account.module.scss';
import { UserStats } from './UserStats';


export const Account: React.FC = () => {
  const { permissions: { canUseApp, canManageAccount } } = useContext(UserContext);

  const dispatch = useDispatch();
  const userProps = useSelector<TStore,
    { userId: string, name: string, login: string, email: string, created: string, roles: Record<string, string> }>((
    {
      user: {
        web: {
          id: userId,
          properties: { name, login, email, roles },
        },
        dbUser: { created },
      }
    }) => ({
      userId,
      name,
      login,
      email,
      created: prettyDate(created),
      roles
    }
  ));

  const [edit, setEdit] = useState(false);
  const editMode = useMemo(() => edit && canUseApp, [edit, canUseApp]);


  const toggleEdit = useCallback((resetForm?: () => void) => {
    if (edit && resetForm) {
      resetForm();
    }
    setEdit(!edit);
  }, [edit]);

  const handleSubmit = useCallback(async (values: { name: string, login: string, email: string, newPassword: string, password: string }) => {
    const { name, login, email } = userProps;

    if (name !== values.name || login !== values.login || email !== values.email) {
      await updateUserProperties(values);
      await dispatch(doFetchUserProperties());
    }

    if (!!values.newPassword && !!values.password) {
      await dispatch(updateUserPassword(values));
    }

    toggleEdit();
  }, [userProps, dispatch, toggleEdit]);

  const handleDelete = useCallback(async () => {
    const { userId } = userProps;
    const answer = window.confirm(
      'Be careful:\n'
      + 'The current user will be deactivated and you WILL NOT BE ABLE to log in.\n'
      + 'Continue?'
    );

    if (answer) {
      await dispatch(apiDeactivateUser(userId));
    }
  }, [userProps, dispatch]);

  const initValues = useMemo(() => ({
    name: userProps.name,
    login: userProps.login,
    email: userProps.email,
    newPassword: '',
    password: '',
  }), [userProps]);

  return (
    <PageContainer parentTitle="Account" title={userProps.name}>
      <Formik
        initialValues={initValues}
        validationSchema={object()
          .shape({
            name: string().required('Required'),
            login: string().required('Required'),
            email: string().email('Invalid email address').required('Required'),
            // phone: string().required('Required')
          })}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({ resetForm }) => (
          <Form>
            <div className={styles.editContainer}>
              <div>
                <Field
                  label="Created"
                  name="created"
                  readOnly
                  component={Input}
                  labelWidth={Input.LABEL_WIDTH.FULL}
                  field={{ value: userProps.created }}
                  spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                />
                <Field
                  label="Full Name"
                  name="name"
                  component={Input}
                  readOnly={!editMode}
                  editClick={toggleEdit}
                  labelWidth={Input.LABEL_WIDTH.FULL}
                  spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                />
                <div className={styles.horizontal}>
                  <Field
                    label="Login Name"
                    name="login"
                    component={Input}
                    readOnly={!editMode}
                    editClick={toggleEdit}
                    labelWidth={Input.LABEL_WIDTH.FULL}
                    spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                  />
                  <Field
                    label="Email"
                    name="email"
                    placeholder="example@domain.com"
                    component={Input}
                    readOnly={!editMode}
                    editClick={toggleEdit}
                    labelWidth={Input.LABEL_WIDTH.FULL}
                    spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                  />
                </div>
                {editMode && (
                  <div className={styles.horizontal}>
                    <Field
                      label="New Password"
                      name="newPassword"
                      type={Input.TYPE.PASSWORD}
                      component={Input}
                      labelWidth={Input.LABEL_WIDTH.FULL}
                      spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                    />
                    <Field
                      label="Old Password"
                      name="password"
                      type={Input.TYPE.PASSWORD}
                      component={Input}
                      labelWidth={Input.LABEL_WIDTH.FULL}
                      spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                    />
                  </div>
                )}
              </div>
              <div className={styles.saveWrapper}>
                {editMode && (
                  <Button
                    small
                    type="submit"
                    className={styles.toggleEdit}
                  >
                    Save
                  </Button>
                )}
                {editMode && (
                  <Button
                    small
                    color={Button.COLOR.WARNING}
                    onClick={handleDelete}
                    className={styles.toggleEdit}
                  >
                    Delete
                  </Button>
                )}
                {canUseApp && (
                  <Button
                    small
                    color={editMode ? Button.COLOR.GRAY : Button.COLOR.PRIMARY}
                    onClick={() => toggleEdit(resetForm)}
                    className={styles.toggleEdit}
                  >
                    {editMode ? 'Cancel' : 'Edit'}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      />
      <PageContainer title="Roles" titleLevel={2}>
        <div className={styles.fields}>
          {Object.entries(userProps.roles).map(([key, name]) => (
            <Input
              key={key}
              labelOnTheRight
              label={name}
              field={{
                name: `roles.${key}`,
                value: true
              }}
              type={Input.TYPE.SWITCH}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              readOnly
            />
          ))}
        </div>
      </PageContainer>
      {canManageAccount && !isWeb && <LocalSettings showTitle />}
      <UserStats />
    </PageContainer>
  );
}
