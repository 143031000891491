import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS } from '../constants/general';
import { api, emitUserError } from '../constants/globals';
import { TUser } from './types-api';


type TUserSaveValues = {
  user_id: string
  login?: string
  name?: string
  email?: string
  is_active?: boolean
  roles?: string[]
}

type TUseUserProps = {
  id: string
}
type TUseUserResponse = {
  user?: TUser
  read: () => Promise<void>
  save: (values: TUserSaveValues) => Promise<void>
  activate: () => Promise<void>
  deactivate: () => Promise<void>
}


export const useUser = (props: TUseUserProps): TUseUserResponse => {
  const { id } = props;

  const [user, setUser] = useState<TUser | undefined>(undefined);


  const read = useCallback(async () => {
    if (id) {
      try {
        const response = (await api.apiGet(ENDPOINTS.user.getUserProperties, { user_id: id })) as TUser;
        if (response?.roles) {
          setUser(response);
        }
      } catch (e) {
        emitUserError(`Read user error\n${(e as Error).message}`)
      }
    }
  }, [id]);

  const save = useCallback(async (values: TUserSaveValues) => {
    try {
      // await updateUserProperties(values);
      await api.apiPost(ENDPOINTS.user.updateUserProperties, values);
    } catch (e) {
      emitUserError(`Save user error\n${(e as Error).message}`)
    }
    await read();
  }, [read]);

  const deactivate = useCallback(async () => {
    if (!user) {
      emitUserError('User not read yet');
    } else {
      try {
        await api.apiPost(ENDPOINTS.user.deactivateUser, { user_id: user.user_id });
      } catch (e) {
        emitUserError(`Deactivate user error\n${(e as Error).message}`)
      }
      await read();
    }
  }, [user, read]);

  const activate = useCallback(async () => {
    if (!user) {
      emitUserError('User not read yet');
    } else {
      try {
        await api.apiPost(ENDPOINTS.user.activateUser, { user_id: user.user_id });
      } catch (e) {
        emitUserError(`Activate user error\n${(e as Error).message}`)
      }
      await read();
    }
  }, [user, read]);


  useEffect(() => {
    if (!user) {
      void read();
    }
  }, [user, read])


  return {
    user,
    read,
    save,
    activate,
    deactivate,
  };
}
