import { ENDPOINTS, ROUTE } from '../constants/general';
import { wait } from './common';
import { getSharingWeb, getUserWeb } from './localStorageUtils';

export const isSharing = () => window.location.pathname.toLowerCase().search(ROUTE.share.root.toLowerCase()) === 0;

export const doCheckToken = web => !!(web && web.token && web.refreshToken);


export const doCheckLoginUser = web => !!(web && !web.refreshing && web.id && doCheckToken(web));

export const doCheckLoginShare = web => !!(web && !web.refreshing && web.ownerId && doCheckToken(web));

export const checkLoggedIn = web => (isSharing() ? doCheckLoginShare(web) : doCheckLoginUser(web));


export const getWeb = () => (isSharing() ? getSharingWeb() : getUserWeb()) || {};

/**
 * @returns {string}
 */
export const getUserId = () => getWeb()[isSharing() ? 'ownerId' : 'id'];

export const isTokenRefresh = url => url === (isSharing() ? ENDPOINTS.share.tokenRefresh : ENDPOINTS.user.tokenRefresh);

export const getFreshToken = async (url) => {
    let web = getWeb();

    const isRefreshTokenUrl = isTokenRefresh(url);

    while (web.refreshing === true && !isRefreshTokenUrl && checkLoggedIn(web)) {
        // eslint-disable-next-line no-await-in-loop
        await wait(100);
        web = getWeb();
    }

    return web.token || '';
};
export const needToRefreshToken = apiResponse => !apiResponse.ok
    && apiResponse.status === 400
    && apiResponse.body.message === 'Signature has expired'
    && !isTokenRefresh(apiResponse.url);

export const validateShareWeb = web => !!(
    web
    && web.ownerId
    && doCheckToken(web)
);
