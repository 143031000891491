import React, { useContext, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { RecentContext } from '../../../../Providers/Recent';
import { SeriesContext, SeriesProvider } from '../../../../Providers/Series';
import EpisodeList from '../EpisodeList/EpisodeList';
import SeasonList from '../SeasonList/SeasonList';
import { SeriesDetails } from './SeriesDetails';


const SeriesPageContainer: React.FC = () => {
  const { series } = useContext(SeriesContext);

  const { addRecent } = useContext(RecentContext);
  const rm = useRouteMatch();
  useEffect(() => {
    if (series?._id) {
      addRecent({
        type: series.type,
        title: series.title,
        to: rm.url
      })
    }
  }, [series, addRecent]);


  return (
    <>
      {series?._id && (
        <>
          <SeriesDetails />
          {series.seasons
            ? (
              <SeasonList
                showSeasons={series.seasons}
                seriesId={series._id}
              />
            )
            : (
              <EpisodeList
                seriesId={series._id}
                noPadding
              />
            )
          }
        </>
      )}
    </>
  );
}


export const SeriesPage: React.FC = () => {
  const { seriesId } = useParams<{ seriesId: string }>();

  return (
    <SeriesProvider seriesId={seriesId}>
      <SeriesPageContainer />
    </SeriesProvider>
  );
}
