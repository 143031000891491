import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ROUTE } from '../../../constants/general';
import { logout } from '../../../modules/user/actions';

class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return <Redirect to={ROUTE.login} />;
    }
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Logout);
