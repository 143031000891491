import moment from 'moment';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DOC_TYPE_SERIES, TDocSeries } from '../../constants/docs';
import { ENDPOINTS } from '../../constants/general';
import { api, emitUserError } from '../../constants/globals';
import { readUserDoc, updateUserDoc } from '../../handlers/userDB';
import { removeSeries } from '../../modules/series/actions';


type TSeriesProviderProps = {
  seriesId: string
};
type TSeriesContext = {
  series: TDocSeries
  isLoading: boolean
  readSeries: () => Promise<void>
  saveSeries: (values: Partial<TDocSeries>) => Promise<boolean>
  deleteSeries: () => Promise<boolean>
  rssURI: string
  rssActivated: boolean
  readRssActivated: (silent?: boolean) => Promise<void>
};


const getInitialSeries = (): TDocSeries => ({
  _id: '',
  _rev: '',
  type: DOC_TYPE_SERIES,
  created: moment().toISOString(),
  updated: moment().toISOString(),
  title: '',
  description: '',
  keywords: '',
  seriesType: 'video',
  sponsored: false,
  seasons: false,
})


export const SeriesContext = createContext<TSeriesContext>({
  series: getInitialSeries(),
  isLoading: false,
  readSeries: () => new Promise(resolve => { resolve(undefined) }),
  saveSeries: async () => new Promise(resolve => { resolve(true) }),
  deleteSeries: () => new Promise(resolve => { resolve(true) }),
  rssURI: '',
  rssActivated: false,
  readRssActivated: () => new Promise(resolve => { resolve(undefined) }),
})

export const SeriesProvider: React.FC<TSeriesProviderProps> = ({ seriesId, children }) => {
  const dispatch = useDispatch();

  // Series doc

  const [series, setSeries] = useState<TDocSeries>(getInitialSeries());
  const [isLoading, setIsLoading] = useState(false);

  const readSeries = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(readUserDoc(seriesId)) as TDocSeries;
      if (res) {
        setSeries(res);
      }
    } catch (e) {
      emitUserError((e as Error).message, 'Read series error');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, seriesId]);
  useEffect(() => {
    if (seriesId && !series?._id) {
      void readSeries()
    }
  }, [series, seriesId, readSeries])

  const saveSeries = useCallback(async (values: Partial<TDocSeries>) => {
    try {
      await dispatch(updateUserDoc(values));
      await readSeries();
      return true;
    } catch (e) {
      emitUserError((e as Error).message, 'Save series error');
    }
    return false;
  }, [dispatch, readSeries]);

  const deleteSeries = useCallback(async () => {
    // const answer = window.confirm('Series will be removed with all related Seasons and Episodes.\nContinue?');
    // if (answer) {
      try {
        await dispatch(removeSeries(seriesId));
        return true;
      } catch (e) {
        emitUserError((e as Error).message, 'Remove series error');
      }
    // }
    return false;
  }, [dispatch, seriesId]);

  // RSS feed

  const [rssActivated, setRssActivated] = useState(false);

  const rssURI = useMemo(() => `${ENDPOINTS.rss.getSeriesRss}?series_id=${series._id}`, [series._id]);
  const readRssActivated = useCallback(async (silent?: boolean) => {
    try {
      const res = await api.apiGet(ENDPOINTS.rss.checkActivationSeriesRss, { series_id: series._id});
      setRssActivated(res?.activated === true);
    } catch (e) {
      if (silent !== true) {
        emitUserError((e as Error).message, 'Read rss error');
      }
    }
  }, [series._id]);
  useEffect(() => {
    if (series._id) {
      void readRssActivated(true)
    }
  }, [series._id, readRssActivated])

  return (
    <SeriesContext.Provider value={{
      series,
      isLoading,
      readSeries,
      saveSeries,
      deleteSeries,
      rssURI,
      rssActivated,
      readRssActivated,
    }}>
      {children}
    </SeriesContext.Provider>
  )
}

