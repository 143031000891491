import { DOC_TYPE_PROJECT, DOCUMENT_DESCRIPTIONS } from '../../constants/docs';
import { synchronizer } from '../../constants/globals';
import { DOC_TYPES_FILES, LIST_OPTIONS } from '../../constants/pouchDB';
import { findUserDocs, readUserDoc, removeUserDocs, writeUserDoc } from '../../handlers/userDB';
import { fetchEpisodesByVideoId } from '../../modules/episodes/actions';
import { removeFile } from '../../modules/projects/actions';
import { getDownloadUrlFunc } from '../cloud/cloud';
import { asyncForEach, asyncForEachParallel } from '../common';
import { runUploadFile } from '../common/syncRunner';
import { getNewDocumentFile } from '../pouchDB/documents';
import FileFS from './FileFS';
import { getFilePath, newFilePath } from './files';
import { scanDirectory } from './fileSystem';
import {
    fsCopy,
    fsExistsSync,
    fsMove,
    fsRemove,
    TEMPORARY_FILE_EXTENSION_FILTER,
    TEMPORARY_FILE_EXTENSION_FILTER_OLD
} from './fse';
import { fetchProjectFiles, readUserProject, scanProjectFiles } from './new_file_search';
import { findProjectFolder, getProjectFolder } from './projects';
import { findProjectFile } from './search';
import { runDownloadFile, runRemoveFile } from './syncRunner';
import { getProjectsRootPath } from './userSearch';
import { isWeb } from '../../constants/modules';

const syncFileDownload = (file, project) => async (dispatch) => {
    const projectFolder = await dispatch(getProjectFolder(project));
    const destination = getFilePath(file, projectFolder);

    if (fsExistsSync(destination)) {
        return;
    }

    const getUrlFunc = getDownloadUrlFunc(file.cloud);

    if (getUrlFunc) {
        await runDownloadFile(file, getUrlFunc, destination);
    }
};

const syncFileRemove = (file, project) => async (dispatch) => {
    await dispatch(runRemoveFile(file, project));
};

const syncFile = (file, paramProject) => async (dispatch) => {
    // console.log('syncFile', {file, paramProject});
    if (file) {
        const project = paramProject || await dispatch(readUserDoc(file.projectId));

        if (file._deleted) {
            // console.log('syncFile Remove', { file, project });
            dispatch(syncFileRemove(file, project));
        } else if (project.storeOnLocalDrive) {
            // console.log('syncFile Download', { file, project });
            dispatch(syncFileDownload(file, project));
        }
    }
};

export const removeProjectFolder = project => async (dispatch) => {
    const errors = [];

    if (!project._deleted) {
        const files = await dispatch(fetchProjectFiles(project._id));
        const lostNames = files
            .filter(file => !file.cloud || Object.keys(file.cloud).length === 0)
            .map(file => file.title);

        if (lostNames.length > 0) {
            errors.push(`Files:\n\n${
                lostNames.join('\n')
            }\n\nnot found in cloud. Removing from the drive is not possible to prevent the loss of files.`,
            );
        }
    }

    if (errors.length === 0) {
        const path = await dispatch(getProjectFolder(project));
        await fsRemove(path);
    }

    return errors;
};

const syncProject = project => async (dispatch) => {
    if (project._deleted) {
        await dispatch(removeProjectFolder(project));
    } else {
        const files = await dispatch(fetchProjectFiles(project._id));
        files.forEach((file) => {
            dispatch(syncFile(file, project));
        });
    }
};


export const getDocTypeFromId = (id) => {
  const descr = Object.values(DOCUMENT_DESCRIPTIONS)
    .find(({ prefix }) => prefix && prefix === id.slice(0, prefix.length));
  return descr?.type ?? '';
};

export const syncChanges = change => async (dispatch) => {
    if (isWeb) { return; }

    console.log('change', change);
    if (change && change.doc) {
        const type = change.doc.type || getDocTypeFromId(change.doc._id);

        if (type === DOC_TYPE_PROJECT) {
            dispatch(syncProject(change.doc));
        } else if (DOC_TYPES_FILES.includes(type)) {
            dispatch(syncFile(change.doc));
        }
    }
};

export const uploadFile = (path, doc) => async (dispatch) => {
    dispatch(runUploadFile([{
        file: FileFS.from(path),
        doc
    }]));
};

export const findAndUploadFile = ({ projectId, _id }) => async (dispatch) => {
    if (isWeb) { return; }

    const doc = await dispatch(readUserDoc(_id));
    await dispatch(syncFile(doc));

    const path = dispatch(findProjectFile(projectId, _id));
    if (path) {
        await dispatch(uploadFile(path, doc));
    }
};


const checkCloud = file => !!file.cloud && Object.keys(file.cloud).length > 0;

const uploadProjectFiles = docsWithPath => async (dispatch) => {
    docsWithPath.forEach((doc) => {
        if (!checkCloud(doc)) {
            dispatch(uploadFile(doc.path, { ...doc, path: undefined }));
        }
    });
};

export const copyNewFileToProjectFolder = async (project, doc, file, move) => {
    if (!isWeb && project.storeOnLocalDrive) {
        const newPath = newFilePath(doc, project.path); // TODO: Or getFilePath() ?
        try {
          if (move) {
            await fsMove(file.path, newPath);
          } else {
            await fsCopy(file.path, newPath);
          }
          return newPath;
        } catch (e) {
            console.error(`Error while copying file "${file.path}" to "${newPath}"`, e);
            throw e;
        }
    }
    return undefined;
};

const findAndSyncNewFiles = (project, files) => async (dispatch) => {
    await asyncForEach(files.filter(doc => !!doc.path), async ({ type, path }) => {
        const doc = await getNewDocumentFile(type, FileFS.from(path), project._id);
        const newPath = newFilePath(doc, project.path);
        await fsMove(path, newPath);

        await dispatch(writeUserDoc(doc));

        if (project.storeInCloud) {
            await dispatch(runUploadFile([{
                doc,
                file: FileFS.from(newPath, project.storeOnLocalDrive ? null : (async () => fsRemove(newPath)))
            }]));
        }
    });
};

export const reSyncProject = projectId => async (dispatch) => {
    if (isWeb) { return; }

    const project = await dispatch(readUserProject(projectId));

    const files = await dispatch(scanProjectFiles(project));

    await dispatch(syncProject(project));
    await dispatch(uploadProjectFiles(files.filter(doc => !!doc._id && !!doc.path)));
    await dispatch(findAndSyncNewFiles(project, files.filter(doc => !doc._id)));
    // await dispatch(scanProjectFolderForNewFiles(project));
};


const checkProjectsOnDrive = syncEvents => async (dispatch) => {
    const projects = await dispatch(findUserDocs({
        ...LIST_OPTIONS.projects,
        fields: ['_id'],
    }));

    if (projects.length) {
        if (syncEvents && syncEvents.setInit) {
            syncEvents.setInit(projects.length);
        }
    }

    const result = await asyncForEach(projects, async (prj) => {
        const prPath = await dispatch(findProjectFolder(prj._id));

        if (syncEvents && syncEvents.addProgress) {
            syncEvents.addProgress(1);
        }

        return ({ id: prj._id, path: prPath });
    });

    if (syncEvents && syncEvents.setComplete) {
        syncEvents.setComplete();
    }

    return result;
};

export const reSyncAllProject = () => async (dispatch) => {
    if (isWeb) { return; }

    await synchronizer.addItem({
        title: 'Syncing projects',
        actions: [{
            fn: async (syncEvents) => {
                const idMap = await dispatch(checkProjectsOnDrive(syncEvents));
                if (idMap.length) {
                    const notFoundCount = idMap.filter(item => !item.path).length;
                    const answer = !!notFoundCount && window.confirm(
                        `${notFoundCount} of ${idMap.length} project(s) not found on your drive.\n`
                        + 'Would you like to download them?'
                    );

                    idMap
                        .filter(item => answer || !!item.path)
                        .forEach(item => dispatch(reSyncProject(item.id)));
                }
            },
            title: 'Scanning folders ...',
            type: 'preparing',
            ignorePreviousErrors: true,
            autoComplete: false,
        }]
    });
};

export const cleanTempFiles = () => async (dispatch) => {
    const rootFolder = await dispatch(getProjectsRootPath());
    const files = [
        ...(await scanDirectory(rootFolder, TEMPORARY_FILE_EXTENSION_FILTER, false, true)),
        ...(await scanDirectory(rootFolder, TEMPORARY_FILE_EXTENSION_FILTER_OLD, false, true)), // TODO: Remove it later
    ];
    await asyncForEachParallel(files, async filepath => fsRemove(filepath));
};

// TODO: service function to remove all projects
export const removeAllProjects = () => async (dispatch) => {
    if (isWeb) { return; }

    const idTitle = { id: 'Removing projects', title: 'Removing projects' };

    await synchronizer.addItem({
        ...idTitle,
        actions: [{
            fn: async (syncEvents) => {

                const prIds = (await dispatch(findUserDocs({
                    ...LIST_OPTIONS.projects,
                    fields: ['_id']
                })))
                    // .filter(doc => !(
                    //     [
                    //         'PRJu0dj3aFgAFjFW',
                    //         'PRJu0dj3aSKHdyJf',
                    //         'PRJu0dj3aH44m6L6',
                    //         'PRJu0dj3aLruJaci'
                    //     ].includes(doc._id)
                    // ))
                    .map(doc => doc._id);

                let ess = [];
                let fls = [];
                let prs = [];

                console.log('prIds', prIds.length);
                if (syncEvents && syncEvents.setInit) {
                    syncEvents.setInit(prIds.length);
                }

                await asyncForEach(prIds, async (projectId) => {
                    if (syncEvents && syncEvents.addProgress) {
                        syncEvents.addProgress(1);
                    }

                    const files = await dispatch(scanProjectFiles(projectId, false));
                    if (files.length) {
                        const episodes = await dispatch(fetchEpisodesByVideoId(
                            files.map(doc => doc._id)
                        ));
                        fls = [...fls, ...files.map(doc => doc._id)];

                        if (episodes.length) {
                            const ids = [
                                ...episodes.map(doc => doc._id),
                                ...episodes.map(doc => doc.seasonId),
                                ...episodes.map(doc => doc.seriesId),
                            ].filter(id => !!id);

                            ess = [...ess, ...ids];

                            console.log('delete e, s, s', await dispatch(removeUserDocs(ids)));
                        }

                        const errors = await dispatch(removeFile(files.map(doc => doc._id), projectId));
                        console.log('remove f err', errors, files.map(doc => doc._id));
                        if (!errors.length) {
                            prs.push(projectId);
                        }
                    } else {
                        prs.push(projectId);
                    }
                });

                console.log('delete ess', ess);
                // console.log('delete prs', prs.length);
                console.log('remove projects', await dispatch(removeUserDocs(prs)));

                // const idMap = await dispatch(checkProjectsOnDrive(syncEvents));
                // console.log('idMap', idMap);
                // if (idMap.length) {
                //     console.log('notFound', idMap.filter(item => !item.path));
                //     const notFoundCount = idMap.filter(item => !item.path).length;
                //     const answer = !!notFoundCount && window.confirm(
                //         `${notFoundCount} of ${idMap.length} project(s) not found on your drive.\n`
                //         + 'Would you like to download them?'
                //     );
                //
                //     console.log(`run sync for ${idMap.filter(item => answer || !!item.path).length} projects`, idMap.filter(item => answer || !!item.path));
                //     // idMap
                //     //     .filter(item => answer || !!item.path)
                //     //     .forEach(item => dispatch(reSyncProject(item.id)));
                // }
            },
            title: 'Scanning folders ...',
            type: 'preparing',
            ignorePreviousErrors: true,
            autoComplete: false,
        }]
    });
};
