import PropTypes from 'prop-types';
import React from 'react';
import { prettyDate } from '../../../utils/format';

const SeriesListItemDetails = ({ item }) => (
    <>
        <div key="created">
            {`Created: ${prettyDate(item.created)}`}
        </div>
        <div key="videoNumber">
            {/* TODO: Move numberOfVideos to item._totals and make it calculable as _totals from Project */}
            {`Number of Videos: ${item.numberOfVideos || 0}`}
        </div>
        <div key="playlistLevel">
            Playlist level: 1
        </div>
    </>
);
SeriesListItemDetails.propTypes = {
    item: PropTypes.shape({
        created: PropTypes.string,
        _totals: PropTypes.shape({
            fileCount: PropTypes.shape({}),
        }),
    }),
};

export default SeriesListItemDetails;
