import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TDocFile } from '../constants/docs';
import * as projectActions from '../modules/projects/actions';
import { scanProjectFiles } from '../utils/desktop/new_file_search';
import { TSelectedFile } from '../utils/files/files';
import { addFilesToProject } from '../utils/files/filesCommon';


type TUseProjectFilesApiProps = {
  projectId: string
}
type TUseProjectFilesApiResult = {
  findFiles: () => Promise<TDocFile[]>
  addFiles: (files: TSelectedFile[], options: { move: boolean }) => Promise<boolean>
  removeFile: (fileIds: string[]) => Promise<string[]>
}

export const useProjectFilesApi = ({ projectId }: TUseProjectFilesApiProps): TUseProjectFilesApiResult => {
  const dispatch = useDispatch();


  const findFiles = useCallback(async () => {
    return await dispatch<Promise<TDocFile[]>>(scanProjectFiles(projectId, false));
  }, [dispatch, projectId]);

  const addFiles = useCallback(async (files: TSelectedFile[], options: { move: boolean }) => {
    await dispatch(addFilesToProject(projectId, files, options));
    return true;
  }, [dispatch, projectId]);

  const removeFile = useCallback(async (fileIds: string[]) => {
    return dispatch<Promise<string[]>>(projectActions.removeFile(fileIds, projectId));
  }, [dispatch, projectId]);


  return {
    findFiles,
    addFiles,
    removeFile,
  };
}
