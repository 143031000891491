import groupBy from 'lodash/groupBy';
import {
  DOC_TYPE_PROJECT,
  DOC_TYPE_SERIES
} from '../constants/docs';
import { DOC_TYPES_FILES } from '../constants/pouchDB';
import { getUserDB } from '../new/dbUtils';
import {
  doFindDocs,
  findDocs,
  readDoc,
  readDocs,
  removeDoc,
  removeDocs,
  writeDoc,
  writeDocs
} from '../utils/pouchDB/general';


/**
 *  Insert file documents into the user DB
 * @param {string} id Document Id
 * @return {Promise<{ _id: string, type: string, ...}>} PouchDB Document
 * */
export const readUserDoc = id => async dispatch => readDoc(id, dispatch(getUserDB()));
export const readUserDocs = ids => async dispatch => readDocs(ids, dispatch(getUserDB()));

/**
 * Remove all reserved fields from document which project uses in different components
 * @param {TDocument & Record<string, unknown>} doc
 * @return {TDocument}
 */
export const removeReservedFields = (doc) => {
  let result = { ...doc };
  ['path', '_totals'].forEach(f => {
    if (result[f]) {
      delete result[f];
    }
  })
  return result;
}

export const writeUserDoc = doc => async dispatch => writeDoc(
    removeReservedFields(doc),
    dispatch(getUserDB())
);
export const writeUserDocs = docs => async dispatch => writeDocs(
    docs.map(doc => removeReservedFields(doc)),
    dispatch(getUserDB())
);

export const updateUserDoc = values => async (dispatch) => {
    const founded = await dispatch(readUserDoc(values._id));
    if (!founded || !founded._id) {
        throw new Error(`Document with id "${values._id}" not found`);
    }
    return dispatch(writeUserDoc({
        ...founded,
        ...values,
        _rev: founded._rev || values._rev,
    }));
};

export const removeUserDoc = id => async dispatch => removeDoc(id, dispatch(getUserDB()));
export const removeUserDocs = ids => async dispatch => removeDocs(ids, dispatch(getUserDB()));


export const findUserDocs = findData => async dispatch => findDocs(findData, dispatch(getUserDB()));

/**
 * @param {string} text
 * @returns {Promise<Record<string, Array<TDocumentWithTitle>>>}
 */
export const quickSearch = text => async (dispatch) => {
  const db = dispatch(getUserDB());

  const docs = await doFindDocs({
    selector: {
      $and: [
        { type: { $in: [DOC_TYPE_PROJECT, DOC_TYPE_SERIES, ...DOC_TYPES_FILES] } },
        {
          $or: [
            { _id: text },
            { title: { $regex: new RegExp(text, 'i') } }
            /*
            { description: { $regex: new RegExp(text, 'i') } },
            { keywords: { $regex: new RegExp(text, 'i') } },
            */
          ]
        }
      ]
    }
  }, db);

  // MI-167: If I search by asset ID it should return the project associated to that asset.
  const modified = docs.map(doc => {
    if (DOC_TYPES_FILES.includes(doc.type) && doc._id === text) {
      return {
        ...doc,
        type: DOC_TYPE_PROJECT,
        _id: doc.projectId,
      };
    }
    return doc;
  })

  return groupBy(modified, item => item.type);
};
