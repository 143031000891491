import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './spinner.module.scss';
import { Icon } from '../Icon';

const SpinnerIcon = ({ big = false, full = false, withSpace, className }) => (
    <>
        {withSpace && ' '}
        <Icon icon={faSpinner} className={cn(styles.loading, {
          [styles.big]: big,
          [styles.full]: full,
        }, className)} />
    </>
);
SpinnerIcon.propTypes = {
    withSpace: PropTypes.bool,
    className: PropTypes.string,
};

const Spinner = ({ darkBackground = false, ...rest }) => (darkBackground
    ? (
        <div className={styles.darkBackground}>
            <SpinnerIcon {...rest} />
        </div>
    )
    : (
        <>
            <SpinnerIcon {...rest} />
        </>
    ));

Spinner.propTypes = {
    className: PropTypes.string,
    withSpace: PropTypes.bool,
    darkBackground: PropTypes.bool,
    big: PropTypes.bool,
};

export default Spinner;
