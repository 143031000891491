const suffixProd = '';
export const suffixDev = 'dev';
const suffixTest = 'test';


const isProd = instance => !instance || instance === 'production';
const isDev = instance => instance && instance === 'development';
const isTest = instance => instance && instance === 'test';

const getSuffix = (instance) => {
    if (isDev(instance)) {
        return suffixDev;
    }
    if (isProd(instance)) {
        return suffixProd;
    }
    if (isTest(instance)) {
        return suffixTest;
    }
    throw new Error('Instance is undefined');
};

const configs = {
    appName: process.env.REACT_APP_APP_NAME,
    instance: process.env.REACT_APP_INSTANCE,
    isProd: isProd(process.env.REACT_APP_INSTANCE),
    isDev: isDev(process.env.REACT_APP_INSTANCE),
    suffix: getSuffix(process.env.REACT_APP_INSTANCE),
    backendApiUri: process.env.REACT_APP_BACKEND_API_URI,
    couchDbUri: process.env.REACT_APP_COUCHDB_URI,
    couchDbSessionTimeout: process.env.REACT_APP_COUCHDB_SESSION_TIMEOUT,
    desktopSecretCode: process.env.DESKTOP_APP_SECRET_CODE,
    frontEndLocation: process.env.DESKTOP_APP_FRONTEND_LOCATION,
};

export default configs;
