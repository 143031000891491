import React from 'react';
import { DialogProvider } from './Dialog';
import { RecentProvider } from './Recent';
import { UserProvider } from './User';
import { UserDataProvider } from './UserData';
import { UserDbProvider } from './UserDB';


export const AllProviders: React.FC = ({ children }) => (
  <UserProvider>
    <UserDbProvider>
      <RecentProvider>
        <UserDataProvider>
          <DialogProvider>
            {children}
          </DialogProvider>
        </UserDataProvider>
      </RecentProvider>
    </UserDbProvider>
  </UserProvider>
)
