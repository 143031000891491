import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import { ButtonNew } from '../ButtonNew';
import { WindowContainer } from '../WindowContainer';
import styles from './dialog.module.scss';


type TAction = {
  caption: string
  handler: () => void | Promise<void>
}
export type TDialogProps = {
  width?: number,
  title?: string
  description?: ReactNode
  actions?: TAction[]
  footer?: ReactNode
  handleClose?: () => void
  classNameWindow?: string
  classNameTitle?: string
  classNameDescription?: string
  classNameActions?: string
  classNameAction?: string
  classNameFooter?: string
}


export const Dialog: FC<TDialogProps> = (props: TDialogProps) => {
  const {
    width,
    title,
    description,
    actions,
    footer,
    handleClose,
    classNameWindow,
    classNameTitle,
    classNameDescription,
    classNameActions,
    classNameAction,
    classNameFooter
  } = props;

  return (
    <WindowContainer width={width} windowClassName={classNameWindow} closeAction={handleClose}>
      {title && (<h3 className={cn(styles.header, classNameTitle)}>{title}</h3>)}
      {description && (
        <div className={cn(styles.description, classNameDescription)}>{description}</div>
      )}
      <div className={cn(styles.actions, classNameActions)}>
        {actions?.map(({ caption, handler }, ind) => (
          <ButtonNew
            key={caption ?? ind}
            onClick={handler}
            className={cn(styles.action, classNameAction)}
          >
            {caption}
          </ButtonNew>
        ))}
      </div>
      {footer && (<div className={cn(styles.footer, classNameFooter)}>{footer}</div>)}
    </WindowContainer>
  );
}
