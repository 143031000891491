// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getElectron = (): any => {
    if (window.require) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return window.require('electron');
    }
    return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isElectron = (): any => getElectron() !== null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getElectronProp = (propName: string): any => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const electron = getElectron();
    if (electron) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
        return electron.remote[propName];
    }
    return null;
};
