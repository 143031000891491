import { emitUserError } from '../constants/globals';
import { closeDBs } from '../new/dbCloseUtils';

export const GENERAL_ACTION_TYPES = {
    authClear: 'USERS_AUTH_CLEAR',
    // errorMessage: 'ERROR_MESSAGE',
};

export const actionAuthError = (message, title = '') => (dispatch) => {
    if (message) {
        emitUserError(message, title);
    }
    dispatch(closeDBs());
    dispatch({
        type: GENERAL_ACTION_TYPES.authClear
    });
};
