import { ENDPOINTS } from '../constants/general';
import { api } from '../constants/globals';

export const apiAllUsersDetails = async () => {
    // Response: { users: [{ user_id: 'u0dj3a', login: 'test 007', name: 'test 007', email: 'e@ma.il' }] }
    const response = await api.apiGet(ENDPOINTS.getAllUsersDetails);
    if (!response || !response.users) {
        throw new Error(`Wrong GetAllUserDetails response:\n${JSON.stringify(response)}`);
    }

    return response.users;
};

export const apiSendByEmails = async (subject, content, emails) => {
    // Response: {}
    const response = await api.apiPost(ENDPOINTS.communications.sendByEmails, {
        subject,
        content,
        emails
    });

    if (!response || !response.message) {
        throw new Error(`Wrong apiSendByEmails response:\n${JSON.stringify(response)}`);
    }

    return response.message;
};
