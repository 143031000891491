import { DOC_TYPE_FILE_FINISH_VIDEO, DOC_TYPE_PROJECT } from '../../constants/docs';
import { LIST_OPTIONS } from '../../constants/pouchDB';
import { GENERAL_ACTION_TYPES } from '../general';

const initialState = {
    ...LIST_OPTIONS.projects,
    list: [],
    readDate: null,
};

export const ACTION_TYPES = {
    projects: {
        success: 'PROJECTS_FETCH_SUCCESS',
        filter: 'PROJECTS_FILTER',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.projects.success: {
        return {
            ...state,
            list: action.list.map(project => ({
                ...project,
                finishedVideos: (project[DOC_TYPE_FILE_FINISH_VIDEO] || []).length
            })),
            readDate: Date.now(),
        };
    }

    case ACTION_TYPES.projects.filter: {
        return {
            ...state,
            options: {
              selector: {
                $and: [
                  { type: { $eq: DOC_TYPE_PROJECT } },
                  ...action.selector
                ]
              }
            }
        };
    }

    case GENERAL_ACTION_TYPES.authClear: {
        return { ...initialState };
    }

    default: {
        return state;
    }
    }
};

export default reducer;
