import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { TDocFile } from '../../../constants/docs';
import { TDocPut } from '../../../helpers/types-db';
import { apiShareGetDocument } from '../../../modules/share/api';
import { RecentContext } from '../../../Providers/Recent';
import { UserDbContext } from '../../../Providers/UserDB';
import { isSharing } from '../../../utils/webSettings';
import FinishVideo from './FinishVideo/FinishVideo';
import FilePages from './FinishVideo/Pages/FilePages';
import { useParams, useRouteMatch } from 'react-router-dom';
import { isFinishFile } from '../../../constants/pouchDB';


export const FilePage: FC = () => {
  const { readUserDoc, writeUserDoc } = useContext(UserDbContext);
  const { addRecent } = useContext(RecentContext);
  const rm = useRouteMatch()

  const [file, setFile] = useState({} as TDocFile);
  const { sharingId, fileId } = useParams<{ sharingId?: string, fileId: string }>();


  const readFile = useCallback(async (): Promise<TDocFile | undefined> => {
    if (fileId) {
      const f = await (
        isSharing()
          ? apiShareGetDocument(fileId)
          : readUserDoc(fileId)
      ) as TDocFile;

      setFile(f);

      return f;
    }

    return undefined;
  }, [readUserDoc, fileId]);

  const saveDoc = useCallback(async (values: TDocPut<TDocFile>) => {
    if (!isSharing()) {
      await writeUserDoc(values)
      await readFile()
    }
  }, [writeUserDoc, readFile]);


  useEffect(() => {
    void readFile().then(f => {
      if (f) {
        addRecent({
          type: f.type,
          title: f.title,
          to: rm.url
        })
      }
    })
  }, [addRecent, readFile, rm.url]);


  return (
    <>
      {!!file._id && isFinishFile(file.type) && (
        <>
          <FinishVideo
            file={file}
            sharingId={sharingId}
            readFile={readFile}
            saveFile={!sharingId ? saveDoc : null}
          />
          <FilePages
            file={file}
            sharingId={sharingId}
            readFile={readFile}
            saveFile={!sharingId ? saveDoc : null}
          />
        </>
      )}
    </>
  );
}
