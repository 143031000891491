import React from 'react';
import Button from '../../../../../components/Button/Button';
import styles from './DownloadBlock.module.css';

const DownloadBlock = ({ title, image, href }) => (
    <div className={styles.container}>
        <Button
            color={Button.COLOR.NONE}
            href={href}
        >
            <img src={image} alt={`img-${title}`} className={styles.image} />
            <h2>{title}</h2>
        </Button>
    </div>
);

export default DownloadBlock;
