import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkPublishMedia } from '../../../utils/permissions';
import StatusIcon from '../StatusIcon/StatusIcon';
import icon from './icon_youtube.svg';

class IconFileUploadToYoutube extends Component {
    static propTypes = {
        youtubeId: PropTypes.string,
        className: PropTypes.string,
        canPublishMedia: PropTypes.bool,
    };

    render() {
        const { canPublishMedia } = this.props;

        if (canPublishMedia) {
            const { youtubeId, className } = this.props;

            const statusIcon = (
                <StatusIcon
                    enabled={!!youtubeId}
                    icon={icon}
                    altOn="not uploaded"
                    altOff="uploaded"
                    title={youtubeId}
                    className={className}
                />
            );

            if (youtubeId) {
                return (
                    <a href={`https://www.youtube.com/watch?v=${youtubeId}`} target="_blank" rel="noopener noreferrer">
                        {statusIcon}
                    </a>
                );
            }

            return statusIcon;
        }

        return '';
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    canPublishMedia: dispatch(checkPublishMedia()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconFileUploadToYoutube);
