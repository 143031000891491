import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import React from 'react';
import { Icon } from '../Icon';
import styles from './search.module.scss';
import Input from '../Input/Input';


type TSearchProps = {
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  loading?: boolean
  inputProps?: { className?: string } & Record<string, unknown>
  containerClassName?: string
}


export const Search: React.FC<TSearchProps> = (props) => {
  const {
    value,
    loading = false,
    containerClassName,
    onChange,
    onBlur,
    inputProps = {},
    children,
  } = props;


  return (
    <div className={cn(styles.container, containerClassName)}>
      {loading && <Icon icon={faSpinner} className={styles.loading} />}
      <Input
        id="search"
        form={{}}
        field={{ value, onChange, onBlur }}
        spaceBottom={Input.SPACE_BOTTOM.NONE}
        {...inputProps}
        className={cn(styles.input, { [styles.iconOff]: loading }, inputProps?.className)}
      />
      {children}
    </div>
  );
}
