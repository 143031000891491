import React from 'react';
import image from './img/image_folders.svg';
import imageHand from '../../../common/Project/helpPage/Pages/Page4/image_hand_2.png';
import styles from './Folder.module.css';

const Folder = () => (
    <div className={styles.container}>
        <div className={styles.left}>
            <h1>
                Never create a folder again
                <img src={imageHand} alt="hands" className={styles.hands}/>
            </h1>
            <div className={styles.leftText}>
                This is a tool that takes the hassle out of creating projects.
                Naming, dating, titling folders is a drag even if you have a
                good system figured out.
            </div>
            <div className={styles.leftText}>
                It will keep track of all your projects on your local drive. It will
                even create the folders for you!
            </div>
            <div className={styles.leftText}>
                Easily find media for projects months ago. Clear your hard
                drive by keeping backups in the cloud.
            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.rightText}>
                A folder is created on your local
                drive
            </div>
            <img src={image} alt="folder tree" />
            <div className={styles.rightText}>
                Your file is renamed with an ID so
                you can always find it!!
            </div>
        </div>
    </div>
);

export default Folder;
