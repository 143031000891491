export const ACTION_TYPE_OTHER = 'other';

export const SyncActionTypes = {
  other: 'other',
  copy: 'copy',
  download: 'download',
  upload: 'upload',
  remove: 'remove',
};

export const RUNNING_LIMIT = 2;

export const EVENTS_NAMES = {
  stateUpdate: 'state-update',
  syncRun: 'sync-started',
  itemsAdd: 'items-added',
  itemRun: 'item-started',
  itemComplete: 'item-complete',
  itemError: 'item-error',
  actionAdd: 'actions-added',
  actionRun: 'action-started',
  actionComplete: 'action-complete',
  actionError: 'action-error',
};

export const debugLogger = {
  debug: (...args) => {
    // IE 10 does not support console.debug
    // eslint-disable-next-line no-console
    console.log.call(console, `[Sync ${new Date().toLocaleString()}]`, ...args); // TODO
    // (console.debug || console.log).call(console, `[Sync ${new Date().toLocaleString()}]`, ...args);
  },
  // eslint-disable-next-line no-console
  warn: (...args) => console.warn(`[Sync ${new Date().toLocaleString()}]`, ...args),
  // eslint-disable-next-line no-console
  error: (...args) => console.error(`[Sync ${new Date().toLocaleString()}]`, ...args),
};

export const getBlankProgress = () => ({
  total: 1,
  current: 0,
  percentage: 0,
  title: '',
  started: 0,
  ended: 0,
  errorMessage: '',
});

export const getBlankAction = () => ({
  itemId: '',
  fn: () => {
  },
  progress: getBlankProgress(),
  title: '',
  type: 'other',
  icon: null,
  ignorePreviousErrors: false,
  hasSpeed: false,
  autoComplete: true,
});
