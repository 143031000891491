import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import React, { useCallback } from 'react';
import Input from '../../../../components/Input/Input';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import Button from '../../../../components/Button/Button';
import { ROUTE } from '../../../../constants/general';
import { emitUserError, emitUserInfo } from '../../../../constants/globals';
import { apiResetPassword } from '../../../../modules/user/actions';

const getInitialValues = () => ({ email: '', reset_password_url: '' });
const validationSchema = object().shape({ email: string().required().email() });

const ResetPassword = () => {
    const handleSubmit = useCallback(async ({ email }, { setSubmitting }) => {
        try {
            const res = await apiResetPassword(email);
            emitUserInfo(res);
        } catch (e) {
            emitUserError(e.message, 'Reset password');
        } finally {
            setSubmitting(false);
        }
    }, []);

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={({ isSubmitting }) => (
                <Form>
                    <Field
                        name="email"
                        component={Input}
                        labelWidth={Input.LABEL_WIDTH.FULL}
                        label="Email"
                        required
                    />
                    <FormButtons>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            color={Button.COLOR.PRIMARY}
                        >
                            Reset
                        </Button>
                        <span>or</span>
                        <Button
                            link={ROUTE.login}
                            color={Button.COLOR.PRIMARY}
                        >
                            Login
                        </Button>
                    </FormButtons>
                </Form>
            )}
        />
    );
};

export default ResetPassword;
