import PropTypes from 'prop-types';
import React from 'react';
import { prettyDate } from '../../../../utils/format';

const EpisodeListItemDetails = ({ item }) => (
    <>
        <div key="created">
            {`Created: ${prettyDate(item.created)}`}
        </div>
    </>
);
EpisodeListItemDetails.propTypes = {
    item: PropTypes.shape({
        created: PropTypes.string,
    }),
};

export default EpisodeListItemDetails;
