import React from 'react';
import BarItem from '../../Component/BarItem/BarItem';
import image from './image_HitSelectDrive.svg';
import configs from '../../../../../../../../constants/configs';

const HitSelectDrive = () => (
    <BarItem
        number={2}
        text={(
            <>
                Sellect a drive where you
                <br />
                would like
                {` ${configs.appName} `}
                to
                <br />
                store your media
            </>
        )}
        image={image}
    />
);

export default HitSelectDrive;
