import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Hr.module.css';

const Hr = ({ className }) => (
    <hr className={cn(styles.container, className)} />
);

Hr.propTypes = {
    className: PropTypes.string,
};
export default Hr;
