import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import createRootReducer from './modules';

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [routerMiddleware(history), thunk];

// Logging Middleware
const logger = createLogger({ level: 'info', collapsed: true });

// Skip redux logs in console during the tests
if (process.env.NODE_ENV !== 'test') {
    middleware.push(logger);
}

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(
        applyMiddleware(...middleware),
        ...enhancers
    )
);

export default store;
