import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../../constants/general';
import styles from './RegisterSuccess.module.css';

const RegisterConfirmed = () => (
    <div className={styles.wrapper}>
        <h2>
            You’re all set. Let the revolution begin!
        </h2>
        <p>
            <Link to={ROUTE.login}>Log into to begin</Link>
        </p>
    </div>
);

export default RegisterConfirmed;
