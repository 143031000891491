import React from 'react';
import Button from '../../../../components/Button/Button';
import styles from './TheFirst.module.css';

const TheFirst = () => (
    <div className={styles.container}>
        <div className={styles.textBlock}>
            <div>
                The tool that let’s creators own and manage their content.
            </div>
            <div>
                Let the revolution begin!
            </div>
        </div>
        <Button
            color={Button.COLOR.WHITE}
            href="#Download"
            className={styles.button}
            blank={false}
        >
            Download to Begin
        </Button>
    </div>
);

export default TheFirst;
