import { faRss } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import { FormikActions } from 'formik/dist/types';
import React, { useCallback, useContext, useState } from 'react';
import Button from '../../../../../components/Button/Button';
import { Icon } from '../../../../../components/Icon';
import Input from '../../../../../components/Input/Input';
import S3img from '../../../../../components/S3img/S3img';
import { SeriesRssSettings } from '../../../../../components/SeriesRssSettings';
import YoutubeAnalytics from '../../../../../components/YoutubeAnalytics/YoutubeAnalytics';
import { TDocSeries } from '../../../../../constants/docs';
import { ROUTE, SERIES_SCHEMA } from '../../../../../constants/general';
import { SERIES_TYPE_IDS, SERIES_TYPES } from '../../../../../constants/pouchDB';
import { SeriesContext } from '../../../../../Providers/Series';
import { UserContext } from '../../../../../Providers/User';
import { prettyDate } from '../../../../../utils/format';
import { ANALYTICS_TYPES, CATEGORIES } from '../../../../../utils/youtube';
import styles from './series-details.module.scss';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';


export const SeriesDetails: React.FC = () => {
  const { series, saveSeries, deleteSeries, rssActivated } = useContext(SeriesContext);
  const { permissions: { canPublishMedia, canUseCloud } } = useContext(UserContext);

  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [showRssSettings, setShowRssSettings] = useState(false);

  const toggleEdit = useCallback((resetForm?: () => void) => {
    if (edit && resetForm) {
      resetForm();
    }
    setEdit(state => !state);
  }, [edit]);

  const handleSubmit = useCallback(
    async (values: Partial<TDocSeries>, { setSubmitting }: FormikActions<TDocSeries>) => {
      setSubmitting(true);
      try {
        await saveSeries(values);
        toggleEdit();
      } finally {
        setSubmitting(false);
      }
    },
    [saveSeries, toggleEdit]
  );

  const handleDelete = useCallback(async () => {
    const answer = window.confirm('Series will be removed with all related Seasons and Episodes.\nContinue?');
    if (answer) {
      if (await deleteSeries()) {
        history.push(ROUTE.series.root);
      }
    }
  }, [deleteSeries, history]);

  const toggleRssSettings = useCallback(() => {
    if (canPublishMedia) {
      setShowRssSettings(s => !s);
    }
  }, [canPublishMedia])


  return (
    <>
      {showRssSettings && <SeriesRssSettings onClose={toggleRssSettings} />}
      <h1 className="left">
        <Button
          link={ROUTE.series.root}
          color={Button.COLOR.NONE}
          className={cn('light', styles.seriesListButton)}
        >
          <h1>Series</h1>
        </Button>
        {' '}
        »
        {' '}
        {series.title}
      </h1>
      <div className={styles.formWrapper}>
        <S3img src={series.image} className={styles.image} />
        <Formik
          initialValues={series}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          validationSchema={SERIES_SCHEMA}
          enableReinitialize
          onSubmit={handleSubmit}
          render={({ values, resetForm, setFieldValue, isSubmitting }) => (
            <Form>
              <div className={styles.editContainer}>
                <div>
                  <Field
                    name="_id"
                    component={Input}
                    label="Series ID:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  />
                  <Field
                    label="Created:"
                    component={Input}
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    field={{ value: prettyDate(series.created) }}
                    readOnly
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  />
                  <Field
                    label="Playlist level:"
                    component={Input}
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    field={{ value: 1 }}
                    readOnly
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  />
                  <Field
                    name="title"
                    component={Input}
                    label="Title:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly={!edit}
                    editClick={toggleEdit}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    spellCheck
                  />
                  <Field
                    name="description"
                    component={Input}
                    type={Input.TYPE.TEXTAREA}
                    label="Description:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly={!edit}
                    editClick={toggleEdit}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    spellCheck
                  />
                  <Field
                    name="keywords"
                    component={Input}
                    type={Input.TYPE.MULTI_SELECT}
                    maxLength={500}
                    label="Keywords:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    readOnly={!edit}
                    editClick={toggleEdit}
                    showCopyButton
                    inputProps={{ spellCheck: true }}
                  />
                  <Field
                    name="seriesType"
                    component={Input}
                    type={Input.TYPE.DROPDOWN}
                    label="Type:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly={!edit || !!series.seriesType}
                    editClick={!series.seriesType ? toggleEdit : undefined}
                    data={series.seriesType ? [series.seriesType] : Object.keys(SERIES_TYPES)}
                    defaultValue={series.seriesType}
                    onChange={(value: string) => setFieldValue('seriesType', value)}
                  />
                  {canPublishMedia && values.seriesType === SERIES_TYPE_IDS.video && edit
                    ? (
                      <Field
                        name="youtube.categoryId"
                        component={Input}
                        type={Input.TYPE.DROPDOWN}
                        label="Youtube category:"
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        readOnly={!edit}
                        data={CATEGORIES}
                        valueField="id"
                        textField="title"
                        onChange={(value: { id: number, title: string }) => setFieldValue(
                          'youtube.categoryId',
                          value.id
                        )}
                      />
                    )
                    : (
                      <Field
                        label="Youtube category:"
                        component={Input}
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        field={{
                          value: CATEGORIES.reduce((acc, val) => {
                            if (
                              acc === ''
                              && val
                              && values.youtube
                              && values.youtube.categoryId
                              && val.id === values.youtube.categoryId
                            ) {
                              return val.title;
                            }
                            return acc;
                          }, '')
                        }}
                        readOnly
                        editClick={toggleEdit}
                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      />
                    )
                  }
                  {canPublishMedia && values.seriesType === SERIES_TYPE_IDS.video && (
                    <Field
                      name="youtube.channelId"
                      label="Youtube channel Id:"
                      component={Input}
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      readOnly={!edit}
                      editClick={toggleEdit}
                    />
                  )}
                  {edit && (
                    <Field
                      name="seasons"
                      label="Show seasons:"
                      component={Input}
                      type={Input.TYPE.SWITCH}
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      readOnly={!edit}
                      editClick={toggleEdit}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('seasons', e.target.value)}
                    />
                  )}
                  {edit && canUseCloud && (
                    <Field
                      name="image"
                      type={Input.TYPE.FILE}
                      component={Input}
                      label="Image:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      readOnly={!edit}
                      editClick={toggleEdit}
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    />
                  )}
                </div>
                <div className={styles.analyticsContainer}>
                  <div className={styles.saveWrapper}>
                    {edit && (
                      <Button small type="submit" className={styles.toggleEdit} disabled={isSubmitting}>
                        Save
                      </Button>
                    )}
                    {!edit && canPublishMedia && (
                      <Button
                        small
                        color={Button.COLOR.GRAY}
                        onClick={toggleRssSettings}
                        className={styles.toggleEdit}
                      >
                        <Icon
                          icon={faRss}
                          className={cn(styles.rssIcon, {
                            [styles[`rssIcon-active-${values.seriesType}`]]: rssActivated
                          })}
                        />
                        {` RSS`}
                      </Button>
                    )}
                    <Button
                      small
                      color={edit ? Button.COLOR.GRAY : Button.COLOR.PRIMARY}
                      onClick={() => toggleEdit(resetForm)}
                      className={styles.toggleEdit}
                      disabled={isSubmitting}
                    >
                      {edit ? 'Cancel' : 'Edit'}
                    </Button>
                    {edit && (
                      <Button
                        small
                        color={Button.COLOR.WARNING}
                        onClick={handleDelete}
                        className={styles.toggleEdit}
                        disabled={isSubmitting}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                  <div>
                    <YoutubeAnalytics
                      showTitle
                      analyticType={ANALYTICS_TYPES.channel}
                      id={series.youtube?.channelId}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </>
  )
}
