import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ShareSettingsButton } from '../../../../components/ShareSettingsButton';
import Button from '../../../../components/Button/Button';
import IconUploadedToCloud from '../../../../components/IconUploadedToCloud/IconUploadedToCloud';
import IconVideoInPlaylist from '../../../../components/IconVideoInPlaylist/IconVideoInPlaylist';
import Input from '../../../../components/Input/Input';
import S3img from '../../../../components/S3img/S3img';
import ShareSettings from '../../../../components/ShareSettings/ShareSettings';
import IconFileUploadToYoutube from '../../../../components/StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';
import VideoPlayer from '../../../../components/VideoPlayer/VideoPlayer';
import { WindowContainer } from '../../../../components/WindowContainer';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../constants/docs';
import { FILE_SCHEMA, ROUTE } from '../../../../constants/general';
import { SHARE_TYPES } from '../../../../constants/share';
import { getDownloadUrl } from '../../../../utils/cloud/cloud';
import { prettyDate } from '../../../../utils/format';
import { checkAllowCloud } from '../../../../utils/permissions';
import styles from './FinishVideo.module.css';

const getTitleCategoryName = (fileType) => {
  switch (fileType) {
  case DOC_TYPE_FILE_FINISH_VIDEO:
    return 'Videos';
  case DOC_TYPE_FILE_FINISH_AUDIO:
    return 'Audio';
  default:
    return '';
  }
};

class FinishVideo extends Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    sharingId: PropTypes.string,
    saveFile: PropTypes.func,
    readFile: PropTypes.func.isRequired,
    canUseCloud: PropTypes.bool,
  };

  state = {
    playURL: '',
    edit: false
  };

  componentDidMount() {
    this._mounted = true;
    this.init()
      .then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  preparePlayURL = async (cloud) => {
    const urlResponse = await getDownloadUrl(cloud);
    if (urlResponse && urlResponse.url) {
      if (this._mounted) {
        this.setState({
          playURL: urlResponse.url
        });
      }
    }
  };

  init = async () => {
    const { file } = this.props;
    this.preparePlayURL(file.cloud);
  };

  handleSubmit = async (values) => {
    const { file, readFile, saveFile } = this.props;

    try {
      await saveFile({ ...values, _id: file._id });
      readFile();
    } catch (err) {
      alert(err.message);
      console.error(err.message);
    }
  };

  toggleEdit = (resetForm) => {
    const { edit } = this.state;
    if (edit && resetForm) {
      resetForm();
    }
    if (this._mounted) {
      this.setState({ edit: !edit });
    }
  };

  showHideShareSettings = async () => {
    this.setState(state => ({ showShareSettings: !state.showShareSettings }));
  };

  render() {
    const { file, saveFile, canUseCloud, sharingId } = this.props;
    const { playURL, edit, showShareSettings } = this.state;

    // If Project already shared - we can not share the file
    const canShare = !sharingId;

    return (
      <div>
        <h1 className="left">
          <span className="light">{getTitleCategoryName(file.type)}</span>
          {' '}
          »
          {' '}
          {file.title}
        </h1>
        <div className={styles.videoDetails}>
          <div className={styles.mediaContainer}>
            <div className={styles.videoPlayer}>
              <VideoPlayer id={file._id} src={playURL} />
              <S3img
                src={file.image}
                className={styles.image}
              />
            </div>
          </div>
          <Formik
            initialValues={file}
            validationSchema={FILE_SCHEMA}
            enableReinitialize
            render={({ resetForm }) => (
              <Form>
                <div className={styles.editContainer}>
                  <div>
                    <Field
                      component={Input}
                      label="Status:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      type={Input.TYPE.LABEL}
                      field={{
                        value: (
                          <>
                            <IconUploadedToCloud cloud={file.cloud} />
                            {!sharingId && <IconVideoInPlaylist videoId={file._id} />}
                            {file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
                              <IconFileUploadToYoutube
                                youtubeId={file.youtube && file.youtube.youtubeId}
                              />
                            )}
                          </>
                        ),
                      }}
                      readOnly
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    />
                    <Field
                      name="_id"
                      component={Input}
                      label="ID:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      readOnly
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    />
                    <Field
                      component={Input}
                      label="Created:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      field={{ value: prettyDate(file.created) }}
                      type={Input.TYPE.LABEL}
                      readOnly
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    />
                    <Field
                      component={Input}
                      label="Project ID:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      type={Input.TYPE.LABEL}
                      readOnly
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      field={{
                        value: (
                          <Link
                            to={!sharingId
                              ? ROUTE.projects.id(file.projectId)
                              : ROUTE.share.project(sharingId)
                            }
                          >
                            {file.projectId}
                          </Link>
                        ),
                      }}
                    />
                    <Field
                      name="filename"
                      component={Input}
                      label="File name"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      readOnly
                      spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    />
                    {
                      edit && canUseCloud && (
                        <Field
                          name="image"
                          type={Input.TYPE.FILE}
                          component={Input}
                          label="Image:"
                          labelWidth={Input.LABEL_WIDTH.RIGHT}
                          labelClassName={styles.label}
                          readOnly={!edit}
                          spaceBottom={Input.SPACE_BOTTOM.SMALL}
                          uploadSuccess={img => this.handleSubmit({ image: img })}
                        />
                      )
                    }
                  </div>
                  {!!saveFile && (
                    <div className={styles.saveWrapper}>
                      <ShareSettingsButton
                        disabled={edit}
                        sharing={file.sharing}
                        type={SHARE_TYPES.file}
                        onClick={this.showHideShareSettings}
                      />
                      <Button
                        small
                        onClick={() => this.toggleEdit(resetForm)}
                        className={styles.toggleEdit}
                      >
                        {edit ? 'Cancel' : 'Edit'}
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          />
        </div>

        {showShareSettings && canShare && (
          <WindowContainer closeAction={this.showHideShareSettings}>
            <ShareSettings
              documentId={file._id}
              type={SHARE_TYPES.file}
            />
          </WindowContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishVideo);
