import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import imageArrow from './image_arrow.svg';
import styles from './ListPlaceholderProject.module.css';
import configs from '../../../../constants/configs';

const ListPlaceholderProject = ({ flag }) => (
    !flag && (
        <div className={styles.container}>
            <div>
                <h2 className={styles.textLine}>
                    To get started click Add Project. It will appear
                    here. There’s also create a project folder in your
                    {` ${configs.appName} `}
                    folder
                </h2>
                <h2 className={styles.textLine}>
                    We recommend creating a new project per episode
                </h2>
            </div>
            <img className={styles.imgArrow} src={imageArrow} alt="arrow" />
        </div>
    )
);

ListPlaceholderProject.propTypes = {
    flag: PropTypes.bool,
};

const mapStateToProps = ({ user: { dbUser: { helpFlags } } }) => ({
    flag: false,//(helpFlags && helpFlags[HELP_FLAG_CREATE_FIRST_PROJECT]) || false,
});

export default connect(mapStateToProps)(ListPlaceholderProject);
