import {path} from '../../constants/modules';

/**
 * Join all arguments together and normalize the resulting path.
 * Arguments must be strings. In v0.8, non-string arguments were silently ignored. In v0.10 and up, an exception is
 * thrown.
 *
 * @param paths paths to join.
 */
export const pathJoin = (...paths: string[]): string => path.join(...paths);

/**
 * Return the directory name of a path. Similar to the Unix dirname command.
 *
 * @param p the path to evaluate.
 */
export const pathDirname = (p: string): string => path.dirname(p);

/**
 * Return the last portion of a path. Similar to the Unix basename command.
 * Often used to extract the file name from a fully qualified path.
 *
 * @param p the path to evaluate.
 * @param ext optionally, an extension to remove from the result.
 */
export const pathBasename = (p: string, ext?: string): string => path.basename(p, ext);

/**
 * Return the extension of the path, from the last '.' to end of string in the last portion of the path.
 * If there is no '.' in the last portion of the path or the first character of it is '.', then it returns
 * an empty string
 *
 * @param p the path to evaluate.
 */
export const pathExtname = (p: string): string => path.extname(p);

/**
 * Returns an object from a path string - the opposite of format().
 * Example:
 *      path.parse('/home/user/dir/file.txt');
 * Returns:
 *      { root: '/', dir: '/home/user/dir', base: 'file.txt', ext: '.txt', name: 'file' }
 *
 * @param p path to evaluate.
 * @return Object { root: string, dir: string, base: string, ext: string, name }
 */
export const pathParse = (p: string) => path.parse(p);
