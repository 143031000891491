import FileFS from './desktop/FileFS';

window.URL = window.URL || window.webkitURL;

export const getVideoDuration = async (fileOrUrl: File | string): Promise<number | undefined> => new Promise((resolve, reject) => {
  try {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.ondurationchange = () => { // onloadedmetadata
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration)
    }
    video.onerror = function () {
      resolve(0)
    }
    video.src = typeof fileOrUrl === 'string'
      ? fileOrUrl
      : (fileOrUrl instanceof FileFS)
        ? `file://${fileOrUrl.path}`
        : window.URL.createObjectURL(fileOrUrl)
  } catch (e) {
    reject(e)
  }
})
