import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TDocFile, TDocProject } from '../../../constants/docs';
import { updateUserDoc } from '../../../handlers/userDB';
import * as projectActions from '../../../modules/projects/actions';
import { readUserProject } from '../../../utils/desktop/new_file_search';
import { ProjectDetails } from '../../../components/ProjectDetails';
import { useProjectFiles } from '../../../helpers/use-project-files';


const Project: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{projectId: string}>();

  const { addFiles, removeFile, findFiles } = useProjectFiles({ projectId });

  const readProject = useCallback(async () => {
    return dispatch(readUserProject(projectId, true)) as Promise<TDocProject>;
  }, [dispatch, projectId]);

  const saveProject = useCallback(async (values: TDocProject) => {
    dispatch(projectActions.saveProject(values));
  }, [dispatch]);

  const deleteProject = useCallback(async () => {
    // @ts-ignore
    return dispatch(projectActions.deleteProject(projectId)) as Promise<string[]>;
  }, [dispatch, projectId]);

  const saveFile = useCallback(async (values: TDocFile) => {
    dispatch(updateUserDoc(values));
  }, [dispatch]);


  return (
    <ProjectDetails
      // projectId={projectId}
      readProject={readProject}
      saveProject={saveProject}
      deleteProject={deleteProject}
      findFiles={findFiles}
      addFiles={addFiles}
      removeFile={removeFile}
      saveFile={saveFile}
    />
  );
}

export default Project;
