import File from "electron";
import { TDocumentTypeFile } from '../../constants/docs';
import { asyncForEach } from '../common';
import platform from '../platform';
import { isApplePackage } from '../sys';
import { TPathType } from './files';

export type TFileType = 'directory' | 'file' | 'package' | 'unknown'

interface Stats {
  dev: number;
  ino: number;
  mode: number;
  nlink: number;
  uid: number;
  gid: number;
  rdev: number;
  size: number;
  blksize: number;
  blocks: number;
  atimeMs: number;
  mtimeMs: number;
  ctimeMs: number;
  birthtimeMs: number;
  atime: Date;
  mtime: Date;
  ctime: Date;
  birthtime: Date;
}

export type TSelectedFile = {
  path?: string
  stat?: Stats
  pathType?: TFileType
  file: File,
  type: TDocumentTypeFile
  typeConfirmed?: boolean
  selected?: boolean
}


export const getPathType = async (stat: Stats, fullPath: string): Promise<TPathType> => {
  return 'file';
}

export const getSelectedFile = async (file: File, type: TDocumentTypeFile): Promise<TSelectedFile> => {
  return new Promise((resolve) => resolve({
    file,
    type,
    path: undefined,
    pathType: 'file',
    stat: undefined,
    typeConfirmed: false,
    selected: true,
  }));
}

export const getSelectedFiles = async (files: File[], type: TDocumentTypeFile): Promise<TSelectedFile[]> => {
  return await asyncForEach<File, TSelectedFile>(
    files,
    async file => await getSelectedFile(file, type)
  )
}


// export const getPathType = async (stat: any, fullPath: string): Promise<TPathType> => {
//   return 'unknown';
// }
