import React from 'react';
import HitConfigure from './Blocks/HitConfigure/HitConfigure';
import HitCreatingProject from './Blocks/HitCreatingProject/HitCreatingProject';
import HitDownload from './Blocks/HitDownload/HitDownload';
import HitSelectDrive from './Blocks/HitSelectDrive/HitSelectDrive';
import styles from './HiwProgressBar.module.css';

const HiwProgressBar = () => (
    <div className={styles.bar}>
        <HitDownload />
        <HitSelectDrive />
        <HitConfigure />
        <HitCreatingProject />
    </div>
);

export default HiwProgressBar;
