import React from 'react';
import PropTypes from 'prop-types';
import styles from './Anchor.module.css';

const Anchor = ({ children, id }) => (
    <div className={styles.anchor}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a id={id} className={styles.target} href="/#" />
        {children}
    </div>
);

Anchor.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
};

export default Anchor;
