import { formatDate } from '../format';
import { findFolderWithId, findFolderWithIdSync } from './fileSystem';
import { fsRemoveSync } from './fse';
import { pathJoin } from './path';
import { getProjectsRootPath, getProjectsRootPathSync } from './userSearch';
import { isWeb } from '../../constants/modules';

/** Find and Return path to project folder */
export const findProjectFolder = _id => async (dispatch) => {
  const founds = await findFolderWithId(await dispatch(getProjectsRootPath()), _id);
  return (founds && founds.length) ? founds[0] : null;
};
// TODO: replace it to async function
export const findProjectFolderSync = _id => (dispatch) => {
  const founds = findFolderWithIdSync(dispatch(getProjectsRootPathSync()), _id);
  return (founds && founds.length) ? founds[0] : null;
};

/** Find and Return path to project folder. If folder did not found new path will be returned */
export const getProjectFolder = ({ title, _id, created }) => async (dispatch) => {
  const result = await dispatch(findProjectFolder(_id));

  if (!result) {
    if (!!title && !!created) {
      const prPath = await dispatch(getProjectsRootPath());
      return pathJoin(prPath, `${formatDate(created)}_${title}_${_id}`);
    }
  }

  return result;
};
// TODO: replace it to async function
export const getProjectFolderSync = ({ title, _id, created }) => (dispatch) => {
  const result = dispatch(findProjectFolderSync(_id));

  if (!result) {
    if (!!title && !!created) {
      const prPath = dispatch(getProjectsRootPathSync());
      return pathJoin(prPath, `${formatDate(created)}_${title}_${_id}`);
    }
  }

  return result;
};

export const deleteProjectFolder = projectId => async (dispatch) => {
  if (!isWeb) {
    const founds = findFolderWithIdSync(dispatch(getProjectsRootPathSync()), projectId);
    if (founds && founds.length) {
      fsRemoveSync(founds[0]);
    }
  }
};
