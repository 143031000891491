import React, { Component } from 'react';
import { USER_EVENTS_NAMES, userEmitter } from '../../../constants/globals';
import { generateId } from '../../../utils/guids';
import UserEventItem from './UserEventItem';
import styles from './UserEventLayout.module.css';

const getMessage = (msg) => {
  if (!msg) {
    return 'Unknown error';
  }
  if (typeof msg === 'string') {
    return msg;
  }
  if (msg instanceof Error) {
    return msg.message;
  }
  if (typeof msg === 'object' && msg.message) {
    return msg.message;
  }
  try {
    return JSON.stringify(msg);
  } catch (e) {
    return msg;
  }
};

class UserEventLayout extends Component {
  state = { items: [] };

  componentDidMount() {
    userEmitter.on(USER_EVENTS_NAMES.message, this.onAddItem);
  }

  componentWillUnmount() {
    userEmitter.off(USER_EVENTS_NAMES.message);
  }

  onAddItem = ({ message, ...rest }) => {
    const key = generateId(5);
    this.setState(state => ({
      items: [
        ...state.items,
        {
          component: (
            <UserEventItem
              key={key}
              message={getMessage(message)}
              {...rest}
            />
          ),
          key
        }
      ]
    }));
    setTimeout(
      () => this.setState(state => ({ items: state.items.filter(it => it.key !== key) })),
      rest.timeout || 10000
    );
  };

  render() {
    const { items } = this.state;

    if (!items.length) {
      return '';
    }

    return (
      <div className={styles.container}>
        {items.map(it => it.component)}
      </div>
    );
  }
}

export default UserEventLayout;
