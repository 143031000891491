import { DOC_TYPE_SEASON, newDocument } from '../../constants/docs';
import { getUserDB } from '../../new/dbUtils';
import { LIST_OPTIONS } from '../../constants/pouchDB';
import { addFilter } from '../../utils/pouchDB/documents';
import { writeUserDoc, readUserDoc, findUserDocs, removeUserDocs } from '../../handlers/userDB';
import { fetchEpisodesBySeasonIds } from '../episodes/actions';
import { ACTION_TYPES } from './reducer';

// TODO: Need to delete related episodes if we are deleting season

const ACTIONS = {
    list: {
        clear: seasonId => ({type: ACTION_TYPES.list.clear, seasonId}),
        read: {
            success: (seriesId, list) => ({type: ACTION_TYPES.list.read.success, seriesId, list})
        }
    },
    setParents: seriesId => ({ type: ACTION_TYPES.setParents, seriesId }),
    current: {
        read: {
            success: current => ({type: ACTION_TYPES.current.read.success, current})
        }
    }
};


export const insert = values => async dispatch => {
  const doc = newDocument(DOC_TYPE_SEASON, values)
  return dispatch(writeUserDoc(doc));
};

export const newSeasonValues = seriesId => async (dispatch) => {
    if (!seriesId) {
        throw new Error('Series should be specified');
    }

    let number = 0;
    let keywords = '';

    const series = await dispatch(readUserDoc(seriesId));
    if (series) {
        keywords = series.keywords;

        const opt = {
            ...addFilter(LIST_OPTIONS.seasons, 'seriesId', seriesId, '$eq'),
            fields: ['number']
        };
        const docs = await dispatch(findUserDocs(opt));
        docs.forEach((doc) => {
            if (doc.number) {
                number = Math.max(number, doc.number);
            }
        });
    } else {
        throw new Error(`Series did not found by id "${seriesId}"`);
    }

    return {
        keywords,
        seriesId,
        number: number + 1
    };
};


export const fetchSeasons = seriesId => (dispatch, getState) => {
    dispatch(ACTIONS.setParents(seriesId));
    return dispatch(findUserDocs(getState().seasons));
};
export const readSeasonsList = seriesId => async (dispatch) => {
    const list = await dispatch(fetchSeasons(seriesId));
    dispatch(ACTIONS.list.read.success(seriesId, list));
};

export const fetch = id => (dispatch) => {
    const userDB = dispatch(getUserDB());

    return userDB
        .get(id)
        .then(doc => {
            return doc;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

/**
 * Remove season with related episodes
 * @param {string|string[]} ids season id(s)
 * @param {function} cb callback
 */
export const removeSeasons = (ids, cb) => async (dispatch) => {
    const seasonIds = typeof ids === 'string' ? [ids] : ids;
    const episodes = await dispatch(fetchEpisodesBySeasonIds(seasonIds));

    await dispatch(removeUserDocs([
        ...seasonIds,
        ...episodes.map(doc => doc._id),
    ]));

    if (cb) {
        await cb();
    }
};

export const fetchSesonsBySeriesId = seriesId => async (dispatch) => {
    const optEpisodes = {
        ...addFilter(LIST_OPTIONS.seasons, 'seriesId', seriesId, '$eq'),
        fields: ['_id']
    };
    return dispatch(findUserDocs(optEpisodes));
};
