import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTE } from '../../../constants/general';
import { emitUserInfo, uppy } from '../../../constants/globals';
import { SHARE_TYPES } from '../../../constants/share';
import { FilePage } from '../../common/FilePage';
import { apiShareGetSettingsByToken } from '../../../modules/share/api';
import SharedProject from '../Project/SharedProject';

import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

class SharedLoggedApp extends React.Component {
  static propTypes = {
    sharingId: PropTypes.string.isRequired,
  };

  state = {
    settings: {},
    loaded: false,
  };

  componentDidMount() {
    this._mounted = true;
    uppy.useStatusBar();
    this.init().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    uppy.useStatusBar();
  }

  init = async () => {
    try {
      const result = await apiShareGetSettingsByToken();
      if (this._mounted) {
        this.setState({ settings: result, loaded: true });
      }
    } catch (e) {
      if (this._mounted) {
        this.setState({ loaded: true });
      }
      emitUserInfo(e.message, 'Error loading settings');
    }
  };

  render() {
    const { loaded } = this.state;
    if (!loaded) {
      return '';
    }

    const { settings } = this.state;
    if (!settings || !settings.documentId) {
      return 'Error';
    }

    const typeProject = (settings.type === SHARE_TYPES.project);
    const typeFile = (settings.type === SHARE_TYPES.file);

    const { sharingId, history } = this.props;
    return (
      <Switch>
        {typeProject && (
          <Route
            path={ROUTE.share.project()}
            component={() => (
              <SharedProject
                settings={settings}
                sharingId={sharingId}
                history={history}
              />
            )}
          />
        )}
        <Route path={ROUTE.share.file.id()} component={FilePage} />
        <Route path={ROUTE.share.file.metadata()} component={FilePage} />
        {typeProject && <Redirect to={ROUTE.share.project(sharingId)} />}
        {typeFile && <Redirect to={ROUTE.share.file.metadata(sharingId, settings.documentId)} />}
      </Switch>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  sharingId: ownProps.match.params.sharingId,
});

export default connect(mapStateToProps)(SharedLoggedApp);
