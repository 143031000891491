import EventEmitter from 'events';
import { asyncForEach, wait } from '../common';
import { generateId } from '../guids';
import {
  ACTION_TYPE_OTHER,
  debugLogger,
  EVENTS_NAMES,
  getBlankAction,
  getBlankProgress,
  RUNNING_LIMIT
} from './constans';
import SyncStore from './SyncStore';

const initialState = {
  items: {},
  total: 0,
  running: 0,
  done: 0,
  error: 0,
  success: 0,
};

class Synchronizer {
  /**
   * @type {SyncStore}
   * @private
   */
  _store = SyncStore();

  /**
   * @type {EventEmitter}
   * @private
   */
  _syncEmitter = new EventEmitter();

  _opts = {};

  /**
   * @param event {string}
   * @param callback {function | undefined}
   * @returns {Synchronizer}
   */
  on = (event, callback) => {
    this._syncEmitter.on(event, callback);
    return this;
  };

  /**
   * @param event {string}
   * @param callback {function | undefined}
   * @returns {Synchronizer}
   */
  off = (event, callback) => {
    this._syncEmitter.off(event, callback);
    return this;
  };

  /**
   * @param event {string}
   * @param listener {function | undefined}
   * @returns {Synchronizer}
   */
  once = (event, listener) => {
    this._syncEmitter.once(event, listener);
    return this;
  };

  /**
   * @param event {string}
   * @param args {any[]}
   * @returns {boolean}
   */
  emit = (event, ...args) => {
    this._syncEmitter.emit(event, args);
    return this;
  };

  _log = (...args) => {
    if (this._opts.debug) {
      debugLogger.debug(...args);
    }
  };

  _error = debugLogger.error;

  setState = this._store.setState;

  getState = this._store.getState;

  clearDoneItems = () => this._clearItems(false, false);

  run = async () => {
    this._syncEmitter.emit(EVENTS_NAMES.syncRun);
    const nextItems = this._getNextItems();

    this._log(`Sync run (${nextItems.length})`);
    if (nextItems.length) {
      this._setItemsStart(nextItems);

      setTimeout(() => {
        asyncForEach(nextItems, async (item) => {
          await this._runItem(item);
          this.run();
        });
      }, 4);
    } else {
      setTimeout(this._clearItems, 6000);
    }
  };

  /**
   *
   * @param itemsData {ItemData[]}
   * @returns {Promise<string[]>}
   */
  addItems = async (itemsData) => {
    const { total, success } = this.getState();
    if (total > 0 && total === success) {
      setTimeout(this._clearItems, 4);
    }

    // Async with wait(4ms) to make dateCreate filed different in each item
    const newItems = await asyncForEach(itemsData, async (data) => {
      await wait(4);
      const itemId = data.id || generateId(36);
      const blankAction = getBlankAction();

      const actions = data.actions.map(act => ({
        ...blankAction,
        itemId,
        ...act,
      }));

      return {
        id: itemId,
        obj: data.obj,
        title: data.title || '',
        dateCreate: Date.now(),
        progress: getBlankProgress(),
        currentAction: actions[0],
        actionResults: [],
        actions,
      };
    });

    const { items } = this.getState();
    const itemIds = Object.keys(items);

    this.setState({
      items: {
        ...items,
        ...newItems
          .reduce((acc, newItem) => {
            if (itemIds.includes(newItem.id)) {
              const oldItem = items[newItem.id];
              const oldActionTypes = !this._opts.allowSameActionType
                && oldItem.actions.map(action => action.type);

              const actions = [
                ...oldItem.actions,
                ...newItem.actions.filter(action => this._opts.allowSameActionType
                  || action.type === ACTION_TYPE_OTHER
                  || !oldActionTypes.includes(action.type)),
              ];
              const currentActionIndex = actions.findIndex(act => !act.ended);

              return {
                ...acc,
                [newItem.id]: {
                  ...oldItem,
                  actions,
                  currentAction: actions[currentActionIndex],
                  progress: this._calculatePercentage({
                    ...oldItem.progress,
                    current: currentActionIndex,
                    total: actions.length,
                    ...(oldItem.progress.ended ? {
                      started: null,
                    } : {}),
                    ended: null,
                  }),
                }
              };
            }
            return { ...acc, [newItem.id]: newItem };
          }, {})
      },
    });

    this._syncEmitter.emit(EVENTS_NAMES.itemsAdd, newItems);
    this._log(`Items added (${newItems.length})`);

    if (this._opts.autoProceed) {
      this.run();
    }

    return newItems.map(item => item.id);
  };

  /**
   *
   * @param itemData {ItemData}
   * @returns {Promise<string>}
   */
  addItem = async (itemData) => this.addItems([itemData])[0];


  constructor(opts) {
    const defaultOptions = {
      autoProceed: true,
      debug: true,
      allowSameActionType: false,
    };

    this._opts = { ...defaultOptions, ...opts };
    this._store.setState(initialState);

    this._storeUnsubscribe = this._store.subscribe((nextState, prevState, patch) => {
      this.emit(EVENTS_NAMES.stateUpdate, nextState, prevState, patch);
    });
  }


  _clearItems = (noErrors = true, waitTime = true) => {
    this._store.setState({
      items: Object.values(this.getState().items)
        .filter((item) => {
          let result = !item.progress.ended;
          if (noErrors) {
            result = result || !!item.progress.errorMessage;
          }
          if (waitTime && !!item.progress.ended) {
            result = result || (Date.now() - item.progress.ended) < 5 * 1000;
          }
          return result;
        })
        .reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
    });
  };

  _checkItemPresent = (itemId) => {
    const result = !!this.getState().items[itemId];
    if (!this.getState().items[itemId]) {
      throw new Error(`Can’t set state for ${itemId} (the item could have been removed)`);
    }
    return result;
  };

  _calculatePercentage = progress => ({
    ...progress,
    percentage: (progress.total !== 0) ? Math.round(progress.current / progress.total * 100) : 0,
  });


  _setItemState = (itemId, state) => {
    if (this._checkItemPresent(itemId)) {
      const newItemState = { ...this.getState().items[itemId], ...state };
      this.setState({
        items: {
          ...this.getState().items,
          [itemId]: newItemState,
        },
      });
      if (newItemState.progress.ended) {
        this.run();
      }
    }
  };

  _setItemsStart = (startItems) => {
    const { items } = this.getState();
    this.setState({
      items: {
        ...items,
        ...startItems.reduce((acc, item) => ({
          ...acc,
          [item.id]: {
            ...item,
            progress: this._calculatePercentage({
              ...getBlankProgress(),
              current: 0,
              started: Date.now(),
              total: item.actions.length,
            }),
            currentAction: { ...item.actions[0] },
          }
        }), {}),
      }
    });
  };

  _setItemProgress = (itemId, progress) => {
    if (this._checkItemPresent(itemId)) {
      const { items } = this.getState();
      this._setItemState(itemId, {
        ...items[itemId],
        progress: this._calculatePercentage({
          ...items[itemId].progress,
          ...progress,
        }),
      });
    }
  };

  _setItemStateComplete = (item, errorMessage = undefined) => {
    const eventName = errorMessage ? EVENTS_NAMES.itemComplete : EVENTS_NAMES.itemError;
    const fnLogMessage = title => `Item done "${title}"${errorMessage ? ` with error: ${errorMessage}` : ''}`;
    const fnLog = errorMessage ? this._error : this._log;

    const itemUpdated = this.getState().items[item.id];
    if (itemUpdated) {
      const progressError = !errorMessage
        ? {
          current: itemUpdated.progress.total,
          errorMessage: itemUpdated.progress.errorMessage || '',
        }
        : {
          errorMessage,
        };

      const progressCurrent = (
        progressError.errorMessage
        || !itemUpdated.actions.length
        || itemUpdated.actions[itemUpdated.actions.length - 1].progress.ended
      )
        ? {
          current: itemUpdated.progress.total,
          ended: Date.now(),
        } : {};

      this._setItemProgress(item.id, {
        ...progressError,
        ...progressCurrent,
      });
      this._syncEmitter.emit(eventName, itemUpdated);
      fnLog(fnLogMessage(itemUpdated.title));
    } else {
      this._syncEmitter.emit(eventName, item);
      fnLog(fnLogMessage(item.title));
    }
  };


  _setActionState = (itemId, actionIndex, progress, props = {}) => {
    if (this._checkItemPresent(itemId)) {
      const { items } = this.getState();
      const item = items[itemId];
      const { actions } = item;
      const action = actions[actionIndex];

      const newAction = {
        ...action,
        ...props,
        progress: this._calculatePercentage({
          ...action.progress,
          ...progress,
        }),
      };

      this._setItemState(itemId, {
        progress: this._calculatePercentage({
          ...item.progress,
          // Current action progress
          current: actionIndex,
          ended: 0,
          // Last action progress
          ...((actionIndex === actions.length - 1 && newAction.progress.ended)
              ? {
                ended: Date.now(),
                errorMessage: item.progress.errorMessage || newAction.progress.errorMessage,
                current: item.progress.total,
              }
              : {}
          ),
        }),
        currentAction: newAction,
        actions: actions.map((act, index) => ((index === actionIndex)
          ? { ...act, ...newAction }
          : act)),
      });
    }
  };

  _addActionProgress = (itemId, actionIndex, add, isDiff) => {
    if (this._checkItemPresent(itemId)) {
      const { items } = this.getState();
      const { progress: { total, current } } = items[itemId].actions[actionIndex];
      const newCurrent = isDiff ? current + add : add;
      this._setActionState(itemId, actionIndex, {
        current: newCurrent,
        ...(current !== total ? { ended: 0 } : {}),
      });
    }
  };

  _setActionProgressComplete = (item, actionIndex, errorMessage = '') => {
    let updatedItem = item;
    let errMsg = errorMessage;

    if (this._checkItemPresent(item.id)) {
      const { items } = this.getState();
      updatedItem = items[item.id];
      const { progress } = updatedItem.actions[actionIndex];
      if (!progress.ended) {
        const newProgress = {
          current: progress.total,
          errorMessage: progress.errorMessage || errorMessage,
          ended: Date.now(),
        };
        this._setActionState(updatedItem.id, actionIndex, newProgress);
        if (newProgress.errorMessage) {
          errMsg = errMsg || newProgress.errorMessage;
        }
      } else {
        return;
      }
    }

    const fnLog = errMsg ? this._error : this._log;
    const emitAction = errMsg ? EVENTS_NAMES.actionError : EVENTS_NAMES.actionComplete;
    const fnLogMessage = () => `Action done ${actionIndex}: "${updatedItem.actions[actionIndex].title}"`
      + ` (item "${updatedItem.title}")`
      + `${errMsg ? ` with error: ${errMsg}` : ''}`;

    this._syncEmitter.emit(emitAction, this.getState().items[item.id] || item, actionIndex);
    fnLog(fnLogMessage(updatedItem, errMsg));
  };


  _getNextItems = () => {
    const state = this.getState();
    return Object.values(state.items)
      .filter(item => !item.progress.started)
      .sort((a, b) => a.dateCreate - b.dateCreate)
      .filter((item, index) => index < (RUNNING_LIMIT - state.running));
  };

  _getNextActionIndex = (itemId) => {
    const { actions } = this.getState().items[itemId];
    let previousErrorMessage = '';
    return actions
      .findIndex((action) => {
        let result = false;
        if (!action.progress.started) {
          if (!previousErrorMessage) {
            result = true;
          } else if (action.ignorePreviousErrors) {
            result = true;
          }
        }
        previousErrorMessage = previousErrorMessage || action.progress.errorMessage;
        return result;
      });
  };


  _runAction = async (itemId, actionIndex) => {
    const { items } = this.getState();

    const item = items[itemId];
    const action = item.actions[actionIndex];

    this._log(`Action start ${actionIndex}: "${action.title}" (item ${item.title})`);

    this._setActionState(item.id, actionIndex, {
      ...getBlankProgress(),
      started: Date.now(),
    });

    this._syncEmitter.emit(EVENTS_NAMES.actionRun, item, actionIndex);

    try {
      const result = await action.fn({
        setComplete: (errorMessage = '') => {
          this._setActionProgressComplete(item, actionIndex, errorMessage);
          if (errorMessage) {
            console.error(new Error(errorMessage));
          }
        },
        addProgress: (add, isDiff = true) => this._addActionProgress(itemId, actionIndex, add, isDiff),
        setInit: (total = 1, title = '', icon = null) => this
          ._setActionState(itemId, actionIndex, { started: Date.now(), total, ended: 0 }, {
            ...(title ? { title } : {}),
            ...(icon ? { icon } : {}),
          }),
      });
      if (action.autoComplete) {
        this._setActionProgressComplete(item, actionIndex);
      }
      return result;
    } catch (e) {
      this._setActionProgressComplete(item, actionIndex, e.message);
      throw e;
    }
    // return null;
  };

  _runItem = async (item) => {
    this._log(`Item start "${item.title}"`);

    this._syncEmitter.emit(EVENTS_NAMES.itemRun, item);
    let nextIndex = this._getNextActionIndex(item.id);
    while (nextIndex >= 0) {
      this._setItemProgress(item.id, {
        current: nextIndex,
      });
      try {
        // eslint-disable-next-line no-await-in-loop
        await this._runAction(item.id, nextIndex);
        nextIndex = this._getNextActionIndex(item.id);
      } catch (e) {
        nextIndex = this._getNextActionIndex(item.id);
        if (nextIndex < 0) {
          this._setItemStateComplete(item, e.message);
          console.error(e);
        }
      }
    }
    // this._setItemStateComplete(item);
  };
}

export default () => new Synchronizer();
