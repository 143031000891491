import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Redirect } from 'react-router-dom';
import { emitUserError } from '../../../constants/globals';
import { ROUTE } from '../../../constants/general';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';
import { doWebLogin } from '../../../new/loginUtils';
import { getLocalHelpScreens, KEY_FIRST_REGISTER } from '../../../utils/localStorageUtils';
import styles from './Login.module.css';


const initialState = {
    login: '',
    password: '',
};

class Login extends Component {
    static propTypes = {
        // history: PropTypes.shape({ push: PropTypes.func }),
        onLogin: PropTypes.func,
    };

    state = initialState;

    handleSubmit = async (values, { setSubmitting }) => {
// scanTest();
// setSubmitting(false);
// return;
        const { onLogin } = this.props;

        try {
            // const loginResult =
            await onLogin({
                login: values.login,
                password: values.password
            });
            // if (loginResult === true) {
            //     this.setState(initialState);
            // }
        } catch (e) {
            emitUserError(e.message, 'Unable to log in');
        } finally {
            // process form submission here
            // done submitting, set submitting to false
            setSubmitting(false);
        }
    };

    render() {
        if (this.props.online === true) {
            const { from } = this.props.location.state || { from: { pathname: ROUTE.home } };
            return <Redirect to={from} />;
        }

        const { login, password } = this.state;

        if (!getLocalHelpScreens()[KEY_FIRST_REGISTER]) {
            return <Redirect to={ROUTE.register} />;
        }

        return (
            <Formik
                initialValues={{ login, password }}
                validationSchema={object().shape({
                    login: string().required().min(1).max(40),
                    password: string().required().min(1)
                })}
                enableReinitialize
                onSubmit={this.handleSubmit}
                render={({ isSubmitting }) => (
                    <Form className={styles.formContainer}>
                        <Field
                            name="login"
                            component={Input}
                            labelWidth={Input.LABEL_WIDTH.FULL}
                            label="Login"
                            required
                        />
                        <Field
                            name="password"
                            type="password"
                            component={Input}
                            label="Password"
                            required
                            labelWidth={Input.LABEL_WIDTH.FULL}
                            rootClassName={styles.password}
                        />
                        <Button
                            link={ROUTE.resetPassword}
                            tiny
                            color="none"
                            noDecorate
                            className={styles.resetPasswordButton}
                        >
                            forgot password?
                        </Button>
                        <FormButtons>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                color={Button.COLOR.PRIMARY}
                            >
                                Sign in
                            </Button>
                            <span>or</span>
                            <Button
                                link={ROUTE.register}
                                color={Button.COLOR.PRIMARY}
                            >
                                Register
                            </Button>
                        </FormButtons>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onLogin: user => dispatch(doWebLogin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
