import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './StatusIcon.module.css';


const StatusIcon = ({ enabled, icon, altOn, altOff, title, noMargin, className, children }) => (
    <span className={cn(styles.container, noMargin && styles.noMargin, className)}>
        {enabled
            ? <img src={icon} alt={altOn} title={title} className={styles.img} />
            : <img src={icon} alt={altOff} title={title} className={cn(styles.img, styles.off)} />
        }
      {children}
    </span>
);

StatusIcon.propTypes = {
    className: PropTypes.any,
    enabled: PropTypes.bool,
    icon: PropTypes.any,
    altOn: PropTypes.string,
    altOff: PropTypes.string,
    title: PropTypes.string,
    noMargin: PropTypes.bool,
};

StatusIcon.defaultProps = {
    noMargin: false,
};

export default StatusIcon;
