import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoPlayer.module.css';
import previewPlaceholder from './resources/previewPlaceholder.svg';
import VideoJsPlayer from './VideoJsPlayer';

// Faced with the problem that some videos did not play if the object {src, type} was transmitted in
// the sources array. Passing an array of strings solved the problem.
// sources: playURL ? [{ src: playURL, type: (file.meta && file.meta.type) || 'video/mp4' }] : null,
const VideoPlayer = ({ id, src, type }) => (
    <>
        {src
            ? <VideoJsPlayer id={id} sources={src ? [src] : null} />
            : <img className={styles.size} src={previewPlaceholder} alt="Preview placeholder" />
        }
    </>
);

VideoPlayer.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.string,
};

export default VideoPlayer;
