// @flow
import { findUserDocs } from '../../handlers/userDB';
import { ACTION_TYPES } from './reducer';

export const ACTIONS = {
    videos: {
        success: list => ({ type: ACTION_TYPES.videos.success, list }),
    },
};

export const fetchVideos = () => async (dispatch, getState) => {
    const { videos } = getState();
    return dispatch(findUserDocs(videos));
};

export const readVideosList = () => async (dispatch) => {
    const list = await dispatch(fetchVideos());
    dispatch(ACTIONS.videos.success(list));
};
