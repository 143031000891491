import React from 'react';
import Anchor from '../../Anchor/Anchor';
import HiwProgressBar from './Blocks/HiwProgressBar/HiwProgressBar';

const HowItWorks = () => (
    <>
        <Anchor id="HowItWorks">
            <h1>How it works</h1>
        </Anchor>
        <HiwProgressBar />
    </>
);

export default HowItWorks;
