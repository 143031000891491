import React from 'react';

const k = 9.0752 / 6.2445;

export const ShareIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 20, fill = '#00a14b', ...rest } = props;


  const aspectWidth = (height as number) * k;
  return (
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 9.0752 6.2445" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g fill={fill}>
        <g transform="matrix(.18063 0 0 -.18063 8.9378 5.5435)">
          <path d="m0 0v-0.787c0-0.288 0.026-0.597-0.033-0.879-0.147-0.673-0.662-1.21-1.373-1.221-0.733-0.011-1.46 0-2.193 0h-10.571c0.125-0.125 0.249-0.25 0.374-0.375v4.651c0 0.972-0.043 1.932-0.238 2.893-0.305 1.487-0.917 2.925-1.813 4.152-0.955 1.313-2.209 2.398-3.69 3.077-0.033-0.228-0.06-0.456-0.093-0.684 1.151 0.472 2.366 0.722 3.614 0.722h5.276c1.498 0 2.952-0.212 4.325-0.852 1.796-0.831 3.315-2.193 4.401-3.848 1.341-2.03 2.008-4.429 2.014-6.849 0-0.483 0.754-0.483 0.749 0-0.012 4.353-2.139 8.727-5.98 10.936-1.575 0.906-3.349 1.361-5.167 1.367h-5.563c-1.324 0-2.632-0.25-3.864-0.749-0.261-0.109-0.418-0.537-0.092-0.684 2.61-1.204 4.417-3.658 5.079-6.425 0.435-1.807 0.288-3.734 0.288-5.579v-2.122c0-0.201 0.174-0.375 0.374-0.375h6.073c2.225 0 4.456-0.016 6.681 0 0.939 6e-3 1.704 0.619 2.019 1.487 0.238 0.663 0.146 1.455 0.146 2.149 0.011 0.478-0.743 0.478-0.743-5e-3" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 9.0083 5.5435)">
          <path d="m0 0v-1.308c0-1.08-0.798-1.954-1.759-1.954h-12.786v5.216c0 4.173-2.301 7.755-5.562 9.242 1.15 0.477 2.393 0.738 3.69 0.738h5.731c5.894 0 10.686-5.351 10.686-11.934" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 7.7104 1.9335)">
          <path d="m0 0c-0.017-2.219-1.422-4.276-3.533-5.02-2.116-0.743-4.493-0.081-5.926 1.645-1.422 1.714-1.585 4.227-0.439 6.126 1.144 1.895 3.419 2.915 5.589 2.481 2.491-0.489 4.292-2.703 4.309-5.232 6e-3 -0.445 0.694-0.445 0.689 0-0.016 2.507-1.589 4.771-3.94 5.65-2.355 0.884-5.128 0.135-6.724-1.813-1.601-1.954-1.834-4.765-0.526-6.941 1.303-2.16 3.864-3.283 6.339-2.806 2.431 0.467 4.401 2.508 4.769 4.955 0.049 0.315 0.077 0.635 0.077 0.955 0.01 0.445-0.678 0.445-0.684 0" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 6.7478 .90349)">
          <path d="m0 0c3.135 0 5.677-2.542 5.677-5.677s-2.542-5.676-5.677-5.676c-3.136 0-5.677 2.541-5.677 5.676s2.541 5.677 5.677 5.677" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 5.4754 6.0935)">
          <path d="m0 0h-27.824c-0.755 0.011-1.411 0.504-1.602 1.243-0.064 0.26-0.049 0.537-0.049 0.808v0.912c0 0.901 0.087 1.791 0.278 2.67 0.412 1.867 1.259 3.636 2.474 5.107 2.333 2.827 5.818 4.455 9.481 4.461h6.567c3.549 0 6.93-1.466 9.285-4.136 1.27-1.438 2.187-3.18 2.66-5.041 0.363-1.438 0.396-2.903 0.374-4.38-0.016-0.912-0.738-1.628-1.644-1.644-0.538-0.011-0.543-0.847 0-0.836 1.091 0.022 2.072 0.728 2.382 1.786 0.18 0.613 0.097 1.324 0.097 1.959 0 1.937-0.395 3.837-1.204 5.595-1.682 3.647-5.09 6.388-9.014 7.234-1.259 0.271-2.524 0.304-3.799 0.304h-4.162c-1.152 0-2.296 0.027-3.442-0.136-1.975-0.277-3.885-1.02-5.524-2.154-3.359-2.328-5.476-6.149-5.633-10.235-0.022-0.614-0.011-1.227-0.011-1.835 5e-3 -0.96 0.532-1.872 1.416-2.279 0.554-0.255 1.108-0.239 1.694-0.239h27.2c0.542-5e-3 0.542 0.836 0 0.836" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 5.4754 6.1735)">
          <path d="m0 0h-27.83c-1.14 0-2.063 0.922-2.063 2.062v1.378c0 6.958 5.639 12.597 12.597 12.597h6.762c6.957 0 12.596-5.639 12.596-12.597v-1.378c0-1.14-0.922-2.062-2.062-2.062" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 4.2461 1.4535)">
          <path d="m0 0c-0.017-2.947-1.878-5.682-4.667-6.691-2.844-1.031-6.052-0.147-7.978 2.176-1.9 2.29-2.117 5.649-0.586 8.189 1.536 2.546 4.591 3.908 7.511 3.311 2.908-0.597 5.177-2.974 5.638-5.91 0.061-0.353 0.082-0.717 0.082-1.075 5e-3 -0.591 0.928-0.597 0.923 0-0.017 3.365-2.15 6.404-5.308 7.571-3.136 1.156-6.811 0.152-8.933-2.431-2.144-2.611-2.458-6.377-0.711-9.286 1.753-2.903 5.199-4.401 8.515-3.739 3.224 0.64 5.828 3.348 6.327 6.599 0.066 0.429 0.104 0.857 0.104 1.286 0.011 0.592-0.912 0.592-0.917 0" />
        </g>
        <g transform="matrix(.18063 0 0 -.18063 2.9589 .083491)">
          <path d="m0 0c4.19 0 7.587-3.396 7.587-7.587 0-4.19-3.397-7.587-7.587-7.587-4.189 0-7.587 3.397-7.587 7.587 0 4.191 3.398 7.587 7.587 7.587" />
        </g>
      </g>
    </svg>
  );
};
