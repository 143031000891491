import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string, ref } from 'yup';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import Input from '../../../../components/Input/Input';
import { ROUTE } from '../../../../constants/general';
import { emitUserError, emitUserInfo } from '../../../../constants/globals';
import { useSearch } from '../../../../helpers/hooks';
import { apiResetPasswordApply } from '../../../../modules/user/actions';
import styles from './ApplyResetPassword.module.scss';


const getInitialValues = () => ({
    password: '',
    passwordConfirm: ''
});
const validationSchema = object().shape({
    password: string()
        .required()
        .min(3),
    passwordConfirm: string()
        .required()
        .oneOf([ref('password'), null], 'Passwords must match'),
});


const ApplyResetPassword = () => {
    const { token } = useSearch();
    const [done, setDone] = useState(false);
    const history = useHistory();

    const handleSubmit = useCallback(async ({ password }, { setSubmitting }) => {
        if (token) {
            try {
                const res = await apiResetPasswordApply(token, password);
                emitUserInfo(res);
                // emitUserInfo('Your password has been changed');
                setDone(true);
                setTimeout(() => {
                    history.push(ROUTE.login);
                }, 5000);
            } catch (e) {
                emitUserError(e.message, 'Apply reset password');
            } finally {
                setSubmitting(false);
            }
        } else {
            emitUserError('Token has not been provided');
        }
    }, [history, token]);

    return (
        <>
            {done
                ? (
                    <div className={styles.container}>
                        <h3>Password has been reset</h3>
                        You will be redirected to login page
                    </div>
                )
                : (
                    <Formik
                        initialValues={getInitialValues()}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        render={({ isSubmitting }) => (
                            <Form>
                                <Field
                                    name="password"
                                    type="password"
                                    component={Input}
                                    labelWidth={Input.LABEL_WIDTH.FULL}
                                    label="New password"
                                    required
                                />
                                <Field
                                    name="passwordConfirm"
                                    type="password"
                                    component={Input}
                                    labelWidth={Input.LABEL_WIDTH.FULL}
                                    label="Confirm new password"
                                    required
                                />
                                <FormButtons>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        color={Button.COLOR.PRIMARY}
                                    >
                                        Set new password
                                    </Button>
                                    <span>or</span>
                                    <Button
                                        link={ROUTE.login}
                                        color={Button.COLOR.PRIMARY}
                                    >
                                        Return to Login page
                                    </Button>
                                </FormButtons>
                            </Form>
                        )}
                    />
                )
            }
        </>
    );
};

export default ApplyResetPassword;
