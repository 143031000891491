import { readUserDoc, writeUserDoc } from '../handlers/userDB';
import { doReadDbUser, getDbUser } from '../new/authUtils';
import { getUserId } from './webSettings';

export const HELP_FLAG_CREATE_FIRST_PROJECT = 'createFirstProject';
export const HELP_FLAG_OPEN_FIRST_PROJECT = 'openFirstProject';

export const setUserHelpFlag = (flagName, done = true) => async (dispatch, getState) => {
    const doc = await dispatch(readUserDoc(getUserId()));

    const newDoc = {
        ...doc,
        helpFlags: {
            ...doc.helpFlags,
            [flagName]: done
        },
    };

    await dispatch(writeUserDoc(newDoc));

    await dispatch(doReadDbUser());
};
export const getUserHelpFlag = flagName => (dispatch) => {
    const { helpFlags } = dispatch(getDbUser());
    return helpFlags && helpFlags[flagName]
        ? helpFlags[flagName]
        : false;
};
