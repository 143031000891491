import { getPathType, TPathType } from '../files/files';
import {readFileStream} from './fileSystem';
import {fsCloseSync, fsLstatSync, fsOpenSync, fsReadSync} from './fse';
import {pathBasename} from './path';
import {isWeb} from '../../constants/modules';

const mime = !isWeb ? window.require('mime') : {};

// const streamToBlob = (stream, mimeType) => new Promise((resolve, reject) => {
//     const chunks = [];
//     stream
//         .on('data', chunk => chunks.push(chunk))
//         .once('end', () => {
//             const blob = mimeType != null
//                 ? new Blob(chunks, { type: mimeType })
//                 : new Blob(chunks);
//             resolve(blob);
//         })
//         .once('error', reject);
// });

class FileFS implements File {
  static from = (path: string, callback = undefined): FileFS => {
    const f = new FileFS(path, callback);
    // f.read();
    return f;//._created ? f : null;
  }

  lastModified = 0;

  name = '';

  size = 0;

  type = '';

  fd: number | undefined = 0;

  lastModifiedDate: Date | undefined = undefined;

  path = '';

  systemType?: TPathType = 'file';

  stream = (): ReadableStream => this._body.stream();

  text = (): Promise<string> => new Promise<string>(resolve => resolve(''));

  arrayBuffer = (): Promise<ArrayBuffer> => new Promise<ArrayBuffer>(resolve => resolve(undefined));

  _created = false;

  _callback: (() => void | undefined) = () => {
  };

  _body = new Blob([]);

  constructor(path: string, callback = () => {}) {
    if (!isWeb) {
      const stat = fsLstatSync(path);
      if (stat.isFile() || stat.isDirectory()) {
        void getPathType(stat, path).then(result => { // TODO: will it work?
          this.systemType = result;
        })
        if (stat.isFile()) {
          this.size = stat.size; // TODO: Calculate folder size
        }

        this.type = mime.getType(path);
        this.lastModifiedDate = stat.mtime;
        this.lastModified = stat.mtime.getTime();
        this.path = path;
        this.name = pathBasename(path);

        this._callback = callback;

        this._created = true;
      }
    }
  }

  openFile = (): void => {
    if (!this.fd) {
      this.fd = fsOpenSync(this.path, 'r');
      // console.log(`${this.fd} opened (${this.name})`);
    }
  };

  closeFile = (): void => {
    // console.log(`${this.fd} closed (${this.name})`);
    fsCloseSync(this.fd);
    this.fd = undefined;
  };

  slice = (start?: number | undefined, end?: number | undefined): Blob => {
    this.openFile();
    return this._body.slice(start, end);
  };

  prepare = async (action: any): Promise<void> => {
    console.debug(`FileFS preparing (${this.size} bytes)...`);
    this._body = await readFileStream(this.path, undefined, action);
  };

  getBase64String = (): string => {
    this.openFile();

    const buffer = Buffer.alloc(this.size);
    fsReadSync(this.fd, buffer, 0, this.size, 0);
    return buffer.toString('base64');
  };

  doCallback = (): void => {
    this.closeFile();

    if (this._callback) {
      this._callback();
    }
  };
}

export default FileFS;
