import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { faAsterisk, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import styles from './Input.module.css';

const ValidationIcon = (props) => {
    const {
        required,
        field,
        fieldTouched,
        fieldError,
    } = props;
    let kind = '';
    let kindName = '';
    if (fieldTouched && fieldError) {
        kind = faTimes;
        kindName = 'times';
    } else if (!field.value && required) {
        kind = faAsterisk;
        kindName = 'asterisk';
    } else if (required && field.value && !fieldError) {
        kind = faCheck;
        kindName = 'check';
    }
    return kind
        ? <Icon icon={kind} className={cn(styles.validationIcon, styles[`validationIcon-${kindName}`])} />
        : '';
};

ValidationIcon.propTypes = {
    required: PropTypes.bool,
    field: PropTypes.object,
    fieldTouched: PropTypes.bool,
    fieldError: PropTypes.string,
};

export default ValidationIcon;
