import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { webRegister } from '../../../modules/user/actions';
import { ROUTE } from '../../../constants/general';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';
import { getLocalHelpScreens, setLocalHelpScreens, KEY_FIRST_REGISTER } from '../../../utils/localStorageUtils';

const SCHEMA = yup.object().shape({
    name: yup.string()
        .required()
        .min(1)
        .max(100),
    login: yup.string()
        .required()
        .min(1)
        .max(40),
    password: yup.string()
        .required()
        .min(1),
    passwordConfirm: yup.string()
        .required()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    email: yup.string()
        .required()
        .email()
});

class Register extends Component {
    static propTypes = {
        history: PropTypes.shape({ push: PropTypes.func }),
        helpFlag: PropTypes.bool,
    };

    goToLoginPage = () => {
        const { history } = this.props;

        setLocalHelpScreens(KEY_FIRST_REGISTER, true);
        history.push(ROUTE.login);
    };

    handleSubmit = async (values, { setSubmitting }) => {
        try {
            // const result =
            await webRegister(values);
            // if (result === '') {
            const { history } = this.props;

            setLocalHelpScreens(KEY_FIRST_REGISTER, true);
            history.push(ROUTE.registerSuccess);
            // } else {
            //     alert(result);
            // }
        } finally {
            setSubmitting(false);
        }
    };

    render() {
        const { helpFlag } = this.props;

        return (
            <Formik
                initialValues={{
                    name: '',
                    login: '',
                    password: '',
                    passwordConfirm: '',
                    email: ''
                }}
                validationSchema={SCHEMA}
                onSubmit={this.handleSubmit}
                render={({ isSubmitting }) => (
                    <Form>
                        {!helpFlag && (
                            <h1>
                                Okay, create an account
                                <br />
                                and let’s get you started!
                            </h1>
                        )}
                        <Field name="name" component={Input} label="Name" required />
                        <Field name="login" component={Input} label="Login" required />
                        <Field name="password" type="password" component={Input} label="Password" required />
                        <Field
                            name="passwordConfirm"
                            type="password"
                            component={Input}
                            label="Confirm password"
                            required
                        />
                        <Field name="email" type="email" component={Input} label="Email" required />
                        <FormButtons>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                color={Button.COLOR.PRIMARY}
                            >
                                Register
                            </Button>
                            <Button
                                disabled={isSubmitting}
                                color={Button.COLOR.PRIMARY}
                                onClick={this.goToLoginPage}
                            >
                                Cancel
                            </Button>
                        </FormButtons>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = () => ({
    helpFlag: getLocalHelpScreens()[KEY_FIRST_REGISTER] || false
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
