import PropTypes from 'prop-types';
import React from 'react';
import { prettyDate } from '../../../utils/format';

const VideoListItemDetails = ({ item }) => (
    <>
        <div key="created">
            {`Created: ${prettyDate(item.created)}`}
        </div>
        <div key="additionalInfo">
            Full Length | YT
        </div>
    </>
);
VideoListItemDetails.propTypes = {
    item: PropTypes.shape({
        created: PropTypes.string,
    }),
};

export default VideoListItemDetails;
