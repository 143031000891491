import { getElectron, getElectronProp } from '../system';

const BrowserWindow = getElectronProp('BrowserWindow');

export const openModalWindow = (windowUrl, onRedirect) => {
    const modalWindow = new BrowserWindow({
        parent: getElectron()
            .remote
            .getCurrentWindow(),
        modal: true,
        show: false,
        width: 600,
        height: 600,
        frame: false
    });

    modalWindow.loadURL(windowUrl);

    modalWindow.webContents.on('did-finish-load', () => {
        if (!modalWindow) {
            throw new Error('"mainWindow" is not defined');
        }
        modalWindow.show();
        modalWindow.focus();
    });

    if (onRedirect) {
        modalWindow.webContents.on('will-redirect', (event, url) => {
            onRedirect(modalWindow, event, url);
        });
    }
};
