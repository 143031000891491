import PropTypes from 'prop-types';
import React from 'react';
import Input from '../../../../components/Input/Input';
import styles from '../../../../components/ListContainer/ListContainer.module.css';
import { prettyDate } from '../../../../utils/format';

const SeasonListItemDetails = ({ item }) => (
    <>
        <div key="created">
            {`Created: ${prettyDate(item.created)}`}
        </div>
        <div key="keywords" className={styles.propertyContainer}>
            Keywords:
            &nbsp;
            <Input
                form={{}}
                type={Input.TYPE.MULTI_SELECT}
                field={{ value: item.keywords, name: 'keywords' }}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                readOnly
                showCopyButton
            />
        </div>
        <div key="seasonNumber">
            {`No: ${item.number}`}
        </div>
        <div key="playlistLevel">
            Playlist level: 2
        </div>
    </>
);
SeasonListItemDetails.propTypes = {
    item: PropTypes.shape({
        created: PropTypes.string,
        keywords: PropTypes.string,
        number: PropTypes.number,
    }),
};

export default SeasonListItemDetails;
