import { addFilter } from '../../utils/pouchDB/documents';
import { LIST_OPTIONS } from '../../constants/pouchDB';
import { GENERAL_ACTION_TYPES } from '../general';

export const ACTION_TYPES = {
    list: {
        clear: 'SEASONS_LIST_CLEAR',
        read: {
            success: 'SEASONS_LIST_READ_SUCCESS'
        }
    },
    setParents: 'SEASONS_SET_PARENTS',
    current: {
        read: {
            success: 'SEASONS_CURRENT_READ_SUCCESS'
        }
    }
};

const initialState = {
    ...LIST_OPTIONS.seasons,
    seriesId: '',
    list: [],
    current: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.setParents: {
        return {
            ...addFilter(state, 'seriesId', action.seriesId, '$eq'),
            list: action.seriesId === state.seriesId ? state.list : [],
            seriesId: action.seriesId
        };
    }
    case ACTION_TYPES.list.read.success: {
        return {
            ...state,
            seriesId: action.seriesId,
            list: action.list
        };
    }

    case ACTION_TYPES.list.clear: {
        return {
            ...initialState,
            seasonId: action.seasonId
        };
    }
    case ACTION_TYPES.current.read.success: {
        return {
            ...state,
            current: action.current
        };
    }

    case GENERAL_ACTION_TYPES.authClear: {
        return { ...initialState };
    }

    default: {
        return state;
    }
    }
};

export default reducer;
