import { safeLoad } from 'js-yaml';

const PARSERS = [
    {
        match: ct => ct.indexOf('text/yaml') >= 0,
        parse: async res => safeLoad(await res.text())
    },
    {
        match: ct => ct.indexOf('text/plain') >= 0,
        parse: res => res.text()
    },
    { // This should be the latest
        match: () => true,
        parse: async (res) => {
            // return res.json(); // Cannot catch error in this operation
            const text = await res.text();
            try {
                return JSON.parse(text);
            } catch (e) {
                return text;
            }
        }
    },
];

const getBody = async (response) => {
    const contentType = response.headers.get('Content-Type');
    return PARSERS.find(item => item.match(contentType))
        .parse(response);
};

class ApiResponse {
    #ok = false;

    #body = undefined;

    #status = 0;

    #statusText = 'no response';

    #url = '';


    get ok() { return this.#ok; }

    get body() { return this.#body; }

    get status() { return this.#status; }

    get statusText() { return this.#statusText; }

    get url() { return this.#url; }


    async readResponse(response) {
        if (response) {
            this.#body = await getBody(response);
            this.#ok = response.ok && [200, 201].includes(response.status);
            this.#status = response.status;
            this.#statusText = response.statusText;
            this.#url = response.url;
        }
        return this;
    }
}

const processResponse = async response => new ApiResponse().readResponse(response);

export default processResponse;
