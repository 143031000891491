import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ButtonNew } from '../ButtonNew';
import DropDownMenu from './DropDownMenu';
import styles from './DropDown.module.css';

class DropDown extends Component {
  static propTypes = {
    items: PropTypes.array,
    children: PropTypes.any,
    noIcon: PropTypes.bool,
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    menuChildren: PropTypes.any,
    btnProps: PropTypes.any,
  };

  state = {
    showMenu: false,
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  showMenu = () => {
    const { items } = this.props;
    return this._mounted && this.setState({ showMenu: items.length > 0 });
  };

  hideMenu = () => setTimeout(() => this._mounted && this.setState({ showMenu: false }));

  render() {
    const { showMenu } = this.state;
    const {
      items,
      children,
      className,
      noIcon,
      menuChildren,
      menuClassName,
      btnProps: { icon, ...btnPropsRest},
      ...rest
    } = this.props;

    return (
      <div className={cn(styles.root, className)}>
        <ButtonNew
          color="none"
          onClick={this.showMenu}
          {...btnPropsRest}
          icon={
            icon
              ? icon
              : !noIcon
                ? showMenu
                  ? faCaretUp
                  : faCaretDown
                : undefined
          }
          className={cn(styles.info, btnPropsRest?.className)}
        >
          {children}
        </ButtonNew>
        <DropDownMenu
          show={showMenu}
          onClickOutside={this.hideMenu}
          items={items}
          className={menuClassName}
          {...rest}
          key="menuItems"
        >
          {menuChildren}
        </DropDownMenu>
      </div>
    );
  }
}

export default DropDown;
