import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { ButtonNew } from '../../../components/ButtonNew';
import { useRoles } from '../../../helpers/use-roles-hook';
import { PageContainer } from '../PageContainer';
import styles from './roles.module.scss';
import { Role } from './Role';

type TRowProps = { name: string, permissions: ReactNode, setRole?: () => void };
const Row: React.FC<TRowProps> = (props) => {
  const { name, permissions, setRole } = props;

  return (
    <div
      className={styles.row}
      onClick={setRole}
    >
      <div>{name}</div>
      <div>{permissions}</div>
    </div>
  );
}


export const Roles: React.FC = () => {
  const { roles, readRoles } = useRoles();

  const [newRole, setNewRole] = useState(false);

  const [showRoleKey, setShowRoleKey] = useState('');
  const handleShowRole = useCallback((id: string) => {
    setShowRoleKey(s => s === id ? '' : id);
  }, [])


  const roleList = useMemo(() => {
    return roles;
  }, [roles]);

  const handleAddRole = useCallback(() => {
    setNewRole(true);
  }, []);
  const handleAddRoleCancel = useCallback(() => {
    setNewRole(false);
  }, []);
  const handleAddRoleChange = useCallback(async () => {
    await readRoles();
    setNewRole(false);
  }, [readRoles]);

  const actions = useMemo(() => {
    return (
      <ButtonNew
        onClick={handleAddRole}
      >
        Add Role
      </ButtonNew>
    );
  }, [handleAddRole]);

  return (<>
    <PageContainer
      title="Roles"
      titleRight={actions}
    >
      <strong>
        <Row name="Name" permissions="Permissions" />
      </strong>
      {newRole && <Role changeCallback={handleAddRoleChange} cancelCb={handleAddRoleCancel} />}
      {roleList.map((role) => {
        return (
          <div key={role.key}>
            {showRoleKey === role.key ? (
              <Role role={role} changeCallback={readRoles} cancelCb={() => handleShowRole(role.key)} />
            ) : (
              <Row
                name={role.name}
                permissions={role.permissions.map(({ key, name }) => <div key={key}>{name}</div>)}
                setRole={() => handleShowRole(role.key)}
              />
            )}
          </div>
        );
      })}
    </PageContainer>
  </>);
}
