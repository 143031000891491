import moment from 'moment';
import { ENDPOINTS } from '../constants/general';
import { api } from '../constants/globals';
import { openModalWindow } from './desktop/system';
import { formatDateApi } from './format';
import { isWeb } from '../constants/modules';

// TODO: Fetch CATEGORIES from backend (need new backend endpoint)
export const CATEGORIES = [
    { id: 1, title: 'Film & Animation' },
    { id: 2, title: 'Autos & Vehicles' },
    { id: 10, title: 'Music' },
    { id: 15, title: 'Pets & Animals' },
    { id: 17, title: 'Sports' },
    { id: 18, title: 'Short Movies' },
    { id: 19, title: 'Travel & Events' },
    { id: 20, title: 'Gaming' },
    { id: 21, title: 'Videoblogging' },
    { id: 22, title: 'People & Blogs' },
    { id: 23, title: 'Comedy' },
    { id: 24, title: 'Entertainment' },
    { id: 25, title: 'News & Politics' },
    { id: 26, title: 'Howto & Style' },
    { id: 27, title: 'Education' },
    { id: 28, title: 'Science & Technology' },
    { id: 29, title: 'Nonprofits & Activism' },
    { id: 30, title: 'Movies' },
    { id: 31, title: 'Anime/Animation' },
    { id: 32, title: 'Action/Adventure' },
    { id: 33, title: 'Classics' },
    { id: 34, title: 'Comedy' },
    { id: 35, title: 'Documentary' },
    { id: 36, title: 'Drama' },
    { id: 37, title: 'Family' },
    { id: 38, title: 'Foreign' },
    { id: 39, title: 'Horror' },
    { id: 40, title: 'Sci-Fi/Fantasy' },
    { id: 41, title: 'Thriller' },
    { id: 42, title: 'Shorts' },
    { id: 43, title: 'Shows' },
    { id: 44, title: 'Trailers' },
];

export const ANALYTICS_TYPES = {
    channel: 'channel',
    video: 'video',
};

const NAMES = {
    estimatedMinutesWatched: 'Estimated minutes watched',
    views: 'Views',
    likes: 'Likes',
    dislikes: 'Dislikes',
    comments: 'Comments',
    subscribersGained: 'Subscribers gained',
};

const apiUploadEpisode = episodeId => async dispatch => api
    .apiPost(ENDPOINTS.youtube.uploadVideo, { episode_id: episodeId });

const apiGetChannelInfo = channelId => async (dispatch) => {
    try {
        return await api.apiGet(ENDPOINTS.youtube.getChannelInfo, { channel_id: channelId });
    } catch (e) {
        // if (e.message && e.message.toLowerCase().includes('access')) {
            return null;
        // }
        // throw e;
    }
};

const apiActivateChannelPermissions = () => async (dispatch) => {
    const result = await api.apiPost(ENDPOINTS.youtube.activate, {
        redirect_url: window.location.href
    });

    if (result && result.authorization_url) {
        return result.authorization_url;
    }

    return null;
};

// eslint-disable-next-line no-unused-vars
const apiGetChannelAnalytics = (channelId, startDate, endDate) => async (dispatch) => {
    try {
        return await api.apiGet(ENDPOINTS.youtube.getChannelAnalytics, {
            channel_id: channelId,
            start_date: formatDateApi(moment('1980-01-01').toDate()), // TODO: period hardcoded
            end_date: formatDateApi(new Date()), // TODO: period hardcoded
            // start_date: formatDateApi(startDate),
            // end_date: formatDateApi(endDate),
        });
    } catch (e) {
        // if (e.message === 'Access to YouTube is not activated') {
        //     return null;
        // }
        throw e;
    }
};

// eslint-disable-next-line no-unused-vars
const apiGetVideoAnalytics = (youtubeId, startDate, endDate) => async (dispatch) => {
    try {
        return await api.apiGet(ENDPOINTS.youtube.getVideoAnalytics, {
            video_id: youtubeId,
            start_date: formatDateApi(moment('1980-01-01').toDate()), // TODO: period hardcoded
            end_date: formatDateApi(new Date()), // TODO: period hardcoded
            // start_date: formatDateApi(startDate),
            // end_date: formatDateApi(endDate),
        });
    } catch (e) {
        // if (e.message === 'Access to YouTube is not activated') {
        //     return null;
        // }
        throw e;
    }
};


const CONFIRM_ACTIVATE_TEXT = 'The application does not have the right to publish video on the channel.\n'
    + 'You need to give permission first to publish the video\n'
    + 'Continue?';

const doUploadEpisode = (episodeId, callback) => async (dispatch) => {
    try {
        await dispatch(apiUploadEpisode(episodeId));
        alert('Upload started you can view progress on Youtube');
        if (callback) {
            callback();
        }
    } catch (e) {
        console.error(`Error while uploading episode ${episodeId}`, e);
        alert(e.message);
    }
};

const doActivateChannelPermissions = callback => async (dispatch) => {
    const activateUrl = await dispatch(apiActivateChannelPermissions());
    if (activateUrl) {
        if (isWeb) {
            window.location.href = activateUrl;
        } else {
            const currentUrl = window.location.href;
            openModalWindow(
                activateUrl,
                async (win, event, url) => {
                    if (url === currentUrl) {
                        if (callback) {
                            await callback();
                        }
                        win.close();
                    }
                }
            );
        }
    }
};

export const activateAnalyticsPermissions = callback => async (dispatch) => {
    await dispatch(doActivateChannelPermissions(callback));
    // const activateUrl = await dispatch(apiActivateChannelPermissions());
    // if (activateUrl) {
    //     if (isWeb) {
    //         window.location.href = activateUrl;
    //     } else {
    //         const currentUrl = window.location.href;
    //         openModalWindow(
    //             activateUrl,
    //             async (win, event, url) => {
    //                 if (url === currentUrl) {
    //                     if (callback) {
    //                         await callback();
    //                     }
    //                     win.close();
    //                 }
    //             }
    //         );
    //     }
    // }
};


export const uploadEpisode = (channelId, episodeId, callback) => async (dispatch) => {
    if (!channelId) {
        alert('You should fill Youtube channel Id in the series first');
        return false;
    }

    const chInfo = await dispatch(apiGetChannelInfo(channelId));
    if (chInfo) {
        await dispatch(doUploadEpisode(episodeId, callback));
    } else if (window.confirm(CONFIRM_ACTIVATE_TEXT)) {
        await dispatch(doActivateChannelPermissions(
            async () => dispatch(doUploadEpisode(episodeId, callback))
        ));
    }

    return true;
};

export const getAnalyticsKeys = () => Object.keys(NAMES).map(key => ({
    key,
    name: NAMES[key],
    value: undefined,
}));

export const getAnalytics = (type, id, startDate, endDate) => async (dispatch) => {
    let func;
    switch (type) {
    case ANALYTICS_TYPES.video:
        func = apiGetVideoAnalytics;
        break;
    case ANALYTICS_TYPES.channel:
        func = apiGetChannelAnalytics;
        break;
    default:
        throw new Error(`Get YT analytics function is not defined fot type ${type}`);
    }

    try {
        const result = await dispatch(func(id, startDate, endDate));

        const row = (result && result.rows && result.rows.length > 0) ? result.rows[0] : [];
        const data = (result && result.columnHeaders) ? result.columnHeaders.reduce((acc, col, index) => ({
            ...acc,
            [col.name]: row.length > index ? row[index] : 0,
        }), {}) : {};

        return getAnalyticsKeys().map(rec => ({
            ...rec,
            value: data[rec.key] !== undefined ? data[rec.key] : 0,
        }));
    } catch (e) {
        console.error(`Error while getting ${type} analytics`, e);
        return null;
    }
};
