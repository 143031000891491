import { LIST_OPTIONS } from '../../constants/pouchDB';
import { GENERAL_ACTION_TYPES } from '../general';

const initialState = {
    ...LIST_OPTIONS.videos,
    list: []
};

export const ACTION_TYPES = {
    videos: {
        success: 'VIDEOS_FETCH_SUCCESS',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.videos.success: {
        return {
            ...state,
            list: action.list
        };
    }

    case GENERAL_ACTION_TYPES.authClear: {
        return { ...initialState };
    }

    default: {
        return state;
    }
    }
};

export default reducer;
