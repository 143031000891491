import PropTypes from 'prop-types';
import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage2.module.css';
import image from './image_store.svg';

const ProjectHelpPage2 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                You can choose to store on
                your local drive or in the cloud
                using these toggles
            </h2>
            <img className={styles.image} src={image} alt="arrow" />
        </div>
    </TitledPage>
);

ProjectHelpPage2.propTypes = {
    title: PropTypes.string,
};

export default ProjectHelpPage2;
