import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { faCheckDouble, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { checkAllowCloud } from '../../utils/permissions';
// import { ActionAddFolderAsFile } from '../ActionAddFolderAsFile';
import ActionCreateFolder from '../ActionCreateFolder/ActionCreateFolder';
import ActionDownloadFile from '../ActionDownloadFile/ActionDownloadFile';
import ActionRemove from '../Actions/ActionRemove/ActionRemove';
import Button from '../Button/Button';
import { Icon } from '../Icon';
import styles from './ActionFileTypeMenu.module.css';
import ActionFileSync from '../ActionFileSync/ActionFileSync';
import DropDown from '../DropDown/DropDown';
import { isWeb } from '../../constants/modules';

const ActionFileTypeMenu = ({
    fileType,
    project,
    checkedFiles,
    checkDisabled,
    checkedAll,
    checkListsCheckAllAction,
    removeFileAction,
    removeFileCallback,
    canUseCloud
}) => (
    <DropDown
        noIcon
        items={[
            // Unused feature for now
            // {
            //     component: <ActionAddFolderAsFile fileType={fileType} projectId={project._id} callback={removeFileCallback} />,
            //     className: styles.underlined,
            //     show: !isWeb && fileType === 'file-project',
            // },
            {
                component: <ActionCreateFolder fileType={fileType} projectId={project._id} />,
                className: styles.underlined,
                show: !isWeb,
            },
            {
                component: (
                    <Button
                        tiny
                        disabled={checkDisabled}
                        color={Button.COLOR.NONE}
                        onClick={checkListsCheckAllAction}
                        className={cn(styles.button, styles.check)}
                    >
                        <div className={styles.icon}>
                            <Icon icon={faCheckDouble} />
                        </div>
                        {checkedAll ? 'Uncheck' : 'Check'}
                        {' '}
                        all
                    </Button>
                )
            },
            {
                component: (
                    <ActionFileSync
                        files={checkedFiles}
                        disabled={!project.storeInCloud}
                    />
                ),
                show: canUseCloud && !isWeb,
            },
            {
                component: <ActionDownloadFile cloud={undefined} />,
                show: canUseCloud,
            },
            {
                component: <ActionRemove docs={checkedFiles} action={removeFileAction} callback={removeFileCallback} />,
                show: !!removeFileAction,
            },
        ]}
        alignRight
        btnProps={{ icon: faEllipsisV }}
    />
);

ActionFileTypeMenu.propTypes = {
    fileType: PropTypes.string,
    project: PropTypes.shape({
        _id: PropTypes.string,
        storeInCloud: PropTypes.bool,
    }),
    removeFileAction: PropTypes.func,
    removeFileCallback: PropTypes.func,
    canUseCloud: PropTypes.bool,

    checkedFiles: PropTypes.array,
    checkDisabled: PropTypes.bool,
    checkedAll: PropTypes.bool,
    checkListsCheckAllAction: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionFileTypeMenu);
