import PropTypes from 'prop-types';
import React from 'react';
import { ShareSettingsButton } from '../../../components/ShareSettingsButton';
import IconProjectHasFileType from '../../../components/Projects/common/IconProjectHasFileType/IconProjectHasFileType';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../constants/docs';
import { SHARE_TYPES } from '../../../constants/share';

const ProjectListItemIcons = ({ item }) => (
  <>
    <IconProjectHasFileType project={item} type={DOC_TYPE_FILE_FINISH_VIDEO} />
    <IconProjectHasFileType project={item} type={DOC_TYPE_FILE_FINISH_AUDIO} />
    <ShareSettingsButton onlyIcon sharing={item.sharing} type={SHARE_TYPES.project} />
  </>
);
ProjectListItemIcons.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    _totals: PropTypes.shape({
      fileCount: PropTypes.shape({}),
    }),
    sharing: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }),
};

export default ProjectListItemIcons;
