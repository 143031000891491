import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../components/Logo/Logo';
import { IconFileTypeMisc } from '../../../../components/ProjectDetails/ProjectFileTypeContainer/icons/IconFileTypeMisc';
import Version from '../../../../components/Version';
import { ROUTE } from '../../../../constants/general';
import { RecentContext, TRecentItemType } from '../../../../Providers/Recent';
import { UserContext } from '../../../../Providers/User';
import { ShareIcon } from '../../../../resources/svg/ShareIcon';
import styles from './nav.module.scss';
import { IconAudioNav } from './resources/IconAudioNav';
import { IconProjectsNav } from './resources/IconProjectsNav';
import { IconSeriesNav } from './resources/IconSeriesNav';
import { IconVideoNav } from './resources/IconVideoNav';
import cn from 'classnames';

type TNavItemProps = {
  title: string
  link?: string
  icon?: ReactNode
  level?: number
  className?: string
}
type TNavGroupProps = {
  title: string
  icon?: ReactNode
  items?: TNavItemProps[]
  showItems?: boolean
  handleShowItems?: () => void
  className?: string
}


const NavDivider = () => <hr style={{ width: '100%' }} />

const NavGroup: React.FC<TNavGroupProps> = (props) => {
  const { title, icon, items, showItems = true, handleShowItems, className = styles.navItem } = props;

  return (
    <>
      {title === '-'
        ? <NavDivider key={title} />
        : title && (
        <div onClick={handleShowItems} className={cn(styles.item, className)}>
          {icon && (
            <div className={styles.imgContainer}>
              {icon}
            </div>
          )}
          {title}
        </div>
      )}
      {showItems && items?.map(item => (
        <NavItem key={`${item.link ?? ''}_${item.title}`} {...item} />
      ))}
    </>
  );
}

const NavItem: React.FC<TNavItemProps> = (props) => {
  const { title, link, icon, level = 1, className = styles.navItem } = props;
  return link
    ? (
      <NavLink to={link} key={`${link}_${title}`} className={cn(styles.item, className)} activeClassName={styles.active}
               style={{ paddingLeft: 8 * level }}>
        {icon && (
          <div className={styles.imgContainer}>
            {icon}
          </div>
        )}
        <div className={styles.title}>{title}</div>
        <span />
      </NavLink>
    )
    : (
      <NavDivider key={`${link ?? ''}_${title}`} />
    );
}


const getRecentIcon = (type: TRecentItemType) => {
  switch (true) {
    case (type === 'project'):
      return <IconProjectsNav />
    case (type === 'video'):
      return <IconVideoNav />
    case (type === 'audio'):
      return <IconAudioNav />
    case (type === 'series'):
      return <IconSeriesNav />
    default:
      return undefined;
  }
}


export const Nav: React.FC = () => {
  const { permissions: { canUseApp, canReadUsers } } = useContext(UserContext);
  const { recent } = useContext(RecentContext);

  const [visibleGroups, setVisibleGroups] = useState<string[]>(['recentPages']);
  const toggleGroup = useCallback((groupId: string) => {
    if (groupId) {
      setVisibleGroups(s => s.includes(groupId) ? [] : [groupId]);
    } else {
      setVisibleGroups([]);
    }
  }, []);

  const items = useMemo<TNavGroupProps[]>(() => {
    if (!canUseApp) {
      return [];
    }
    return [
      {
        title: '',
        items: [
          {
            title: 'Projects',
            link: ROUTE.projects.root,
            icon: <IconProjectsNav />
          },
          {
            title: 'Series',
            link: ROUTE.series.root,
            icon: <IconSeriesNav />
          },
          {
            title: 'Videos',
            link: ROUTE.videos.root,
            icon: <IconVideoNav />,
          },
          {
            title: 'Audio',
            link: ROUTE.audios.root,
            icon: <IconAudioNav />,
          },
        ],
      },
      ...(canUseApp && canReadUsers
          ? [{
            title: 'Manage Users',
            icon: <ShareIcon fill="white" height={17} />,
            showItems: canUseApp && canReadUsers && visibleGroups.includes('users'),
            handleShowItems: () => toggleGroup('users'),
            items: [
              { title: 'Users', link: ROUTE.users, level: 2 },
              { title: 'Roles', link: ROUTE.roles, level: 2 },
              { title: 'Comms', link: ROUTE.communications, level: 2 },
              { title: 'Stats', link: ROUTE.stats, level: 2 },
            ],
          }]
          : []
      ),
      ...(recent.length
          ? [
            { title: '-' },
            {
              title: 'Recent pages',
              icon: <IconFileTypeMisc fill="white" />,
              showItems: canUseApp && visibleGroups.includes('recentPages'),
              handleShowItems: () => toggleGroup('recentPages'),
              items: recent.map(it => {
                return {
                  title: it.title,
                  link: it.to,
                  icon: getRecentIcon(it.type),
                  className: styles.recentItem
                }
              })
            },
          ]
          : []
      ),

    ]
  }, [canUseApp, canReadUsers, visibleGroups, toggleGroup, recent]);

  return (
    <nav>
      <div className={styles.logoWrapper}>
        {/* @ts-ignore */}
        <Logo inverse />
      </div>
      {items.map((group, ind) => <NavGroup key={group.title || ind} {...group} />)}
      <div className={styles.bottom}>
        <Version />
      </div>
    </nav>
  );
}
