import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getS3Url } from '../../utils/cloud/cloudS3';
import placeholder16x9 from './16x9.svg';

export const PLACEHOLDER_TYPES = {
  t16x9: '16x9'
};

class S3img extends Component {
  static propTypes = {
    src: PropTypes.shape({ key: PropTypes.string.isRequired }),
    getSrc: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: PLACEHOLDER_TYPES.t16x9,
  };

  state = {
    src: null,
  };

  componentDidMount() {
    this._mounted = true;
    this.getSrc();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevSrc = prevProps.src || {};
    const curSrc = this.props.src || {};
    if (prevSrc.key !== curSrc.key) {
      this.getSrc();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getSrc = () => {
    const { src, getSrc } = this.props;
    if (src) {
      getSrc()
        .then((data) => {
          if (this._mounted) {
            this.setState({ src: data.url });
          }
          return data;
        })
        .catch(() => {
          if (this._mounted) {
            this.setState({ src: '' });
          }
        });
    }
  };


  render() {
    const { getSrc, placeholder, ...otherProps } = this.props;
    let { src } = this.state;

    if (!src && placeholder) {
      switch (placeholder) {
      case PLACEHOLDER_TYPES.t16x9:
        src = placeholder16x9;
        break;
      default:
      }
    }

    return <img alt="" {...otherProps} src={src} />;
  }
}

const mapDispatchToProps = (dispatch, { src }) => ({
  getSrc: () => getS3Url(src || {}),
});

export default connect(null, mapDispatchToProps)(S3img);
