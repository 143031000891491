import cn from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { ButtonNew } from '../../../components/ButtonNew';
import { FileInputButton } from '../../../components/FileInputButton';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import { PROJECT_SCHEMA } from '../../../constants/general';
import { IconStoreInCloud } from '../../../resources/svg/IconStoreInCloud';
import { IconStoreOnLocalDrive } from '../../../resources/svg/IconStoreOnLocalDrive';
import { formatDateApi } from '../../../utils/format';
import useProjectCreate from './project-create';
import styles from './projectCreate.module.scss';
import { ProjectCreateFiles } from './ProjectCreateFiles';
import { ProjectCreateImport } from './ProjectCreateImport';


const ProjectCreate = () => {
  const {
    canUseCloud,
    showCreatedDate,
    project,
    files,
    handleSubmit,
    handleAddFiles,
    handleSelectFile,
    handleImportFiles,
    handleDeleteFiles,
    chosenType,
    setChosenType,
    showFiles,
  } = useProjectCreate();

  return (
    <>
      <h1 className={styles.title}>Okay, lets create a new&nbsp;project</h1>
      <Formik
        initialValues={project}
        validationSchema={PROJECT_SCHEMA}
        // enableReinitialize
        onSubmit={handleSubmit}
        render={({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Field name="title"
                   component={Input}
                   noValidateIcon
                   label="Title:"
                   labelWidth={Input.LABEL_WIDTH.FULL}
                   placeholder="Give your project a title"
                   spellCheck
            />
            <Field name="description"
                   type="textarea"
                   noValidateIcon
                   component={Input}
                   label="Description:"
                   labelWidth={Input.LABEL_WIDTH.FULL}
                   placeholder="Brief description about your project"
                   spellCheck
            />
            <div className={styles.flags}>
              <Field name="storeOnLocalDrive"
                     label={(
                       <>
                         Store on local drive:
                         <IconStoreOnLocalDrive fill={values.storeOnLocalDrive ? 'black' : '#9e9e9e'} />
                       </>
                     )}
                     component={Input}
                     noValidateIcon
                     type={Input.TYPE.SWITCH}
                     labelClassName={styles.flagLabel}
                     onChange={(e) => {
                       const val = e.target.value;
                       if (canUseCloud) {
                         setFieldValue('storeOnLocalDrive', val);
                         if (!val && !values.storeInCloud) {
                           setFieldValue('storeInCloud', true);
                         }
                       } else {
                         setFieldValue('storeInCloud', true);
                       }
                       setFieldValue('storeOnLocalDrive', e.target.value);
                     }}
              />
              {canUseCloud && (
                <Field name="storeInCloud"
                       label={(
                         <>
                           Store in cloud:
                           <IconStoreInCloud fill={values.storeInCloud ? 'black' : '#9e9e9e'} />
                         </>
                       )}
                       component={Input}
                       noValidateIcon
                       type={Input.TYPE.SWITCH}
                       labelClassName={styles.flagLabel}
                       onChange={(e) => {
                         const val = e.target.value;
                         setFieldValue('storeInCloud', val);
                         if (!val && !values.storeOnLocalDrive) {
                           setFieldValue('storeOnLocalDrive', true);
                         }
                       }}
                />
              )}
            </div>
            <div className={styles.noLabelContainer}>
              <div className={cn(styles.lightText, styles.flexCenter)}>
                <div>
                  {files.length
                    ? `You have already added ${files.length} files`
                    : 'Add files to your project'
                  }
                </div>
                <FileInputButton
                  type={chosenType}
                  addFiles={handleAddFiles}
                  disabled={isSubmitting}
                />
                {/*<ButtonNew*/}
                {/*  color="none"*/}
                {/*  noTextTransform*/}
                {/*  onClick={handleShowFiles}*/}
                {/*  icon={showFiles ? faCaretUp : faCaretDown}*/}
                {/*  className={cn(styles.addFilesButton, styles.lightText)}*/}
                {/*  iconPosition="after"*/}
                {/*>{showFiles ? 'Hide files' : 'Show files'}</ButtonNew>*/}
              </div>
              <ProjectCreateImport
                setMinimumDate={date => date && setFieldValue('created', formatDateApi(date))}
                addFiles={handleImportFiles}
              />
            </div>
            {showCreatedDate && (
              <Field name="created"
                     type="date"
                     noValidateIcon
                     component={Input}
                     label="Created:"
                     labelWidth={Input.LABEL_WIDTH.FULL}
              />
            )}
            <ProjectCreateFiles
              defaultType={chosenType}
              setDefaultType={setChosenType}
              files={files}
              handleAddFiles={handleAddFiles}
              handleSelectFile={handleSelectFile}
              handleDeleteFiles={handleDeleteFiles}
              disabled={isSubmitting}
              showFiles={showFiles}
            />
            <FormButtons topMargin>
              <ButtonNew size="big" type="submit" disabled={isSubmitting}>
                Create
              </ButtonNew>
            </FormButtons>
          </Form>
        )}
      />
    </>
  );
};

export default ProjectCreate;
