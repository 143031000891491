import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core';
import StatusBar from '@uppy/status-bar';

class UppyWrapper {
    _uppy;

    constructor() {
        this._uppy = Uppy(this._getUppyOptions());
        this._initEvents();
        this.upload = this._uppy.upload;
        this.addFile = this._uppy.addFile;
    }

    _initEvents = () => {
        this._uppy
            .on('file-added', (file) => {
                if (file.meta && file.meta.syncEvents && file.meta && file.meta.syncEvents.setInit) {
                    file.meta.syncEvents.setInit(file.data.size);
                }
            })
            .on('upload-progress', (file, progress) => {
                // console.log('upload-progress', file, progress);
                if (file.meta && file.meta.syncEvents && file.meta && file.meta.syncEvents.addProgress) {
                    file.meta.syncEvents.addProgress(progress.bytesUploaded, false);
                }
            })
            .on('upload-success', async (file, data) => {
                // console.log('upload-success', { file: uppy.getFile(file.id), data });
                if (file.meta && file.meta.onUploadSuccess) {
                    await file.meta.onUploadSuccess(this._uppy.getFile(file.id), data);
                }
                if (file.meta && file.meta.syncEvents && file.meta && file.meta.syncEvents.setComplete) {
                    file.meta.syncEvents.setComplete();
                }
            })
            .on('upload-error', (file, error) => {
                // console.error('upload-error', { file, error: error && error.message });
                if (file.meta && file.meta.syncEvents && file.meta && file.meta.syncEvents.setComplete) {
                    file.meta.syncEvents.setComplete(error && error.message);
                }
            });

        // Unused events
        // uppy
        //     .on('upload', (data) => {
        //         console.log(`[${new Date().toLocaleString()}] upload`, data);
        //         // data = {
        //         //     id: "ck5gpl60k00003b5yg7bc4az6", // bunch upload id
        //         //     fileIDs: [] // uppy file ids
        //         // }
        //     })
        //     .on('complete', (result) => {
        //         console.log(`[${new Date().toLocaleString()}] complete`, result);
        //     })
        //     .on('file-removed', (file) => {
        //         console.log('file-removed', file);
        //     })
        //     .on('upload-retry', (fileID) => {
        //         console.log('upload retried:', fileID);
        //     })
        //     .on('info-visible', () => {
        //         // const { info } = uppy.getState();
        //         // info: { isHidden: false, type: 'error', message: 'Failed to upload', details: 'Error description' }
        //         // alert(`${info.message} ${info.details}`)
        //         console.info('info-visible', { info: uppy.getState().info });
        //     })
        //     .on('restriction-failed', (file, error) => {
        //         // do some customized logic like showing system notice to users
        //         console.log('restriction-failed', { file, error });
        //     })
        //     .on('file-removed', (file) => {
        //         console.log('file-removed', file);
        //     })
        //     .on('error', (error) => {
        //         console.log('error', error);
        //     });
    };

    _getUppyOptions = () => ({
        debug: true,
        autoProceed: true,
        restrictions: {}
    });

    upload = () => { throw new Error('Function upload not set'); };

    addFile = () => { throw new Error('Function addFile not set'); };

    useStatusBar = () => {
        if (document.getElementsByClassName('uppy-StatusBar').length === 0) {
            this._uppy.use(StatusBar, {
                target: 'body',
                hideProgressDetails: false,
                hideAfterFinish: true,
                hideUploadButton: false,
            });
        }
    };

    setOptions = ({ awsS3MultipartOptions }) => {
        this._uppy.iteratePlugins(plugin => this._uppy.removePlugin(plugin));
        this._uppy.setOptions(this._getUppyOptions());

        if (awsS3MultipartOptions) {
            this._uppy.use(AwsS3Multipart, awsS3MultipartOptions);
        }
    };
}

export default () => new UppyWrapper();
