import { getElectronProp } from '../utils/system';
import configs from './configs';
import { pathJoin } from '../utils/desktop/path';
import { isWeb } from './modules';

export const frontEndLocation = isWeb ? window.location.origin : configs.frontEndLocation;

export const isMac = !isWeb && process.platform === 'darwin';

export const app = !isWeb ? getElectronProp('app') : { getPath: () => undefined };

export const systemPaths = !isWeb
    ? {
        home: app.getPath('home'), // TODO: remove later
        documents: app.getPath('documents'),
        appData: app.getPath('appData'),
        appPath: pathJoin(app.getPath('appData'), configs.appName),
        mainPath: pathJoin(app.getPath('documents'), configs.appName),
        userData: pathJoin(app.getPath('appData'), configs.appName),
        // app.getPath('userData'),
    }
    : {};

if (!isWeb) {
    console.log('app.getPath(name)', {
        home: app.getPath('home'),
        appData: app.getPath('appData'),
        userData: app.getPath('userData'),
        cache: app.getPath('cache'),
        temp: app.getPath('temp'),
        exe: app.getPath('exe'),
        module: app.getPath('module'),
        desktop: app.getPath('desktop'),
        documents: app.getPath('documents'),
        downloads: app.getPath('downloads'),
        music: app.getPath('music'),
        pictures: app.getPath('pictures'),
        videos: app.getPath('videos'),
        logs: app.getPath('logs'),
    });
    console.log('systemPaths', systemPaths);
}
