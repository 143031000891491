import React from 'react';
import BarItem from '../../Component/BarItem/BarItem';
import image from './image_HitDownload.svg';
import configs from '../../../../../../../../constants/configs';

const HitDownload = () => (
    <BarItem
        number={1}
        text={(
            <>
                Download and install
                <br />
                {` ${configs.appName} `}
                app on your
                <br />
                computer
            </>
        )}
        image={image}
    />
);

export default HitDownload;
