import React, { useContext, useMemo } from 'react';
import { TSharing } from '../../constants/docs';
import { UserContext } from '../../Providers/User';
import { getShareProperty } from '../../utils/share';
import { isSharing } from '../../utils/webSettings';
import { ButtonNew } from '../ButtonNew';
import { ShareIcon } from '../../resources/svg/ShareIcon';


type TShareSettingsButtonProps = {
  type: 'project' | 'file'
  sharing?: TSharing
  disabled?: boolean
  onlyIcon?: boolean
  onClick?: () => void
};


export const ShareSettingsButton: React.FC<TShareSettingsButtonProps> = (props) => {
  const {
    type,
    disabled = false,
    onlyIcon = false,
    onClick = () => undefined,
    sharing,
  } = props

  const { permissions: { canUseCloud } } = useContext(UserContext);

  const show = useMemo(() => {
    return canUseCloud && !isSharing()
  }, [canUseCloud])

  const color = useMemo(() => {
    if (!show) {
      return '#00a14b';
    }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return getShareProperty(sharing).filter(sh => sh.type === type).length ? '#00a14b' : '#d6d6d6';
  }, [sharing, type, show]);


  return !show ? <></> : (
    onlyIcon ? (
      <ShareIcon fill={color} />
    ) : (
      <ButtonNew
        color={onlyIcon ? 'transparent' : 'primary'}
        disabled={disabled}
        onClick={onClick}
        iconComponent={<ShareIcon fill={color} height={17} />}
      >
        {!onlyIcon && 'Share'}
      </ButtonNew>
    )
  )
}
