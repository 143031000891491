import { Field, Form, Formik, FormikActions } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { ButtonNew } from '../../../../../../components/ButtonNew';
import Input from '../../../../../../components/Input/Input';
import { TDocFile } from '../../../../../../constants/docs';
import { useDocument } from '../../../../../../helpers/use-document';
import styles from './file-notes.module.scss';


type TFileNotesProps = {
  docId: string
}
type TFileNotesValues = {
  _id: string
  notes: string
}

export const FileNotes: FC<TFileNotesProps> = ({ docId }) => {
  const { doc, writeDoc } = useDocument<TDocFile>({ id: docId });
  const [isEdit, setIsEdit] = useState(false);


  const handleEditClick = useCallback((resetForm?: () => void) => {
    if (isEdit && resetForm) {
      resetForm();
    }
    setIsEdit(s => !s);
  }, [isEdit])

  const handleSubmit = useCallback(async (values: TFileNotesValues, action: FormikActions<TFileNotesValues>) => {
    action.setSubmitting(true);
    await writeDoc(values);
    setIsEdit(false);
    action.setSubmitting(true);
  }, [writeDoc]);


  return (
    <Formik<TFileNotesValues>
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{ _id: docId, notes: doc.notes ?? '' }}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <Form className={styles.wrapper}>
          <div className={styles.fields}>
            <Field name="notes"
                   type="textarea"
                   component={Input}
                   noValidateIcon
                   label="Notes:"
                   labelWidth={Input.LABEL_WIDTH.FULL}
                   placeholder="Add notes about your file"
                   spellCheck
                   readOnly={!isEdit}
                   editClick={handleEditClick}
                   spaceBottom={Input.SPACE_BOTTOM.SMALL}
                   className={styles.notes}
            />
          </div>
          <div className={styles.actions}>
            <ButtonNew onClick={() => handleEditClick(resetForm)}
                       color={isEdit ? 'secondary' : 'primary'}
              // className={styles.toggleEdit}
            >
              {isEdit ? 'Cancel' : 'Edit'}
            </ButtonNew>
            {isEdit && (
              <ButtonNew type="submit"
                // className={styles.toggleEdit}
              >
                Save
              </ButtonNew>
            )}
          </div>

        </Form>
      )}
    </Formik>
  )
}
