import React from 'react';
import PropTypes from 'prop-types';
import styles from './TitledPage.module.css';

const TitledPage = ({ title, children }) => (
    <div className={styles.container}>
        {!!title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.page}>
            {children}
        </div>
    </div>
);

TitledPage.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
};

export default TitledPage;
