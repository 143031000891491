// @flow
import { findUserDocs } from '../../handlers/userDB';
import { ACTION_TYPES } from './reducer';

export const ACTIONS = {
    success: list => ({ type: ACTION_TYPES.success, list }),
};

export const fetchAudios = () => async (dispatch, getState) => {
    const { audios } = getState();
    return dispatch(findUserDocs(audios));
};

export const readAudiosList = () => async (dispatch) => {
    const list = await dispatch(fetchAudios());
    dispatch(ACTIONS.success(list));
};
