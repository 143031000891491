import { initUppy } from '../constants/handlers';
import { actionSetLoginProcess } from '../modules/user/userActions';
import { cleanTempFiles } from '../utils/desktop/desktop';
import { updateDb } from '../utils/pouchDB/dbUpdate/updater';
import { doCheckToken, getUserId, getWeb } from '../utils/webSettings';
import { doFetchUserId, doFetchUserProperties, doReadDbUser, doReadLocalSettings } from './authUtils';
import { initLocalDB, initRemoteDB, initSyncDB } from './dbUtils';
import { webTokenAuth } from './tokenUtils';
import { isWeb } from '../constants/modules';

export const doDBLogin = () => async (dispatch, getState) => {
    if (await dispatch(initLocalDB())) {
        if (await dispatch(initRemoteDB())) {
            initUppy();

            if (await dispatch(initSyncDB())) {
                await dispatch(updateDb()); // Need to launch after replicate data from remote DB
            }
        }
    }

    const { db: { isDbConnected } } = getState();
    return isDbConnected;
};

export const checkLogin = () => async (dispatch) => {
    dispatch(actionSetLoginProcess(true));
    if (doCheckToken(getWeb())) {
        if (await dispatch(doFetchUserId())) {
            if (await dispatch(doFetchUserProperties())) {
                if (await dispatch(doReadLocalSettings(getUserId()))) {
                    if (!isWeb) {
                        await dispatch(cleanTempFiles());
                    }

                    if (await dispatch(doDBLogin())) {
                        await dispatch(doReadDbUser());
                    }
                }
            }
        }
    }
    dispatch(actionSetLoginProcess(false));
};

export const doWebLogin = user => async (dispatch) => {
    await dispatch(webTokenAuth(user));
    await dispatch(checkLogin());
};
