import React from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'react-widgets/lib/Multiselect';
import cn from 'classnames';
import { isEmpty } from '../../../utils/common';
import CopyValueButton from '../../CopyValueButton/CopyValueButton';
import MultiSelectReadOnly from './MultiSelectReadOnly';
import styles from './MultiSelectWrapper.module.css';


const getValueLength = (value) => {
  if (Array.isArray(value)) {
    return value.join(',').length;
  }
  if (typeof value === 'string') {
    return value.length;
  }
  return 0;
};

class MultiSelectWrapper extends React.Component {
  static propTypes = {
    showCopyButton: PropTypes.bool,
    copyAction: PropTypes.func,
    inputProps: PropTypes.any,
    maxLength: PropTypes.number,
    onClick: PropTypes.func,
  };

  static defaultProps = { showCopyButton: false };

  render() {
    const { showCopyButton, copyAction, inputProps, maxLength, noClick, ...rest } = this.props;

    const copyButton = showCopyButton && !isEmpty(rest.value) && (
      <CopyValueButton
        key="CopyValueButton"
        value={rest.value}
        className={styles.button}
        onClick={copyAction}
      />
    );

    if (rest.readOnly) {
      return (
        <div className={styles.container}>
          <MultiSelectReadOnly key="MultiSelectReadOnly" noClick={noClick} {...rest} />
          {copyButton}
        </div>
      );
    }

    const valueLength = maxLength && getValueLength(rest.value);
    const lengthText = maxLength && (
      <div className={cn(styles.valueLength, valueLength > maxLength && styles.red)}>
        {`${valueLength} / ${maxLength}`}
      </div>
    );

    return (
      <div>
        <div className={styles.container}>
          <Multiselect
            containerClassName={styles.selectContainer}
            key="Multiselect"
            inputProps={{
              spellCheck: false,
              ...(inputProps || {})
            }}
            {...rest}
          />
          {copyButton}
        </div>
        {lengthText}
      </div>
    );
  }
}

MultiSelectWrapper.propTypes = {
  showCopyButton: PropTypes.bool,
  copyAction: PropTypes.func
};

export default MultiSelectWrapper;
