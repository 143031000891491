import React, { Component } from 'react';
import { getLatestBuilds } from '../../../../utils/site';
import Anchor from '../../Anchor/Anchor';
import DownloadBlock from './DownloadBlock/DownloadBlock';
import styles from './Download.module.css';
import imageMac from './img/image_mac_os.png';
import imageWin from './img/image_windows.png';
import imageLinux from './img/image_linux.svg';

class Download extends Component {
  state = {
    mac: null,
    win: null,
    linux: null,
  };

  componentDidMount() {
    this._mounted = true;
    this.checkLatestBuilds().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  checkLatestBuilds = async () => {
    const result = await getLatestBuilds();
    if (this._mounted) {
      this.setState(result);
    }
  };

  render() {
    const { mac, win, linux } = this.state;

    return (
      <>
        <Anchor id="Download">
          <h1>Download</h1>
        </Anchor>
        <div className={styles.fileContainer}>
          {mac && mac.files && mac.files.dmg && (
            <DownloadBlock
              title="Mac"
              image={imageMac}
              href={`${process.env.REACT_APP_RELEASES_URI}/${mac.files.dmg.url}`}
            />
          )}
          {win && win.files && win.files.exe && (
            <DownloadBlock
              title="Windows"
              image={imageWin}
              href={`${process.env.REACT_APP_RELEASES_URI}/${win.files.exe.url}`}
            />
          )}
          {linux && linux.files && linux.files.AppImage && (
            <DownloadBlock
              title="Linux"
              image={imageLinux}
              href={`${process.env.REACT_APP_RELEASES_URI}/${linux.files.AppImage.url}`}
            />
          )}
        </div>
      </>
    );
  }
}

export default Download;
