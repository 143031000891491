import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import styles from './HomeBlock.module.css';

const HomeBlock = ({ children, noPadding, fullWidth, className }) => (
    <div
        className={cn(
            styles.container,
            noPadding && styles.noPadding,
            fullWidth && styles.fullWidth,
            className
        )}
    >
        {children}
    </div>
);

HomeBlock.propTypes = {
    className: PropTypes.string,
    noPadding: PropTypes.bool,
    fullWidth: PropTypes.bool,
    children: PropTypes.any,
};

export default HomeBlock;
