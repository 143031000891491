import React, { createContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TDocument } from '../../constants/docs';
import { removeReservedFields } from '../../handlers/userDB';
import { readDoc, readDocs, writeDoc, writeDocs } from '../../utils/pouchDB/general';
import { TDoc, TDocPut } from '../../helpers/types-db';

type TUserDbContext = {
  db?: PouchDB.Database // TODO: make it required
  readUserDoc: (id: string) => Promise<TDoc>
  readUserDocs: (ids: string[]) => Promise<TDoc[]>
  writeUserDoc: (values: TDocPut) => Promise<TDoc>
  writeUserDocs: (values: TDocPut[]) => Promise<TDoc[]>
};


export const UserDbContext = createContext<TUserDbContext>({
  readUserDoc: () => Promise.resolve({} as TDoc),
  readUserDocs: () => Promise.resolve([]),
  writeUserDoc: () => Promise.resolve({} as TDoc),
  writeUserDocs: () => Promise.resolve([]),
})


export const UserDbProvider: React.FC = ({ children }) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment
  const db: PouchDB.Database<TDocument> = useSelector(state => state?.db?.userDB);

  const readUserDoc = useCallback(async (id: string): Promise<TDoc> => await readDoc(id, db), [db]);
  const readUserDocs = useCallback(async (ids: string[]): Promise<TDoc[]> => await readDocs(ids, db), [db]);

  const writeUserDoc = useCallback(async (doc: TDocPut): Promise<TDoc> => {
    return await writeDoc(removeReservedFields(doc), db);
  }, [db]);
  const writeUserDocs = useCallback(async (docs: TDocPut[]): Promise<TDoc[]> => {
    return await writeDocs(docs.map(removeReservedFields), db);
  }, [db]);


  return (
    <UserDbContext.Provider
      value={{
        db,
        readUserDoc,
        readUserDocs,
        writeUserDoc,
        writeUserDocs
      }}
    >
      {children}
    </UserDbContext.Provider>
  )
}

