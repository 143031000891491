import { asyncForEach, combineArraysOfArrays } from '../common';
import {
    fsCopy,
    fsCreateReadStream,
    fsExists,
    fsExistsSync,
    fsFstatSync,
    fsLstat,
    fsLstatSync,
    fsMove,
    fsReaddir,
    fsReaddirSync
} from './fse';
import { pathJoin } from './path';


/**
 * Source: http://zytzagoo.net/blog/2008/04/11/a-php-script-for-removing-thumbsdb-ds_store-macos-hidden-and-other-annoying-files/
 *
 * new RegEx('^\._.*'):
 * Assume it's a mac hidden file only if it starts with
 * a dot and an underscore, other files might have that
 * string somewhere else in the filename and we don't want to
 * nuke those.
 *
 * new RegEx('^\.DS_Store'), // mac specific
 * new RegEx('^\.localized'), // mac specific
 * new RegEx('^\Thumbs.db') // windows specific
 */
export const macFilter = /(?:^Thumbs\.db|^\.DS_Store|^\.localized|^\._.*)$/;


/**
 * Find all files/folders in specific folder, e.g:
 * scanDirectory('./project/src', /\.html$/) ==> ['./project/src/a.html','./project/src/build/index.html']
 * @param  {String} startPath    Path relative to this file or other file which requires this files
 * @param  {RegExp} filter       Extension name, e.g: /\.html$/
 * @param  {String} isDirectory  Flag what we should find
 * @param  {boolean} recursive   Enable recursive search
 * @return {String[]}               Result files with path string in an array
 */
export const scanDirectory = async (startPath, filter = undefined, isDirectory = false, recursive = true) => {
    if (startPath && await fsExists(startPath)) {
        const files = await fsReaddir(startPath);

        return combineArraysOfArrays(await asyncForEach(files, async (filename) => {
            const res = [];

            const filepath = pathJoin(startPath, filename);
            const stat = await fsLstat(filepath);

            if (stat.isDirectory()) {
                if (isDirectory && (!filter || filter.test(filename))) {
                    res.push(filepath);
                } else if (recursive) { // Will not continue search
                    Array.prototype.push.apply(
                        res,
                        await scanDirectory(filepath, filter, isDirectory, recursive)
                    );
                }
            } else if (!isDirectory && (!filter || filter.test(filename))) {
                if (!macFilter.test(filename)) {
                    res.push(filepath);
                }
            }

            return res;
        }));
    }

    return [];
};
/**
 * Find all files/folders in specific folder, e.g:
 * scanDirectory('./project/src', /\.html$/) ==> ['./project/src/a.html','./project/src/build/index.html']
 * @param  {String} startPath    Path relative to this file or other file which requires this files
 * @param  {RegExp} filter       Extension name, e.g: /\.html$/
 * @param  {String} isDirectory  Flag what we should find
 * @param  {boolean} recursive   Enable recursive search
 * @return {Array}               Result files with path string in an array
 */
export const scanDirectorySync = (startPath, filter, isDirectory = false, recursive = true) => {
    let results = [];

    if (!startPath || !fsExistsSync(startPath)) {
        return results;
    }

    const files = fsReaddirSync(startPath);
    for (let i = 0; i < files.length; i += 1) {
        const filename = files[i];
        const filepath = pathJoin(startPath, filename);
        const stat = fsLstatSync(filepath);

        if (stat.isDirectory()) {
            if (isDirectory && filter.test(filename)) {
                results.push(filepath);
            } else if (recursive) { // Will not continue search
                results = [
                    ...results,
                    ...scanDirectorySync(filepath, filter, isDirectory, recursive), // recurse
                ];
            }
        } else if (!isDirectory && filter.test(filename)) { // } else if (filepath.indexOf(filter) >= 0) {
            if (!macFilter.test(filename)) {
                results.push(filepath);
            }
        }
    }

    return results;
};


export const getIdPart = id => `_${id}`;

export const getDateFiler = () => /^\d{8}$/;
export const getFiler = searchString => new RegExp(searchString || '.*');
export const getIdFiler = id => new RegExp(`^.+_${id}($|(\\.[^.]*)?$)`);
export const getIdFilerOld = id => new RegExp(`^${id ? `${id}` : ''}.*`);

/**
 * Find all files recursively in specific folder, e.g:
 * @param  {String} startPath    Path relative to this file or other file which requires this files
 * @param  {RegExp} docId        file._id
 * @return {Array}               Result files with path string in an array
 */
export const findFilesSync = (startPath, docId, recursive = true, isDirectory = false) => {
    const idPart = docId ? getIdPart(docId) : '';
    const files = scanDirectorySync(startPath, new RegExp(`.+${idPart}(\\.[^.]*)?$`), isDirectory, recursive);
    if (files && files.length) {
        return files;
    }
    // Old name convention // TODO: remove it later
    return scanDirectorySync(startPath, new RegExp(`^${docId ? `${docId}` : ''}.*`), isDirectory, recursive);
};

/**
 * Find project recursively in specific folder, e.g:
 * @param  {String} startPath    Path relative to this file or other file which requires this files
 * @param  {RegExp} docId        project._id
 * @return {Array}               Result files with path string in an array
 */
export const findFolderWithId = async (startPath, docId, recurcive = false) => {
    const idPart = getIdPart(docId);
    return scanDirectory(startPath, new RegExp(`${idPart}$`), true, recurcive);
};
// TODO: replace it to async function
export const findFolderWithIdSync = (startPath, docId, recurcive = false) => {
    const idPart = getIdPart(docId);
    return scanDirectorySync(startPath, new RegExp(`${idPart}$`), true, recurcive);
};

const pathsEquals = (from, to) => from.split('\\').join('/').toLowerCase() === to.split('\\').join('/').toLowerCase();

export const isFolderEmpty = (dirname) => {
    try {
        const files = fsReaddirSync(dirname);
        return !files.length;
    } catch (e) {
        // nothing
    }
    return true;
};

export const copyFile = async (from, to, move) => {
    if (!pathsEquals(from, to)) {
        try {
            if (move) {
                await fsMove(from, to);
            } else {
                await fsCopy(from, to);
            }
        } catch (e) {
            throw new Error(`Can not copy file from "${from}" to "${to}": ${e.message}`);
        }
    }
};

/** Returned file data in Blob */
export const readFileStream = async (filepath, options, syncEvents) => new Promise(async (resolve, reject) => {
    try {
        const chunks = []; // Store file data chunks in this array
        // let fileBuffer; // We can use this variable to store the final data

        // Read file into stream.Readable
        const fileStream = fsCreateReadStream(filepath, options);

        fileStream
            .on('open', (fd) => {
                const stats = fsFstatSync(fd);
                // console.log('RS on open', fd);
                if (syncEvents && syncEvents.setInit) {
                    syncEvents.setInit(stats.size / 0.99); // 1% on 'end' event
                }
            })
            .once('error', (err) => {
                // console.log('RS on error', err);
                // An error occurred with the stream
                // Be sure to handle this properly!
                console.error(err);
                if (syncEvents && syncEvents.setComplete) {
                    syncEvents.setComplete(err.message);
                }
            })
            .once('end', () => {
                // console.log('RS on end', chunks.length);
                // File is done being read
                // create the final data Buffer from data chunks;
                const fileBuffer = new Blob(chunks);
                if (syncEvents && syncEvents.setComplete) {
                    syncEvents.setComplete();
                }
                resolve(fileBuffer);
            })
            .on('data', (chunk) => {
                // console.log('RS on data', chunk.length);
                // Data is flushed from fileStream in chunks, this callback will be executed for each chunk
                chunks.push(chunk); // push data chunk to array
                if (syncEvents && syncEvents.addProgress) {
                    syncEvents.addProgress(chunk.length);
                }
            });
    } catch (e) {
        if (syncEvents && syncEvents.setComplete) {
            syncEvents.setComplete(e.message);
        }
        reject(e);
    }
});
