import React, { FC, useContext, useEffect } from 'react';
import { ROUTE } from '../../../../constants/general';
import { uppy } from '../../../../constants/globals';
import { r } from '../../../../constants/routes';
import { UserContext } from '../../../../Providers/User';
import { Account } from '../../Account';
import AudiosList from '../../AudiosList/AudiosList';
import { FilePage } from '../../FilePage';
import Footer from '../../Footer/Footer';
import { Header } from '../../Header';
import Logout from '../../Logout/Logout';
import Project from '../../Project';
import ProjectHelpPages from '../../Project/helpPage/ProjectHelpPages';
import { ProjectsList } from '../../ProjectsList';
import { Roles } from '../../Roles';
import { SeriesPage } from '../../Series/SeriesDetails';
import SeriesList from '../../SeriesList/SeriesList';
import { Stats } from '../../Stats';
import EmailCommunications from '../../UserManagement/pages/EmailCommunications';
import { UsersPermissions } from '../../UsersPermissions';
import VideosList from '../../VideosList/VideosList';
import { Nav } from '../Nav';
import { Switch, Redirect, Route } from 'react-router-dom';


type TGetRouteProps = {
  path: string
  component?: typeof FilePage | typeof ProjectsList | FC // TODO: Remove typeof ... later
  exact?: boolean
}
const getRoute = ({ path, ...rest }: TGetRouteProps): JSX.Element => <Route key={path} path={path} {...rest} />;

const ROUTES: JSX.Element[] = [
  getRoute({
    path: r.logout.path,
    component: Logout
  }),

  getRoute({
    path: ROUTE.account.root,
    component: Account
  }),

  getRoute({
    path: ROUTE.series.root,
    component: SeriesList,
    exact: true
  }),
  getRoute({
    path: ROUTE.series.create(),
    component: SeriesList,
    exact: true
  }),
  getRoute({
    path: ROUTE.series.id(),
    component: SeriesPage
  }),

  getRoute({
    path: ROUTE.projects.root,
    component: ProjectsList,
    exact: true
  }),
  getRoute({
    path: ROUTE.projects.id(),
    component: Project
  }),

  getRoute({
    path: r.videos.path,
    component: VideosList,
    exact: true
  }),
  getRoute({
    path: r.audios.path,
    component: AudiosList,
    exact: true
  }),

  getRoute({
    path: r.video.path,
    component: FilePage
  }),
  getRoute({
    path: r.audio.path,
    component: FilePage
  }),
  getRoute({
    path: r.users.path,
    component: UsersPermissions
  }),
  getRoute({
    path: r.roles.path,
    component: Roles
  }),
  getRoute({
    path: r.communications.path,
    component: EmailCommunications
  }),
  getRoute({
    path: r.stats.path,
    component: Stats
  }),
];


export const LoggedApp: FC = () => {
  const { permissions: { canUseApp } } = useContext(UserContext);

  useEffect(() => {
    uppy.useStatusBar(); // TODO: Remove it from page to ... new Provider ... maybe
  }, [])

  return (
    <div className="appContainer">
      <Header />
      <Nav />
      <main>
        {canUseApp
          ? (
            <>
              <Switch>
                {ROUTES}
                <Redirect to={ROUTE.projects.root} />
              </Switch>
              <Route path={ROUTE.projects.id()} component={ProjectHelpPages} />
            </>
          )
          : (
            <Switch>
              <Route path={ROUTE.account.root} component={Account} />
              <Redirect to={ROUTE.account.root} />
            </Switch>
          )
        }
      </main>
      <Footer />
    </div>
  );
}
