import { getS3UrlFunc } from './cloudS3';

export const getDownloadUrlFunc = (cloud) => {
    if (cloud) {
        if (cloud.s3) {
            return getS3UrlFunc(cloud.s3);
        }
    }
    return null;
};

export const getDownloadUrl = async (cloud) => {
    const func = await getDownloadUrlFunc(cloud);
    return func ? func() : null;
};
