import moment, { MomentInput } from 'moment';

export const parseDate = (dateString: string): Date => moment(dateString).toDate();

export const formatDate = (date: MomentInput): string => moment(date).format('YYYYMMDD');

export const formatDateApi = (date: MomentInput): string => moment(date).format('YYYY-MM-DD');

export const formatDateToIsoString = (date: MomentInput): string => moment(date).toISOString();

export const prettyDate = (date: MomentInput): string => date ? moment(date).format('MMMM DD, YYYY') : '';

export const prettySize = (bytes: number, separator = '', suffix = ''): string => {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);
    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${suffix}`;
  }
  return '-'; // 'n/a';
};
