import {
  DOC_GROUP_TYPE_FILE,
  DOC_TYPE_EPISODE,
  // DOC_TYPE_FILE_AUDIO,
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO,
  // DOC_TYPE_FILE_MEDIA,
  // DOC_TYPE_FILE_PROJECT,
  DOC_TYPE_PROJECT,
  DOC_TYPE_SEASON,
  DOC_TYPE_SERIES,
  // DOC_TYPE_USER,
  DOCUMENT_DESCRIPTIONS
} from './docs';

export const DB_TYPES = {
    user: 'userDB',
    remote: 'remoteDB'
};

// export const DOC_TYPES = { // TODO: Remove it
//     project: DOC_TYPE_PROJECT,
//     series: DOC_TYPE_SERIES,
//     season: DOC_TYPE_SEASON,
//     episode: DOC_TYPE_EPISODE,
//     link: 'link',
//     file: {
//         media: DOC_TYPE_FILE_MEDIA,
//         audio: DOC_TYPE_FILE_AUDIO,
//         project: DOC_TYPE_FILE_PROJECT,
//         video: DOC_TYPE_FILE_FINISH_VIDEO,
//         finalAudio: DOC_TYPE_FILE_FINISH_AUDIO,
//     },
//     user: DOC_TYPE_USER
// };
/** @type {TDocumentTypeFile[]} */
export const DOC_TYPES_FILES = Object.values(DOCUMENT_DESCRIPTIONS)
  .filter(d => d.group === DOC_GROUP_TYPE_FILE)
  .map(d => d.type);
export const DOCUMENT_TYPES_FINISH_FILES = Object.values(DOCUMENT_DESCRIPTIONS)
  .filter(d => d.group === DOC_GROUP_TYPE_FILE && d.isFinishFile)
  .map(d => d.type);
export const DOCUMENT_TYPES_MEDIA_FILES = Object.values(DOCUMENT_DESCRIPTIONS)
  .filter(d => d.group === DOC_GROUP_TYPE_FILE && !d.isFinishFile)
  .map(d => d.type);

export const isFinishFile = type => DOCUMENT_DESCRIPTIONS[type]?.isFinishFile === true;
// export const isFinishFile = type => [DOC_TYPES.file.video, DOC_TYPES.file.finalAudio].includes(type);

export const SERIES_TYPE_IDS = {
  video: 'video',
  audio: 'audio',
};
export const SERIES_TYPES = {
  [SERIES_TYPE_IDS.video]: {
    id: SERIES_TYPE_IDS.video,
    name: 'Video',
    fileType: DOC_TYPE_FILE_FINISH_VIDEO,
  },
  [SERIES_TYPE_IDS.audio]: {
    id: SERIES_TYPE_IDS.audio,
    name: 'Audio',
    fileType: DOC_TYPE_FILE_FINISH_AUDIO,
  },
};


// export const DOC_ID_SETTINGS = {
//     project: {
//         prefix: 'PRJ',
//         size: 7
//     },
//     series: {
//         prefix: 'SRS',
//         size: 7
//     },
//     season: {
//         prefix: 'SEA',
//         size: 7
//     },
//     episode: {
//         prefix: 'EPS',
//         size: 7
//     },
//     link: {
//         prefix: 'ln',
//         size: 7
//     },
//     [DOC_TYPES.file.media]: {
//         prefix: 'FME',
//         size: 7
//     },
//     [DOC_TYPES.file.audio]: {
//         prefix: 'FAU',
//         size: 7
//     },
//     [DOC_TYPES.file.project]: {
//         prefix: 'FPR',
//         size: 7
//     },
//     [DOC_TYPES.file.video]: {
//         prefix: 'VID',
//         size: 7
//     },
//     [DOC_TYPES.file.finalAudio]: {
//         prefix: 'AUD',
//         size: 7
//     }
// };

export const LIST_OPTIONS_GENERAL = {
    startId: null,
    limit: null, // TODO: set something after implementing pagination
    sortBy: [],
    options: {
        selector: {
            $and: []
        }
    }
};

export const LIST_OPTIONS = {
    files: {
        startId: null,
        limit: null,
        sortBy: [{ created: 'desc' }],
        options: {
            selector: {
                $and: [{ type: { $in: DOC_TYPES_FILES } }]
            }
        }
    },
    projects: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ created: 'desc' }],
        options: {
            selector: {
                $and: [{ type: { $eq: DOC_TYPE_PROJECT } }]
            }
        }
    },
    series: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ created: 'desc' }],
        options: {
            selector: {
                $and: [{ type: { $eq: DOC_TYPE_SERIES } }]
            }
        }
    },
    seasons: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ number: 'desc' }],
        options: {
            selector: {
                $and: [
                    { type: { $eq: DOC_TYPE_SEASON } },
                    { seriesId: { $eq: '' } },
                ]
            }
        },
        fields: ['_id', 'title', 'created', 'description', 'keywords', 'number']
    },
    episodes: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ number: 'desc' }],
        options: {
            selector: {
                $and: [
                    { type: { $eq: DOC_TYPE_EPISODE } },
                ]
            }
        },
        fields: ['_id', 'title', 'created', 'description', 'keywords', 'number', 'seasonNumber', 'seriesId', 'videoId']
    },
    videos: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ created: 'desc' }],
        options: {
            selector: {
                $and: [
                    { type: { $eq: DOC_TYPE_FILE_FINISH_VIDEO } }
                ]
            }
        },
        fields: ['_id', 'title', 'created', 'image', 'cloud', 'youtube', 'sharing']
    },
    audios: {
        startId: null,
        limit: null, // TODO: set something after implementing pagination
        sortBy: [{ created: 'desc' }],
        options: {
            selector: {
                $and: [
                    { type: { $eq: DOC_TYPE_FILE_FINISH_AUDIO } }
                ]
            }
        },
        fields: ['_id', 'title', 'created', 'image', 'cloud', 'sharing']
    },
};
