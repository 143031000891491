import { ENDPOINTS } from '../../../constants/general';
import { api } from '../../../constants/globals';

export const apiRssAddSeriesRss = async (userId, seriesId) => {
    try {
        return await api.apiPost(ENDPOINTS.rss.addSeriesRss, {
            user_id: userId,
            series_id: seriesId,
        });
    } catch (e) {
        return { message: e.message };
    }
};
