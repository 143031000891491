import React from 'react';
import Anchor from '../../Anchor/Anchor';
import styles from './About.module.css';

const About = () => (
    <>
        <Anchor id="About">
            <h1 className={styles.title}>All the stuff it does:</h1>
        </Anchor>
        <ul className={styles.list}>
            <li>Automatically create organized, dated folders on your local drive</li>
            <li>Track and find assets easily</li>
            <li>Backup your raw and finished assets in the cloud</li>
            <li>Push videos to directly to YouTube from the app</li>
            <li>Generate podcast MRSS feeds</li>
            <li>Work collaboratively. Let people download and upload to your project</li>
            <li>Log in from anywhere via web</li>
            <li>Upload and download assets from anywhere</li>
            <li>Create a searchable directory of all your media assets</li>
            <li>Track performance across multiple platforms</li>
        </ul>
    </>
);

export default About;
