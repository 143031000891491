import React from 'react';
import styles from './Version.module.css';
import configs from '../../constants/configs';


// const version = process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION
//     .replace('-alpha', '')
//     .replace('-beta', '');
// let versionSuffix = '';
// if (process.env.REACT_APP_VERSION) {
//     if (process.env.REACT_APP_VERSION.includes('alpha')) {
//         versionSuffix = 'α';
//     } else if (process.env.REACT_APP_VERSION.includes('beta')) {
//         versionSuffix = 'β';
//     }
// }

const Version = () => (
    <div
        className={styles.root}
        style={{
            ...(configs.suffix ? { color: 'orange' } : {})
        }}
    >
        {!!configs.suffix && (
            <h3 className={styles.suffix}>
                {configs.suffix}
            </h3>
        )}
        {process.env.REACT_APP_VERSION}
        {/* {versionSuffix && ( */}
        {/*    <div className={styles.versionSuffix} style={{ color: fillColor }}> */}
        {/*        {versionSuffix} */}
        {/*    </div> */}
        {/* )} */}
        {/* {version && ( */}
        {/*    <div className={styles.version} style={{ color: textColor }}> */}
        {/*        {`${version}`} */}
        {/*    </div> */}
        {/* )} */}
    </div>
);

export default Version;
