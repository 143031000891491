import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';


type TUseFilterProps = {
  minLength?: number
  delay?: number
  action?: (text: string) => void
  resetAction?: () => Promise<void> | void
  autoReset?: boolean
}

type TUseFilterResponse = {
  value: string
  filter: string
  loading: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
}


export const useFilter = (props?: TUseFilterProps): TUseFilterResponse => {
  const {
    minLength = 3,
    delay = 1000,
    action,
    resetAction,
    autoReset = false,
  } = (props ?? {});

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('')
  const [filter, setFilter] = useState('')


  const search = useCallback(debounce(async (text: string) => {
    if (text.length >= minLength) {
      setLoading(true);

      setFilter(text);
      if (action) {
        await action(text);
      }

      setLoading(false);
    }
  }, delay), [action, delay]);


  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    void search(e.target.value);
  }, [search]);

  const onBlur = useCallback(() => setTimeout(() => {
    setLoading(false);
    if (autoReset) {
      setValue('');
      setFilter('');
    }

    if (resetAction) {
      void resetAction();
    }
  }, 200), [autoReset, resetAction]);


  return {
    value,
    filter,
    loading,
    onChange,
    onBlur,
  };
}
