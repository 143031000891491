import { boolean, number, object, string, array } from 'yup';
import configs from './configs';
import { DOC_TYPE_USER } from './docs';
import { SERIES_TYPES } from './pouchDB';
import { SHARE_LINK_ACCESS_TYPES } from './share';

export const ROUTE = {
    home: '/',
    login: '/login',
    register: '/register',
    registerConfirmed: '/register/confirmed',
    registerSuccess: '/register/success',
    logout: '/logout',
    resetPassword: '/resetPassword',
    applyResetPassword: '/applyResetPassword',
    users: '/users',
    roles: '/roles',
    communications: '/communications',
    stats: '/stats',
    videos: {
        root: '/videos',
        id: (fileId = ':fileId') => `${ROUTE.videos.root}/${fileId}`,
        pages: {
            metadata: (fileId = ':fileId') => `${ROUTE.videos.id(fileId)}/metadata`,
            notes: (fileId = ':fileId') => `${ROUTE.videos.id(fileId)}/notes`,
            project: (fileId = ':fileId') => `${ROUTE.videos.id(fileId)}/project`,
            series: (fileId = ':fileId') => `${ROUTE.videos.id(fileId)}/series`,
            analytics: (fileId = ':fileId') => `${ROUTE.videos.id(fileId)}/analytics`
        },
    },
    audios: {
        root: '/audio',
        id: (fileId = ':fileId') => `${ROUTE.audios.root}/${fileId}`,
        pages: {
            metadata: (fileId = ':fileId') => `${ROUTE.audios.id(fileId)}/metadata`,
            notes: (fileId = ':fileId') => `${ROUTE.audios.id(fileId)}/notes`,
            project: (fileId = ':fileId') => `${ROUTE.audios.id(fileId)}/project`,
            series: (fileId = ':fileId') => `${ROUTE.audios.id(fileId)}/series`,
            distribution: (fileId = ':fileId') => `${ROUTE.audios.id(fileId)}/distribution`
        },
    },
    share: {
        root: '/share',
        id: (sharingId = ':sharingId') => `${ROUTE.share.root}/${sharingId}`,
        project: (sharingId = ':sharingId') => `${ROUTE.share.id(sharingId)}/project`,
        file: {
            id: (
                sharingId = ':sharingId',
                fileId = ':fileId'
            ) => `${ROUTE.share.id(sharingId)}/file/${fileId}`,
            metadata: (
                sharingId = ':sharingId',
                fileId = ':fileId'
            ) => `${ROUTE.share.file.id(sharingId, fileId)}/metadata`,
        },
    },
    projects: {
        root: '/projects',
        id: (projectId = ':projectId') => `${ROUTE.projects.root}/v/${projectId}`,
        project: {
            share: (projectId = ':projectId') => `${ROUTE.projects.id(projectId)}/share`,
        },
    },
    series: {
        root: '/series',
        create: () => `${ROUTE.series.root}/create`,
        id: (seriesId = ':seriesId') => `${ROUTE.series.root}/v/${seriesId}/seasons`,
        seasons: {
            root: (seriesId = ':seriesId') => `${ROUTE.series.id(seriesId)}`,
            create: (seriesId = ':seriesId') => `${ROUTE.series.seasons.root(seriesId)}/create`,
            id: (seriesId = ':seriesId', seasonId = ':seasonId') => `${ROUTE.series.seasons.root(seriesId)}/v/${seasonId}`,
            episodes: {
                root: (seriesId = ':seriesId', seasonId = ':seasonId') => `${ROUTE.series.seasons.id(seriesId, seasonId)}/episodes`,
                create: (seriesId = ':seriesId', seasonId = ':seasonId') => `${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/create`,
                id: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId') => `${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/v/${episodeId}`,
                links: {
                    root: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType') => `${ROUTE.series.seasons.episodes.id(seriesId, seasonId, episodeId)}/${linkType}`,
                    create: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType') => `${ROUTE.series.seasons.episodes.links.root(seriesId, seasonId, episodeId, linkType)}/create`,
                    id: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType', linkId = ':linkId') => `${ROUTE.series.seasons.episodes.links.root(seriesId, seasonId, episodeId, linkType)}/v/${linkId}`,
                }
            },
        },
        episodes: {
            root: (seriesId = ':seriesId') => `${ROUTE.series.id(seriesId)}/episodes`,
            create: (seriesId = ':seriesId') => `${ROUTE.series.episodes.root(seriesId)}/create`,
            id: (seriesId = ':seriesId', episodeId = ':episodeId') => `${ROUTE.series.episodes.root(seriesId)}/v/${episodeId}`,
            seasons: {
                create: (seriesId = ':seriesId') => `${ROUTE.series.episodes.root(seriesId)}/seasoncreate` // TODO:
            }
        }
    },
    account: {
        root: '/account',
        pages: {},
    },
};
// ROUTE.account.pages = {
//     settings: `${ROUTE.account.root}/settings`,
//     test: `${ROUTE.account.root}/test`,
// };


const beHost = configs.backendApiUri;
export const ENDPOINTS = {
    user: {
        getUserId: `${beHost}/get-user-id`,
        getUserProperties: `${beHost}/get-user-properties`,
        updateUserProperties: `${beHost}/update-user-properties`,
        changeUserPassword: `${beHost}/change-user-password`,
        tokenAuth: `${beHost}/token-auth`,
        tokenRefresh: `${beHost}/token-refresh`,
        deactivateUser: `${beHost}/deactivate-user`,
        activateUser: `${beHost}/activate-user`,
        register: `${beHost}/register`,
        resetPassword: `${beHost}/reset-password`,
        resetPasswordApply: `${beHost}/reset-password-apply`,
    },
    couch: {
        getSessionCookies: `${beHost}/couch/get-session-cookies`,
    },
    share: {
        tokenAuth: `${beHost}/sharing/token-auth`,
        tokenRefresh: `${beHost}/sharing/token-refresh`,
        getUserId: `${beHost}/sharing/get-user-id`,
        getSettings: `${beHost}/sharing/get-settings`,
        getSettingsByToken: `${beHost}/sharing/get-settings-by-token`,
        saveSettings: `${beHost}/sharing/save-settings`,
        delSettings: `${beHost}/sharing/del-settings`,

        getDocument: `${beHost}/sharing/get-document`,
        getDocuments: `${beHost}/sharing/get-documents`,
        updateDocument: `${beHost}/sharing/update-document`,
        delDocumentAndFile: `${beHost}/sharing/del-document-and-file`,
        createDocument: `${beHost}/sharing/create-document`,
    },
    roles: {
      getAllRoles: `${beHost}/role/get-all-roles`,
      getAllPermissions: `${beHost}/role/get-all-permissions`,
      addRole: `${beHost}/role/add-role`,
      updateRole: `${beHost}/role/update-role`,
      delRole: `${beHost}/role/del-role`,
      getByKey: `${beHost}/role/get-by-key`,
    },
    rss: {
      addSeriesRss: `${beHost}/rss/add-series-rss`,
      getSeriesRss: `${beHost}/rss`,
      checkActivationSeriesRss: `${beHost}/rss/check-activation-series-rss`,
      getSeriesTemplate: `${beHost}/rss/get-series-template`,
      setSeriesTemplate: `${beHost}/rss/set-series-template`,
      delSeriesTemplate: `${beHost}/rss/del-series-template`,
    },
    youtube: {
        getChannelInfo: `${beHost}/youtube/get-channel-info`,
        activate: `${beHost}/youtube/activate`,
        uploadVideo: `${beHost}/youtube/upload-video`,
        getChannelAnalytics: `${beHost}/youtube/get-channel-analytics`,
        getVideoAnalytics: `${beHost}/youtube/get-video-analytics`,
    },
    cloud: {
        s3: {
            remove: `${beHost}/s3/delete-object`,
            getDownloadFileUrl: `${beHost}/s3/get-download-file-url`,
            createMultipartUpload: `${beHost}/s3/create-multipart-upload`,
            listParts: `${beHost}/s3/list-parts`,
            prepareUploadPart: `${beHost}/s3/prepare-upload-part`,
            completeMultipartUpload: `${beHost}/s3/complete-multipart-upload`,
            abortMultipartUpload: `${beHost}/s3/abort-multipart-upload`,
        },
    },
    statistic: {
        getProjectStructureSizes: `${beHost}/tracking/get-project-structure-sizes`,
        getUserStructureSizes: `${beHost}/tracking/get-user-structure-sizes`,
        getAllUsersStructureSizes: `${beHost}/tracking/get-all-users-structure-sizes`,
        getAllUsersStatisticsLogin: `${beHost}/tracking/get-all-users-statistics-login`,
        getAllUsersStatisticsProjects: `${beHost}/tracking/get-all-users-statistics-projects`,
        getAllUsersAllFilesSize: `${beHost}/tracking/get-all-users-all-files-size`,
        getAllUsersAllFinalAudioFilesSize: `${beHost}/tracking/get-all-users-all-final-audio-files-size`,
        getAllUsersAllFinalVideoFilesSize: `${beHost}/tracking/get-all-users-all-final-video-files-size`,
    },
    getAllUsersDetails: `${beHost}/get-all-users-details`,
    communications: {
        sendByEmails: `${beHost}/communication/send-by-emails`,
    },
};

export const CONTENT_TYPE = {
    PROJECT: 'project',
    SERIES: 'series',
    SEASON: 'season',
    EPISODE: 'episode',
    VIDEO: 'video',
    AUDIO: 'audio',
};

export const SHARE_LINK_SCHEMA = object().shape({
    id: string().default(''),
    documentId: string().default(''),
    type: string().default(''),
    enabled: boolean().required('Required').default(false),
    accessType: string().required('Required').default(SHARE_LINK_ACCESS_TYPES.password).min(1),
    pass: string().default('').required('1 char min').min(1),
    // .when('enabled', {
    //     is: true,
    //     then: fieldSchema => fieldSchema.required('1 char min').min(1),
    // }),
    canEdit: boolean().required('Required').default(false),
    canAddFiles: boolean().required('Required').default(false),
    canRemoveFiles: boolean().required('Required').default(false),
    expirationEnabled: boolean().required('Required').default(false),
    expirationDate: string().default(''),
});

const COMMON_SCHEMA_ITEMS = {
    type: string().default(''),
    created: string().default(''),
    updated: string().default(''),
};

const KEYWORDS_TAG_MAX_LENGTH = 100;
const KEYWORDS_SCHEMA = string()
    .default('')
    // eslint-disable-next-line no-template-curly-in-string
    .max(500, 'Maximum length is ${max}')
    // eslint-disable-next-line prefer-arrow-callback
    .test('tagMaxLength', 'error', function tagMaxLength(value) {
        if (value && typeof value === 'string') {
            const res = value.split(',').filter(val => val && val.length > KEYWORDS_TAG_MAX_LENGTH);
            if (res.length) {
                const { path, createError } = this;
                return createError({
                    path,
                    message: `Some of tags has length more then ${KEYWORDS_TAG_MAX_LENGTH}: ("${res.join('", "')}")`
                });
            }
        }
        return true;
    });

export const PROJECT_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    title: string().required('Required').min(1).max(40),
    description: string(),
    sponsored: boolean().default(false),
    sponsoredBy: string(),
    sponsoredSeries: boolean().default(false),
    invoiceNumber: string(),
    amount: number().default(0),
    storeOnLocalDrive: boolean().default(true),
    storeInCloud: boolean().default(false),
    created: string().default('').test('empty-check', 'Required', function emptyCheck(value) {
      console.log('created value 0', value)
      if (!value) {
        console.log('created value 1', value)
        const { path, createError } = this;
        return createError({
          path,
          message: `Required`
        });
      }
      return true;
    }),
    tags: array(string()),
    // files: array().default([]).of(object().shape({
    //     type: string(),
    //     id: string(),
    // })),
    // filesCount: object().default([]).of(object().shape({
    //     ...DOC_TYPES_FILES.reduce((acc, fileType) => ({
    //         ...acc,
    //         [fileType]: number().default(0)
    //     }), {}),
    // })),
    // ...EMPTY_FILE_ARRAYS
});

export const EPISODE_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    title: string().default('').required('Required').min(1),
    description: string().default(''),
    keywords: KEYWORDS_SCHEMA,
    number: number().default(0).required('Required'),
    seasonNumber: number().default(0),
    seriesId: string().default('').required('Required'),
    seasonId: string().default(''),
    videoId: string().default('').required('Required'),
});

export const SERIES_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    title: string().default('').required('Required').min(1)
        .max(250),
    description: string().default('').required('Required'),
    keywords: KEYWORDS_SCHEMA,
    sponsored: boolean().default(false),
    seasons: boolean().default(false),
    seriesType: string().default('video').required()
        .oneOf(Object.keys(SERIES_TYPES), 'Choose Series type'),
    youtube: object().shape({
        categoryId: number(),
        channelId: string().default(''),
    }),
});

export const SEASON_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    title: string().default('').required('Required').min(1)
        .max(250),
    description: string().default(''),
    keywords: KEYWORDS_SCHEMA,
    sponsored: boolean().default(false),
    number: number().default(0).required('Required'),
    seriesId: string().default('').required('Required'),
});

export const USER_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    _id: string().default(''),
    type: string().default(DOC_TYPE_USER),
    helpFlags: object().shape({
        openFirstProject: boolean().default(false),
        createFirstProject: boolean().default(false),
    }),
});

export const FILE_SCHEMA = object().shape({
    ...COMMON_SCHEMA_ITEMS,
    type: string(),
    title: string().required('Required').min(1).max(255),
    filename: string().default(''),
    description: string(),
    keywords: KEYWORDS_SCHEMA,
    // TODO: if meta is undefined validator does not show error but also does not allow to save file
    // meta: object().shape({
    //     type: string().default(''),
    //     lastModified: number().default(''),
    // }),
});
