import React from 'react';

const k = 6.2442 / 6.2442;

export const IconDelete: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 24, fill = '#d1d3d4', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 6.2442 6.2442" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.15633 0 0 -.15633 5.0656 6.2442)" fill={fill}>
        <path d="m0 0h-24.864c-4.147 0-7.539 3.393-7.539 7.539v24.864c0 4.147 3.392 7.539 7.539 7.539h24.864c4.146 0 7.539-3.392 7.539-7.539v-24.864c0-4.146-3.393-7.539-7.539-7.539"/>
      </g>
      <g transform="matrix(.15633 0 0 -.15633 3.5488 3.0252)">
        <path d="m0 0 6.334-6.334c0.593-0.593 0.593-1.52 0-2.111-0.592-0.593-1.519-0.593-2.111 0l-6.334 6.334-6.333-6.334c-0.593-0.593-1.52-0.593-2.112 0-0.592 0.591-0.592 1.518 0 2.111l6.334 6.334-6.334 6.334c-0.592 0.592-0.592 1.519 0 2.111s1.519 0.592 2.112 0l6.333-6.334 6.334 6.334c0.592 0.592 1.519 0.592 2.111 0 0.593-0.592 0.593-1.519 0-2.111z"
              style={{
                fillRule: 'evenodd',
                fill: '#fff',
              }}/>
      </g>
    </svg>
  );
};
