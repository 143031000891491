import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../Logo/Logo';
import styles from './LoginContainer.module.css';

const LoginContainer = ({ children }) => (
    <div className={styles.wrapper}>
        <Logo />
        <div className={styles.content}>
            {children}
        </div>
    </div>
);

LoginContainer.propTypes = {
    children: PropTypes.any,
};

export default LoginContainer;
