import React from 'react';
import styles from './OrganizeBackup.module.css';
import OrganizeSchema from './Schema/OrganizeSchema';
import configs from '../../../../constants/configs';

const OrganizeBackup = () => (
    <div className={styles.container}>
        <div className={styles.schema}>
            <OrganizeSchema />
        </div>
        <div>
            <h1>Organize. Backup. Track. Own.</h1>
            <div className={styles.text}>
                Creating is fun but managing it all sucks. Creating folders, sticking to
                naming conventions, managing assets, backing up, clearing space, worrying
                about what happens if your back up fails or if you lose it like we’ve done!
            </div>
            <div className={styles.text}>
                We had enough! So we set about creating a tool that would help.
            </div>
            <div className={styles.textItalic}>
                {` ${configs.appName} `}
                is a platform and a tool that let’s you
                organize, package and distribute your content;
                giving you ownership of your IP.
            </div>
            <div className={styles.textItalic}>
                It’s a platform made by creators for creators.
            </div>
        </div>
    </div>
);

export default OrganizeBackup;
