import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';
import styles from './Button.module.css';

const Button = ({
    name,
    type,
    className,
    children,
    color,
    icon,
    small,
    bordered,
    noDecorate,
    tiny,
    href,
    link,
    onClick,
    disabled,
    id,
    blank,
    tip,
    tipClass,
    tipPlace
}) => {
    const buttonClassName = cn(
        styles.button,
        styles[`button-${color}`],
        color,
        small && styles.small,
        tiny && styles.tiny,
        disabled && styles.disabled,
        icon && styles.hasIcon,
        icon && !children && styles.iconOnly,
        bordered && styles.bordered,
        noDecorate && styles.noTextDecoration,
        className
    );

    const iconElement = icon ? <Icon icon={icon} className={styles.icon} /> : null;

    const handleClick = (e) => {
        if (e) {
            // Disable event bubbling, especially with a Form
            e.preventDefault();
            e.stopPropagation();
        }

        if (disabled) {
            return;
        }

        onClick(e);
    };

    switch (true) {
    case !!href:
        return (
            <a
                name={name}
                className={buttonClassName}
                href={href}
                target={blank ? '_blank' : '_self'}
                rel="noopener noreferrer"
                id={id}
            >
                {iconElement}
                {children}
            </a>
        );
    case !!link:
        return (
            <Link
                name={name}
                className={buttonClassName}
                to={link}
                id={id}
            >
                {iconElement}
                {children}
            </Link>
        );
    default:
        return (
            <button
                type={type || 'button'}
                name={name}
                className={buttonClassName}
                onClick={onClick ? handleClick : null}
                disabled={disabled}
                data-tip={tip}
                data-place={tipPlace}
                data-class={tipClass}
                id={id}
            >
                {iconElement}
                <span className="btn-text">{children}</span>
            </button>
        );
    }
};

Button.defaultProps = {
    color: 'primary',
    type: 'button',
    blank: true,
};

Button.COLOR = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    GRAY: 'gray',
    NONE: 'none',
    WARNING: 'warning',
    WHITE: 'white',
};

Button.propTypes = {
    bordered: PropTypes.bool,
    noDecorate: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.oneOf(
        Object.keys(Button.COLOR).map(key => Button.COLOR[key])
    ),
    disabled: PropTypes.bool,
    href: PropTypes.string, // Outer link
    blank: PropTypes.bool,
    id: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    link: PropTypes.string, // Inner application link
    name: PropTypes.string,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    tip: PropTypes.string,
    tipPlace: PropTypes.string,
    tipClass: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    onClick: PropTypes.func,
};

export default Button;
