import React from 'react';
import PropTypes from 'prop-types';

const YoutubePlayer = ({ youtubeId }) => (
    youtubeId
        ? (
            <iframe
                title={youtubeId}
                width="601"
                height="338"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                src={`https://www.youtube.com/embed/${youtubeId}`}
                allowFullScreen
            />
        )
        : ''
);

YoutubePlayer.propTypes = {
    youtubeId: PropTypes.string,
};

export default YoutubePlayer;
