import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../constants/general';
import styles from './Logo.module.css';
import { isWeb } from '../../constants/modules';
import configs from '../../constants/configs';
import { LogoIcon } from './resources/LogoIcon';

const Wrapper = ({ children }) => (!isWeb
  ? children
  : <Link to={ROUTE.home}>{children}</Link>);

Wrapper.propTypes = {
  children: PropTypes.any,
};

const props = configs.suffix ? { stroke: 'orange' } : {};

const Logo = () => (
  <div className={styles.container}>
    <Wrapper>
      <LogoIcon {...props} />
    </Wrapper>
  </div>
);

export default Logo;
