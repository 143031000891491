import moment from 'moment';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import {
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO,
  DOC_TYPE_PROJECT,
  DOCUMENT_DESCRIPTIONS,
  TDocumentType
} from '../../constants/docs';
import { UserContext } from '../User';


const RECENT_ITEM_TYPES = ['project', 'video', 'audio', 'series'] as const; // TS3.4 syntax
export type TRecentItemType = typeof RECENT_ITEM_TYPES[number]; // 'a'|'b'|'c';


type TRecentItem = {
  type: TRecentItemType
  to: string
  title: string
  created: number
}

type TRecentItemInput = {
  type: TDocumentType | TRecentItemType
  to: string
  title: string
}

type TRecentContext = {
  recent: TRecentItem[]
  addRecent: (item: TRecentItemInput) => void
};


const MAX_COUNT = 5;

export const RecentContext = createContext<TRecentContext>({
  recent: [],
  addRecent: () => undefined
})


const getRecentType = (type: TDocumentType | TRecentItemType): TRecentItemType | undefined => {
  let result: TRecentItemType | undefined = undefined;

  if (RECENT_ITEM_TYPES.find(val => val === type)) {
    result = type as TRecentItemType;

  } else if (Object.keys(DOCUMENT_DESCRIPTIONS).find(key => key === type)) {
    switch (type) {
      case DOC_TYPE_PROJECT:
        result = 'project';
        break;
      case DOC_TYPE_FILE_FINISH_AUDIO:
        result = 'audio';
        break;
      case DOC_TYPE_FILE_FINISH_VIDEO:
        result = 'video';
        break;
    }

  } else {
    console.error(`Recent item type "${type}" is not supported`)
  }

  return result;
}

const getUserLocalRecent = (id: string) => {
  const restored = localStorage.getItem(`${id}/recent`);
  return restored
    ? (JSON.parse(restored) as TRecentItem[])
      .filter(it => !!it.created)
    : [];
}
const saveUserLocalRecent = (id: string, recent: TRecentItem[]) => {
  localStorage.setItem(`${id}/recent`, JSON.stringify(recent));
}

export const RecentProvider: FC = ({ children }) => {
  const { id } = useContext(UserContext);
  const [recent, setRecent] = useState<TRecentItem[]>(getUserLocalRecent(id));

  const addRecent = useCallback((item: TRecentItemInput) => {
    let newState: TRecentItem[] = [];
    const type = getRecentType(item.type);

    if (type) {
      setRecent(s => {
        const current = s.find(it => it.to === item.to)
        if (current) {
          newState = s.map(it => it === current ? {...it, created: Date.now()} : it);

        } else {
          newState = [
            { ...item, type, created: Date.now() },
            ...s,
          ]

          if (newState.length > MAX_COUNT) {
            let oldest: TRecentItem | undefined = undefined;
            newState.forEach(it => {
              if (!oldest || it.created < oldest.created) {
                oldest = it;
              }
            })
            newState = newState.filter(it => it !== oldest)
          }
        }

        saveUserLocalRecent(id, newState)

        return newState;
      })
    }
  }, [id]);

  return (
    <RecentContext.Provider value={{ recent, addRecent }}>
      {children}
    </RecentContext.Provider>
  )
}

