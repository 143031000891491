import { TPathType } from '../utils/files/files';
import { generateId } from '../utils/guids';
import { getUserId } from '../utils/webSettings';

export const DOC_GROUP_TYPE_FILE = 'file';


export const DOC_TYPE_USER = 'user';
export const DOC_TYPE_PROJECT = 'project';
export const DOC_TYPE_SERIES = 'series';
export const DOC_TYPE_SEASON = 'season';
export const DOC_TYPE_EPISODE = 'episode';
export const DOC_TYPE_FILE_AUDIO = 'file-audio';
export const DOC_TYPE_FILE_MEDIA = 'file-media';
export const DOC_TYPE_FILE_MISC = 'file-misc';
export const DOC_TYPE_FILE_PROJECT = 'file-project';
export const DOC_TYPE_FILE_FINISH_VIDEO = 'file-video';
export const DOC_TYPE_FILE_FINISH_AUDIO = 'file-final-audio';


export type TDocumentTypeFile =
  typeof DOC_TYPE_FILE_AUDIO
  | typeof DOC_TYPE_FILE_MEDIA
  | typeof DOC_TYPE_FILE_MISC
  | typeof DOC_TYPE_FILE_PROJECT
  | typeof DOC_TYPE_FILE_FINISH_VIDEO
  | typeof DOC_TYPE_FILE_FINISH_AUDIO

export type TDocumentType = TDocumentTypeFile
  | typeof DOC_TYPE_USER
  | typeof DOC_TYPE_PROJECT
  | typeof DOC_TYPE_SERIES
  | typeof DOC_TYPE_SEASON
  | typeof DOC_TYPE_EPISODE


type TTypeDescription = Readonly<{
  type: TDocumentType
  group: string
  isFinishFile: boolean
  prefix: string
  prefixSize: number
  title: string
  folderNames?: readonly string[]
  // folderNameOld?: string // TODO: very old folder name. I think only Peter has it on his drive
}>


type TTypeDescriptionProps = Partial<Omit<TTypeDescription, 'type' | 'prefix'>> & {
  type: TDocumentType
  prefix: string
}
const docDescription = (props: TTypeDescriptionProps): TTypeDescription => {
  const {
    type,
    group,
    isFinishFile = false,
    prefixSize = 7,
    title = '',
  } = props;
  return {
    ...props,
    group: group || type,
    isFinishFile,
    prefixSize,
    title,
  };
};

/** @type {Record<TDocumentType, TTypeDescription>>} */
export const DOCUMENT_DESCRIPTIONS = Object.freeze({
  // ...docTypeDescription({
  //   type: 'link',
  //   prefix: 'ln',
  //   prefixSize: 7
  // }), // TODO: Delete it ???
  [DOC_TYPE_USER]: docDescription({
    type: DOC_TYPE_USER,
    prefix: ''
  }),
  [DOC_TYPE_PROJECT]: docDescription({
    type: DOC_TYPE_PROJECT,
    prefix: 'PRJ'
  }),
  [DOC_TYPE_SERIES]: docDescription({
    type: DOC_TYPE_SERIES,
    prefix: 'SRS'
  }),
  [DOC_TYPE_SEASON]: docDescription({
    type: DOC_TYPE_SEASON,
    prefix: 'SEA'
  }),
  [DOC_TYPE_EPISODE]: docDescription({
    type: DOC_TYPE_EPISODE,
    prefix: 'EPS'
  }),
  [DOC_TYPE_FILE_MEDIA]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_MEDIA,
    prefix: 'FME',
    title: 'Media',
    folderNames: Object.freeze(['Raw Media', 'media'])
  }),
  [DOC_TYPE_FILE_AUDIO]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_AUDIO,
    prefix: 'FAU',
    title: 'Audio',
    folderNames: Object.freeze(['Raw Audio', 'audio'])
  }),
  [DOC_TYPE_FILE_PROJECT]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_PROJECT,
    prefix: 'FPR',
    title: 'Project files',
    folderNames: Object.freeze(['Project Files', 'project'])
  }),
  [DOC_TYPE_FILE_MISC]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_MISC,
    prefix: 'FMI',
    title: 'Other files',
    folderNames: Object.freeze(['Other Files', 'Miscellaneous'])
  }),
  [DOC_TYPE_FILE_FINISH_VIDEO]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_FINISH_VIDEO,
    prefix: 'VID',
    isFinishFile: true,
    title: 'Finished Videos',
    folderNames: Object.freeze(['Finished Video', 'video'])
  }),
  [DOC_TYPE_FILE_FINISH_AUDIO]: docDescription({
    group: DOC_GROUP_TYPE_FILE,
    type: DOC_TYPE_FILE_FINISH_AUDIO,
    prefix: 'AUD',
    isFinishFile: true,
    title: 'Finished Audio',
    folderNames: Object.freeze(['Finished Audio', 'finalAudio'])
  }),
});

export const newId = (type: TDocumentType, userId: string): string => {
  const docDescr = DOCUMENT_DESCRIPTIONS[type];
  return `${docDescr.prefix}${userId}${generateId(docDescr.prefixSize)}`;
}

export type TSharingItem = { id: string, type: 'project' | 'file' }
export type TSharing = TSharingItem[] | TSharingItem

export type TDocument = {
  _id: string
  _rev: string
  type: TDocumentType
  created: string
  updated: string
}
export type TDocUser = {
  _id: string
  _rev: string
  type: TDocumentType
  created: string
  updated: string
  name: string
  email: string
  phone: string
  helpFlags: {
    openFirstProject: boolean
    createFirstProject: boolean
  }
}
export type TDocumentWithTitle = TDocument & {
  title: string
}
export type TDocProject = TDocumentWithTitle & {
  description: string
  // sponsored: boolean
  // sponsoredBy?: string
  // sponsoredSeries: boolean
  // invoiceNumber?: string
  // amount?: number
  storeOnLocalDrive: boolean
  storeInCloud: boolean
  sharing?: TSharing
  tags?: string[]
}
export type TDocFile = TDocumentWithTitle & {
  filename: string
  size: number
  projectId: string
  systemType: TPathType
  meta: {
    type: string
    lastModified: string
    [key: string]: any
  }
  cloud?: Record<string, any>
  sharing?: TSharing
  description?: string
  notes?: string
}
export type TDocSeries = TDocumentWithTitle & {
  description: string
  keywords: string
  seriesType: 'video' | 'audio'
  sponsored: boolean
  seasons: boolean
  youtube?: {
    categoryId?: number
    channelId?: string
  }
  image?: string
}
type TDocSeason = TDocumentWithTitle & {
  description: string
  keywords: string
  seriesId: string
  number: number
  sponsored: boolean
}
type TDocEpisode = TDocumentWithTitle & {
  description: string
  keywords: string
  seriesId: string
  seasonId: string
  seasonNumber: number
  number: number
  videoId: string
}

type TDoc = TDocProject | TDocFile | TDocSeries | TDocSeason | TDocEpisode

export const newDocument = <T extends TDoc>(type: TDocumentType, props: Omit<Partial<T>, '_id'>): T => {
  // console.log('props', props)
  const curDate = new Date().toISOString();

  return {
    ...props,
    _id: newId(type, getUserId()),
    _rev: '',
    type,
    created: props?.created ?? curDate,
    updated: curDate,
  } as T
};
