import { useCallback } from 'react';
import { TDocFile } from '../constants/docs';
import { TSelectedFile } from '../utils/files/files';
import { useProjectFilesApi } from './use-project-files-api';


type TUseProjectFilesProps = {
  projectId: string
}
type TUseProjectFilesResult = {
  findFiles: () => Promise<TDocFile[]>
  addFiles: (files: TSelectedFile[]) => Promise<boolean>
  removeFile: (fileIds: string[]) => Promise<string[]>
}


export const useProjectFiles = ({ projectId }: TUseProjectFilesProps): TUseProjectFilesResult => {
  const { findFiles, addFiles, removeFile } = useProjectFilesApi({ projectId });


  const handleAddFiles = useCallback(async (files: TSelectedFile[]) => {
    return await addFiles(files, { move: false });
  }, [addFiles]);


  return {
    addFiles: handleAddFiles,
    removeFile,
    findFiles,
  };
}
