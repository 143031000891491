import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import series from './series/reducer';
import seasons from './seasons/reducer';
import episodes from './episodes/reducer';
import videos from './videos/reducer';
import audios from './audios/reducer';
import projects from './projects/reducer';
import db from './db/reducer';
import user from './user/reducer';
import share from './share/reducer';


const reducers = {
    series,
    seasons,
    episodes,
    projects,
    audios,
    videos,
    db,
    user,
    share,
};

const createRootReducer = history => combineReducers({
    router: connectRouter(history),
    ...reducers,
});


export default createRootReducer;
