import { TUseWrongMediaDurationResult, useWrongMediaDuration } from './use-wrong-media-duration.hook';


export type TUseUserInformationResult = {
  wrongDuration: TUseWrongMediaDurationResult
}


export const useUserInformation = (): TUseUserInformationResult => {
  const wrongDuration = useWrongMediaDuration();


  return {
    wrongDuration
  };
}
