import { DOCUMENT_DESCRIPTIONS } from '../../constants/docs';
import { LIST_OPTIONS } from '../../constants/pouchDB';
import { findUserDocs, readUserDoc } from '../../handlers/userDB';
import { getProjectTotals } from '../../modules/projects/actions';
import { asyncForEach, combineArraysOfArrays } from '../common';
import { addFilters } from '../pouchDB/documents';
import { getFiler, getIdFiler, scanDirectory } from './fileSystem';
import { pathBasename, pathJoin } from './path';
import { getProjectFolder } from './projects';
import { findDatedFolders } from './search';
import { isWeb } from '../../constants/modules';

const checkPathProperty = (doc) => {
    if (!isWeb && !doc.path) {
        throw new Error(`Document does not have "path" property:\n${JSON.stringify(doc)}`);
    }
};


/** Find /{fileType}/{date} folders in the projects folder. Returned { type, path } object */
export const findFilesFolders = async (projectFolder) => {
    const fileFolderNames = [];
    Object.values(DOCUMENT_DESCRIPTIONS).filter(({folderNames}) => !!folderNames?.length)
      .forEach(({ type, folderNames }) => {
        folderNames.forEach(dirName => {
          fileFolderNames.push({ type, dirName })
        })
      })

    return combineArraysOfArrays(await asyncForEach(
        fileFolderNames,
        async ({ type, dirName }) => (await findDatedFolders(pathJoin(projectFolder, dirName)))
            .map(path => ({ type, path }))
    ));
};

/** Search for files with specific id or any file in folder. Not recursive */
export const findFiles = async (folder, fileId = '') => {
    const filter = fileId ? getIdFiler(fileId) : getFiler();
    return scanDirectory(folder, filter, true, false);
};

/**
 *  Search for files. Returned { type, path } object for each file
 * @param project Document Project with "path" field
 * */
export const findProjectFilesAll = async (project) => {
    checkPathProperty(project);

    const fileFolders = await findFilesFolders(project.path);
    return combineArraysOfArrays(await asyncForEach(
        fileFolders,
        async ({ type, path }) => (await findFiles(path)).map(filepath => ({ type, path: filepath }))
    ));
};

/** Match docs[{ _id, type, ...fields }] and files[{ type, path }] and return { _id, type, path, ...fields } for each */
export const matchFileDocsWithPaths = (docs, files) => {
    const fileDocs = [...docs]; // Copy array. We will modify it

    return [
        ...files.map(({ type, path }) => {
            const filename = pathBasename(path);
            let matchedDoc = {};
            fileDocs.some((doc, ind) => {
                if (doc.type === type && getIdFiler(doc._id).test(filename)) {
                    matchedDoc = doc;
                    fileDocs.splice(ind, 1); // Remove id from array
                    return true;
                }
                return false;
            });
            return {
                _id: '',
                ...matchedDoc,
                type,
                path
            };
        }),
        ...fileDocs.map(doc => ({ ...doc, path: '' }))
    ];
};

/**
 * Return files by Project Id(s)
 * @param {string|string[]} projectIds Project id(s)
 * @param {{}[]} filters additional filters
 * @param {string[]} fields fields in result
 * @return {function(*): *}
 */
export const fetchProjectFiles = (projectIds, filters = [], fields = undefined) => async (dispatch) => {
    const opt = {
        ...addFilters(LIST_OPTIONS.files, [
            { key: 'projectId', value: projectIds },
            ...filters
        ]),
        sort: [{ title: 'asc' }],
        fields: LIST_OPTIONS.files.fields || fields,
    };
    return dispatch(findUserDocs(opt));
};

/**
 *  Insert file documents into the user DB
 * @param {string} id Document Id
 * @param {boolean} withTotals
 * @return {Promise<{ _id: string, type: string, ...}>} PouchDB Document
 * */
export const readUserProject = (id, withTotals = false) => async (dispatch) => {
    const project = {
        ...(await dispatch(readUserDoc(id))),
        ...(withTotals ? (await dispatch(getProjectTotals([id])))[id] : {}),
    };

    if (isWeb) {
        return project;
    }

    const path = await dispatch(getProjectFolder(project));
    return {
        ...project,
        path
    };
};

/**
 * Fetch files in DB, search for them on a drive in the project folder
 * @return {Promise<TDocFile[]>}
 * */
export const scanProjectFiles = (projectParam, newFiles = true, existsFiles = true) => async (dispatch) => {
    const project = (typeof projectParam === 'string')
        ? await dispatch(readUserProject(projectParam))
        : projectParam;

    const docs = await dispatch(fetchProjectFiles(project._id));

    if (isWeb) {
        return docs;
    }

    checkPathProperty(project);

    const files = await findProjectFilesAll(project);

    return matchFileDocsWithPaths(docs, files)
        .filter(doc => ((existsFiles && !!doc._id) || (newFiles && !doc._id)));
};
