import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ProjectDetails } from '../../../components/ProjectDetails';
import { SHARE_LINK_SCHEMA } from '../../../constants/general';
import { emitUserError } from '../../../constants/globals';
import {
    apiShareDelDocumentAndFile,
    apiShareGetDocument,
    apiShareGetDocuments,
    apiShareUpdateDocument
} from '../../../modules/share/api';
import { asyncForEach } from '../../../utils/common';
import { addFilesToProject } from '../../../utils/files/filesShare';

const initialState = {
    settings: SHARE_LINK_SCHEMA.default()
};

class SharedProject extends React.Component {
    static propTypes = {
        sharingId: PropTypes.string.isRequired,
        addFiles: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    };

    state = initialState;

    componentDidMount() {
        this.getProject().then();
    }

    getProject = async () => {
        const { settings } = this.props;
        try {
            return await apiShareGetDocument(settings.documentId);
        } catch (e) {
            emitUserError(e.message, 'Unable to fetch Project');
        }
        return null;
    };

    getProjectFiles = async () => {
        try {
            return await apiShareGetDocuments();
        } catch (e) {
            emitUserError(e.message, 'Unable to fetch Project files');
        }
        return null;
    };

    updateProject = async (values) => {
        const project = await this.getProject();
        if (project) {
            try {
                await apiShareUpdateDocument({ ...project, ...values });
            } catch (e) {
                emitUserError(e.message, 'Unable to update Project');
            }
        }
    };

    deleteDocument = async (ids) => {
        const project = await this.getProject();
        if (project) {
            try {
                await asyncForEach(ids, async id => apiShareDelDocumentAndFile(id));
            } catch (e) {
                emitUserError(e.message, 'Unable to delete document');
            }
        }
    };

    render() {
        const { sharingId, addFiles, history, settings } = this.props;

        return (
            <>
                <ProjectDetails
                    sharingId={sharingId}
                    canUseCloud
                    readProject={this.getProject}
                    findFiles={this.getProjectFiles}
                    addFiles={settings.canAddFiles ? (files) => addFiles(settings.documentId, files) : undefined}
                    saveProject={settings.canEdit ? async values => this.updateProject(values) : undefined}
                    removeFile={settings.canRemoveFiles ? async fileId => this.deleteDocument(fileId) : undefined}
                    history={history}
                />
            </>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    addFiles: (projectId, files) => dispatch(addFilesToProject(projectId, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedProject);
