import { ENDPOINTS, SHARE_LINK_SCHEMA } from '../../constants/general';
import { api } from '../../constants/globals';
import { errorResponse } from '../../utils/api/common';
import { getSharingWeb } from '../../utils/localStorageUtils';


export const apiShareTokenAuth = async (sharingId, password) => {
    const response = await api.apiPost(ENDPOINTS.share.tokenAuth, {
        sharing_id: sharingId,
        password,
    });

    if (response && response['sharing-token'] && response['sharing-refresh-token']) {
        return {
            token: response['sharing-token'],
            refreshToken: response['sharing-refresh-token'],
        };
    }

    throw errorResponse(response);
};
export const apiShareTokenRefresh = async () => {
    const web = getSharingWeb();

    const response = await api.apiPost(ENDPOINTS.share.tokenRefresh, {
        sharing_refresh_token: web.refreshToken,
    });

    if (response && response['sharing-token'] && response['sharing-refresh-token']) {
        return {
            token: response['sharing-token'],
            refreshToken: response['sharing-refresh-token'],
        };
    }

    throw errorResponse(response);
};

export const apiShareGetUserId = async () => {
    const response = await api.apiGet(ENDPOINTS.share.getUserId);

    if (response && response.user_id) {
        return response;
    }

    throw errorResponse(response);
};

const processSettingsResponse = async (response) => {
    if (response && response.sharing && response.sharing.id) {
        const { sharing } = response;
        return {
            enabled: !!sharing.id,
            id: sharing.id,
            documentId: sharing.document_id,
            type: sharing.type,
            userId: sharing.user_id,
            canEdit: sharing.can_edit,
            canAddFiles: sharing.can_add_files,
            canRemoveFiles: sharing.can_remove_files,
            pass: sharing.password, // TODO: is it secure?
            expirationEnabled: sharing.expiration_enabled,
            expirationDate: sharing.expiration_date,
            // TODO: backend does not support accessType for now (2019-09-04)
            accessType: sharing.access_type || SHARE_LINK_SCHEMA.default().accessType,
        };
    }

    throw errorResponse(response);
};
export const apiShareGetSettingsByToken = async () => processSettingsResponse(
    await api.apiGet(ENDPOINTS.share.getSettingsByToken)
);
export const apiShareGetSettings = async (id) => {
    const response = await api.apiGet(ENDPOINTS.share.getSettings, {
        // project_id: id,
        document_id: id,
    });
    return processSettingsResponse(response);
};
export const apiShareSaveSettings = async data => api.apiPost(ENDPOINTS.share.saveSettings, {
    // project_id: data.documentId,
    document_id: data.documentId,
    type: data.type,
    password: data.pass,
    can_edit: data.canEdit,
    can_add_files: data.canAddFiles,
    can_remove_files: data.canRemoveFiles,

    // TODO: still does not exists on backend. Just planned
    expiration_enabled: data.expirationEnabled,
    expiration_date: data.expirationDate,
    access_type: data.accessType,
});
export const apiShareDelSettings = async sharingId => api.apiPost(ENDPOINTS.share.delSettings, {
    sharing_id: sharingId
});

export const apiShareGetDocument = async id => api.apiGet(ENDPOINTS.share.getDocument, {
    file_id: id
});
export const apiShareGetDocuments = async () => {
    const response = await api.apiGet(ENDPOINTS.share.getDocuments);

    const key = 'sharing_files';
    if (response && response[key]) {
        return response[key];
    }

    throw new Error('Unable to fetch files');
};

export const apiShareCreateDocument = async doc => api.apiPost(ENDPOINTS.share.createDocument, doc);
export const apiShareUpdateDocument = async doc => api.apiPost(ENDPOINTS.share.updateDocument, doc);
export const apiShareDelDocumentAndFile = async id => api.apiPost(ENDPOINTS.share.delDocumentAndFile, { file_id: id });


// export const apiShareGetObject = async (documentId) => {
//     const response = await api.apiGet(ENDPOINTS.share.getObject, {
//         document_id: documentId
//     });
//
//     if (response && response._id) {
//         return response;
//     }
//
//     throw errorResponse(response);
// };
// export const apiShareGetProject = async () => {
//     const response = await api.apiGet(ENDPOINTS.share.project.getProject);
//
//     if (response && response._id) {
//         return response;
//     }
//
//     throw errorResponse(response);
// };
// export const apiShareUpdateProject = async doc => api
//     .apiPost(ENDPOINTS.share.project.updateProject, doc);
//
// export const apiShareGetProjectFiles = async () => {
//     const response = await api.apiGet(ENDPOINTS.share.project.getProjectFiles);
//
//     if (response && response[`${'project_files'}`]) {
//         return response;
//     }
//
//     throw errorResponse(response);
// };
// export const apiShareGetProjectFile = async (fileId) => {
//     const response = await api.apiGet(ENDPOINTS.share.project.getProjectFile, { file_id: fileId });
//
//     if (response && response._id) {
//         return response;
//     }
//
//     throw errorResponse(response);
// };
// export const apiShareCreateProjectFile = async (doc) => {
//     await api.apiPost(ENDPOINTS.share.file.createProjectFile, doc);
// };
// export const apiShareUpdateProjectFile = async (doc) => {
//     await api.apiPost(ENDPOINTS.share.file.updateProjectFile, doc);
// };
// export const apiShareDelProjectFile = async (fileId) => {
//     await api.apiPost(ENDPOINTS.share.file.delProjectFile, {
//         file_id: fileId,
//     });
// };
