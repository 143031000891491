import cn from 'classnames';
import { Field, Form, Formik } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, string, array } from 'yup';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Spinner from '../../../../components/Spinner/Spinner';
import { apiAllUsersDetails, apiSendByEmails } from '../../../../utils/emailCommunications';
import { PageContainer } from '../../PageContainer';
import styles from './EmailCommunications.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class EmailCommunications extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    users: [],
    usersLoaded: false,
    showEmails: false,
  };

  componentDidMount() {
    this._mounted = true;
    this.readAllUsers().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  readAllUsers = async (props) => {
    const { setFieldValue, setSubmitting } = (props || {});
    if (setSubmitting) {
      setSubmitting(true);
    }

    try {
      const users = (await apiAllUsersDetails())
        .filter(({ email }) => !!email)
        .map(({ name, email }) => ({ name, email }));

      if (setFieldValue) {
        setFieldValue('users', users);
        this.setState({
          showEmails: false,
        });
      } else if (this._mounted) {
        this.setState({
          users,
          usersLoaded: true,
          showEmails: false,
        });
      }
    } finally {
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  toggleShowEmails = () => {
    const { showEmails } = this.state;
    if (this._mounted) {
      this.setState({ showEmails: !showEmails });
    }
  };

  clearUsers = (props) => {
    const { setFieldValue } = props;
    setFieldValue('users', []);
  };

  handleSend = async (values, props) => {
    const { setSubmitting } = props;

    setSubmitting(true);
    const { editorState } = this.state;
    try {
      const { subject, users } = values;
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const response = await apiSendByEmails(subject, content, users.map(usr => usr.email));
      alert(response);
    } catch (e) {
      throw e;
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { editorState, users, usersLoaded, showEmails } = this.state;

    if (!usersLoaded) {
      return '';
    }

    return (
      <PageContainer title="Communications">
        <Formik
          initialValues={{ subject: '', users }}
          validationSchema={object().shape({
            subject: string().required(),
            users: array().required()
          })}
          onSubmit={this.handleSend}
          render={({ values, setFieldValue, setSubmitting, isSubmitting }) => (
            <Form>
              <div className={styles.header}>
                {showEmails
                  ? (
                    <>
                      <Field
                        name="users"
                        component={Input}
                        type={Input.TYPE.MULTI_SELECT}
                        label="To:"
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        spaceBottom={Input.SPACE_BOTTOM.NONE}
                        textField="name"
                        allowCreate={false}
                        data={users}
                        itemComponent={({ item }) => (
                          <span>
                                                        <strong>{item.name}</strong>
                            {' <'}
                            {item.email}
                            {'>'}
                                                    </span>
                        )}
                        rootClassName={styles.input}
                        // tagComponent={TagItem}
                      />
                      <div className={cn(styles.buttons, !showEmails && styles.row)}>
                        <Button
                          small
                          disabled={isSubmitting}
                          onClick={() => this.readAllUsers({ setFieldValue, setSubmitting })}
                          className={styles.button}
                        >
                          All
                        </Button>
                        <Button
                          small
                          disabled={!users.length || isSubmitting}
                          onClick={() => this.clearUsers({ setFieldValue, setSubmitting })}
                          className={styles.button}
                        >
                          Clear
                        </Button>
                      </div>
                    </>
                  )
                  : (
                    <Field
                      label="То:"
                      name="labelTo"
                      placeholder="example@email.com"
                      component={Input}
                      labelClassName={styles.label}
                      type={Input.TYPE.LABEL}
                      editClick={this.toggleShowEmails}
                      field={{
                        name: 'labelTo',
                        value: (
                          <>
                            {usersLoaded ? values.users.length : <Spinner withSpace />}
                            {' '}
                            users
                          </>
                        ),
                      }}
                      spaceBottom={Input.SPACE_BOTTOM.NONE}
                      readOnly
                      rootClassName={styles.input}
                    />
                  )}
              </div>
              <div className={styles.header}>
                <Field
                  label="Subject"
                  name="subject"
                  placeholder="subject"
                  component={Input}
                  labelClassName={styles.label}
                  spaceBottom={Input.SPACE_BOTTOM.NONE}
                  rootClassName={styles.input}
                  spellCheck
                />
                <div className={styles.buttons}>
                  <Button
                    small
                    disabled={isSubmitting}
                    type="submit"
                    className={cn(styles.button, styles.send)}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
        <Editor
          editorState={editorState}
          wrapperClassName={styles.wrapper}
          toolbarClassName={styles.toolbar}
          editorClassName={styles.editor}
          onEditorStateChange={this.onEditorStateChange}
          spellCheck
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ user: { web: { properties: { permissions } } } }) => ({
  userPermissions: permissions,
});

export default connect(mapStateToProps)(EmailCommunications);
