import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ListContainer from '../../../../components/ListContainer/ListContainer';
import SeasonActionMenu from '../../../../components/Seasons/SeasonActionMenu/SeasonActionMenu';
import { WindowContainer } from '../../../../components/WindowContainer';
import { CONTENT_TYPE, ROUTE } from '../../../../constants/general';
import { readSeasonsList } from '../../../../modules/seasons/actions';
import EpisodeList from '../EpisodeList/EpisodeList';
import SeasonCreate from '../SeasonCreate/SeasonCreate';
import SeasonListItemDetails from './SeasonListItemDetails';

const ItemIcons = ({ item, ...rest }) => (
  <SeasonActionMenu item={item} {...rest} />
);
ItemIcons.propTypes = {
  item: PropTypes.shape({}),
  removeCallback: PropTypes.func,
};


class SeasonList extends Component {
  static propTypes = {
    seriesId: PropTypes.string.isRequired,
    seasons: PropTypes.shape({
      list: PropTypes.array,
    }),
    readList: PropTypes.func.isRequired,
  };

  state = {
    ready: false,
    showCreateNewSeason: false,
  };

  componentDidMount() {
    this._mounted = true;
    this.update().then();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { seriesId } = this.props;
    if (prevProps.seriesId !== seriesId) {
      this.update().then();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  update = async () => {
    const { seriesId, readList } = this.props;
    await readList(seriesId);
    if (this._mounted) {
      this.setState({ ready: true });
    }
  };

  toggleCreateNewSeason = async () => {
    if (this._mounted) {
      this.setState(state => ({ showCreateNewSeason: !state.showCreateNewSeason }));
    }
  };

  render() {
    const { seriesId, seasons } = this.props;
    const { ready, showCreateNewSeason } = this.state;

    return (
      <>
        <ListContainer
          ready={ready}
          items={seasons.list}
          type={CONTENT_TYPE.SEASON}
          actions={[
            { label: 'Add Season', onClick: this.toggleCreateNewSeason },
            // { label: 'Add Episode', link: ROUTE.series.episodes.create(seriesId) }
          ]}
          details={SeasonListItemDetails}
          title="Seasons"
          titleLevel={2}
          titleClassName="left"
          seriesId={seriesId}
          icons={props => <ItemIcons removeCallback={this.update} {...props} />}
          footer={item => (
            <Route
              path={ROUTE.series.seasons.episodes.root(seriesId, item._id)}
              component={() => (
                <EpisodeList
                  seriesId={seriesId}
                  seasonId={item._id}
                />
              )}
            />
          )}
        />
        {showCreateNewSeason && (
          <WindowContainer closeAction={this.toggleCreateNewSeason}>
            <SeasonCreate
              seriesId={seriesId}
              onChange={this.toggleCreateNewSeason}
            />
          </WindowContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  seasons: state.seasons,
});

const mapDispatchToProps = dispatch => ({
  readList: seriesId => dispatch(readSeasonsList(seriesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonList);
