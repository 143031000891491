import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { DOC_TYPE_PROJECT } from '../../constants/docs';
import { ROUTE, SHARE_LINK_SCHEMA } from '../../constants/general';
import { frontEndLocation } from '../../constants/system';
import { apiShareDelSettings, apiShareGetSettings, apiShareSaveSettings } from '../../modules/share/api';
import { updateSharesDoc } from '../../utils/share';
import Button from '../Button/Button';
import CopyValueButton from '../CopyValueButton/CopyValueButton';
import Spinner from '../Spinner/Spinner';
import styles from './ShareSettings.module.css';
import Input from '../Input/Input';

const initialState = {
  linkPrev: undefined,
  link: SHARE_LINK_SCHEMA.default(),
  loaded: false,
};

class ShareSettings extends Component {
  static propTypes = {
    documentId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    updateDoc: PropTypes.func,
  };

  state = initialState;

  componentDidMount() {
    this._mounted = true;
    this.getSettings().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getSettings = async () => {
    const { documentId } = this.props;
    try {
      const response = await apiShareGetSettings(documentId);
      if (this._mounted) {
        this.setState(state => ({
          link: {
            ...response,
            pass: state.link.pass ? state.link.pass : state.link.pass, // TODO:
          },
          loaded: true,
        }));
      }
    } catch (e) {
      if (this._mounted) {
        this.setState({ ...initialState, loaded: true });
      }
    }
  };

  handleDisableShare = async (setSubmitting) => {
    const { link: { id } } = this.state;
    setSubmitting(true);

    try {
      await apiShareDelSettings(id);
      await this.getSettings();
      await this.updateUserDocs();

      const { onChange } = this.props;
      if (onChange) {
        await onChange();
      }
    } finally {
      setSubmitting(false);
    }
  };

  updateUserDocs = async () => {
    const { documentId, updateDoc, type } = this.props;
    const { link } = this.state;

    await updateDoc(documentId, type, link && link.id);
  };

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { documentId, type } = this.props;

    try {
      await apiShareSaveSettings({
        ...values,
        documentId,
        type
      });
      if (this._mounted) {
        this.setState(state => ({ link: { ...state.link, pass: values.pass } }));
      }
      await this.getSettings();
      await this.updateUserDocs();

      const { onChange } = this.props;
      if (onChange) {
        await onChange();
      }
    } catch (e) {
      // nothing
    } finally {
      setSubmitting(false);
    }
  };


  render() {
    const { link, loaded } = this.state;
    const { type } = this.props;

    const shareLink = `${frontEndLocation}${ROUTE.share.id(link.id)}`;

    return (
      <div>
        <h3 className={styles.header}>
          Share settings
        </h3>
        <Formik
          initialValues={link}
          validationSchema={SHARE_LINK_SCHEMA}
          enableReinitialize
          onSubmit={this.handleSubmit}
          render={({ isSubmitting, setSubmitting }) => (
            <Form>
              {link.id && (
                <div>
                  <div className={styles.flexRow}>
                    <div className={styles.flex1}>Link</div>
                    <CopyValueButton
                      value={shareLink}
                      className={styles.copyButton}
                    />
                  </div>
                  <Input
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    field={{
                      value: shareLink,
                      onChange: () => {
                      }
                    }}
                  />
                </div>
              )}
              {type === DOC_TYPE_PROJECT && (
                <Field
                  name="canEdit"
                  label="Edit project:"
                  component={Input}
                  type={Input.TYPE.SWITCH}
                  labelWidth={Input.LABEL_WIDTH.RIGHT}
                  labelClassName={styles.label}
                  spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  // disabled={!link.enabled}
                />
              )}
              {type === DOC_TYPE_PROJECT && (
                <Field
                  name="canAddFiles"
                  label="Add files:"
                  component={Input}
                  type={Input.TYPE.SWITCH}
                  labelWidth={Input.LABEL_WIDTH.RIGHT}
                  labelClassName={styles.label}
                  spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  // disabled={!link.enabled}
                />
              )}
              {type === DOC_TYPE_PROJECT && (
                <Field
                  name="canRemoveFiles"
                  label="Remove files:"
                  component={Input}
                  type={Input.TYPE.SWITCH}
                  labelWidth={Input.LABEL_WIDTH.RIGHT}
                  labelClassName={styles.label}
                  spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  // disabled={!link.enabled}
                />
              )}
              <Field
                name="accessType"
                label="Who has access:"
                component={Input}
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                // disabled={!link.enabled}
                readOnly
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
              />
              <Field
                name="pass"
                label={`${link.enabled ? 'Change' : 'Set'} password:`}
                component={Input}
                autoComplete="off"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
              />
              <div className={styles.saveWrapper}>
                <Button
                  small
                  type="submit"
                  className={styles.toggleEdit}
                  disabled={isSubmitting}
                >
                  {link.enabled ? 'Save' : 'Enable'}
                </Button>
                {link.enabled && (
                  <Button
                    small
                    color={Button.COLOR.WARNING}
                    onClick={() => this.handleDisableShare(setSubmitting)}
                    className={styles.toggleEdit}
                    disabled={isSubmitting}
                  >
                    Disable
                  </Button>
                )}
              </div>
            </Form>
          )}
        />
        {!loaded && <Spinner darkBackground />}
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  updateDoc: updateSharesDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareSettings);
