import React from 'react';
import AbsoluteText from './Components/AbsoluteText';
import image from './img/image_organize_schema.svg';
import styles from './OrganizeSchema.module.css';

const OrganizeSchema = () => (
    <div className={styles.container}>
        <img src={image} alt="diagram"/>
        <AbsoluteText level={3} text="Track" className={styles.track} />
        <AbsoluteText level={2} text="Organize" className={styles.organize} />
        <AbsoluteText level={2} text="Publish" className={styles.publish} />
        <AbsoluteText level={1} text="Own" className={styles.own} />

        <AbsoluteText level={3} text="Cloud" className={styles.cloud} />
        <AbsoluteText level={3} text="Local" className={styles.local} />
        <AbsoluteText level={2} text="Backup" className={styles.backup} />
    </div>
);

export default OrganizeSchema;
