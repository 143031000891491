import React, { ReactNode } from 'react';
import { ButtonLink } from '../../../components/ButtonNew';
import styles from './page-container.module.scss';
import cn from 'classnames';


type TPageContainerProps = {
  parentTitle?: string
  parentLink?: string,
  parentClassName?: string
  titleClassName?: string
  title?: string
  titleLevel?: number
  titleRight?: ReactNode
}


const HTag: React.FC<{ level: number } & React.HTMLAttributes<HTMLHeadingElement>> = (props) => {
  const { level = 1, children, ...rest } = props
  const H = `h${level}`;
  return <H {...rest}>{children}</H>;
};

export const PageContainer: React.FC<TPageContainerProps> = (props) => {
  const { parentTitle, parentClassName, parentLink, title, titleClassName, titleLevel = 1, titleRight, children } = props

  return (
    <>
      {(parentTitle || title || titleRight) && (
        <HTag className={cn(styles.titleContainer, titleClassName)} level={titleLevel}>
          <div className={styles.title}>
            {parentTitle && (
              <>
                {parentLink ? (
                  <ButtonLink
                    color="transparent"
                    link={parentLink}
                    className={cn(styles.parent, styles.parentBtn, parentClassName)}
                  >
                    {parentTitle}
                  </ButtonLink>
                ) : (
                  <span className={cn(styles.parent, parentClassName)}>{parentTitle}</span>
                )}
                {' '}
                »
                {' '}
              </>
            )}
            {title}
          </div>
          {titleRight && <div>{titleRight}</div>}
        </HTag>
      )}
      {children}
    </>
  );
}
