import { validateShareWeb } from '../../utils/webSettings';
import {
    getSharingWeb,
    removeSharingId,
    removeSharingWeb,
    setSharingId,
    setSharingWeb
} from '../../utils/localStorageUtils';

export const ACTION_TYPES = {
    clear: 'SHARE_CLEAR_STATE',
    setSharingId: 'SHARE_SET_SHARING_ID',
    token: {
        success: 'SHARE_TOKEN_SUCCESS',
        refreshing: 'SHARE_TOKEN_REFRESHING',
    },
    ownerId: {
        success: 'SHARE_OWNER_ID_SUCCESS',
    },
};

const initialSharingWeb = {
    ownerId: '',
    token: '',
    refreshToken: '',
    refreshing: false,
};


const processWeb = (sharingWeb, state) => ({
    ...state,
    isLoggedIn: validateShareWeb(sharingWeb),
});

const initialState = {
    isLoggedIn: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.clear: {
        removeSharingWeb();
        removeSharingId();
        return processWeb({ ...initialSharingWeb }, { ...initialState });
    }
    case ACTION_TYPES.setSharingId: {
        setSharingId(action.sharingId);

        const sharingWeb = {
            ...initialSharingWeb,
            ...getSharingWeb(),
        };

        return processWeb(sharingWeb, state);
    }

    case ACTION_TYPES.token.success: {
        const sharingWeb = {
            ...initialSharingWeb,
            ...getSharingWeb(),
            token: action.token,
            refreshToken: action.refreshToken,
            refreshing: false
        };

        setSharingWeb(sharingWeb);

        return processWeb(sharingWeb, state);
    }
    case ACTION_TYPES.token.refreshing: {
        const sharingWeb = {
            ...initialSharingWeb,
            ...getSharingWeb(),
            refreshing: action.refreshing
        };

        setSharingWeb(sharingWeb);

        return processWeb(sharingWeb, state);
    }

    case ACTION_TYPES.ownerId.success: {
        const sharingWeb = {
            ...initialSharingWeb,
            ...getSharingWeb(),
            ownerId: action.ownerId,
        };

        setSharingWeb(sharingWeb);

        return processWeb(sharingWeb, state);
    }

    default: {
        return state;
    }
    }
};

export default reducer;
