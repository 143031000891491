import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconStoreOnLocalDrive } from '../../../resources/svg/IconStoreOnLocalDrive';
import { checkAllowCloud } from '../../../utils/permissions';
import Input from '../../Input/Input';
import * as projectActions from '../../../modules/projects/actions';
import styles from './ButtonStoreOnLocalDrive.module.css';

class ButtonStoreOnLocalDrive extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        storeOnLocalDrive: PropTypes.bool.isRequired,
        storeInCloud: PropTypes.bool.isRequired,
        canUseCloud: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        saveProject: PropTypes.func,
    };

    onClick = async () => {
        const { storeOnLocalDrive, onChange, projectId, saveProject } = this.props;

        await saveProject({
            storeOnLocalDrive: !storeOnLocalDrive,
            _id: projectId,
        });

        if (onChange) {
            await onChange();
        }
    };

    render() {
        const { disabled, storeOnLocalDrive, storeInCloud, canUseCloud } = this.props;

        return (
            <>
              <div className={styles.icon}>
                <IconStoreOnLocalDrive {...{
                  ...(storeOnLocalDrive ? {} : {fill: "#9e9e9e"})
                }}
                />
              </div>
                <Input
                    type={Input.TYPE.SWITCH}
                    readOnly={disabled || (storeOnLocalDrive && (!storeInCloud || !canUseCloud))}
                    form={{ touched: null, errors: null }}
                    field={{ name: 'storeOnLocalDrive', value: storeOnLocalDrive }}
                    rootClassName={styles.inputRoot}
                    wrapperClassName={styles.inputWrapper}
                    onChange={this.onClick}
                />
            </>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    saveProject: values => dispatch(projectActions.saveProject(values)),
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStoreOnLocalDrive);
