import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { ButtonNew } from '../../../../components/ButtonNew';
import DropDown from '../../../../components/DropDown/DropDown';
import Input from '../../../../components/Input/Input';
import {
  DOC_TYPE_FILE_AUDIO,
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO, DOC_TYPE_FILE_MEDIA, DOC_TYPE_FILE_MISC, DOC_TYPE_FILE_PROJECT,
  DOCUMENT_DESCRIPTIONS, TDocumentTypeFile
} from '../../../../constants/docs';
import { DOC_TYPES_FILES } from '../../../../constants/pouchDB';
import { IconDelete } from '../../../../resources/svg/IconDelete';
import { TSelectedFile } from '../../../../utils/files/files';
import styles from './project-create-files.module.scss';


type TProjectCreateFilesProps = {
  files: TSelectedFile[]
  handleSelectFile: (file: TSelectedFile) => void
  handleAddFiles: (newFiles: TSelectedFile[]) => void
  handleDeleteFiles: (files: TSelectedFile[]) => void
  showFiles: boolean
  disabled?: boolean
  defaultType: TDocumentTypeFile
  setDefaultType: (type: TDocumentTypeFile) => void
};


const DOC_TYPES_PLACEHOLDERS: Record<TDocumentTypeFile, string> = {
  [DOC_TYPE_FILE_MEDIA]: 'Add raw video clips, images, photos, etc',
  [DOC_TYPE_FILE_AUDIO]: 'Add raw audio clips, music, etc',
  [DOC_TYPE_FILE_PROJECT]: 'Add editable project files',
  [DOC_TYPE_FILE_FINISH_VIDEO]: 'Add any finalized or finished videos',
  [DOC_TYPE_FILE_FINISH_AUDIO]: 'Add any finalized or finished audio',
  [DOC_TYPE_FILE_MISC]: 'Add any other files that don’t fit any other folder',
};

export const ProjectCreateFiles: React.FC<TProjectCreateFilesProps> = (props) => {
  const { files, defaultType, setDefaultType, showFiles, handleSelectFile, handleAddFiles, handleDeleteFiles, disabled = false } = props;

  const handleConfirm = useCallback(() => {
    const curFiles = files.filter(fl => fl.selected);
    handleDeleteFiles(curFiles);
    handleAddFiles(curFiles.map(f => ({ ...f, type: defaultType, typeConfirmed: true, selected: false })));
  }, [files, defaultType, handleDeleteFiles, handleAddFiles]);


  const groupItems = useMemo(() => DOC_TYPES_FILES.map((type: TDocumentTypeFile) => ({
    label: <><strong>{DOCUMENT_DESCRIPTIONS[type].title}</strong>{` (${DOC_TYPES_PLACEHOLDERS[type]})`}</>,
    onClick: () => {
      setDefaultType(type)
    }
  })), [setDefaultType]);


  const unconfirmedFiles = useMemo(() => files.filter(fl => fl.typeConfirmed !== true), [files]);

  return (
    <>
      {!!unconfirmedFiles.length && (
        <>
          <div className={styles.fileType}>
            <div />
            <div className={cn(styles.fileItem, styles.flex)}>
              <span className={styles.lightText}>
                The following files will be added to the
              </span>
              <DropDown
                items={groupItems}
                // alignRight
                btnProps={{
                  size: 'tiny',
                  color: 'none',
                  iconPosition: 'after',
                  noTextTransform: true,
                  className: styles.groupButton
                }}
                menuClassName={styles.typeMenu}
              >
                {DOCUMENT_DESCRIPTIONS[defaultType].title}
              </DropDown>
              <span className={styles.lightText}>
                folder
              </span>
              <ButtonNew
                disabled={files.filter(f => f.selected).length === 0 || disabled}
                onClick={handleConfirm}
                className={styles.confirmButton}
              >
                Add to Folder
              </ButtonNew>
            </div>
          </div>
          <div className={styles.fileType}>
            <div />
            <div className={styles.typeContent}>
              {unconfirmedFiles.map((fl, ind) => (
                <div className={styles.fileItem} key={`${fl.file.name}-${ind}`}>
                  <span>{fl.file.name}</span>
                  <Input
                    id={`${fl.file.name}_${fl.type}`}
                    type={Input.TYPE.CHECKBOX}
                    field={{
                      value: fl.selected,
                      onChange: () => handleSelectFile(fl),
                    }}
                    noValidateIcon
                    spaceBottom={Input.SPACE_BOTTOM.NONE}
                  />
                  <ButtonNew
                    size="tiny"
                    color="transparent"
                    onClick={() => handleDeleteFiles([fl])}
                    disabled={disabled}
                  >
                    <IconDelete />
                  </ButtonNew>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {showFiles && (DOC_TYPES_FILES.map((fileType: TDocumentTypeFile) => (
        <div className={styles.fileType} key={fileType}>
          <div className={styles.typeTitle}>
            {`${DOCUMENT_DESCRIPTIONS[fileType].title}:`}
          </div>
          <div className={styles.typeContent}>
            {!files.filter(fl => fl.type === fileType).length && (
              <div className={styles.typeContentEmpty}>
                {/*{DOC_TYPES_PLACEHOLDERS[fileType]}*/}
              </div>
            )}
            {files.filter(fl => fl.type === fileType && fl.typeConfirmed === true).map((fl, ind) => (
              <div className={styles.fileItem} key={`${fl.file.name}-${ind}`}>
                <span>{fl.file.name}</span>
                <ButtonNew
                  size="tiny"
                  color="transparent"
                  onClick={() => handleDeleteFiles([fl])}
                  disabled={disabled}
                >
                  <IconDelete />
                </ButtonNew>
              </div>
            ))}
          </div>
        </div>
      )))}
    </>
  );
}
