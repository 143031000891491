import { DOCUMENT_DESCRIPTIONS } from '../../constants/docs';
import { systemPaths } from '../../constants/system';
import { formatDate } from '../format';
import { getLocalSettings, getUserWeb } from '../localStorageUtils';
import { findFilesSync } from './fileSystem';
import { fsEnsureDir, fsEnsureDirSync, fsExistsSync, fsRemoveSync } from './fse';
import { pathDirname, pathJoin } from './path';
import { getProjectFolderSync } from './projects';
import { findUserDataFolder } from './userSearch';
import { isWeb } from '../../constants/modules';


// TODO: remove from project folder (desktop app only)
export const removeFileFromFolders = (fileIds, project) => (dispatch) => {
    if (isWeb) {
        return true;
    }

    const projectFolder = dispatch(getProjectFolderSync(project));
    fileIds.forEach((id) => {
      const founded = findFilesSync(projectFolder, id, true, true)
      founded.forEach(filename => {
        fsRemoveSync(filename);
      });
    });

    return true;
};

const getFolderNameFile = (projectFolder, fileType, date) => (
  `${projectFolder}/${DOCUMENT_DESCRIPTIONS[fileType].folderNames[0]}/${formatDate(date || new Date())}`
);

export const getFileNewFolderName = (fileType, project, created = undefined, useData = true) => (dispatch) => {
    const projectFolder = dispatch(getProjectFolderSync(project));

    if (useData) {
      return getFolderNameFile(projectFolder, fileType, created)
    }
    return `${projectFolder}/${DOCUMENT_DESCRIPTIONS[fileType].folderNames[0]}`;
};

export const createFileName = (title, _id) => { // Web and desktop
    if (title.includes(_id)) {
        return title;
    }

    const baseName = title.includes('.') ? title.match(/(.*)[.]/)[1] || '' : title;
    const extension = title.includes('.') ? title.split('.').pop() : '';
    return `${baseName}_${_id}${extension ? `.${extension}` : ''}`;
};
export const newFilePath = ({ title, _id, type, created }, projectFolder) => pathJoin(
    projectFolder, DOCUMENT_DESCRIPTIONS[type].folderNames[0], formatDate(created), createFileName(title, _id)
);
export const getFilePath = (doc, projectFolder) => {
    const founds = findFilesSync(projectFolder, doc._id);
    if (founds && founds.length) {
        return founds[0];
    }

    return newFilePath(doc, projectFolder);
};

export const getDbPathLatest = userId => pathJoin(systemPaths.userData, 'DB', userId, userId);
export const findDbFolder = (userId) => {
    let dbPath = getDbPathLatest(userId);
    // since 2019-12-04
    const p20191204 = dbPath;
    if (fsExistsSync(p20191204)) {
        dbPath = p20191204;
        // console.log('DB folder version: the latest', dbPath);
    } else {
        const settings = getLocalSettings(userId);

        // since 2019-11-01
        if (settings && settings.projectPath) {
            const p20191101 = pathJoin(settings.projectPath, 'db', userId);
            if (fsExistsSync(p20191101)) {
                dbPath = p20191101;
                // console.log('DB folder version: 2019-11-01', dbPath);
            } else {
                const p20191101Upper = pathJoin(pathDirname(settings.projectPath), 'db', userId);
                if (fsExistsSync(p20191101Upper)) {
                    dbPath = p20191101Upper;
                    // console.log('DB folder version: 2019-11-01 (up)', dbPath);
                }
            }
        } else {
            const pTheFirst = pathJoin(systemPaths.mainPath, 'db');
            if (fsExistsSync(pTheFirst)) {
                dbPath = pTheFirst;
                // console.log('DB folder version: the first', dbPath);
            }
        }
    }

    fsEnsureDirSync(dbPath);

    return dbPath;
};

const getUserProjectFolderName = () => {
    const web = getUserWeb();
    return `${web.properties.name}'s Projects_${web.id}`;
};

export const getProjectFolderLatest = projectsPath => pathJoin(projectsPath, getUserProjectFolderName());

const findDataFolderLatest = async (userId) => {
    const settings = getLocalSettings(userId);
    if (settings) {
        const pathLatest = await findUserDataFolder(settings.projectPath, userId);
        if (pathLatest) {
            return pathLatest;
        }
    }

    return null;
};
export const findDataFolder = async (userId) => {
    let dataPath = await findDataFolderLatest(userId);
    // console.log('Project folder version: the latest', dataPath);

    if (!dataPath) {
        const settings = getLocalSettings(userId);
        if (settings && settings.projectPath) {
            const p20191101 = await findUserDataFolder(pathJoin(settings.projectPath, 'Projects'), userId);
            if (p20191101) {
                dataPath = p20191101;
                // console.log('Project folder version: 2019-11-01', dataPath);
            }
        } else {
            const pTheFirst = await findUserDataFolder(
                pathJoin(systemPaths.mainPath, 'Projects', userId),
                userId
            );
            if (pTheFirst) {
                dataPath = pTheFirst;
                // console.log('Project folder version: the first', dataPath);
            }
        }
    }

    if (dataPath) {
        await fsEnsureDir(dataPath);
    }

    return dataPath;
};
