import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { ButtonNew } from '../../../../components/ButtonNew';
import DropDown from '../../../../components/DropDown/DropDown';
import Input from '../../../../components/Input/Input';
import { TRole } from '../../../../helpers/types-api';
import { useRoles } from '../../../../helpers/use-roles-hook';
import { PageContainer } from '../../PageContainer';
import styles from './role.module.scss';


type TRoleValues = Omit<TRole, 'permissions'> & { permissions: Record<string, boolean> }

type TRoleProps = {
  role?: TRole
  changeCallback: () => void
  cancelCb: () => void
}


export const Role: React.FC<TRoleProps> = (props) => {
  const { role, changeCallback, cancelCb } = props;

  const { permissions, delRole, updateRole, addRole } = useRoles({ autoRead: false });


  const roleValues = useMemo<TRoleValues>(() => {
    if (role) {
      return {
        ...role,
        permissions: role.permissions.reduce((acc, { key }) => ({ ...acc, [key]: true }), {})
      };
    }
    return {
      key: '',
      name: '',
      permissions: {},
    };
  }, [role]);


  const handleSubmit = useCallback(async (values: TRoleValues) => {
    const saveValues = {
      ...values,
      permissions: Object.entries(values.permissions).filter(([, value]) => value).map(([key]) => key)
    };

    if (!role) {
      await addRole(saveValues, changeCallback);
    } else {
      await updateRole(saveValues, changeCallback);
    }
    // read
  }, [role, updateRole, addRole, changeCallback]);

  const handleDelete = useCallback(async () => {
    if (role) {
      const answer = window.confirm(
        'The current role will be deleted.\n'
        + 'Continue?'
      );

      if (answer) {
        await delRole(role.key, changeCallback);
      }
    }
  }, [role, delRole, changeCallback]);


  return (<>
    {roleValues && (
      <Formik
        initialValues={roleValues}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({ dirty, isSubmitting }) => {
          return (
            <Form>
              <PageContainer
                title={role?.name || '(no title)'}
                titleLevel={3}
                titleRight={(<>
                  <ButtonNew color="secondary" onClick={cancelCb} disabled={isSubmitting}>
                    Cancel
                  </ButtonNew>
                  <ButtonNew type="submit" disabled={!dirty || isSubmitting}>
                    Save
                  </ButtonNew>
                  <DropDown
                    noIcon
                    items={[{
                      component: (
                        <ButtonNew color="transparent" onClick={handleDelete} disabled={isSubmitting || !role}>
                          Delete
                        </ButtonNew>
                      ),
                    }]}
                    alignRight
                    btnProps={{ icon: faEllipsisV }}
                  />
                </>)}
              >
                <div className={styles.fields}>
                  <Field name="key" {...{
                    label: 'Key',
                    component: Input,
                    disabled: isSubmitting,
                    labelWidth: Input.LABEL_WIDTH.FULL,
                    spaceBottom: Input.SPACE_BOTTOM.MEDIUM,
                  }} />
                  <Field name="name" {...{
                    label: 'Name',
                    component: Input,
                    disabled: isSubmitting,
                    labelWidth: Input.LABEL_WIDTH.FULL,
                    spaceBottom: Input.SPACE_BOTTOM.MEDIUM,
                  }} />
                </div>
                <h3>Permissions</h3>
                <div className={styles.fields}>
                  {permissions.map(({ key, name }) => (
                    <Field name={`permissions.${key}`} key={key} {...{
                      labelOnTheRight: true,
                      label: name,
                      component: Input,
                      type: Input.TYPE.SWITCH,
                      spaceBottom: Input.SPACE_BOTTOM.SMALL,
                      readOnly: isSubmitting,
                    }} />
                  ))}
                </div>
              </PageContainer>
            </Form>
          );
        }}
      />
    )}
  </>)
}
