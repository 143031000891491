import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage3.module.css';
import imageShareEdit from './image_shre_edit.svg';

const ProjectHelpPage3 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                Let others upload and download
                assets to your project by sharing it
            </h2>
            <div className={styles.example}>
                <img className={styles.example} src={imageShareEdit} alt="share and edit button"/>
            </div>
        </div>
    </TitledPage>
);

export default ProjectHelpPage3;
