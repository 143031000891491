import { apiShareGetDocument, apiShareUpdateDocument } from '../../modules/share/api';
import { writeUserDoc, readUserDoc } from '../../handlers/userDB';
import { isSharing } from '../webSettings';

export const CLOUD_TYPES = {
    s3: 's3'
};

const getCloudResult = (uppyFile) => {
    if (uppyFile) {
        if (uppyFile.s3Multipart) {
            return {
                [CLOUD_TYPES.s3]: {
                    key: uppyFile.s3Multipart.key,
                    uploaded: new Date().toISOString(),
                },
            };
        }
    }
    return {};
};

export const saveCloudInfo = (uppyFile) => async (dispatch) => {
    const isShare = isSharing();

    const foundDoc = isShare
        ? await apiShareGetDocument(uppyFile.meta.doc._id)
        : await dispatch(readUserDoc(uppyFile.meta.doc._id));

    if (foundDoc && foundDoc._id) {
        const doc = {
            ...foundDoc,
            _id: uppyFile.meta.doc._id,
            cloud: {
                ...foundDoc.cloud,
                ...getCloudResult(uppyFile),
            }
        };

        if (isShare) {
            await apiShareUpdateDocument(doc);
        } else {
            await dispatch(writeUserDoc(doc));
        }
    } else {
        // TODO: Maybe we should remove file from cloud in this case
        throw new Error(`Error while reading file from user DB by id "${uppyFile.meta.doc._id}"`);
    }
};
