import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconStoreInCloud } from '../../../resources/svg/IconStoreInCloud';
import { checkAllowCloud } from '../../../utils/permissions';
import Input from '../../Input/Input';
import styles from './button-storeIn-cloud.module.css';
import * as projectActions from '../../../modules/projects/actions';

class ButtonStoreInCloud extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        projectId: PropTypes.string.isRequired,
        storeOnLocalDrive: PropTypes.bool.isRequired,
        storeInCloud: PropTypes.bool.isRequired,
        canUseCloud: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        saveProject: PropTypes.func,
    };

    onClick = async () => {
        const { storeInCloud, onChange, projectId, saveProject } = this.props;

        await saveProject({
            storeInCloud: !storeInCloud,
            _id: projectId,
        });

        if (onChange) {
            await onChange();
        }
    };

    render() {
        const { disabled, storeOnLocalDrive, storeInCloud, canUseCloud } = this.props;

        return (
            <>
            <div className={styles.icon}>
              <IconStoreInCloud {...{
                ...(storeInCloud ? {} : {fill: "#9e9e9e"})
              }}
              />
            </div>
                <Input
                    type={Input.TYPE.SWITCH}
                    readOnly={disabled || !canUseCloud || (storeInCloud && !storeOnLocalDrive)}
                    form={{ touched: null, errors: null }}
                    field={{ name: 'storeInCloud', value: storeInCloud }}
                    rootClassName={styles.inputRoot}
                    wrapperClassName={styles.inputWrapper}
                    onChange={this.onClick}
                />
            </>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    saveProject: values => dispatch(projectActions.saveProject(values)),
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStoreInCloud);
