import React from 'react';

const k = 4.2339 / 4.2333;

export const IconVideoNav: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const {
    height = 17,
    fill = 'white',
    ...rest } = props;


  const aspectWidth = (height as number) * k;
  return (
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 4.2339 4.2333" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="a">
          <path d="m0 792h612v-792h-612z"/>
        </clipPath>
      </defs>
      <g transform="matrix(.55672 0 0 -.55672 -5.3764 378.37)" clipPath="url(#a)" fill={fill}>
        <g transform="translate(12.054 676.94)">
          <path d="m0 0c0 0.601 0.425 0.846 0.945 0.546l2.074-1.197c0.52-0.301 0.52-0.791 0-1.091l-2.074-1.198c-0.52-0.3-0.945-0.054-0.945 0.545z"/>
        </g>
        <g transform="translate(13.459 678.91)">
          <path d="m0 0c-1.692 0-3.068-1.377-3.068-3.069s1.376-3.069 3.068-3.069 3.068 1.377 3.068 3.069-1.376 3.069-3.068 3.069m0-6.871c-2.097 0-3.802 1.705-3.802 3.802 0 2.096 1.705 3.802 3.802 3.802s3.803-1.706 3.803-3.802c0-2.097-1.706-3.802-3.803-3.802"/>
        </g>
      </g>
    </svg>
  );
};
