import { Circle, Line } from 'rc-progress';
import React from 'react';
import PropTypes from 'prop-types';
// import { prettySize } from '../../../../utils/format';
import styles from './SyncLayoutItem.module.css';

const PROGRESS_COLOR_SUCCESS = '#9dc66c';
const PROGRESS_COLOR_ERROR = 'coral';

const ActionStatus = ({ action }) => {
    if (action.progress.ended) {
        if (action.progress.errorMessage) {
            return `error: ${action.progress.errorMessage}`;
        }
        return 'complete';
    }
    return `${action.progress.percentage}%`;
};

// const ActionSpeed = ({ action }) => {
//     if (action.hasSpeed) {
//         const timeMs = (action.progress.ended || Date.now()) - action.progress.started;
//         if (timeMs) {
//             return prettySize(
//                 (action.progress.total - action.progress.current) / timeMs * 1000,
//                 ' ',
//                 '/s'
//             );
//         }
//     }
//     return '';
// };

const SyncLayoutItem = ({ item }) => (
    <div className={styles.item} key={item.id}>
        <div className={styles.itemProgress}>
            <Circle
                percent={item.progress.percentage || 0}
                strokeWidth="8"
                strokeColor={item.progress.errorMessage
                    ? PROGRESS_COLOR_ERROR
                    : PROGRESS_COLOR_SUCCESS}
            />
            {/* <div style={{ */}
            {/*    position: 'absolute', */}
            {/*    top:0, */}
            {/*    bottom:0, */}
            {/*    left:0, */}
            {/*    right:0, */}
            {/*    // verticalAlign: 'middle', */}
            {/*    fontSize: '12px', */}
            {/* }}> */}
            {/*    {item.progress.total} */}
            {/* </div> */}
        </div>
        <div className={styles.itemContent}>
            <div className={styles.itemHeader}>
                {item.title}
                {' '}
                {item.id}
            </div>
            <div className={styles.action}>
                <div className={styles.actionHeader}>
                    {/* TODO: icon size */}
                    {/* {item.currentAction.icon */}
                    {/*    ? <Spinner /> */}
                    {/*    : <Icon icon={item.currentAction.icon} /> */}
                    {/* } */}
                    {/* {' '} */}
                    {item.currentAction.title}
                    {' '}
                    <ActionStatus action={item.currentAction} />
                    {/* {' '} */}
                    {/* <ActionSpeed action={item.currentAction} /> */}
                </div>
                <div className={styles.actionProgress}>
                    <Line
                        percent={item.currentAction.progress.percentage || 0}
                        strokeWidth="8"
                        strokeColor={item.currentAction.progress.errorMessage
                            ? PROGRESS_COLOR_ERROR
                            : PROGRESS_COLOR_SUCCESS}
                    />
                </div>
            </div>
        </div>
    </div>
);

SyncLayoutItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        currentAction: PropTypes.shape({
            title: PropTypes.string,
            progress: PropTypes.shape({
                percentage: PropTypes.number,
                errorMessage: PropTypes.string,
                ended: PropTypes.number,
            }),
        }),
        progress: PropTypes.shape({
            percentage: PropTypes.number,
            errorMessage: PropTypes.string,
        }),
    }),
};

export default SyncLayoutItem;
