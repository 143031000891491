import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkAllowCloud } from '../../utils/permissions';
import StatusIcon from '../StatusIcons/StatusIcon/StatusIcon';
import iconCloud from './icon_cloud.svg';

const getIcon = (cloud) => {
    if (cloud.s3) {
        return iconCloud;
    }
};

const IconUploadedToCloud = ({ cloud, canUseCloud }) => (canUseCloud && (
  <StatusIcon
    enabled={cloud && Object.keys(cloud).length > 0}
    icon={cloud && Object.keys(cloud).length > 0
      ? getIcon(cloud)
      : iconCloud
    }
  />
));

IconUploadedToCloud.propTypes = {
    cloud: PropTypes.object,
    canUseCloud: PropTypes.bool,
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = dispatch => ({
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconUploadedToCloud);
