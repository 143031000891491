import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { findAndUploadFile } from '../../utils/desktop/desktop';
import { checkAllowCloud } from '../../utils/permissions';
import Button from '../Button/Button';
import icon from './icon_resync.svg';
import styles from './ActionFileSync.module.css';
import { isWeb } from '../../constants/modules';

class ActionFileSync extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        files: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string.isRequired,
            projectId: PropTypes.string.isRequired,
            cloud: PropTypes.object,
            ...(isWeb ? {} : { path: PropTypes.string.isRequired }),
        })),
        canUseCloud: PropTypes.bool,
        disabled: PropTypes.bool,
        upload: PropTypes.func,
    };

    getFilteredFiles = () => {
        const { files } = this.props;
        return files.filter((f) => {
            const hasCloud = !!(f.cloud && Object.keys(f.cloud).length);
            const hasPath = !!f.path;
            return ((f.systemType ?? 'file') === 'file') && (
              ((hasCloud && !hasPath) || (!hasCloud && hasPath))
            );
        });
    };

    onClick = async () => {
        const { upload } = this.props;
        this.getFilteredFiles().forEach(f => upload(f));
    };

    render() {
        const { disabled, canUseCloud } = this.props;
        if (!canUseCloud || isWeb) {
            return '';
        }

        const matchedCount = this.getFilteredFiles().length;

        return (
            <Button
                tiny
                disabled={disabled || !matchedCount}
                onClick={this.onClick}
                color={Button.COLOR.NONE}
                className={cn(styles.button, styles.left0)}
            >
                <div className={styles.icon}>
                    <img src={icon} alt="Re sync to web" title={`${matchedCount} files`} />
                </div>
                {`Sync (${matchedCount} files)`}
            </Button>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    upload: doc => dispatch(findAndUploadFile(doc)),
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionFileSync);
