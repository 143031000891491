import moment from 'moment';
import { asyncForEach } from '../../common';
import { setConvertSharePropsToArray, setFileNames, setProjectShareProps, setProjectSyncProps } from './updates';
import { writeUserDoc, readUserDoc } from '../../../handlers/userDB';

const ID_INFO = 'INFO_DB';

// Month should be decreased by 1 because it should be an month index in moment() input
const formatV = ([yyyy, m, d]) => moment([yyyy, m - 1, d]).format('YYYYMMDD');

const UPDATES = [
    { version: formatV([2019, 9, 26]), action: setProjectSyncProps },
    { version: formatV([2020, 2, 3]), action: setProjectShareProps },
    { version: formatV([2020, 2, 5]), action: setConvertSharePropsToArray },
    { version: formatV([2020, 3, 2]), action: setFileNames },
];

// eslint-disable-next-line import/prefer-default-export
export const updateDb = () => async (dispatch) => {
    const infoDoc = await dispatch(readUserDoc(ID_INFO));

    let { version } = infoDoc;
    if (!version) {
        version = formatV([2019, 1, 1]);
    }

    const updateList = UPDATES
        .filter(upd => upd.version > version)
        .sort();

    if (updateList.length) {
        const results = await asyncForEach(updateList, async (upd) => {
            await dispatch(upd.action());
            return upd.version;
        });

        if (results && results.length) {
            await dispatch(writeUserDoc({
                ...infoDoc,
                _id: ID_INFO,
                version: results[results.length - 1],
            }));
        }
    }
};
