import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import * as seasonsActions from '../../../../modules/seasons/actions';
import { SEASON_SCHEMA } from '../../../../constants/general';
import styles from './SeasonCreate.module.css';

class SeasonCreate extends Component {
  static propTypes = {
    seriesId: PropTypes.string.isRequired,
    newSeasonValues: PropTypes.func.isRequired,
    insert: PropTypes.func.isRequired,
    readSeasonsList: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  state = {
    season: SEASON_SCHEMA.default()
  };

  componentDidMount() {
    this._mounted = true;
    this.init().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { seriesId, newSeasonValues } = this.props;

    const values = await newSeasonValues(seriesId);

    if (this._mounted) {
      this.setState(state => ({
        ...state,
        season: {
          ...state.season,
          ...values
        }
      }));
    }
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { seriesId, insert, readSeasonsList, onChange } = this.props;

    await insert(values);

    setSubmitting(false);
    await readSeasonsList(seriesId);

    if (onChange) {
      await onChange();
    }
  };

  render() {
    const { season } = this.state;

    return (
      <div className={styles.container}>
        <h1 className="margin-top-none">New Season</h1>
        <Formik
          initialValues={season}
          enableReinitialize
          validationSchema={SEASON_SCHEMA}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          render={({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field
                name="title"
                component={Input}
                label="Name:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                required
                spellCheck
              />
              <Field
                name="description"
                type="textarea"
                component={Input}
                label="Description:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spellCheck
              />
              <Field
                name="keywords"
                component={Input}
                type={Input.TYPE.MULTI_SELECT}
                maxLength={500}
                label="Keywords:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                showCopyButton
                inputProps={{ spellCheck: true }}
              />
              <Field
                name="number"
                component={Input}
                label="Number:"
                readOnly
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                required
              />
              <Field
                name="sponsored"
                label="Sponsored:"
                component={Input}
                type={Input.TYPE.SWITCH}
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                onChange={e => setFieldValue('sponsored', e.target.value)}
              />
              <FormButtons>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  color={Button.COLOR.PRIMARY}
                >
                  Create
                </Button>
              </FormButtons>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  newSeasonValues: seriesId => dispatch(seasonsActions.newSeasonValues(seriesId)),
  insert: values => dispatch(seasonsActions.insert(values)),
  readSeasonsList: seriesId => dispatch(seasonsActions.readSeasonsList(seriesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonCreate);
