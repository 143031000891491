import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// import { HELP_FLAG_CREATE_FIRST_PROJECT } from '../../../../utils/helpText';
import imageArrow from './image_arrow.svg';
import styles from './ListPlaceholderSeries.module.css';

const ListPlaceholderSeries = ({ flag }) => (
    !flag && (
        <div className={styles.container}>
            <div>
                <h2 className={styles.textLine}>
                    Now that you have a finished video or audio you
                    can add them to a series.
                </h2>
                <h2 className={styles.textLine}>
                    To create a series click `Add Series`
                </h2>
            </div>
            <img className={styles.imgArrow} src={imageArrow} alt="arrow" />
        </div>
    )
);

ListPlaceholderSeries.propTypes = {
    flag: PropTypes.bool,
};

const mapStateToProps = ({ user: { dbUser: { helpFlags } } }) => ({
    flag: false,//(helpFlags && helpFlags[HELP_FLAG_CREATE_FIRST_PROJECT]) || false,
});

export default connect(mapStateToProps)(ListPlaceholderSeries);
