import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button/Button';
import ListContainer from '../../../components/ListContainer/ListContainer';
import { useListFilter } from '../../../components/ListFilter';
import { WindowContainer } from '../../../components/WindowContainer';
import { DOC_TYPE_PROJECT, TDocProject } from '../../../constants/docs';
import { CONTENT_TYPE } from '../../../constants/general';
import { isWeb } from '../../../constants/modules';
import { TStore } from '../../../helpers/types-store';
import { usePrevious } from '../../../hooks/common';
import { getProjectTotals, readProjectsList } from '../../../modules/projects/actions';
import { UserContext } from '../../../Providers/User';
import { reSyncAllProject } from '../../../utils/desktop/desktop';
import { isSharing } from '../../../utils/webSettings';
import ProjectCreate from '../ProjectCreate';
import ProjectListItemDetails from './ProjectListItemDetails';
import ProjectListItemIcons from './ProjectListItemIcons';
import { ACTION_TYPES } from '../../../modules/projects/reducer';


type TProjectsListProps = {
  projects: TDocProject[]
  isReady: boolean
  readList: () => void
  getTotals: () => void
  reSyncAll: () => void
}


export const ProjectsList: FC<TProjectsListProps> = (props) => {
  const dispatch = useDispatch();
  const { projects, isReady, selector } = useSelector<TStore, { projects: TDocProject[], isReady: boolean, selector: string[] }>(
    ({
       projects: {
         list,
         readDate,
         options: {
           selector: {
             $and: selector
           }
         }
       }
     }
    ) => ({
      projects: list,
      isReady: !!readDate,
      selector: selector.find(s => !!s.tags)?.tags?.$in ?? [],
    }));

  const { permissions: { canUseCloud } } = useContext(UserContext);

  const prevProjects = usePrevious<TDocProject[]>(projects)
  const [totals, setTotals] = useState<Record<string, any>>({})
  const [showCreate, setShowCreate] = useState(false)
  const toggleCreateNewProject = useCallback(() => {
    setShowCreate(s => !s)
  }, [])


  const readList = useCallback(async () => {
    await dispatch(readProjectsList())
  }, [dispatch]);

  const reSyncAll = useCallback(async () => {
    await dispatch(reSyncAllProject())
  }, [dispatch]);

  const readTotals = useCallback(async () => {
    const totalsIds = Object.keys(totals);
    const projectIds = projects?.map(pr => pr._id) ?? [];

    const newIds = projectIds.filter(id => !totalsIds.includes(id));
    if (newIds) {
      const newTotals = await dispatch(getProjectTotals(newIds))

      if (true) { // (this._mounted) { // TODO: How to control this in React.FC?
        setTotals({
          ...projectIds.reduce((acc, id) => ({
            ...acc,
            [id]: totals[id]
          }), {}),
          ...newTotals
        });
      }
    }
  }, [dispatch, projects, totals]);


  const setTagFilter = useCallback((tags: string[]) => {
    dispatch({
      type: ACTION_TYPES.projects.filter,
      selector: [
        ...(tags.length ? [{ tags: { $in: tags } }] : []),
      ],
    });
    void readList();
  }, [dispatch, readList])

  const filterProps = useListFilter({
    tagSearch: {
      enabled: true,
      onChange: setTagFilter,
      values: selector,
    }
  });


  useEffect(() => {
    void readList();
  }, [readList])

  const shouldUpdateTotals = useMemo<boolean>(() => {
    return projects !== prevProjects;
  }, [projects, prevProjects]);

  useEffect(() => {
    if (shouldUpdateTotals) {
      void readTotals();
    }
  }, [readTotals, shouldUpdateTotals])

  return (
    <>
      <ListContainer
        ready={isReady}
        title="Projects"
        items={projects?.map(pr => ({ ...pr, ...totals[pr._id] })) ?? []}
        actions={[
          {
            label: 'Sync projects',
            onClick: reSyncAll,
            color: Button.COLOR.GRAY,
            visible: !isWeb && !isSharing() && canUseCloud
          },
          {
            label: 'Add Project',
            onClick: toggleCreateNewProject,
          },
        ]}
        details={(item: Record<string, any>) => (
          <ProjectListItemDetails
            {...item}
            onTagClick={filterProps.tagSearch.add}
          />
        )}
        icons={ProjectListItemIcons}
        type={CONTENT_TYPE.PROJECT}
        filterProps={filterProps}
      />
      {showCreate && (
        <WindowContainer closeAction={toggleCreateNewProject}>
          <ProjectCreate
            // onChange={toggleCreateNewProject}
          />
        </WindowContainer>
      )}
    </>
  );
}
