import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { uppy } from '../../constants/globals';
import Button from '../Button/Button';

import styles from './Input.module.css';

const isFileTypeAllowed = (file, allowedFileTypes) => {
    if (!allowedFileTypes) {
        return true;
    }
    const escapedString = allowedFileTypes.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    return new RegExp(`(${escapedString.replace(/,/g, '|')})$`).test(file.name.toLowerCase());
};

const isFileSizeOk = (file, maxFileSize) => {
    if (!maxFileSize) {
        return true;
    }
    return file.size <= maxFileSize;
};


class FileUploader extends Component {
    state = {
        isDragOver: false,
        uploading: false,
        uploadProgress: 0,
        uploaded: false,
        previewSource: '',
    };

    onChange = (e) => {
        const { fileTypes, maxFileSize, showPreview } = this.props;
        const file = e.target.files[0];

        if (!isFileTypeAllowed(file, fileTypes)) {
            this.setError('Sorry, this file type is not allowed, please try again with a correct format.');
            return false;
        }

        if (!isFileSizeOk(file, maxFileSize)) {
            this.setError('Sorry, this file is too big, please try again with a smaller file.');
            return false;
        }

        if (showPreview) {
            this.previewFile(file);
        } else {
            this.uploadFile(file);
        }

        return true;
    };

    setError = (error) => {
        const { form, name } = this.props;
        form.setFieldTouched(name, true);
        window.setTimeout(() => form.setFieldError(name, error));
    };

    previewFile = (file) => {
        const { previewCallback } = this.props;
        this.setState({ uploading: true });

        const reader = new FileReader();
        reader.onload = (evt) => {
            this.setState({ uploading: false }, () => {
                if (previewCallback) {
                    previewCallback(evt.target.result, () => this.uploadFile(file));
                } else {
                    this.setState({
                        uploaded: true,
                        previewSource: evt.target.result,
                    });
                }
            });
        };
        reader.readAsDataURL(file);
    };

    uploadFile = (file) => {
        const { form, name, uploadSuccess } = this.props;
        this.setState({
            uploading: true,
            uploadProgress: 0,
        });

        file.isRawUpload = true;

        uppy.addFile({
            name: file.name,
            type: file.type,
            data: file,
        });
        uppy.upload().then((result) => {
            this.setState({
                uploading: false,
                uploaded: true,
                uploadProgress: 100,
            });

            const fieldValue = {
                key: result.successful[0].s3Multipart.key,
                uploaded: new Date().toISOString()
            };

            form.setFieldValue(name, fieldValue);
            this.setError('');

            console.log(result.successful[0].s3Multipart.key);

            if (uploadSuccess) {
                uploadSuccess(fieldValue);
            }

            return result;
        }).catch(() => this.setState({ uploading: false }));
    };

    render() {
        const { isDragOver, uploading, uploadProgress, uploaded, previewSource } = this.state;
        const { className, icon, fileTypes, message, hideDragAndDropHelpMessage } = this.props;
        return (
            <div className={cn(styles.fileUploader, className)}>
                {!!icon && (
                    <p>
                        <img
                            src={icon}
                            alt={`File names ending in ${fileTypes}`}
                        />
                    </p>
                )}
                { !uploaded && !!message && <p>{message}</p> }
                <p>
                    {!hideDragAndDropHelpMessage
                        && <span>You can drag and drop the file from your computer to here.</span>
                    }
                    { isDragOver && <span>Now drop it!</span> }
                </p>
                { uploading && (uploadProgress <= 100) && <p>Saving...</p> }
                { uploaded && (
                    <div id="successElement">
                        {
                            !previewSource && (
                                <p>Your file has been saved.</p>
                            )
                        }
                        {
                            !!previewSource && (
                                <img
                                    alt="Preview"
                                    className={styles.fileUploaderButton}
                                    src={previewSource}
                                />
                            )
                        }
                    </div>
                )}
                {!uploading && (
                    <Button
                        small
                        color={Button.COLOR.PRIMARY}
                        className={styles.fileUploaderButton}
                    >
                        Browse
                    </Button>
                )}
                {!uploading && (
                    <input
                        type="file"
                        onChange={this.onChange}
                    />
                )}
            </div>

        );
    }
}

FileUploader.defaultProps = {
    fileTypes: '.png,.jpg,.jpeg,.gif',
    hideDragAndDropHelpMessage: false,
    maxFileSize: 10485760,
    showPreview: false,
    previewCallback: null,
};

FileUploader.propTypes = {
    className: PropTypes.string,
    fileTypes: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.any,
    hideDragAndDropHelpMessage: PropTypes.bool,
    maxFileSize: PropTypes.number,
    showPreview: PropTypes.bool,
    previewCallback: PropTypes.func,
    uploadSuccess: PropTypes.func,
    uploadUrl: PropTypes.string,
    uploadParamName: PropTypes.string,
    form: PropTypes.object.isRequired,
    name: PropTypes.string,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(FileUploader);
