import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatusIcon from '../StatusIcons/StatusIcon/StatusIcon';
import iconInPlaylistCheck from './icon_in_playlist_check.png';
import iconInPlaylist from './icon_in_playlist.png';
import * as epActions from '../../modules/episodes/actions';

class IconVideoInPlaylist extends Component {
  static propTypes = {
    fetchEpisodesByVideoId: PropTypes.func.isRequired,
    videoId: PropTypes.string,
  };

  state = { hasEpisodes: false };

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { videoId, fetchEpisodesByVideoId } = this.props;
    if (videoId) {
      const docs = await fetchEpisodesByVideoId(videoId);
      if (this._mounted) {
        this.setState({ hasEpisodes: docs && docs.length > 0 });
      }
    }
  };

  render() {
    const { hasEpisodes } = this.state;
    return (
      <StatusIcon
        enabled={hasEpisodes}
        icon={hasEpisodes ? iconInPlaylistCheck : iconInPlaylist}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchEpisodesByVideoId: fileId => dispatch(epActions.fetchEpisodesByVideoId([fileId]))
});

export default connect(undefined, mapDispatchToProps)(IconVideoInPlaylist);
