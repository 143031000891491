import { faEllipsisV, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { FC, useCallback, useContext, useState } from 'react';
import { TDocFile, TDocProject } from '../../constants/docs';
import { isWeb } from '../../constants/modules';
import { UserContext } from '../../Providers/User';
import ActionDownloadFile from '../ActionDownloadFile/ActionDownloadFile';
import ActionFileSync from '../ActionFileSync/ActionFileSync';
import ActionEditFile from '../Actions/ActionEditFile/ActionEditFile';
import WindowContainer from '../WindowContainer/temp/WindowContainer';
import styles from './action-file-menu.module.scss';
import ActionRemove from '../Actions/ActionRemove/ActionRemove';
import Button from '../Button/Button';
import DropDown from '../DropDown/DropDown';
import { Icon } from '../Icon';
import cn from 'classnames';


type TActionFileMenuProps = {
  project: TDocProject
  file: TDocFile
  removeFileAction?: () => void
  removeFileCallback?: () => void
  saveFileAction?: () => void
  editFileCallback?: () => void
}


export const ActionFileMenu: FC<TActionFileMenuProps> = (props) => {
  const { permissions: { canUseCloud } } = useContext(UserContext)
  const { project, file, removeFileAction, removeFileCallback, saveFileAction, editFileCallback } = props;

  const [showEditFile, setShowEditFile] = useState(false)
  const toggleEdit = useCallback(() => {
    setShowEditFile(s => !s)
  }, [])

  return (
    <>
      <DropDown
        noIcon
        items={[
          {
            component: <ActionFileSync files={[file]} disabled={!project.storeInCloud} />,
            showItem: canUseCloud && !isWeb,
          },
          {
            component: <ActionDownloadFile cloud={file.cloud} />,
            showItem: canUseCloud,
          },
          {
            component: <ActionRemove docs={[file]} action={removeFileAction} callback={removeFileCallback} />,
            showItem: !!removeFileAction,
          },
          {
            component: (
              <Button
                key="button"
                tiny
                color={Button.COLOR.NONE}
                onClick={toggleEdit}
                className={cn(styles.container, styles.left0)}
              >
                <div className={styles.icon}>
                  <Icon icon={faPencilAlt} />
                </div>
                Notes
              </Button>
            ),
            showItem: !!saveFileAction,
          },
        ]}
        alignRight
        btnProps={{ icon: faEllipsisV }}
      />
      {showEditFile && (
        <WindowContainer key="edit" closeAction={toggleEdit} width={500}>
          <ActionEditFile doc={file} action={saveFileAction} callback={() => {
            if (editFileCallback) {
              editFileCallback()
            }
            toggleEdit();
          }} />
        </WindowContainer>
      )}
    </>
  );
}
