import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import styles from './BarItem.module.css';

const BarItem = ({ number, text, image, isLast }) => (
    <div className={cn(styles.container, !isLast && styles.notLast)}>
        <span />
        <h2 className={styles.number}>
            {`${number}.`}
        </h2>
        <div className={styles.text}>
            {text}
        </div>
        <img src={image} alt="progress icon" className={styles.image} />
    </div>
);

BarItem.propTypes = {
    number: PropTypes.any,
    text: PropTypes.any,
    image: PropTypes.any,
    isLast: PropTypes.bool,
};

export default BarItem;
