import { refreshToken, refreshTokenState } from '../utils/api/apiHelper';
import { getAwsS3MultipartOptions } from '../utils/uppyUploader/uppy';
import { api, uppy } from './globals';

export const initGlobals = () => async (dispatch) => {
    api.setOptions({
        funcRefreshToken: () => dispatch(refreshToken()),
        funcRefreshTokenState: () => dispatch(refreshTokenState()),
    });
};

export const initUppy = () => {
    uppy.setOptions({ awsS3MultipartOptions: getAwsS3MultipartOptions() });
};
