import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Icon } from '../Icon';
import styles from './Switch.module.css';

const Switch = props => (
    <div
        // htmlFor={props.id}
        className={cn(styles.container, props.checkbox ? styles.checkContainer : styles.switchContainer)}
        onClick={props.onChange}
    >
        <input
            id={props.id}
            name={props.name}
            value={props.value}
            checked={props.value}
            type="checkbox"
            onBlur={props.onBlur}
            onChange={props.onChange}
        />
        {props.checkbox
            ? (
                <span className={cn(styles.checkbox, props.readOnly && styles.readOnly)}>
                    <Icon className={cn(styles.icon, styles.checkIcon)} icon={props.value ? faCheckSquare : faSquare} />
                </span>
            )
            : <span className={cn(styles.slider, styles.round, props.readOnly && styles.readOnly)} />}
    </div>
);

Switch.defaultProps = {
    value: false
};

Switch.propTypes = {
    value: PropTypes.bool
};

export default Switch;
