import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as videoActions from '../../../modules/videos/actions';
import { CONTENT_TYPE } from '../../../constants/general';
import ListContainer from '../../../components/ListContainer/ListContainer';
import VideoListItemDetails from './VideoListItemDetails';

class VideosList extends Component {
  static propTypes = {
    readVideosList: PropTypes.func.isRequired,
    videos: PropTypes.array.isRequired,
  };

  state = { ready: false };

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { readVideosList } = this.props;
    await readVideosList();
    if (this._mounted) {
      this.setState({ ready: true });
    }
  };

  render() {
    const { videos } = this.props;
    const { ready } = this.state;

    return (
      <>
        <ListContainer
          ready={ready}
          title="Videos"
          items={videos}
          actions={[
            // { label: 'Add Project', link: ROUTE.projects.create() }
          ]}
          details={VideoListItemDetails}
          type={CONTENT_TYPE.VIDEO}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  videos: state.videos.list
});

const mapDispatchToProps = dispatch => ({
  readVideosList: () => dispatch(videoActions.readVideosList())
});

export default connect(mapStateToProps, mapDispatchToProps)(VideosList);
