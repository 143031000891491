import cn from 'classnames';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/Button';
import copyIcon from './copy.svg';
import styles from './CopyValueButton.module.css';

class CopyValueButton extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        onClick: PropTypes.func,
        className: PropTypes.string,
    };

    state = {
        showPopup: false,
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    newState = (state, cb) => {
        if (this._mounted) {
            this.setState(state, cb);
        }
    };

    handleCopy = async () => {
        const { onClick, value } = this.props;
        if (onClick) {
            await onClick();
        } else {
            copy(value);
        }
        this.newState(
            { showPopup: true },
            () => setTimeout(() => this.newState({ showPopup: false }), 5000)
        );
    };

    render() {
        const { className } = this.props;
        const { showPopup } = this.state;
        return (
            <div className={cn(styles.popup, className)}>
                <div className={cn(styles.popupText, showPopup && styles.show)}>copied</div>
                <Button
                    color={Button.COLOR.NONE}
                    className={cn(styles.button)}
                    onClick={this.handleCopy}
                    noDecorate
                >
                    <img src={copyIcon} alt="copy" />
                </Button>
            </div>
        );
    }
}

export default CopyValueButton;
