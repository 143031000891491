import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { findProjectFile } from '../../../utils/desktop/search';
import StatusIcon from '../StatusIcon/StatusIcon';
import icon from './icon_file_on_local_drive.svg';
import { isWeb } from '../../../constants/modules';

class IconFileOnLocalDrive extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    fileId: PropTypes.string.isRequired,
    getPath: PropTypes.func,
  };

  state = { path: '' };

  componentDidMount() {
    if (!isWeb) {
      this.checkFileOnDrive();
    }
  }

  checkFileOnDrive() {
    const { projectId, fileId, getPath } = this.props;
    this.setState({ path: getPath(projectId, fileId) });
  }

  render() {
    if (isWeb) {
      return '';
    }

    const { path } = this.state;
    return <StatusIcon enabled={path.length > 0} icon={icon} altOn="on drive" altOff="not on drive" title={path} />;
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  getPath: (projectId, fileId) => dispatch(findProjectFile(projectId, fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconFileOnLocalDrive);
