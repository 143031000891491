import React from 'react';

const k = 4.5197 / 3.4396;

export const IconProjectsNav: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 17, fill = 'white', ...rest } = props;

  const aspectWidth = (height as number) * k;
  return (
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 4.5197 3.4396" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.3 0 0 1.3 9.1001e-6 -382.66)" fill={fill}>
        <path d="m3.2942 295.76-0.21547 0.7821c-0.046533 0.16854-0.19983 0.28507-0.37462 0.28585h-1.8465c0.0653-0.0673 0.11223-0.14976 0.13726-0.24049l0.26552-0.96629c0.0305-0.11262 0.13296-0.19043 0.24949-0.19043h1.5353c0.14273 0 0.25848 0.11575 0.25887 0.25848 0 0.0236-0.00311 0.0466-0.00944 0.0689zm-2.841 1.068h-0.004667c-0.15251 0-0.27608-0.12356-0.27647-0.27608v-1.7476c3.9111e-4 -0.1529 0.12396-0.27607 0.27647-0.27647h0.55646c0.062578 0 0.12318 0.0208 0.17245 0.0594l0.39965 0.31558c0.079378 0.0625 0.17715 0.0966 0.27804 0.0966h0.84897c0.14625 4e-4 0.26709 0.11458 0.2753 0.26084h-1.4696c-0.19474 0-0.36485 0.13021-0.41608 0.31752l-0.26748 0.96433c-0.046144 0.16816-0.19865 0.28508-0.37306 0.28586m2.7014-1.554c-7.833e-4 -0.24793-0.20256-0.44814-0.45049-0.44736h-0.84975c-0.062567 0-0.12357-0.0207-0.17245-0.0594l-0.39965-0.31601c-0.079378-0.0622-0.17715-0.0962-0.27804-0.0962h-0.55529c-0.24792-2e-5 -0.44892 0.20137-0.44892 0.44929v1.7476c3.9e-4 0.24753 0.201 0.44853 0.44892 0.44892h2.2552c0.25262-7.8e-4 0.47395-0.16971 0.54121-0.41333l0.21586-0.7821c0.062967-0.22955-0.071567-0.46653-0.30111-0.52987-2e-3 -4e-4 -0.00389-7.9e-4 -0.00544-1e-3"/>
      </g>
    </svg>
  );
};
