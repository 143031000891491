import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { ButtonNew } from '../../../../../../components/ButtonNew';
import YoutubePlayer from '../../../../../../components/YoutubePlayer/YoutubePlayer';
import { DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../../../constants/docs';
import { checkPublishMedia } from '../../../../../../utils/permissions';
import styles from './FileMetadata.module.css';
import { FILE_SCHEMA } from '../../../../../../constants/general';
import Input from '../../../../../../components/Input/Input';

const initialState = {
    file: {},
    editMode: false
};

class FileMetadata extends Component {
    static propTypes = {
        file: PropTypes.object.isRequired,
        saveFile: PropTypes.func,
        readFile: PropTypes.func.isRequired,
        canPublishMedia: PropTypes.bool,
    };

    state = initialState;

    toggleEdit = (resetForm) => {
        const { editMode } = this.state;
        if (editMode && resetForm) {
            resetForm();
        }
        this.setState({ editMode: !editMode });
        return true;
    };

    handleSubmitVideo = async (file) => {
        const { saveFile, readFile } = this.props;

        const values = {
            _id: file._id,
            title: file.title,
            description: file.description,
            keywords: file.keywords,
            youtube: file.youtube,
        };

        try {
            await saveFile(values);
            readFile();
        } catch (err) {
            alert(err.message);
            console.error(err.message);
        }
    };

    render() {
        const { editMode } = this.state;
        const { file, saveFile, canPublishMedia } = this.props;
        const readOnly = !saveFile;

        return (
            <div>
                <Formik
                    initialValues={file}
                    validationSchema={FILE_SCHEMA}
                    enableReinitialize
                    onSubmit={this.handleSubmitVideo}
                    render={({ resetForm }) => (
                        <Form>
                            <div className={styles.editContainer}>
                                <div>
                                    {canPublishMedia && file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
                                        !editMode && file.youtube && file.youtube.youtubeId
                                            ? <YoutubePlayer youtubeId={file.youtube.youtubeId} />
                                            : (
                                                <Field
                                                    name="youtube.youtubeId"
                                                    component={Input}
                                                    label="Youtube Embed:"
                                                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                                                    labelClassName={styles.label}
                                                    readOnly={!editMode}
                                                    editClick={!readOnly && this.toggleEdit}
                                                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                                />
                                            )
                                    )}
                                    <Field
                                        name="title"
                                        component={Input}
                                        label="Title:"
                                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                                        labelClassName={styles.label}
                                        readOnly={!editMode}
                                        editClick={!readOnly && this.toggleEdit}
                                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                        spellCheck
                                    />
                                    <Field
                                        name="description"
                                        component={Input}
                                        type={Input.TYPE.TEXTAREA}
                                        label="Description:"
                                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                                        labelClassName={styles.label}
                                        readOnly={!editMode}
                                        editClick={!readOnly && this.toggleEdit}
                                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                        spellCheck
                                    />
                                    <Field
                                        name="keywords"
                                        component={Input}
                                        type={Input.TYPE.MULTI_SELECT}
                                        maxLength={500}
                                        label="Keywords:"
                                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                                        labelClassName={styles.label}
                                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                        readOnly={!editMode}
                                        editClick={!readOnly && this.toggleEdit}
                                        showCopyButton
                                        inputProps={{ spellCheck: true }}
                                    />
                                    {file.meta && Object.keys(file.meta).map(curMetaField => (
                                        <Field
                                            key={curMetaField}
                                            name={'file.meta.$curMetaField}'}
                                            component={Input}
                                            label={`${curMetaField}:`}
                                            labelWidth={Input.LABEL_WIDTH.RIGHT}
                                            labelClassName={styles.label}
                                            field={{
                                                value: file.meta[curMetaField]
                                            }}
                                            readOnly
                                            spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                        />
                                    ))}
                                </div>
                                <div className={styles.saveWrapper}>
                                    {editMode && (
                                        <ButtonNew type="submit" className={styles.toggleEdit}>
                                            Save
                                        </ButtonNew>
                                    )}
                                    {!readOnly && (
                                        <ButtonNew onClick={() => this.toggleEdit(resetForm)} className={styles.toggleEdit} color={editMode ? 'secondary' : 'primary'}>
                                            {editMode ? 'Cancel' : 'Edit'}
                                        </ButtonNew>
                                    )}
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    canPublishMedia: dispatch(checkPublishMedia()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileMetadata);
