import { actionCloseLocalDB, actionCloseRemoteDB } from '../modules/db/dbActions';
import { getRemoteDB, getUserDB } from './dbUtils';

export const closeLocalDB = () => async (dispatch) => {
    const db = dispatch(getUserDB());
    if (db) {
        try {
            await db.close();
        } catch (e) {
            // nothing
        }
        dispatch(actionCloseLocalDB());
    }
};

export const closeRemoteDB = () => async (dispatch) => {
    const db = getRemoteDB();
    if (db) {
        try {
            await db.close();
        } catch (e) {
            // nothing
        }
        dispatch(actionCloseRemoteDB());
    }
};

export const closeDBs = () => async (dispatch) => {
    await dispatch(closeLocalDB());
    await dispatch(closeRemoteDB());
};
