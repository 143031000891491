import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './NavMenu.module.css';

const NavMenu = ({ items }) => (
    <div className={styles.menuContainer}>
        {items.map((itm => (
            <NavLink
                exact={itm.exact || false}
                key={itm.to}
                to={itm.to}
                className={cn(styles.menuItem, itm.className)}
                activeClassName={cn(styles.menuItemActive, itm.activeClassName)}
            >
                <h2>{itm.title}</h2>
            </NavLink>
        )))}
    </div>
);

NavMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        exact: PropTypes.bool,
        to: PropTypes.string,
        title: PropTypes.string,
        className: PropTypes.string,
        activeClassName: PropTypes.string,
    })),
};

export default NavMenu;
