import React from 'react';
import { useSelector } from 'react-redux';
import DropDown from '../../../components/DropDown/DropDown';
import { ROUTE } from '../../../constants/general';
import { TStore } from '../../../helpers/types-store';
import { InformationMenu } from './InformationMenu';
import { QuickSearch } from './QuickSearch';
import styles from './header.module.scss';


const items = [
  { to: ROUTE.account.root, label: 'Account' },
  { to: ROUTE.logout, label: 'Logout' },
];


export const Header: React.FC = () => {
  const name = useSelector<TStore, string>(({ user }) => user.web.properties.name);

  return (
    <header id="globalHeader" className={styles.wrapper}>
      <div className={styles.searchBar}>
        <QuickSearch />
      </div>
      <div className={styles.user}>
        <InformationMenu />
        <DropDown
          items={items}
          alignRight
          btnProps={{
            size: 'tiny',
            color: 'transparent',
            iconPosition: 'after',
            noTextTransform: true,
            className: styles.userButton
          }}
        >
          {name}
        </DropDown>
      </div>
    </header>
  );
}
