import _ from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { TDocument } from '../../constants/docs';
import { UserContext } from '../User';
import { UserDbContext } from '../UserDB';
import { TUseUserInformationResult, useUserInformation } from './use-user-information.hook';



type TDocUser = TDocument & {
  settings: {
    filesCopyMethod?: 'copy' | 'move'
  }
}
type TUserDataContext = {
  user: TDocUser
  information: TUseUserInformationResult
  updateUser: (values: Record<string, unknown>) => Promise<void>
};

const USER_DEFAULT: TDocUser = {
  _id: '',
  _rev: '',
  type: 'user',
  updated: new Date().toISOString(),
  created: new Date().toISOString(),
  settings: {
    filesCopyMethod: undefined
  }
};

export const UserDataContext = createContext<TUserDataContext>({
  user: USER_DEFAULT,
  information: {
    wrongDuration: {
      count: 0,
      update: () => undefined,
      updating: false,
      calculate: () => undefined,
      calculating: undefined,
      message: '',
      busy: false,
    }
  },
  updateUser: () => Promise.resolve(undefined),
})


export const UserDataProvider: React.FC = ({ children }) => {
  const { id } = useContext(UserContext);
  const { readUserDoc, writeUserDoc } = useContext(UserDbContext);
  const information = useUserInformation();
  const [user, setUser] = useState<TDocUser>({ ...USER_DEFAULT, _id: id });


  const readUser = useCallback(async () => {
    const newUser = await readUserDoc(id) as TDocUser;
    setUser(_.merge({}, USER_DEFAULT, newUser));
  }, [id, readUserDoc]);
  const updateUser = useCallback(async (values: Record<string, unknown>) => {
    const newUser = await writeUserDoc({ ...user, ...values}) as TDocUser;
    setUser(_.merge({}, USER_DEFAULT, newUser));
  }, [user, writeUserDoc]);


  useEffect(() => void readUser(), [readUser])


  return (
    <UserDataContext.Provider value={{ user, information, updateUser }}>
      {children}
    </UserDataContext.Provider>
  )
}

