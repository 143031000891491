import PropTypes from 'prop-types';
import React from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { removeEpisodes } from '../../../modules/episodes/actions';
import ActionRemove from '../../Actions/ActionRemove/ActionRemove';
import DropDown from '../../DropDown/DropDown';

const EpisodeActionMenu = ({ item, removeAction, removeCallback }) => (
    <DropDown
        noIcon
        items={[
            {
                component: <ActionRemove docs={[item]} action={removeAction} callback={removeCallback} />,
                showItem: !!removeAction,
            },
        ]}
        alignRight
        btnProps={{ icon: faEllipsisV }}
    />
);

EpisodeActionMenu.propTypes = {
    item: PropTypes.shape({}),
    removeAction: PropTypes.func,
    removeCallback: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    removeAction: removeEpisodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeActionMenu);
