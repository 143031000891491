import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import { SERIES_TYPES } from '../../../../constants/pouchDB';
import * as episodesActions from '../../../../modules/episodes/actions';
import { EPISODE_SCHEMA } from '../../../../constants/general';
import { readUserDoc } from '../../../../handlers/userDB';
import styles from './EpisodeCreate.module.css';
import 'react-widgets/dist/css/react-widgets.css'; // TODO: need for Combobox

class EpisodeCreate extends Component {
  static propTypes = {
    seriesId: PropTypes.string.isRequired,
    // TODO: seasonId required while we save doc, but id season is empty - we should create one to series
    seasonId: PropTypes.string,
    newEpisodeValues: PropTypes.func.isRequired,
    insert: PropTypes.func.isRequired,
    readEpisodesList: PropTypes.func.isRequired,
    fetchVideos: PropTypes.func.isRequired,
    readSeries: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  state = {
    episode: EPISODE_SCHEMA.default(),
    seriesType: null,
    files: [],
    busy: false
  };

  componentDidMount() {
    this._mounted = true;
    this.init().then();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { seriesId, seasonId, newEpisodeValues, readSeries } = this.props;
    const values = await newEpisodeValues(seriesId, seasonId);
    const series = await readSeries(seriesId);

    if (this._mounted) {
      this.setState(state => ({
        ...state,
        episode: {
          ...state.episode,
          ...values,
          seriesId,
          seasonId
        },
        seriesType: series.seriesType,
      }));
    }
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { seriesId, seasonId, insert, readEpisodesList, onChange } = this.props;
    await insert(values);

    setSubmitting(false);
    await readEpisodesList(seriesId, seasonId);

    if (onChange) {
      await onChange();
    }
  };

  fetchFiles = (value) => {
    const { fetchVideos } = this.props;
    const { seriesType } = this.state;

    fetchVideos(value, SERIES_TYPES[seriesType].fileType)
      .then(result => this._mounted && this.setState({
        files: result,
        busy: false
      }))
      .catch((err) => {
        console.error(err);
        if (this._mounted) {
          this.setState({
            files: [],
            busy: false
          });
        }
      });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedFetchFiles = debounce(this.fetchFiles, 500);

  handleTitleChange = (value) => {
    if (this._mounted) {
      if (value && (typeof value === 'object')) {
        this.setState(state => ({
          episode: {
            ...state.episode,
            videoId: value._id,
            title: value.title
          },
          busy: false
        }));
      } else if (value && value.length > 0) {
        this.setState({ busy: true });
        this.debouncedFetchFiles(value);
      } else if (this.state.files.length) {
        this.setState({ files: [] });
      }
    }
  };


  render() {
    const { episode, busy, files, seriesType } = this.state;
    const disabled = !seriesType;
    const fileLabel = seriesType ? SERIES_TYPES[seriesType].name : 'Video';

    if (!episode.number) {
      return '';
    }

    return (
      <div className={styles.container}>
        <h1 className="margin-top-none">New Episode</h1>
        {!seriesType
        && (episode.number || '')
        && <div className={styles.error}>You should define series type first</div>
        }
        <Formik
          initialValues={episode}
          enableReinitialize
          validationSchema={EPISODE_SCHEMA}
          onSubmit={async (values, actions) => this.handleSubmit(values, actions)}
          render={({ isSubmitting }) => (
            <Form>
              <Field
                name="videoId"
                component={Input}
                type={Input.TYPE.COMBOBOX}
                label={fileLabel}
                placeHolder="Type to find finished video"
                busy={busy}
                onChange={this.handleTitleChange}
                data={files}
                valueField="_id"
                textField="title"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                required
                disabled={disabled}
              />
              <Field
                name="number"
                component={Input}
                label="Number:"
                readOnly
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                required
              />
              <Field
                name="description"
                type="textarea"
                component={Input}
                label="Description:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                disabled={disabled}
                spellCheck
              />
              <Field
                name="keywords"
                component={Input}
                type={Input.TYPE.MULTI_SELECT}
                maxLength={500}
                label="Keywords:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                disabled={disabled}
                showCopyButton
                inputProps={{ spellCheck: true }}
              />
              <FormButtons>
                <Button type="submit" disabled={isSubmitting || disabled} color={Button.COLOR.PRIMARY}>
                  Create
                </Button>
              </FormButtons>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  readSeries: seriesId => dispatch(readUserDoc(seriesId)),
  newEpisodeValues: (seriesId, seasonId) => dispatch(episodesActions.newEpisodeValues(seriesId, seasonId)),
  insert: values => dispatch(episodesActions.insert(values)),
  readEpisodesList: (seriesId, seasonId) => dispatch(episodesActions.readEpisodesList(seriesId, seasonId)),
  fetchVideos: (title, fileType) => dispatch(episodesActions.searchFiles(title, fileType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeCreate);
