import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import { SERIES_TYPES } from '../../../../constants/pouchDB';
import * as seriesActions from '../../../../modules/series/actions';
import { ROUTE, SERIES_SCHEMA } from '../../../../constants/general';
import styles from './SeriesCreate.module.css';

class SeriesCreate extends Component {
    static propTypes = {
        save: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        updateSeriesList: PropTypes.func.isRequired,
    };

    state = {
        series: SERIES_SCHEMA.default()
    };

    handleSubmit = async (values, { setSubmitting }) => {
        const { save, history, updateSeriesList } = this.props;
        setSubmitting(true);
        try {
            const newSeries = await save(values);
            updateSeriesList();
            history.push(ROUTE.series.id(newSeries._id));
        } catch (e) {
            throw e;
        } finally {
            setSubmitting(false);
        }
    };

    render() {
        const { series } = this.state;

        return (
            <div className={styles.container}>
                <h1 className="margin-top-none">New Series</h1>
                <Formik
                    initialValues={series}
                    validationSchema={SERIES_SCHEMA}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                    render={({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <Field
                                name="title"
                                component={Input}
                                label="Name:"
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                required
                                spellCheck
                            />
                            <Field
                                name="description"
                                type="textarea"
                                component={Input}
                                label="Description:"
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                required
                                spellCheck
                            />
                            <Field
                                name="keywords"
                                component={Input}
                                type={Input.TYPE.MULTI_SELECT}
                                maxLength={500}
                                label="Keywords:"
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                showCopyButton
                                inputProps={{ spellCheck: true }}
                            />
                            <Field
                                name="seriesType"
                                component={Input}
                                type={Input.TYPE.DROPDOWN}
                                label="Type:"
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                data={Object.keys(SERIES_TYPES)}
                                defaultValue={series.seriesType || undefined}
                                required
                                onChange={value => setFieldValue('seriesType', value)}
                            />
                            <Field
                                name="sponsored"
                                label="Sponsored:"
                                component={Input}
                                type={Input.TYPE.SWITCH}
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                onChange={e => setFieldValue('sponsored', e.target.value)}
                            />
                            <Field
                                name="seasons"
                                label="Seasons:"
                                component={Input}
                                type={Input.TYPE.SWITCH}
                                labelWidth={Input.LABEL_WIDTH.RIGHT}
                                labelClassName={styles.label}
                                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                                onChange={e => setFieldValue('seasons', e.target.value)}
                            />
                            <FormButtons>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    color={Button.COLOR.PRIMARY}
                                >
                                    Create
                                </Button>
                            </FormButtons>
                        </Form>
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    save: values => dispatch(seriesActions.insert(values)),
    updateSeriesList: () => dispatch(seriesActions.readSeriesList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesCreate);
