import PropTypes from 'prop-types';
import React from 'react';
import Input from '../../../components/Input/Input';
import styles from './project-list-item-details.module.scss';
import IconProjectHasFileType from '../../../components/Projects/common/IconProjectHasFileType/IconProjectHasFileType';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../constants/docs';
import { prettyDate } from '../../../utils/format';

const ProjectListItemDetails = ({ item, onTagClick }) => (
  <div className={styles.container}>
    <div className={styles.left}>
      <div key="created">
        {`Created: ${prettyDate(item.created)}`}
      </div>
      <div key="fileNumbers">
        Finished videos:
        {' '}
        <IconProjectHasFileType project={item} type={DOC_TYPE_FILE_FINISH_VIDEO} onlyNumber />
        {' | '}
        Finished audio:
        {' '}
        <IconProjectHasFileType project={item} type={DOC_TYPE_FILE_FINISH_AUDIO} onlyNumber />
      </div>
    </div>
    <div className={styles.right}>
      <div key="tags">
        <Input
          readOnly={true}
          field={{
            name: 'tags',
            value: item.tags,
          }}
          type={Input.TYPE.MULTI_SELECT}
          noClick={onTagClick}
        />
      </div>
    </div>
  </div>
);
ProjectListItemDetails.propTypes = {
  item: PropTypes.shape({
    created: PropTypes.string,
    _totals: PropTypes.shape({
      fileCount: PropTypes.shape({}),
    }),
  }),
};

export default ProjectListItemDetails;
