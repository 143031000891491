import { isWeb } from '../../constants/modules';
import { readUserDoc, writeUserDocs } from '../../handlers/userDB';
import { asyncForEach } from '../common';
import { runUploadFile } from '../common/syncRunner';
import { copyNewFileToProjectFolder } from '../desktop/desktop';
import FileFS from '../desktop/FileFS';
import { readUserProject } from '../desktop/new_file_search';
import { getNewDocumentFile } from '../pouchDB/documents';

/**
 * Return object { file: File, doc: {} }
 * @param {string} fileType Doc-type of the file
 * @param {File} file Chosen file
 * @param {string} projectId Project Id
 *
 * @return {{ file: File, doc: {} }} Complex object with File and new DB Doc
 * */
export const getFileObject = async (fileType, file, projectId) => ({
    file,
    doc: await getNewDocumentFile(fileType, file, projectId)
});

/**
 * Insert file documents into the user DB
 * @param {string} projectId Project Id
 * @param {[{ _id: string, type: string, ...}]} docs Array of prepared file documents
 * */
const insertFileDocsToUserDB = (projectId, docs) => async (dispatch) => {
    const project = await dispatch(readUserDoc(projectId));

    if (!project._id) {
        throw new Error(`Project with id "${project._id}" not found`);
    }

    // docs.forEach((doc) => {
    //     const fileType = doc.type;
    //     if (!project[fileType]) {
    //         project[fileType] = [];
    //     }
    //     project[fileType].push(doc._id); // TODO: Do we really still need this?
    // });

    await dispatch(writeUserDocs([project, ...docs]));
};

/**
 * Process files (copy to project folder, upload to cloud)
 * @param {string} projectId Project Id
 * @param {[{ doc: {}, file: File }]} fileObjects Array of prepared files with documents
 * @param {{ move: boolean }} options Object with file
 * */
const insertFilesProcessFileObjects = (projectId, fileObjects, options) => async (dispatch) => {
    if (fileObjects && fileObjects.length > 0) {
        const project = await dispatch(readUserProject(projectId));

        await asyncForEach(fileObjects, async (fo) => {
            let path = undefined;
            try {
                path = await copyNewFileToProjectFolder(project, fo.doc, fo.file, options.move);
                if (path && !isWeb) {
                  fo.file = FileFS.from(path);
                }
            } catch (e) {
                return;
            }
            if (project.storeInCloud && ((fo.doc.systemType ?? 'file') === 'file')) {
                dispatch(runUploadFile([fo]));
            }
        });
    }
};

/**
 *  Add chosen files to project
 * @param {string} projectId Project Id where files should be added
 * @param {TSelectedFile[]} files Object with file
 * @param {{ move: boolean }} options Object with file
 * */
export const addFilesToProject = (projectId, files, options) => async (dispatch) => {
    if (files && files.length) {
      const fileObjects = await asyncForEach(
        files,
        async (fl) => await getFileObject(fl.type, fl.file, projectId)
      );

      //console.log('files, fileObjects', files, fileObjects)

      await dispatch(insertFileDocsToUserDB(projectId, fileObjects.map(fo => fo.doc)));

      await dispatch(insertFilesProcessFileObjects(projectId, fileObjects, options));
    }
};
