class SyncStore {
  state = {
    items: {},
    total: 0,
    running: 0,
    done: 0,
    error: 0,
    success: 0,
  };

  callbacks = [];

  getState = () => this.state;

  setState = (patch) => {
    const prevState = { ...this.state };
    const nextState = { ...this.state, ...patch };

    // if (Object.keys(patch.items).length > 0) {
    //     console.log('patch', patch);
    //     console.trace('patch', {
    //         cur: patch.items['3'].progress.current,
    //         a0cur: patch.items['3'].actions[0].progress.current,
    //         a0typ: patch.items['3'].actions[0].type,
    //         a1cur: patch.items['3'].actions[1].progress.current,
    //         a1typ: patch.items['3'].actions[1].type,
    //     });
    // }

    this.state = { ...nextState, ...this._getUpdatePatch(nextState) };
    this._publish(this.state, prevState, patch);

    // const errors = Object.values(this.state.items)
    //     .map(item => item.actions)
    //     .reduce((acc, actions) => ([...acc, ...actions]), [])
    //     .filter(action => isNaN(action.progress.total))
    // if (errors.length) {
    //     console.trace('errors', errors);
    //     // throw new Error('errors');
    // }
  };

  subscribe = (listener) => {
    this.callbacks.push(listener);
    return () => {
      // Remove the listener.
      this.callbacks.splice(
        this.callbacks.indexOf(listener),
        1
      );
    };
  };

  _publish = (...args) => {
    this.callbacks.forEach((listener) => {
      listener(...args);
    });
  };

  _getUpdatePatch = (nextState) => {
    const itemArray = Object.values(nextState.items);

    const done = itemArray.filter(item => !!item.progress.ended).length;
    const error = itemArray.filter(item => !!item.progress.ended && !!item.progress.errorMessage).length;

    return {
      total: itemArray.length,
      running: itemArray.filter(item => !!item.progress.started && !item.progress.ended).length,
      done,
      error,
      success: done - error,
    };
  };
}

export default () => new SyncStore();
