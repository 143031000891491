import { GENERAL_ACTION_TYPES } from '../general';

export const DB_ACTION_TYPES = {
    setLocalDB: 'LOCAL_DB_SUCCESS',
    closeLocalDB: 'LOCAL_DB_CLOSED',
    setRemoteDB: 'REMOTE_DB_SUCCESS',
    closeRemoteDB: 'REMOTE_DB_CLOSED',
    setSyncHandler: 'REMOTE_SYNC_SUCCESS',
    clearSyncHandler: 'REMOTE_SYNC_CANCEL',
};

const initialState = {
    userDB: null,
    remoteDB: null,
    syncHandler: null,
    isDbConnected: false,
};

const processDbConnected = state => ({
    ...state,
    isDbConnected: !!state.userDB && !!state.remoteDB && !!state.syncHandler,
});

const reducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
    case DB_ACTION_TYPES.setLocalDB: {
        newState = { ...state, userDB: action.userDB };
        break;
    }
    case DB_ACTION_TYPES.closeLocalDB: {
        newState = { ...state, userDB: null };
        break;
    }

    case DB_ACTION_TYPES.setRemoteDB: {
        newState = { ...state, remoteDB: action.remoteDB };
        break;
    }
    case DB_ACTION_TYPES.closeRemoteDB: {
        newState = { ...state, remoteDB: null };
        break;
    }

    case DB_ACTION_TYPES.setSyncHandler: {
        newState = { ...state, syncHandler: action.syncHandler };
        break;
    }
    case DB_ACTION_TYPES.clearSyncHandler: {
        newState = { ...state, syncHandler: null };
        break;
    }

    case GENERAL_ACTION_TYPES.authClear: {
        newState = { ...initialState };
        break;
    }

    default: { return state; }
    }

    return processDbConnected(newState);
};

export default reducer;
