import { useCallback, useState } from 'react';
import { TUseDialogCopyMoveFilesResult, TUseDialogCopyMoveFilesShowResult } from './use-dialog-copy-move-files.shared';


export const useDialogCopyMoveFiles = (): TUseDialogCopyMoveFilesResult => {
  const [completeState, setCompleteState] = useState<{ complete: boolean, method?: 'copy' | 'move' }>({ complete: false, method: 'copy' });

  const showDialog = useCallback(() => {
    setCompleteState({ complete: true });
  }, []);

  return {
    isShown: false,
    isComplete: completeState.complete,
    copyMethod: completeState.method,
    showDialog,
  };
}
