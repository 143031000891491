import React, { createContext, FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TPermissionName } from '../../helpers/types-api';
import { TStore } from '../../helpers/types-store';
import { getUserId, isSharing } from '../../utils/webSettings';


type TUserContext = {
  // user: {
  id: string
  //   name: string
  //   login: string
  //   email: string
  // },
  permissions: {
    canUseApp: boolean
    canManageAccount: boolean
    canPublishMedia: boolean
    canUseCloud: boolean
    canManageUsers: boolean
    canReadUsers: boolean
  }
};


export const UserContext = createContext<TUserContext>({
  // user: {
  //   id: "",
  //   name: "",
  //   login: "",
  //   email: "",
  // },
  id: '',
  permissions: {
    canUseApp: false,
    canManageAccount: false,
    canPublishMedia: false,
    canUseCloud: false,
    canManageUsers: false,
    canReadUsers: false,
  }
})

export const UserProvider: FC = ({ children }) => {
  const permissions = useSelector<TStore, TPermissionName[]>(({ user }) => user.web.properties.permissions)


  const hasPermission = useCallback((name: TPermissionName) => {
    return isSharing()
      ? true
      : permissions.includes(name);
  }, [permissions]);

  const id = useMemo(() => getUserId(), []);

  const canUseApp = useMemo(() => hasPermission('DesktopAndWebApp'), [hasPermission]);
  const canManageAccount = useMemo(() => hasPermission('ManagementAccount'), [hasPermission]);
  const canPublishMedia = useMemo(() => hasPermission('AbilityPublicMedia'), [hasPermission]);
  const canUseCloud = useMemo(() => hasPermission('S3UploadAudioAndVideo'), [hasPermission]);
  const canManageUsers = useMemo(() => hasPermission('UserManagement'), [hasPermission]);
  const canReadUsers = useMemo(() => hasPermission('UserManagementRead'), [hasPermission]);

  return (
    <UserContext.Provider value={{
      id,
      permissions: {
        canUseApp,
        canManageAccount,
        canPublishMedia,
        canUseCloud,
        canManageUsers,
        canReadUsers,
      }
    }}>
      {children}
    </UserContext.Provider>
  )
}

