import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS } from '../constants/general';
import { api, emitUserError } from '../constants/globals';
import { apiRolesGetRoles } from '../utils/user';
import { TPermission, TRole } from './types-api';


export type TRoleSaveValues = {
  key: string
  name?: string
  permissions?: string[]
}

type TUseRolesProps = {
  autoRead?: boolean
}
type TUseRolesResponse = {
  roles: TRole[]
  permissions: TPermission[]
  readRoles: () => Promise<void>
  readRole: (role_key: string) => Promise<void>
  addRole: (role: TRoleSaveValues, cb?: () => void) => Promise<void>
  updateRole: (role: TRoleSaveValues, cb?: () => void) => Promise<void>
  delRole: (key: string, cb?: () => void) => Promise<void>
}


export const useRoles = (props?: TUseRolesProps): TUseRolesResponse => {
  const { autoRead = true } = props ?? {};

  const [roles, setRoles] = useState<TRole[]>([]);
  const [permissions, setPermissions] = useState<TPermission[]>([]);


  const readRoles = useCallback(async () => {
    try {
      setRoles(((await apiRolesGetRoles()) as TRole[])
        .sort((a, b) => a.name > b.name ? 1 : -1));
    } catch (e) {
      emitUserError(`Read roles\n${(e as Error).message}`)
    }
  }, []);

  const readRole = useCallback(async (role_key: string) => {
    try {
      const res = await api.apiGet(ENDPOINTS.roles.getByKey, { role_key });
      console.log(`read Role "${role_key}"`, res)
      if (roles.length) {
        setRoles(list => list.map(r => {
          if (r.key === role_key) {
            return r;
          }
          return r;
        }))
      }
      return undefined;
    } catch (e) {
      emitUserError(`Read role\n${(e as Error).message}`)
    }
  }, [roles]);

  const addRole = useCallback(async (values: TRoleSaveValues, cb?: () => void) => {
    try {
      await api.apiPost(ENDPOINTS.roles.addRole, values);

      if (autoRead) {
        await readRoles();
      }
    } catch (e) {
      emitUserError(`Add role\n${(e as Error).message}`)
      return;
    }

    if (cb) {
      await cb()
    }
  }, [autoRead, readRoles]);

  const updateRole = useCallback(async (values: TRoleSaveValues, cb?: () => void) => {
    try {
      await api.apiPost(ENDPOINTS.roles.updateRole, values);

      if (autoRead) {
        await readRole(values.key);
      }
    } catch (e) {
      emitUserError(`Update role\n${(e as Error).message}`)
      return;
    }

    if (cb) {
      await cb()
    }
  }, [autoRead, readRole]);

  const delRole = useCallback(async (key: string, cb?: () => void) => {
    try {
      await api.apiPost(ENDPOINTS.roles.delRole, { key });

      if (autoRead) {
        await readRoles();
      }
    } catch (e) {
      emitUserError(`Del role\n${(e as Error).message}`)
      return;
    }

    if (cb) {
      await cb()
    }
  }, [autoRead, readRoles]);


  const readPermissions = useCallback(async () => {
    try {
      const res = await api.apiGet(ENDPOINTS.roles.getAllPermissions) as { permissions: TPermission[] };
      if (res?.permissions) {
        setPermissions(res?.permissions);
      }
    } catch (e) {
      emitUserError(`Read permissions\n${(e as Error).message}`)
    }
  }, []);


  useEffect(() => {
    if (autoRead && !roles.length) {
      void readRoles();
    }
  }, [autoRead, roles.length, readRoles]);

  useEffect(() => {
    if (!permissions.length) {
      void readPermissions();
    }
  }, [permissions.length, readPermissions]);


  return {
    roles,
    permissions,
    readRoles,
    readRole,
    addRole,
    updateRole,
    delRole,
  };
}
