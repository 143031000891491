import React from 'react';
import PropTypes from 'prop-types';
import { ButtonNew } from '../../ButtonNew';
import styles from './MultiSelectReadOnly.module.css';


const ItemWrapper = ({ onClick, children, val }) => onClick
  ? (
    <ButtonNew
      color="transparent"
      onClick={() => onClick(val)}
      noTextTransform
      className={styles.button}
    >
      {children}
    </ButtonNew>
  )
  : children;

const MultiSelectReadOnly = ({ value, noClick }) => (
  <div className={styles.container}>
    <ul className={styles.rwMultiSelectTagListReadOnly}>
      {value && value.map(val => (
        <ItemWrapper key={val} onClick={noClick} val={val}>
          <li id={val} className="rw-multiselect-tag">
            <span>{val}</span>
          </li>
        </ItemWrapper>
      ))}
    </ul>
  </div>
);

MultiSelectReadOnly.propTypes = {
  value: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default MultiSelectReadOnly;
