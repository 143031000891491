import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTE } from '../../../constants/general';
import { checkLogin } from '../../../modules/share/actions';
import SharedLogin from './SharedLogin';
import SharedLoggedApp from './SharedLoggedApp';

class SharedApp extends Component {
    // state = {
    //     setting: {}
    // };

    componentDidMount() {
        this.init().then();
    }

    init = async () => {
        const { sharingId, checkLoginShareId } = this.props;
        await checkLoginShareId(sharingId);
    };

    render() {
        const { isLoggedIn } = this.props;

        return (
            <main>
                {isLoggedIn
                    ? (
                        <Route path={ROUTE.share.id()} component={SharedLoggedApp} />
                    )
                    : (
                        <Route path={ROUTE.share.id()} component={SharedLogin} />
                    )
                }
            </main>
        );
    }
}

SharedApp.propTypes = {
    sharingId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    checkLoginShareId: PropTypes.func,
};

const mapStateToProps = ({ share: { isLoggedIn } }, ownProps) => ({
    sharingId: ownProps.match.params.sharingId,
    isLoggedIn
});

const mapDispatchToProps = dispatch => ({
    checkLoginShareId: sharingId => dispatch(checkLogin(sharingId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedApp);
