import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { request, synchronizer } from '../../constants/globals';
import { copyFile } from './fileSystem';
import { removeFileFromFolders } from './files';
import { fsClose, fsEnsureDir, fsOpen, fsRemove, fsWrite, TEMPORARY_FILE_EXTENSION } from './fse';

const actionDownloadFile = async (getUrlFunc, destination, syncEvents) => {
    return new Promise(async (resolve, reject) => {
        try {
            const urlResponse = await getUrlFunc();
            if (!urlResponse || !urlResponse.url) {
                let errorText = urlResponse;
                try {
                    errorText = JSON.stringify(errorText);
                } catch (e) {
                    // nothing
                }
                reject(new Error(`Error while getting file url: ${errorText}`));
            }

            const tempDestination = `${destination}${TEMPORARY_FILE_EXTENSION}`;
            const fnRemoveFiles = async () => {
                await fsRemove(destination); // Delete file before downloading
                await fsRemove(tempDestination); // Delete temp file before downloading
            };

            await fsEnsureDir(destination.match(/^.*[\\|/]/)[0]); // Need to create folder for file before
            await fnRemoveFiles();

            const fd = await fsOpen(tempDestination, 'w');
            const req = request({
                method: 'GET',
                uri: urlResponse.url
            });

            req
                .on('response', (response) => {
                    // Change the total bytes value to get progress later.
                    // console.log('response', response);
                    // console.log('response.headers', response.headers);

                    // xml files goes without 'content-length' :-(
                    const fileSize = parseInt(response.headers['content-length'] || '0', 10);
                    // if (!fileSize) {
                    //     throw new Error(`Parse file size error (from "${response.headers['content-length']}")`);
                    // }
                    if (syncEvents && syncEvents.setInit) {
                        syncEvents.setInit(fileSize);
                    }
                })
                .on('error', async (e) => {
                    await fnRemoveFiles();
                    if (syncEvents && syncEvents.setComplete) {
                        syncEvents.setComplete(e.message);
                    }
                    console.error(e);
                    reject(e);
                })
                .on('data', (chunk) => {
                    // TODO: It is unsafe to use fs.write() multiple times on the same file without waiting for the
                    //  callback. For this scenario, fs.createWriteStream() is recommended.
                    //  But for large files we should use fs.write() instead fs.createWriteStream()
                    //  (https://stackoverflow.com/a/6210821)
                    //  Maybe we need to crete queue for writing file
                    fsWrite(fd, chunk, 0, chunk.length);
                    // Update the received bytes
                    if (syncEvents && syncEvents.addProgress) {
                        syncEvents.addProgress(chunk.length);
                    }
                })
                .on('abort', async () => {
                    console.error(`Download to ${destination} aborted by user`);
                    await fsClose(fd);
                    await fnRemoveFiles();
                    if (syncEvents && syncEvents.setComplete) {
                        syncEvents.setComplete('Aborted by user');
                    }
                    reject(new Error('Aborted'));
                })
                .on('end', async () => {
                    await fsClose(fd);
                    await copyFile(tempDestination, destination, true);
                    if (syncEvents && syncEvents.setComplete) {
                        syncEvents.setComplete();
                    }
                    resolve();
                });
        } catch (e) {
            if (syncEvents && syncEvents.setComplete) {
                syncEvents.setComplete(e.message);
            }
            reject(e);
        }
    });
};

export const runRemoveFile = (file, project) => async (dispatch) => {
    await synchronizer.addItem({
        id: file._id,
        obj: file,
        title: file.title,
        actions: [{
            fn: async syncEvents => dispatch(removeFileFromFolders([file._id], project)),
            title: 'Removing ...',
            type: 'remove',
            ignorePreviousErrors: true
        }]
    });
};

export const runDownloadFile = async (file, getUrlFunc, destination) => {
    await synchronizer.addItem({
        id: file._id,
        obj: file,
        title: file.title,
        actions: [{
            fn: async syncEvents => actionDownloadFile(getUrlFunc, destination, syncEvents),
            title: 'Downloading ...',
            icon: faDownload,
            type: 'download',
            hasSpeed: true,
        }]
    });

    // dispatch(runRemoveFile(file, await readDoc(file.projectId, DB_TYPES.user)));
};
