import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconDelete } from '../../resources/svg/IconDelete';
import { removeProjectFolder } from '../../utils/desktop/desktop';
import { readUserDoc } from '../../handlers/userDB';
import { isWeb } from '../../constants/modules';
import { ButtonNew } from '../ButtonNew';

class ActionDeleteProjectFilesFromDrive extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        disabled: PropTypes.bool,
        projectId: PropTypes.string.isRequired,
        readProject: PropTypes.func,
        removeProjectFromDrive: PropTypes.func,
    };

    handleRemoveFiles = async () => {
        const { projectId, readProject, removeProjectFromDrive } = this.props;

        const answer = window.confirm('All files for this project will be deleted from the disk.\nContinue?');

        if (answer) {
            const project = await readProject(projectId);
            const errors = await removeProjectFromDrive(project);

            if (errors.length >= 0) {
                alert(errors.join('\n'));
            } else {
                alert('Project removed from drive');
            }
        }
    };

    render() {
        if (isWeb) {
            return '';
        }

        const { disabled } = this.props;

        return (
            <ButtonNew
                size="tiny"
                color="transparent"
                disabled={disabled || false}
                onClick={this.handleRemoveFiles}
                title="remove files from drive"
            >
              <IconDelete />
            </ButtonNew>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => (isWeb ? {} : {
    readProject: projectId => dispatch(readUserDoc(projectId)),
    removeProjectFromDrive: project => dispatch(removeProjectFolder(project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionDeleteProjectFilesFromDrive);
