import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reSyncProject } from '../../../utils/desktop/desktop';
import Button from '../../Button/Button';
import icon from './icon_resync_white.svg';
import styles from './ActionProjectReSync.module.css';
import { isWeb } from '../../../constants/modules';

class ActionProjectReSync extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        reSync: PropTypes.func,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
    };

    handleReSyncProject = async () => {
        const { projectId, onChange, reSync } = this.props;

        await reSync(projectId);

        if (onChange) {
            await onChange();
        }
    };

    render() {
        if (isWeb) {
            return '';
        }

        const { disabled } = this.props;

        return (
            <Button
                small
                disabled={disabled || false}
                onClick={this.handleReSyncProject}
            >
                <img height="15px" className={styles.icon} src={icon} alt="ReSync" />
                Sync
            </Button>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    reSync: projectId => dispatch(reSyncProject(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionProjectReSync);
