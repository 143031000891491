import React from 'react';
import { connect } from 'react-redux';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                {/* <Uploader/> */}
            </footer>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Footer);
