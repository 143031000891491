import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { readUserDoc } from '../../handlers/userDB';
import styles from './ActionEpisodeUploadToYoutube.module.css';
import { checkPublishMedia } from '../../utils/permissions';
import { uploadEpisode } from '../../utils/youtube';
import Button from '../Button/Button';
import IconFileUploadToYoutube from '../StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';

class ActionEpisodeUploadToYoutube extends Component {
    static defaultProps = {
        disabled: false,
    };

    state = { enabled: true };

    static propTypes = {
        youtubeId: PropTypes.string,
        episodeId: PropTypes.string,
        seriesId: PropTypes.string,
        canPublishMedia: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        upload: PropTypes.func,
        readSeries: PropTypes.func,
    };

    onChange = async () => {
        const { onChange } = this.props;
        if (onChange) {
            await onChange();
        }
    };

    handleUpload = async () => {
        try {
            this.setState({ enabled: false });
            const { upload, seriesId, readSeries, episodeId } = this.props;

            const series = await readSeries(seriesId);
            const channelId = series.youtube && series.youtube.channelId;

            upload(channelId, episodeId, this.onChange);
        } finally {
            this.setState({ enabled: true });
        }
    };

    render() {
        const { canPublishMedia } = this.props;

        if (!canPublishMedia) {
            return '';
        }

        const { disabled, youtubeId } = this.props;
        const { enabled } = this.state;

        return !youtubeId
            ? (
                <Button
                    tiny
                    disabled={disabled || !enabled}
                    onClick={!youtubeId && this.handleUpload}
                    color={Button.COLOR.NONE}
                >
                    <IconFileUploadToYoutube youtubeId={youtubeId} />
                </Button>
            )
            : (
                <div className={styles.withIdContainer}>
                    <IconFileUploadToYoutube youtubeId={youtubeId} />
                </div>
            );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    readSeries: id => dispatch(readUserDoc(id)),
    upload: async (channelId, episodeId, callback) => dispatch(uploadEpisode(channelId, episodeId, callback)),
    canPublishMedia: dispatch(checkPublishMedia()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionEpisodeUploadToYoutube);
