import { SHARE_ACTIONS, webShareRefreshToken } from '../../modules/share/actions';
import { actionSetTokenRefreshing } from '../../modules/user/tokenActions';
import { webRefreshToken } from '../../new/tokenUtils';
import { getSharingWeb, getUserWeb } from '../localStorageUtils';
import { isSharing } from '../webSettings';

export const refreshTokenState = () => (dispatch) => {
    if (isSharing()) {
        dispatch(SHARE_ACTIONS.token.refreshing());
    } else {
        dispatch(actionSetTokenRefreshing());
    }
};

export const refreshToken = () => async (dispatch) => {
    if (isSharing()) {
        await dispatch(webShareRefreshToken());

        const web = getSharingWeb();
        if (web.refreshing === true) {
            throw new Error('Unable to refresh share token. Please login again');
        }
    } else {
        await dispatch(webRefreshToken());

        const web = getUserWeb();
        if (web && web.refreshing === true) {
            throw new Error('Unable to refresh token. Please login again');
        }
    }
};
