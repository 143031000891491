import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PROJECT_SCHEMA } from '../../../constants/general';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import styles from './ActionEditFile.module.css';

class ActionEditFile extends Component {
    static propTypes = {
        doc: PropTypes.shape({
            _id: PropTypes.string,
            note: PropTypes.string,
        }),
        action: PropTypes.func,
        callback: PropTypes.func,
    };

    componentDidMount() {
        this._mounted = true;
    }
    componentWillUnmount() {
        this._mounted = false;
    }

    handleSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const { doc, action, callback } = this.props;
            if (action) {
                await action({ _id: doc._id, ...values });
                if (callback) {
                    callback();
                }
            }
        } finally {
            setSubmitting(false);
        }
    };

    render() {
        const { doc } = this.props;
        return (
          <Formik
            initialValues={doc}
            validationSchema={PROJECT_SCHEMA}
            enableReinitialize
            onSubmit={this.handleSave}
            render={({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    name="description"
                    component={Input}
                    type={Input.TYPE.TEXTAREA}
                    label="Notes:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    spellCheck
                    className={styles.description}
                  />
                </div>
                <div className={styles.buttonSaveContainer}>
                  <Button small type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          />
        );
    }
}

export default ActionEditFile;
