import { DOC_TYPE_PROJECT, DOCUMENT_DESCRIPTIONS } from '../../constants/docs';
import { getLocalSettings } from '../localStorageUtils';
import { getUserId } from '../webSettings';
import { findFolderWithId, findFolderWithIdSync, getIdPart } from './fileSystem';
import { fsExists, fsExistsSync } from './fse';
import { pathDirname } from './path';

export const findUserDataFolder = async (rootFolder, userId) => {
    // Handle all variants of user folder search (with old folder structure)

    if (rootFolder && (await fsExists(rootFolder))) {
        if (rootFolder.includes(getIdPart(userId))) {
            // C:\Projects\test 007's Projects_{userId} <---
            console.log('Found user folder: step 1', rootFolder);
            return rootFolder;
        }

        const userFolders = await findFolderWithId(rootFolder, userId);
        if (userFolders.length) {
            // C:\Projects\Projects\
            //      - {userId}  <---
            console.log('Find user folder: step 2', userFolders[0]);
            return userFolders[0];
        }

        const projectFolders = await findFolderWithId(
          rootFolder,
          `${DOCUMENT_DESCRIPTIONS[DOC_TYPE_PROJECT].prefix}${userId}.*`,
          true
        );
        if (projectFolders.length) {
            // C:\Projects\AnyRootFolderName
            //      - any user sub folder <--- returned
            //          - {project name}_{projectId}  <--- founded this project folder
            console.log('Find user folder: step 3', pathDirname(projectFolders[0]));
            return pathDirname(projectFolders[0]);
        }
    }

    return null;
};
// TODO: replace it to async function
export const findUserDataFolderSync = (rootFolder, userId) => {
    // Handle all variants of user folder search (with old folder structure)

    if (rootFolder && fsExistsSync(rootFolder)) {
        if (rootFolder.includes(getIdPart(userId))) {
            // C:\Projects\test 007's Projects_{userId} <---
            console.log('Found user folder: step 1', rootFolder);
            return rootFolder;
        }

        const userFolders = findFolderWithIdSync(rootFolder, userId);
        if (userFolders.length) {
            // C:\Projects\Projects\
            //      - {userId}  <---
            console.log('Find user folder: step 2', userFolders[0]);
            return userFolders[0];
        }

        const projectFolders = findFolderWithIdSync(
          rootFolder,
          `${DOCUMENT_DESCRIPTIONS[DOC_TYPE_PROJECT].prefix}${userId}.*`,
          true
        );
        if (projectFolders.length) {
            // C:\Projects\AnyRootFolderName
            //      - any user sub folder <--- returned
            //          - {project name}_{projectId}  <--- founded this project folder
            console.log('Find user folder: step 3', pathDirname(projectFolders[0]));
            return pathDirname(projectFolders[0]);
        }
    }

    return null;
};

/** Find and Return root path to projects folders */
export const getProjectsRootPath = () => async (dispatch, getState) => {
    const userId = getUserId();

    const settings = getLocalSettings(userId);

    return settings && settings.projectPath
        ? settings.projectPath
        : await findUserDataFolder(settings.projectPath, userId);
};
// TODO: replace it to async function
export const getProjectsRootPathSync = () => (dispatch, getState) => {
    const userId = getUserId();

    const settings = getLocalSettings(userId);

    return settings && settings.projectPath
        ? settings.projectPath
        : findUserDataFolderSync(settings.projectPath, userId);
};
