import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS } from '../constants/general';
import { api, emitUserError } from '../constants/globals';
import { TUser } from './types-api';


type TUsersManagementProps = {
  autoRead?: boolean
  autoReadStats?: boolean
}
type TUsersManagementResponse = {
  users: TUser[]
  readUsers: () => Promise<void>
  readUser: (userId: string) => Promise<TUser|undefined>
}


export const useUsersManagement = (props?: TUsersManagementProps): TUsersManagementResponse => {
  const { autoRead = true } = (props ?? {});

  const [users, setUsers] = useState<TUser[]>([]);


  const readUsers = useCallback(async () => {
    try {
      // {users: [{ user_id: "05y25t", login: "test", name: "", email: "e@ma.il" }] }
      const response = (await api.apiGet(ENDPOINTS.getAllUsersDetails)) as ({ users: TUser[] } | undefined);
      if (response?.users) {
        setUsers(response?.users.sort((val1, val2) => (val1.user_id < val2.user_id ? -1 : 1)));
      }
    } catch (e) {
      emitUserError(`Get users error\n${(e as Error).message}`)
    }
  }, [])

  const readUser = useCallback(async (userId: string) => {
    try {
      const response = (await api.apiGet(ENDPOINTS.user.getUserProperties, { user_id: userId })) as (TUser | undefined);
      if (response?.roles) {
        setUsers(s => s.map(u => {
          if (u.user_id === userId) {
            return {...u, ...response};
          } else {
            return u;
          }
        }))
        return response;
      }
    } catch (e) {
      emitUserError(`Get user '${userId}' error\n${(e as Error).message}`)
    }
  }, [])


  useEffect(() => {
    if (autoRead && users.length === 0) {
      void readUsers();
    }
  }, [autoRead, users.length, readUsers])


  return {
    users,
    readUsers,
    readUser,
  };
}
