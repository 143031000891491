import { LIST_OPTIONS } from '../../../constants/pouchDB';
import { findUserDocs, writeUserDocs } from '../../../handlers/userDB';
import { apiShareGetSettings } from '../../../modules/share/api';
import { asyncForEach } from '../../common';
import { getShareProperty, updateSharesDoc } from '../../share';

export const setProjectSyncProps = () => async (dispatch) => {
    const docs = await dispatch(findUserDocs({ ...LIST_OPTIONS.projects, limit: null }));
    // Set new sync options
    await dispatch(writeUserDocs(
        docs.map(doc => ({
            ...doc,
            storeOnLocalDrive: true, // doc.syncToDesktop,
            storeInCloud: true
        }))
    ));
};

export const setProjectShareProps = () => async (dispatch) => {
    const projects = await dispatch(findUserDocs(LIST_OPTIONS.projects));

    await asyncForEach(projects.filter(pr => !pr.sharing), async (pr) => {
        try {
            const response = await apiShareGetSettings(pr._id);
            if (response && response.id) {
                dispatch(updateSharesDoc(pr._id, response.type, response.id));
            }
        } catch (e) {
            // nothing
        }
    });
};

export const setConvertSharePropsToArray = () => async (dispatch) => {
    const projects = await dispatch(findUserDocs(LIST_OPTIONS.projects));

    const files = await dispatch(findUserDocs(LIST_OPTIONS.files));

    const updated = [...projects, ...files]
        .filter(doc => doc.sharing && doc.sharing.id)
        .map(doc => ({
            ...doc,
            sharing: getShareProperty(doc.sharing),
        }));

    if (updated.length) {
        await dispatch(writeUserDocs(updated));
    }
};

export const setFileNames = () => async (dispatch) => {
    const files = (await dispatch(findUserDocs(LIST_OPTIONS.files))).filter(doc => !doc.filename);

    if (files.length) {
        const fileMap = files.reduce((acc, doc) => ({ ...acc, [doc._id]: doc }), {});
        let fileIds = files.map(doc => doc._id);

        const episodes = (await dispatch(findUserDocs(LIST_OPTIONS.episodes)))
            .filter(ep => fileIds.includes(ep.videoId))
            .filter(ep => !!ep.title);

        let updates = [];
        if (episodes.length) {
            updates = [
                ...updates,
                ...episodes.map(ep => ({
                    ...fileMap[ep.videoId],
                    filename: ep.title,
                })),
            ];
            fileIds = fileIds.filter(doc => !Object.keys(updates).includes(doc._id));
        }

        updates = [
            ...updates,
            ...fileIds.map(id => ({
                ...fileMap[id],
                filename: fileMap[id].title,
            })),
        ];

        if (updates.length) {
            await dispatch(writeUserDocs(updates));
            console.log(`Added "filename" to ${updates.length} files`);
        }
    }
};
