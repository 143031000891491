import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';
import { submitNewProjectPath } from '../../../modules/user/actions';
import { getIdPart } from '../../../utils/desktop/fileSystem';
import { pathDirname } from '../../../utils/desktop/path';
import {
  getLocalSettings, getLocalUserHelpScreens,
  KEY_LOCAL_USER_SETTINGS_TEXT,
  setLocalUserHelpScreens
} from '../../../utils/localStorageUtils';
import { getUserId } from '../../../utils/webSettings';
import {PageContainer } from '../../common/PageContainer';
import { HelpLocalSettings } from './HelpLocalSettings';
import styles from './LocalSettings.module.css';
import { getElectron, getElectronProp } from '../../../utils/system';
import { isWeb } from '../../../constants/modules';
import { systemPaths } from '../../../constants/system';

const electron = getElectron();
const dialog = isWeb ? null : getElectronProp('dialog');

const initialState = {
  local: { projectPath: '' },
  info: '',
  error: '',
};

class LocalSettings extends Component {
  static propTypes = {
    showTitle: PropTypes.bool,
    local: PropTypes.shape({
      projectPath: PropTypes.string,
    }),
  };

  static defaultProps = {
    showTitle: true,
  };

  state = { ...initialState };

  componentDidMount() {
    this.init();
  }

  getProjectPath = () => {
    const userId = getUserId();
    const currentSettings = getLocalSettings(userId);
    if (currentSettings && currentSettings.projectPath) {
      if (currentSettings.projectPath.includes(getIdPart(userId))) {
        return pathDirname(currentSettings.projectPath);
      }
      return currentSettings.projectPath;
    }

    return '';
  };


  init = async () => {
    if (isWeb) {
      return;
    }

    const projectPath = this.getProjectPath();

    this.setState({ local: { projectPath } });

    if (!projectPath) {
      this.handleSetDefaultFolder();
    }
  };

  handleSetDefaultFolder = () => {
    const { local } = this.state;

    this.setState({
      local: {
        ...local,
        projectPath: systemPaths.mainPath,
      },
    });
  };

  doSetNewFolder = (paths) => {
    const { local } = this.state;

    if (paths && paths.length) {
      this.setState({
        local: {
          ...local,
          projectPath: paths[0],
        },
      });
    }
  };

  handleChangeFolder = async () => {
    const { local } = this.state;

    const options = {
      defaultPath: local && local.projectPath,
      properties: ['openDirectory'], // 'createDirectory', 'promptToCreate'],
    };

    const { filePaths } = await dialog.showOpenDialog(electron.remote.getCurrentWindow(), options);
    this.doSetNewFolder(filePaths);
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { submit } = this.props;
    const { local } = this.state;
    const userId = getUserId();

    this.setState({ info: 'Checking folders and moving old files to it ...' });
    try {
      // const loginResult =
      setSubmitting(true);
      await submit(local.projectPath, (info) => {this.setState({ info });});
      setLocalUserHelpScreens(userId, KEY_LOCAL_USER_SETTINGS_TEXT, true);
      window.location.reload();
    } catch (e) {
      this.setState({ error: e.message });
    } finally {
      // process form submission here
      // done submitting, set submitting to false
      setSubmitting(false);
      // this.init();
    }
  };

  render() {
    if (isWeb) {
      return '';
    }

    const { showTitle } = this.props;
    const { local, info, error } = this.state;
    const userId = getUserId();
    const showHelp = !getLocalUserHelpScreens(userId)[KEY_LOCAL_USER_SETTINGS_TEXT];

    if (!local) {
      return 'Loading ...';
    }
    return (
      <PageContainer title={(showTitle && !showHelp) ? "Local settings" : undefined} titleLevel={2}>
        <Formik
          initialValues={local}
          validationSchema={object().shape({
            projectPath: string().required().min(1),
          })}
          enableReinitialize
          onSubmit={this.handleSubmit}
          render={({ isSubmitting }) => (
            <Form className={styles.localSettingsContainer}>
              {/*{showTitle && !showHelp && <h1>Local settings</h1>}*/}
              {showTitle && showHelp && (
                <div className={styles.helpContainer}>
                  <HelpLocalSettings />
                </div>
              )}
              <div className={styles.editContainer}>
                <div>
                  <Field
                    name="projectPath"
                    component={Input}
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    // label="Data path:"
                    required
                    readOnly
                  />
                </div>
                <div>
                  <Button
                    small
                    onClick={this.handleChangeFolder}
                    className={styles.folderButton}
                  >
                    Change
                  </Button>
                  <Button
                    small
                    onClick={this.handleSetDefaultFolder}
                    className={styles.folderButton}
                  >
                    Default
                  </Button>
                </div>
              </div>
              {info && (
                <div>
                  {info}
                </div>
              )}
              {error && (
                <div style={{ color: 'red' }}>
                  {error}
                </div>
              )}
              <FormButtons>
                <Button
                  small
                  type="submit"
                  disabled={isSubmitting}
                  color={Button.COLOR.PRIMARY}
                >
                  Save
                </Button>
              </FormButtons>
            </Form>
          )}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ user: { local } }) => ({
  local,
});

const mapDispatchToProps = dispatch => ({
  submit: (newDataPath, setInfoCallback) => dispatch(submitNewProjectPath(newDataPath, setInfoCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalSettings);
