import configs from '../constants/configs';
import { ENDPOINTS } from '../constants/general';
import { api } from '../constants/globals';
import { errorResponse } from './api/common';
import { getUserWeb } from './localStorageUtils';
import { isWeb } from '../constants/modules';

const API_PARAM_REFRESH_TOKEN = 'refresh-token';


const convertTokenResponse = response => ({
    token: response.token,
    refreshToken: response[API_PARAM_REFRESH_TOKEN],
});
const checkApiTokenResponse = response => !!(response && response.token && response[API_PARAM_REFRESH_TOKEN]);


export const apiTokenAuth = async ({ login, password }) => {
    const response = await api.apiPost(ENDPOINTS.user.tokenAuth, {
        login,
        password,
        ...(!isWeb ? { desktop_secret_code: configs.desktopSecretCode } : {}),
    });

    if (checkApiTokenResponse(response)) {
        return convertTokenResponse(response);
    }

    throw errorResponse(response);
};
export const apiTokenRefresh = async () => {
    const response = await api.apiPost(ENDPOINTS.user.tokenRefresh, {
        refresh_token: (getUserWeb() || {}).refreshToken
    });

    if (checkApiTokenResponse(response)) {
        return convertTokenResponse(response);
    }

    throw errorResponse(response);
};

export const apiUserGetUserProperties = async (userIdParam) => {
    const response = await api.apiGet(ENDPOINTS.user.getUserProperties, { user_id: userIdParam });

    if (response && typeof response.name === 'string' && response.roles) {
      return response;
    }

    throw errorResponse(response);
};

export const apiRolesGetRoles = async () => {
    const response = await api.apiGet(ENDPOINTS.roles.getAllRoles);

    if (response && response.roles) {
        return response.roles;
    }

    throw errorResponse(response);
};
