import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  DOC_TYPE_FILE_AUDIO,
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO,
  DOC_TYPE_FILE_MEDIA,
  DOC_TYPE_FILE_MISC,
  DOC_TYPE_FILE_PROJECT,
  DOCUMENT_DESCRIPTIONS
} from '../../../constants/docs';
import { isFinishFile } from '../../../constants/pouchDB';
// import { getSelectedFiles } from '../../../utils/files/files';
import ActionFileTypeMenu from '../../ActionFileTypeMenu/ActionFileTypeMenu';
// import Button from '../../Button/Button';
import { FileInputButton } from '../../FileInputButton';
import Input from '../../Input/Input';
import ProjectListItemFile from '../ProjectListItemFile/ProjectListItemFile';
import { IconFileTypeAudio } from './icons/IconFileTypeAudio';
import { IconFileTypeMedia } from './icons/IconFileTypeMedia';
import { IconFileTypeMisc } from './icons/IconFileTypeMisc';
import { IconFileTypeProject } from './icons/IconFileTypeProject';
import styles from './ProjectFileTypeContainer.module.css';

const DOC_TYPES_SETTINGS = {
  [DOC_TYPE_FILE_MEDIA]: {
    icon: <IconFileTypeMedia />,
    addButtonLabel: 'Add',
  },
  [DOC_TYPE_FILE_MISC]: {
    icon: <IconFileTypeMisc />,
    addButtonLabel: 'Add',
  },
  [DOC_TYPE_FILE_AUDIO]: {
    icon: <IconFileTypeAudio />,
    addButtonLabel: 'Add',
  },
  [DOC_TYPE_FILE_PROJECT]: {
    icon: <IconFileTypeProject />,
    addButtonLabel: 'Add',
  },
  [DOC_TYPE_FILE_FINISH_VIDEO]: {
    addButtonLabel: 'Add Video',
  },
  [DOC_TYPE_FILE_FINISH_AUDIO]: {
    addButtonLabel: 'Add Audio',
  },
};


class ProjectFileTypeContainer extends Component {
    static propTypes = {
        sharingId: PropTypes.string,
        projectId: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        files: PropTypes.array,
        projectProps: PropTypes.shape({
            storeInCloud: PropTypes.bool,
        }),
        addFiles: PropTypes.func,
        removeFile: PropTypes.func,
        saveFile: PropTypes.func,
        callback: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.inputOpenFileRef = React.createRef();
    }

    state = { checkList: [] };

    // handleAddFilesInputChange = async (e) => {
    //     const openFiles = [...e.target.files];
    //     // console.log('openFiles', openFiles);
    //     e.target.value = '';
    //     if (openFiles && openFiles.length) {
    //         const { addFiles, fileType, callback } = this.props;
    //
    //         await addFiles(await getSelectedFiles(openFiles, fileType));
    //
    //         if (callback) { // TODO: make global refresh mechanism
    //           setTimeout(callback, 1000)
    //         }
    //     }
    // };

    isItemChecked = (id) => {
        const { checkList } = this.state;
        return checkList.includes(id);
    };

    handleItemCheckAction = (id) => {
        const { checkList } = this.state;
        if (checkList.includes(id)) {
            this.setState({ checkList: checkList.filter(val => val !== id), });
        } else {
            this.setState({ checkList: [...checkList, id], });
        }
    };

    handleItemCheckAllAction = () => {
        const { checkList } = this.state;
        const { files } = this.props;

        if (checkList.length !== files.length) {
            this.setState({ checkList: files.map(file => file._id), });
        } else if (files.length) {
            this.setState({ checkList: [], });
        }
    };

    render() {
        const {
            projectId,
            projectProps,
            fileType,
            files,
            removeFile,
            saveFile,
            callback,
            addFiles,
            sharingId
        } = this.props;
        const { checkList } = this.state;

        const header = isFinishFile(fileType)
            ? <h1>{DOCUMENT_DESCRIPTIONS[fileType].title}</h1>
            : (
                <>
                    <div className={styles.mediaGroupIcon}>
                      {DOC_TYPES_SETTINGS[fileType].icon}
                    </div>
                    <div className={styles.mediaGroupTitle}>
                        {`${DOCUMENT_DESCRIPTIONS[fileType].title}:`}
                    </div>
                </>
            );

        const footer = (
            <>
                {addFiles && (
                <>
                  <FileInputButton
                    fileType={fileType}
                    addFiles={addFiles}
                    className={styles.inlineBlock}
                    label={DOC_TYPES_SETTINGS[fileType].addButtonLabel}
                  />
                    {/*<Button*/}
                    {/*    small*/}
                    {/*    onClick={() => this.inputOpenFileRef.current.click()}*/}
                    {/*    className={styles.inlineBlock}*/}
                    {/*>*/}
                    {/*    {DOC_TYPES_SETTINGS[fileType].addButtonLabel}*/}
                    {/*</Button>*/}
                    {/*<input*/}
                    {/*    ref={this.inputOpenFileRef}*/}
                    {/*    type="file"*/}
                    {/*    multiple="multiple"*/}
                    {/*    style={{ display: 'none' }}*/}
                    {/*    onChange={this.handleAddFilesInputChange}*/}
                    {/*/>*/}
                </>
                )}
                <ActionFileTypeMenu
                    fileType={fileType}
                    project={{
                        _id: projectId,
                        storeInCloud: projectProps.storeInCloud,
                    }}
                    removeFileAction={removeFile}
                    removeFileCallback={callback}
                    checkedFiles={files.filter(f => this.isItemChecked(f._id))}
                    checkDisabled={!files.length}
                    checkedAll={checkList.length === files.length}
                    checkListsCheckAllAction={this.handleItemCheckAllAction}
                />
            </>
        );

        return (
            <div className={styles.container}>
                <div className={cn(styles.header, isFinishFile(fileType) && styles.headerFull)}>
                    {header}
                </div>
                <div className={cn(styles.content, isFinishFile(fileType) && styles.leftPad0)}>
                    {files.map(fileDoc => (
                        <div key={fileDoc._id} className={cn(styles.file, isFinishFile(fileType) && styles.withHover)}>
                            <ProjectListItemFile
                                sharingId={sharingId}
                                file={fileDoc}
                                removeFile={removeFile}
                                saveFile={saveFile}
                                callback={callback}
                                projectProps={projectProps}
                            />
                            <div className={styles.fileActions}>
                                <Input
                                    type={Input.TYPE.CHECKBOX}
                                    form={{ touched: null, errors: null }}
                                    field={{ name: `isChecked_${fileDoc._id}`, value: this.isItemChecked(fileDoc._id) }}
                                    rootClassName={styles.checkInput}
                                    noValidate
                                    onChange={() => this.handleItemCheckAction(fileDoc._id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cn(styles.footer, isFinishFile(fileType) && styles.footerRight)}>
                    {footer}
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFileTypeContainer);
