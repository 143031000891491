import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeProjectFromCloud } from '../../modules/projects/actions';
import { IconDelete } from '../../resources/svg/IconDelete';
import { isWeb } from '../../constants/modules';
import { ButtonNew } from '../ButtonNew';

class ActionDeleteProjectFilesFromCloud extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        disabled: PropTypes.bool,
        projectId: PropTypes.string.isRequired,
        removeProject: PropTypes.func,
        onChange: PropTypes.func,
    };

    handleRemoveFiles = async () => {
        const { projectId, onChange, removeProject } = this.props;

        const answer = window.confirm('All files for this project will be deleted from the cloud.\nContinue?');

        if (answer) {
            const errors = await removeProject(projectId);

            if (errors.length > 0) {
                alert(errors.join('\n'));
                return;
            }

            alert('Project removed from cloud');

            if (onChange) {
                await onChange();
            }
        }
    };

    render() {
        if (isWeb) {
            return '';
        }

        const { disabled } = this.props;

        return (
            <ButtonNew
              size="tiny"
              color="transparent"
              disabled={disabled || false}
              onClick={this.handleRemoveFiles}
              title="remove files from cloud"
            >
              <IconDelete />
            </ButtonNew>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    removeProject: projectId => dispatch(removeProjectFromCloud(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionDeleteProjectFilesFromCloud);
