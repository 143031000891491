import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListContainer from '../ListContainer/ListContainer';
import { CONTENT_TYPE } from '../../constants/general';
import { findSeriesByFileId } from '../../modules/series/actions';
import SeriesSearch from './SeriesSearch';

const initialState = {
  series: [],
  ready: false,
};

class FileSeriesList extends Component {
  static propTypes = {
    fileId: PropTypes.string.isRequired,
    findSeries: PropTypes.func,
  };

  state = initialState;

  componentDidMount() {
    this._mounted = true;
    this.readList();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  readList = async () => {
    const { fileId, findSeries } = this.props;
    if (this._mounted) {
      this.setState({
        series: await findSeries(fileId) || [],
        ready: true,
      });
    }
  };

  render() {
    const { fileId } = this.props;
    const { series, ready } = this.state;

    return (
      <>
        <SeriesSearch
          fileId={fileId}
          addSeriesCallback={this.readList}
          excludeIds={series.map(doc => doc._id)}
        />
        <ListContainer title="" ready={ready} items={series} type={CONTENT_TYPE.SERIES} />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  findSeries: fileId => dispatch(findSeriesByFileId(fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileSeriesList);
