import React from 'react';
import BarItem from '../../Component/BarItem/BarItem';
import image from './image_HitCreatingProject.svg';

const HitCreatingProject = () => (
    <BarItem
        isLast
        number={4}
        text={(
            <>
                Begin by creating a project
                <br />
                (we recommend a project = an
                <br />
                episode, but you can configure
                <br />
                it however it works for you).
            </>
        )}
        image={image}
    />
);

export default HitCreatingProject;
