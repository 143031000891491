import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './window-container.module.scss';
import Button from '../../Button/Button';

const WindowContainer = ({ // TODO: Remove this component
  width, height,
  contentOnly, children, history, hideCloseButton, closeAction, windowClassName, isModal
}) => (
  <>
    <div
      className={cn(
        styles.background,
        (isModal && styles.backgroundModal),
        (contentOnly && styles.noBackground)
      )}
      onClick={closeAction}
    />
    <div className={styles.containerWrapper} onClick={() => console.log('aa')}>
      <div
        className={cn(styles.windowContainer, (contentOnly && styles.windowNoContainer), windowClassName)}
        style={{
          ...(width ? { width: `${width}px` } : {}),
          ...(height ? { height: `${height}px` } : {})
        }}
        onClick={() => console.log('ss')}
      >
        {!hideCloseButton && (
          <div className={styles.closeButtonContainer}>
            <Button
              tiny
              className={styles.closeButton}
              color={Button.COLOR.NONE}
              icon={faTimes}
              onClick={closeAction || (history ? () => history.goBack() : null)}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  </>
);

WindowContainer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  hideCloseButton: PropTypes.bool,
  contentOnly: PropTypes.bool,
  isModal: PropTypes.bool,
  history: PropTypes.shape({ goBack: PropTypes.func }),
  closeAction: PropTypes.func,
  windowClassName: PropTypes.string,
};

WindowContainer.defaultProps = {
  isModal: true,
  contentOnly: false,
};

export default WindowContainer;
