import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FileSeriesList from '../../../../../components/FileSeriesList/FileSeriesList';
import NavMenu from '../../../../../components/NavMenu/NavMenu';
import YoutubeAnalytics from '../../../../../components/YoutubeAnalytics/YoutubeAnalytics';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../../constants/docs';
import { ROUTE } from '../../../../../constants/general';
import { ANALYTICS_TYPES } from '../../../../../utils/youtube';
import FileMetadata from './FileMetadata/FileMetadata';
import { FileNotes } from './FileNotes';

const FILE_ROUTES = {
  [DOC_TYPE_FILE_FINISH_VIDEO]: ROUTE.videos,
  [DOC_TYPE_FILE_FINISH_AUDIO]: ROUTE.audios,
};

const FilePages = ({ sharingId, file, readFile, saveFile }) => {
    const PageMetadata = () => (
        <FileMetadata
            file={file}
            readFile={readFile}
            saveFile={!sharingId ? saveFile : null}
        />
    );
    const PageNotes = () => <FileNotes docId={file._id} />;

    return (
        <>
            <NavMenu
                items={sharingId
                    ? [
                        {
                            exact: true,
                            title: 'Metadata',
                            to: ROUTE.share.file.metadata(sharingId, file._id)
                        }
                    ]
                    : [
                        {
                            exact: true,
                            title: 'Metadata',
                            to: FILE_ROUTES[file.type].pages.metadata(file._id)
                        },
                        {
                            exact: true,
                            title: 'Notes',
                            to: FILE_ROUTES[file.type].pages.notes(file._id)
                        },
                        // { exact: true, title: 'Project', to: ROUTE.videos.pages.project(file._id) },
                        {
                            exact: true,
                            title: 'Series',
                            to: FILE_ROUTES[file.type].pages.series(file._id)
                        },
                        ...(file.type === DOC_TYPE_FILE_FINISH_VIDEO
                            ? [{
                                exact: true,
                                title: 'Analytics',
                                to: FILE_ROUTES[file.type].pages.analytics(file._id)
                            }]
                            : [])
                    ]
                }
            />
            <Switch>
                <Route path={ROUTE.share.file.metadata()} component={PageMetadata} />
                <Route path={FILE_ROUTES[file.type].pages.metadata()} component={PageMetadata} />
                <Route path={FILE_ROUTES[file.type].pages.notes()} component={PageNotes} />
                {/* <Route */}
                {/*    path={FILE_ROUTES[file.type].pages.project()} */}
                {/*    component={() => <FileProjectPage fileId={file._id} />} */}
                {/* /> */}
                <Route
                    path={FILE_ROUTES[file.type].pages.series()}
                    component={() => <FileSeriesList fileId={file._id} />}
                />
                {file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
                    <Route
                        path={FILE_ROUTES[file.type].pages.analytics()}
                        component={() => (
                            <YoutubeAnalytics
                                analyticType={ANALYTICS_TYPES.video}
                                id={file.youtube && file.youtube.youtubeId}
                            />
                        )}
                    />
                )}
            </Switch>
        </>
    );
};

FilePages.propTypes = {
    file: PropTypes.object.isRequired,
    sharingId: PropTypes.string,
    saveFile: PropTypes.func,
    readFile: PropTypes.func.isRequired,
};

export default FilePages;
