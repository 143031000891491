import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO,
  DOC_TYPE_FILE_MEDIA,
  DOCUMENT_DESCRIPTIONS, TDocFile, TDocProject
} from '../../constants/docs';
import { PROJECT_SCHEMA, ROUTE } from '../../constants/general';
import { emitUserError, emitUserInfo } from '../../constants/globals';
import { isWeb } from '../../constants/modules';
import { DOCUMENT_TYPES_FINISH_FILES, DOCUMENT_TYPES_MEDIA_FILES } from '../../constants/pouchDB';
import { SHARE_TYPES } from '../../constants/share';
import { PageContainer } from '../../Pages/common/PageContainer';
import { RecentContext } from '../../Providers/Recent';
import { UserContext } from '../../Providers/User';
import { TSelectedFile } from '../../utils/files/files';
import { prettyDate } from '../../utils/format';
import ActionDeleteProjectFilesFromCloud
  from '../ActionDeleteProjectFilesFromCloud/ActionDeleteProjectFilesFromCloud';
import ActionDeleteProjectFilesFromDrive
  from '../ActionDeleteProjectFilesFromDrive/ActionDeleteProjectFilesFromDrive';
import ActionProjectReSync from '../Actions/ActionProjectReSync/ActionProjectReSync';
import { ButtonNew } from '../ButtonNew';
import Input from '../Input/Input';
import IconProjectHasFileType from '../Projects/common/IconProjectHasFileType/IconProjectHasFileType';
import ShareSettings from '../ShareSettings/ShareSettings';
import { ShareSettingsButton } from '../ShareSettingsButton';
import Spinner from '../Spinner/Spinner';
import ButtonStoreInCloud from '../StatusIcons/ButtonStoreInCloud/ButtonStoreInCloud';
import ButtonStoreOnLocalDrive from '../StatusIcons/ButtonStoreOnLocalDrive/ButtonStoreOnLocalDrive';
import { WindowContainer } from '../WindowContainer';
import styles from './project-details.module.scss';
import ProjectFileTypeContainer from './ProjectFileTypeContainer/ProjectFileTypeContainer';
import { useHistory, useRouteMatch } from 'react-router-dom';


type TProjectDetailsProps = {
  // projectId: string
  sharingId?: string
  readProject: () => Promise<TDocProject>
  saveProject?: (values: TDocProject) => Promise<void>
  deleteProject?: () => Promise<string[]>
  findFiles: () => Promise<TDocFile[]>
  addFiles?: (files: TSelectedFile[]) => Promise<void>
  removeFile?: (fileIds: string[]) => Promise<string[]>
  saveFile?: (values: TDocFile) => Promise<void>
};


export const ProjectDetails: React.FC<TProjectDetailsProps> = (props) => {
  const { permissions: { canUseCloud } } = useContext(UserContext);
  const history = useHistory();

  const { addRecent } = useContext(RecentContext);
  const rm = useRouteMatch();

  const {
    // projectId,
    sharingId,
    readProject,
    saveProject,
    deleteProject,
    findFiles,
    addFiles,
    removeFile,
    saveFile
  } = props;

  const [project, setProject] = useState<TDocProject>({} as TDocProject)
  const [files, setFiles] = useState<TDocFile[]>([])
  const [editProject, setEditProject] = useState(false)
  const [showShareSettings, setShowShareSettings] = useState(false)

  const handleRead = useCallback(async () => {
    setProject(await readProject());
    setFiles((await findFiles()).sort((a, b) => moment(a.created).isBefore(b.created) ? 1 : -1));
  }, [readProject, findFiles]);

  const handleSubmit = useCallback(async (values: TDocProject) => {
    if (saveProject) {
      try {
        await saveProject(values);
        setProject(await readProject());
        setEditProject(s => !s);
        emitUserInfo('Project updated!')
      } catch (e) {
        emitUserError((e as Error).message)
      }
    }
  }, [saveProject, readProject]);

  const handleDelete = useCallback(async () => {
    if (deleteProject && project._id) {
      let answer = true;
      if (project.storeOnLocalDrive) {
        answer = window.confirm('Project will be removed.\nContinue?');
      }

      if (answer) {
        const errors = await deleteProject();
        if (!errors || errors.length === 0) {
          history.push(ROUTE.projects.root);
        } else {
          emitUserError(`Can not delete project. Reasons:\n\n${errors.reduce((acc, err) => `${acc}\n${err}`)}`)
        }
      }
    }
  }, [project, deleteProject, history]);


  const handleShowShareSettings = useCallback(() => {
    setShowShareSettings(s => !s);
  }, []);

  const toggleEdit = useCallback((resetForm: () => void) => {
    setEditProject(s => {
      if (s && resetForm) {
        resetForm();
      }
      return !s;
    })

    return true;
  }, []);


  useEffect(() => {
    void handleRead();
  }, [handleRead]);

  useEffect(() => {
    if (project?._id) {
      addRecent({
        type: project.type,
        title: project.title,
        to: rm.url
      })
    }
  }, [project, addRecent]);


  return (
    <PageContainer
      title={project.title}
      parentTitle="Projects"
      parentLink={!sharingId ? ROUTE.projects.root : undefined}
    >
      {!project?._id && <Spinner big full />}
      {project?._id && (<>
        <Formik
          initialValues={project}
          validationSchema={PROJECT_SCHEMA}
          enableReinitialize
          onSubmit={handleSubmit}
          render={({ resetForm }) => (
            <Form>
              <div className={styles.editContainer}>
                <div>
                  <div className={styles.editContainer}>
                    <div>
                      <Field
                        component={Input}
                        label="Status:"
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        type={Input.TYPE.LABEL}
                        field={{
                          value: (
                            <>
                              <IconProjectHasFileType
                                project={project}
                                type={DOC_TYPE_FILE_FINISH_VIDEO}
                              />
                              <IconProjectHasFileType
                                project={project}
                                type={DOC_TYPE_FILE_FINISH_AUDIO}
                              />
                            </>
                          ),
                        }}
                        readOnly
                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      />
                      <Field
                        name="_id"
                        component={Input}
                        label="Project ID:"
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        readOnly
                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      />
                      <Field
                        component={Input}
                        label="Created:"
                        labelWidth={Input.LABEL_WIDTH.RIGHT}
                        labelClassName={styles.label}
                        field={{ value: prettyDate(project.created) }}
                        readOnly
                        spaceBottom={Input.SPACE_BOTTOM.SMALL}
                      />
                    </div>
                    <div className={styles.flex1}>
                      <div className={styles.fitContent}>
                        <Field
                          component={Input}
                          label="Store on local drive:"
                          type={Input.TYPE.LABEL}
                          labelWidth={Input.LABEL_WIDTH.RIGHT}
                          labelClassName={styles.label}
                          className={styles.flex}
                          field={{
                            value: (
                              <>
                                <ButtonStoreOnLocalDrive
                                  projectId={project._id}
                                  storeInCloud={project.storeInCloud}
                                  storeOnLocalDrive={project.storeOnLocalDrive}
                                  onChange={handleRead}
                                  disabled={!!sharingId}
                                />
                                {!isWeb && (
                                  <ActionDeleteProjectFilesFromDrive
                                    projectId={project._id}
                                    disabled={!!sharingId || project.storeOnLocalDrive}
                                  />
                                )}
                              </>
                            ),
                          }}
                          readOnly
                          spaceBottom={Input.SPACE_BOTTOM.SMALL}
                        />
                        {canUseCloud && (
                          <Field
                            component={Input}
                            label="Store in cloud:"
                            type={Input.TYPE.LABEL}
                            labelWidth={Input.LABEL_WIDTH.RIGHT}
                            labelClassName={styles.label}
                            className={styles.flex}
                            field={{
                              value: (
                                <>
                                  <ButtonStoreInCloud
                                    projectId={project._id}
                                    storeInCloud={project.storeInCloud}
                                    storeOnLocalDrive={project.storeOnLocalDrive}
                                    onChange={handleRead}
                                    disabled={!!sharingId}
                                  />
                                  {!isWeb && (
                                    <ActionDeleteProjectFilesFromCloud
                                      projectId={project._id}
                                      disabled={!!sharingId || project.storeInCloud}
                                      onChange={handleRead}
                                    />
                                  )}
                                </>
                              ),
                            }}
                            readOnly
                            spaceBottom={Input.SPACE_BOTTOM.SMALL}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Field
                    name="title"
                    component={Input}
                    label="Video Project Title:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly={!editProject}
                    editClick={toggleEdit}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    spellCheck
                  />
                  <Field
                    name="description"
                    component={Input}
                    type={Input.TYPE.TEXTAREA}
                    label="Project Notes:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    readOnly={!editProject}
                    editClick={toggleEdit}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    placeholder="Add notes about your project."
                    spellCheck
                  />
                  <Field
                    name="tags"
                    component={Input}
                    type={Input.TYPE.MULTI_SELECT}
                    multiSelectArray={true}
                    // maxLength={500}
                    label="Tags:"
                    labelWidth={Input.LABEL_WIDTH.RIGHT}
                    labelClassName={styles.label}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    readOnly={!editProject}
                    editClick={toggleEdit}
                    showCopyButton
                    inputProps={{ spellCheck: true }}
                  />
                </div>
                <div className={styles.saveWrapper}>
                  {saveProject && (
                    <div>
                      <ActionProjectReSync
                        projectId={project._id}
                        disabled={!!sharingId}
                        onChange={handleRead}
                      />
                      <ShareSettingsButton
                        disabled={editProject}
                        sharing={project.sharing}
                        // @ts-ignore
                        type={SHARE_TYPES.project}
                        onClick={handleShowShareSettings}
                      />
                      {
                        editProject && (
                          <ButtonNew
                            type="submit"
                            className={styles.toggleEdit}
                          >
                            Save
                          </ButtonNew>
                        )
                      }
                      <ButtonNew
                        color={editProject ? 'secondary' : 'primary'}
                        onClick={() => toggleEdit(resetForm)}
                        className={styles.toggleEdit}
                      >
                        {editProject ? 'Cancel' : 'Edit'}
                      </ButtonNew>
                      {deleteProject && editProject && (
                        <ButtonNew
                          color="warning"
                          onClick={handleDelete}
                          // disabled={files.length > 0}
                          className={styles.toggleEdit}
                        >
                          Delete
                        </ButtonNew>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        />

        <h1>{DOCUMENT_DESCRIPTIONS[DOC_TYPE_FILE_MEDIA].title}</h1>
        {DOCUMENT_TYPES_MEDIA_FILES.map(fileType => (
          <ProjectFileTypeContainer
            key={fileType}
            sharingId={sharingId}
            projectId={project._id}
            projectProps={{
              storeInCloud: project.storeInCloud,
              storeOnLocalDrive: project.storeOnLocalDrive
            }}
            fileType={fileType}
            files={files.filter(file => file.type === fileType)}
            addFiles={addFiles}
            removeFile={removeFile}
            saveFile={saveFile}
            callback={handleRead}
          />
        ))}

        {DOCUMENT_TYPES_FINISH_FILES.map(fileType => (
          <ProjectFileTypeContainer
            key={fileType}
            sharingId={sharingId}
            projectId={project._id}
            projectProps={{
              storeInCloud: project.storeInCloud,
              storeOnLocalDrive: project.storeOnLocalDrive
            }}
            fileType={fileType}
            files={files.filter(file => file.type === fileType)}
            addFiles={addFiles}
            removeFile={removeFile}
            saveFile={saveFile}
            callback={handleRead}
          />
        ))}

        {showShareSettings && !sharingId && (
          <WindowContainer width={500} closeAction={handleShowShareSettings}>
            <ShareSettings
              documentId={project._id}
              type={SHARE_TYPES.project}
              onChange={() => setTimeout(() => void handleRead(), 500)} // TODO: need tp subscribe to update event
            />
          </WindowContainer>
        )}
      </>)}
    </PageContainer>
  );
}
