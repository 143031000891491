import React from 'react';
import greetingsImage from './resources/GreetingsHeader.svg';
import styles from './help-local-settings.module.scss';

export const HelpLocalSettings: React.FC = () => {
  return (
    <>
      <h1>Sweet!</h1>
      <h2 className={styles.subtitles}>Choose where you want files to be saved.</h2>
      <h2 className={styles.subtitles}>
        The default will be saved in your documents folder but you can change it now or
        at any time to a different folder or an external drive.
      </h2>
      <img src={greetingsImage} alt="example" />
    </>
  );
}
