import { findDataFolder } from '../utils/desktop/files';
import { isWeb } from '../constants/modules';

const readWebLocalSettings = () => undefined;

const readDesktopLocalSettings = async userId => (isWeb ? undefined : ({
    projectPath: await findDataFolder(userId)
}));

export const readLocalSettings = async userId => ({
    read: true,
    ...readWebLocalSettings(userId),
    ...(await readDesktopLocalSettings(userId))
});
