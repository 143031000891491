import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONTENT_TYPE } from '../../../constants/general';
import ListContainer from '../../../components/ListContainer/ListContainer';
import { readAudiosList } from '../../../modules/audios/actions';
import AudioListItemDetails from './AudioListItemDetails';

class AudiosList extends Component {
  static propTypes = {
    readList: PropTypes.func.isRequired,
    audios: PropTypes.array.isRequired,
  };

  state = { ready: false };

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { readList } = this.props;
    await readList();
    if (this._mounted) {
      this.setState({ ready: true });
    }
  };

  render() {
    const { audios } = this.props;
    const { ready } = this.state;

    return (
      <>
        <ListContainer
          ready={ready}
          title="Audio"
          items={audios}
          actions={[
            // { label: 'Add Project', link: ROUTE.projects.create() }
          ]}
          details={AudioListItemDetails}
          type={CONTENT_TYPE.AUDIO}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  audios: state.audios.list
});

const mapDispatchToProps = dispatch => ({
  readList: () => dispatch(readAudiosList())
});

export default connect(mapStateToProps, mapDispatchToProps)(AudiosList);
