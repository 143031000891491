import { isWeb } from '../../constants/modules';


const fse = isWeb ? {} : window.require('fs-extra');

export const TEMPORARY_FILE_EXTENSION = '.tiv_temp';
export const TEMPORARY_FILE_EXTENSION_FILTER = new RegExp(`\\${TEMPORARY_FILE_EXTENSION}$`);
export const TEMPORARY_FILE_EXTENSION_FILTER_OLD = /\.meedi_temp/;

export const FS_COPY_OPTIONS = {
    overwrite: false,
    errorOnExist: true,
    preserveTimestamps: true
};


export const fsLstat = async (path, ...args) => fse.lstat(path, ...args);
export const fsLstatSync = (path, ...args) => fse.lstatSync(path, ...args);
export const fsFstatSync = (fd, ...args) => fse.fstatSync(fd, ...args);

export const fsReaddir = async (path, ...args) => fse.readdir(path, ...args);
export const fsReaddirSync = (path, ...args) => fse.readdirSync(path, ...args);

export const fsExists = async fileName => fse.pathExists(fileName);
export const fsExistsSync = path => fse.existsSync(path);

export const fsOpen = async (path, flags = 'r', ...args) => fse.open(path, flags, ...args);
export const fsOpenSync = (path, flags = 'r', ...args) => fse.openSync(path, flags, ...args);

export const fsClose = async (fd, ...args) => fse.close(fd, ...args);
export const fsCloseSync = fd => fse.closeSync(fd);

// export const fsRead = async (fd, buffer, offset, length, position, ...args) => fse
//     .read(fd, buffer, offset, length, position, ...args);
export const fsReadSync = (fd, buffer, offset, length, position) => fse
    .readSync(fd, buffer, offset, length, position);

export const fsEnsureDir = async (dir, ...args) => fse.ensureDir(dir, ...args);
export const fsEnsureDirSync = (dir, ...args) => fse.ensureDirSync(dir, ...args);

export const fsRenameSync = (oldPath, newPath) => fse.renameSync(oldPath, newPath);

export const fsRemove = (path, ...args) => fse.remove(path, ...args);
export const fsRemoveSync = path => fse.removeSync(path);

const doMove = async (src, dest, ...args) => {
    try {
        await fse.move(src, dest, ...args);
    } catch (e) {
        if (await fsExists(src)) {
            if (!(await fsExists(dest))) {
                throw e;
            } else {
                console.error(`Two file versions are present at once: "${src}" and "${dest}".`);
            }
        } else if (!await fsExists(dest)) {
            console.error(`Can not find none of these files: "${src}" and "${dest}".`);
        } else {
            // move file back
            await fse.move(dest, src);
        }
        throw e;
    }
};
export const fsMove = async (src, dest, ...args) => {
    if (TEMPORARY_FILE_EXTENSION_FILTER.test(src) || TEMPORARY_FILE_EXTENSION_FILTER.test(dest)) {
        await doMove(src, dest, ...args);
        return;
    }

    const tempDest = `${dest}${TEMPORARY_FILE_EXTENSION}`;
    await doMove(src, tempDest);

    if (!(await fsExists(src)) && await fsExists(tempDest)) {
        await doMove(tempDest, dest, ...args);
    }
};
export const fsMoveSync = (src, dest, ...args) => fse.moveSync(src, dest, ...args);

export const fsCopy = async (src, dest, options = FS_COPY_OPTIONS, ...args) => {
    const tempDest = `${dest}${TEMPORARY_FILE_EXTENSION}`;
    await fsRemove(tempDest);

    try {
        await fse.copy(src, tempDest, options, ...args);
        await fsMove(tempDest, dest);
    } catch (e) {
        throw e;
    } finally {
        await fsRemove(tempDest);
    }
};

export const fsCreateReadStream = (path, ...args) => fse.createReadStream(path, ...args);

/**
 * Asynchronously writes `buffer` to the file referenced by the supplied file descriptor.
 * @param fd A file descriptor.
 * @param buffer Contains file data.
 * @param offset The part of the buffer to be written. If not supplied, defaults to `0`.
 * @param length The number of bytes to write. If not supplied, defaults to `buffer.length - offset`.
 * @param position The offset from the beginning of the file where this data should be written. If not supplied,
 *                  defaults to the current position.
 * @param args
 */
export const fsWrite = async (fd, buffer, offset = undefined, length = undefined, position = undefined, ...args) => fse
    .write(fd, buffer, offset, length, position, ...args);
