import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './DropDownMenu.module.css';

import clickOutside from '../../utils/clickOutside';

const Item = ({ item }) => {
    if (item.show === false) {
        return '';
    }

    if (item.component) {
        return (
            <div className={cn(styles.component, item.className)}>
                {item.component}
            </div>
        );
    }

    const props = {};
    if (item.onClick) {
        props.onClick = item.onClick;
    }

    if (item.className) {
        props.className = item.className;
    }

    if (item.to) {
        props.to = item.to;
        return <NavLink {...props} activeClassName={styles.active}>{item.label}</NavLink>;
    }

    return <a {...props}>{item.label}</a>;
};

class DropDownMenu extends Component {
    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { show, onClickOutside, pathname } = this.props;
        if (show && (prevProps.pathname !== pathname)) {
            onClickOutside();
        }
    }

    render() {
        const { show, onClickOutside, items, alignRight, className, children } = this.props;
        if (!show) {
            return null;
        }

        const Ul = clickOutside('ul');
        return (
            <Ul
                onClickOutside={onClickOutside}
                className={cn(styles.root, alignRight && styles.alignRight, className)}
            >
                {children}
                {items
                    .filter(item => (item.show !== false))
                    .map((item, index) => (
                        <li
                            className={styles.item}
                            key={index}
                            onClick={onClickOutside}
                        >
                            <Item item={item} />
                        </li>
                    ))}
            </Ul>
        );
    }
}

DropDownMenu.defaultProps = {
    items: [],
    alignRight: false,
};

DropDownMenu.propTypes = {
    alignRight: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    onClickOutside: PropTypes.func.isRequired,
    items: PropTypes.array,
    className: PropTypes.string,
    children: PropTypes.any,
    pathname: PropTypes.string,
};

Item.propTypes = {
    item: PropTypes.shape({
        to: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func,
    }),
};

const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
});


export default connect(mapStateToProps)(DropDownMenu);
