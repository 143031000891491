const ERROR_NOT_FOUND = 'not_found';

/** Get and Return document from DB. Return empty object if document not found
 * @param {string} id
 * @param {PouchDB.Database} db
 * @return {Promise<PouchDB.Core.Document<Record<string, unknown>> & PouchDB.Core.GetMeta>} */
export const readDoc = async (id, db) => {
    try {
        return await db.get(id);
    } catch (e) {
        if (e.name !== ERROR_NOT_FOUND) {
            throw e;
        }
    }

    return {};
};

/** Get and Return documents from DB. Return empty array if documents not found
 * @param {string[]} ids
 * @param {PouchDB.Database} db
 * @return {Promise<(PouchDB.Core.Document<Record<string, unknown>> & PouchDB.Core.GetMeta)[]>} */
export const readDocs = async (ids, db) => {
    const response = await db.allDocs({
        keys: ids,
        include_docs: true,
    });

    return response && response.rows
        ? response.rows.map(row => row.doc)
        : [];
};

/** Write document to DB and return new version of it or raise an error */
export const writeDoc = async (doc, db) => {
    const founded = await readDoc(doc._id, db);

    const curDate = new Date().toISOString();

    const response = await db.put({
        ...founded,
        ...doc,
        updated: curDate,
        created: founded.created || doc.created || curDate,
    });

    if (!response || response.ok !== true) {
        throw new Error(`Could not put document\n${JSON.stringify(doc)}\nResponse:\n${JSON.stringify(response)}`);
    }

    return readDoc(doc._id, db);
};

/** Write documents to DB or raise an error
 * @param {TDocument[]} docs
 * @param {PouchDB.Database} db
 * @return {Promise<(PouchDB.Core.Document<Record<string, unknown>> & PouchDB.Core.GetMeta)[]>} */
export const writeDocs = async (docs, db) => {
    const results = await db.bulkDocs(Array.isArray(docs) ? docs : [docs]);

    if (!results) {
        throw new Error('Error while writing in DB. Response is empty');
    }

    results.forEach((res) => {
        if (res.ok !== true) {
            throw new Error(`Error while writing in DB. Response:\n${JSON.stringify(res)}`);
        }
    });

    return results;
};

/** Remove document with id from DB */
export const removeDoc = async (id, db) => {
    const foundDoc = await readDoc(id, db);
    if (foundDoc._id) {
        const response = await db.remove(foundDoc);
        if (!response || response.ok !== true) {
            throw new Error(`Error while removing document with id "${id}"\nResponse:\n${JSON.stringify(response)}`);
        }
    }
};

/** Remove documents with ids from DB */
export const removeDocs = async (ids, db) => {
    if (ids.length) {
        const docs = await readDocs(ids, db);
        if (docs.length !== ids.length) {
            const newIds = docs.map(doc => doc._id);
            throw new Error(`Cannot find several ids in DB: ${ids.filter(!newIds.includes).join(', ')}`);
        }

        return writeDocs(docs.map(doc => ({ ...doc, _deleted: true })), db);
    }
    return [];
};


const createIndex = async (indexOptions, db) => {
    if (indexOptions) {
        await db.createIndex(indexOptions);
    }
};
const getFindOptions = ({ options, sortBy, startId, limit, fields }) => {
    const $and = [
        ...options.selector.$and.filter(item => !item._id),
        ...(startId
            ? [{ _id: { $gt: startId } }]
            : options.selector.$and.filter(item => !!item._id)
        )
    ];

    const $andBySort = sortBy
        .filter((itemSort) => {
            const field = typeof itemSort === 'string' ? itemSort : Object.keys(itemSort)[0];
            return !$and.map(itemAnd => Object.keys(itemAnd)[0]).includes(field);
        })
        .map((item) => {
            const field = typeof item === 'string' ? item : Object.keys(item)[0];
            return { [field]: { $gt: null } };
        });

    return {
        ...options,
        selector: {
            $and: [
                ...$and,
                ...$andBySort
            ]
        },
        ...(fields ? { fields } : {}),
        sort: sortBy,
        ...(limit ? { limit } : {}),
    };
};
const getIndexOptions = (options) => {
    const indexFields = [];

    // Sort fields MUST be the first in index field array
    // comment: https://github.com/pouchdb/pouchdb/issues/6399#issuecomment-312827943
    // issue: https://github.com/pouchdb/pouchdb/issues/7207
    options.sort.forEach((item) => {
        const field = typeof item === 'string' ? item : Object.keys(item)[0];
        if (!indexFields.includes(field)) {
            indexFields.push(field);
        }
    });

    options.selector.$and.forEach((item) => {
        const field = Object.keys(item)[0];
        if (!indexFields.includes(field)) {
            indexFields.push(field);
        }
    });
    return { index: { fields: indexFields } };
};


/**
 *  Find and Return documents[] from DB depends of FIND options. Return empty array if documents not found
 * @param {object} findOptions
 * @param {PouchDB.Static} db
 * @returns {Promise<Array<TDoc>>}
 */
export const doFindDocs = async (findOptions, db) => {
    const result = await db.find(findOptions);
    // console.log('DB_READ', result, findOptions);
    return result.docs;
};

/** Find and Return documents[] from DB depends of LIST options. Return empty array if documents not found */
export const findDocs = async (listOptions, db) => {
    const findOptions = getFindOptions(listOptions);
    const indexOptions = getIndexOptions(findOptions);

    await createIndex(indexOptions, db);
    return doFindDocs(findOptions, db);
};
