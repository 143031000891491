import { debounce } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import styles from './SeriesSearch.module.css';
import Button from '../Button/Button';
import { insertVideoToSeries } from '../../modules/episodes/actions';
import { findSeriesByTitle } from '../../modules/series/actions';
import Input from '../Input/Input';

const initialState = {
  busy: false,
  seriesId: '',
  list: [],
  title: ''
};

class SeriesSearch extends Component {
  static propTypes = {
    fileId: PropTypes.string.isRequired,
    excludeIds: PropTypes.array,
    updateList: PropTypes.func.isRequired,
    addToSeries: PropTypes.func.isRequired,
    addSeriesCallback: PropTypes.func,
  };

  state = initialState;

  componentDidMount() {
    this._mounted = true;
    this.debouncedFetchSeries = debounce(this.fetchSeries, 500);
    this.handleTitleChange('');
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchSeries = async (value) => {
    const { excludeIds, updateList } = this.props;

    const list = await updateList(value, excludeIds);

    if (this._mounted) {
      this.setState({
        busy: false,
        list
      });
    }
  };

  handleTitleChange = async (value) => {
    if ((typeof value === 'object') && (value !== null)) {
      if (this._mounted) {
        this.setState({
          busy: false,
          list: [],
          seriesId: value._id,
          title: value.title
        });
      }
    } else if (typeof value === 'string') {
      if (this._mounted) {
        this.setState({
          busy: true,
          title: value
        });
      }
      this.debouncedFetchSeries(value);
    } else if (this.state.list.length) {
      if (this._mounted) {
        this.setState({
          list: []
        });
      }
    }
  };

  handleAddToSeries = async (values, { setSubmitting }) => {
    const { seriesId } = values;
    const { addToSeries, fileId, addSeriesCallback } = this.props;

    await addToSeries(seriesId, fileId);

    this.setState({ ...initialState });
    setSubmitting(false);

    if (addSeriesCallback) {
      addSeriesCallback();
    }

    this.handleTitleChange('');
  };

  render() {
    const { busy, list, seriesId, title } = this.state;

    return (
      <Formik
        initialValues={{
          seriesId,
          title
        }}
        enableReinitialize
        validationSchema={object()
          .shape({
            seriesId: string()
              .default('')
              .required('Required')
          })}
        onSubmit={async (values, actions) => this.handleAddToSeries(values, actions)}
        render={({ isSubmitting }) => (
          <Form>
            <div className={styles.searchContainer}>
              <Field
                name="seriesId"
                component={Input}
                type={Input.TYPE.COMBOBOX}
                label="Add to Series:"
                placeHolder="Type to find series"
                busy={busy}
                onChange={this.handleTitleChange}
                data={list}
                valueField="_id"
                value={title}
                textField="title"
                required
                rootClassName={styles.searchInput}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                color={Button.COLOR.PRIMARY}
              >
                Add to series
              </Button>
            </div>
          </Form>
        )}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateList: (title, excludeIds) => dispatch(findSeriesByTitle(title, excludeIds)),
  addToSeries: async (seriesId, fileId) => dispatch(insertVideoToSeries(seriesId, fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesSearch);
