import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { checkPublishMedia } from '../../utils/permissions';
import { activateAnalyticsPermissions, getAnalytics, getAnalyticsKeys } from '../../utils/youtube';
import Spinner from '../Spinner/Spinner';
import styles from './YoutubeAnalytics.module.css';

class YoutubeAnalytics extends Component {
  static propTypes = {
    analyticType: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    id: PropTypes.string,
    getInfo: PropTypes.func.isRequired,
    getPermissions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showTitle: false,
  };

  state = {
    data: getAnalyticsKeys(),
    error: false,
  };

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getStatistics = async () => {
    const { getInfo, id, analyticType } = this.props;

    if (!!id) {
      const data = await getInfo(id, analyticType);
      if (!!data) {
        if (this._mounted) {
          this.setState({ data });
        }
      } else if (this._mounted) {
        this.setState({ error: true });
      }
    }

    return true;
  };

  init = async () => {
    await this.getStatistics();
  };

  handleGetPermissions = async () => {
    const { getPermissions } = this.props;
    getPermissions(this.getStatistics);
  };

  render() {
    const { id, showTitle } = this.props;
    const { data, error } = this.state;

    if (!id) {
      return '';
    }

    return (
      <>
        {showTitle && <h3 className={styles.title}>Analytics</h3>}
        {!error
          ? data.map(rec => (
            <Input
              key={rec.key}
              id={rec.key}
              labelClassName={styles.label}
              rootClassName={styles.itemContainer}
              type={Input.TYPE.LABEL}
              label={rec.name}
              field={{
                value: rec.value !== undefined
                  ? rec.value
                  : <Spinner />
              }}
            />
          ))
          : (
            <Button
              small
              color={Button.COLOR.PRIMARY}
              onClick={this.handleGetPermissions}
            >
              Get analytics permissions
            </Button>
          )
        }
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  getInfo: async (id, analyticType) => dispatch(getAnalytics(
    analyticType,
    id,
    moment('1980-01-01').toDate(),
    new Date()
  )),
  getPermissions: callback => dispatch(activateAnalyticsPermissions(callback)),
  canPublishMedia: dispatch(checkPublishMedia()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YoutubeAnalytics);
