import { ENDPOINTS } from '../../constants/general';
import { api } from '../../constants/globals';
import { getUserId } from '../webSettings';

export const apiS3Remove = async (key) => {
    try {
        return await api.apiPost(ENDPOINTS.cloud.s3.remove, { key });
    } catch (e) {
        return { message: e.message };
    }
};

export const apiS3GetDownloadFileUrl = async (key) => {
    const response = await api.apiGet(ENDPOINTS.cloud.s3.getDownloadFileUrl, { key });
    if (!response || !response.url) {
        throw new Error(`Error while fetching download url. Response:\n${response
            ? JSON.stringify(response)
            : undefined
        }`);
    }
    return { url: response.url };
};

export const apiCreateMultipartUpload = async (key) => {
    return await api.apiGet(ENDPOINTS.cloud.s3.createMultipartUpload, { key });
};

export const apiListParts = async (key, uploadId) => {
    return await api.apiGet(ENDPOINTS.cloud.s3.listParts, { key, uploadId });
};

export const apiPrepareUploadPart = async (key, uploadId, number) => {
    return await api.apiGet(ENDPOINTS.cloud.s3.prepareUploadPart, { key, uploadId, number });
};

export const apiCompleteMultipartUpload = async (key, uploadId, parts) => {
    return await api.apiPost(ENDPOINTS.cloud.s3.completeMultipartUpload, { key, uploadId, parts });
};

export const apiAbortMultipartUpload = async (key, uploadId) => {
    return await api.apiGet(ENDPOINTS.cloud.s3.abortMultipartUpload, { key, uploadId });
};


export const getS3Url = async (cloudS3) => {
    if (!cloudS3.key) {
        return Promise.reject(new Error('Key property is required'));
    }
    return apiS3GetDownloadFileUrl(cloudS3.key);
};

export const getS3UrlFunc = (cloudS3) => {
    if (cloudS3.key) {
        return async () => getS3Url(cloudS3);
    }
    return null;
};

export const getS3ProjectKey = projectId => `${getUserId()}/${projectId}`;
