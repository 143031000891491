import { safeLoad } from 'js-yaml';
import { parseDate } from './format';

export const RELEASE_TYPES = {
    win: 'win',
    mac: 'mac',
    linux: 'linux',
};

export const getLatestBuilds = async () => {
    const types = Object.values(RELEASE_TYPES);
    const calls = types.map(async (type) => {
        // TODO: Check name for Linux
        const latestYml = type === RELEASE_TYPES.win ? 'latest.yml' : `latest-${type}.yml`;
        try {
            // const result = await api.apiGet(`${process.env.REACT_APP_RELEASES_URI}/${latestYml}`);
            const result = await fetch(`${process.env.REACT_APP_RELEASES_URI}/${latestYml}`, { method: 'GET', mode: 'cors' });
            if (result.ok) {
                const textBody = await result.text();
                if (typeof textBody === 'string') {
                    return safeLoad(textBody);
                }
                return textBody;
            }
        } catch (e) {
            // console.error(`Error checkin release for ${type}`, e);
        }
        return null;
    });

    return (await Promise.all(calls))
        .reduce((acc, res, ind) => ({
            ...acc,
            [types[ind]]: res && typeof res === 'object'
                ? {
                    version: res.version,
                    releaseDate: parseDate(res.releaseDate),
                    files: res.files
                        .filter(fl => !fl.url.includes('.blockmap'))
                        .reduce((accFl, fl) => ({
                            ...accFl,
                            [fl.url.split('.').pop()]: fl,
                        }), {}),
                }
                : null,
        }), {});
};
