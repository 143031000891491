import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import LoginContainer from '../../../components/LoginContainer/LoginContainer';
import { onLogin } from '../../../modules/share/actions';
import { ROUTE } from '../../../constants/general';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';

const initialState = {
    password: ''
};

class SharedLogin extends Component {
    static propTypes = {
        history: PropTypes.shape({ push: PropTypes.func }),
        sharingId: PropTypes.string.isRequired,
        doAuth: PropTypes.func,
    };

    state = initialState;

    handleSubmit = async ({ sharingId, password }, { setSubmitting }) => {
        const { doAuth, history } = this.props;

        try {
            if (await doAuth(sharingId, password)) {
                // this.setState(initialState);
                history.push(ROUTE.share.project(sharingId));
            }
        } finally {
            setSubmitting(false);
        }
    };

    render() {
        const { sharingId } = this.props;
        const { password } = this.state;

        return (
            <LoginContainer>
                <Formik
                    initialValues={{ password, sharingId }}
                    validationSchema={object().shape({
                        sharingId: string().required().min(1),
                        password: string().required().min(1)
                    })}
                    enableReinitialize
                    onSubmit={this.handleSubmit}
                    render={({ isSubmitting }) => (
                        <Form>
                            <Field
                                name="sharingId"
                                component={Input}
                                labelWidth={Input.LABEL_WIDTH.FULL}
                                label="Sharing Id"
                                readOnly
                                required
                            />
                            <Field
                                name="password"
                                type="password"
                                component={Input}
                                label="Password"
                                required
                                labelWidth={Input.LABEL_WIDTH.FULL}
                            />
                            <FormButtons>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    color={Button.COLOR.PRIMARY}
                                >
                                Sign in
                                </Button>
                            </FormButtons>
                        </Form>
                    )}
                />
            </LoginContainer>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    sharingId: ownProps.match.params.sharingId,
});

const mapDispatchToProps = dispatch => ({
    doAuth: (sharingId, password) => dispatch(onLogin(sharingId, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedLogin);
