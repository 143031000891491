import './constants/globals';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { initGlobals } from './constants/handlers';
import { checkLogin } from './new/loginUtils';
import { App } from './Pages/common/App';
import store, { history } from './store';

import 'sanitize.css/sanitize.css';
import './index.css';
import { isSharing } from './utils/webSettings';
import { cleanOldLocalValues } from './utils/localStorageUtils';

const target = document.querySelector('#root');

const renderApp = () => {
    render(
        <BrowserRouter>
            <Provider store={store}>
                <ConnectedRouter history={history}> {/* TODO: Remove it later (need to use useHistory everywhere) */}
                    <App />
                </ConnectedRouter>
            </Provider>
        </BrowserRouter>,
        target
    );
};

const getAppClient = async () => {
    cleanOldLocalValues().then(); // No need to wait
    await store.dispatch(initGlobals());

    if (isSharing()) {
        // await store.dispatch(shareCheckLogin());
    } else {
        await store.dispatch(checkLogin());
    }
    renderApp();
};

getAppClient();
