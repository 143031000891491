import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage6.module.css';
import imageExample from './image_example.svg';
import imageHand2 from './image_hand_2.png';

const ProjectHelpPage4 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                2) Your file is renamed with
                an ID so you can always find
                it!!
                {' '}
                <img className={styles.imageHand2} src={imageHand2} alt="two hands" />
            </h2>
            <img src={imageExample} alt="example" className={styles.imageExample} />
        </div>
    </TitledPage>
);

export default ProjectHelpPage4;
