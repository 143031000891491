import cn from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFileNewFolderName } from '../../utils/desktop/files';
import { readUserDoc } from '../../handlers/userDB';
import { fsEnsureDirSync, fsExistsSync } from '../../utils/desktop/fse';
import { pathDirname } from '../../utils/desktop/path';
import styles from './ActionCreateFolder.module.css';
import icon from './icon_folder.svg';
import Button from '../Button/Button';
import { isWeb } from '../../constants/modules';

class ActionCreateFolder extends Component {
    static defaultProps = {
        disabled: false,
    };

    static propTypes = {
        disabled: PropTypes.bool,
        fileType: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
        readProject: PropTypes.func,
        getFolderName: PropTypes.func,
    };

    handleCreateFolder = async () => {
        const { fileType, projectId, readProject, getFolderName } = this.props;

        const project = await readProject(projectId);
        const path = getFolderName(fileType, project);

        const mediaFolderExist = fsExistsSync(pathDirname(path));

        fsEnsureDirSync(path);

        const folderText = path.replace(
            mediaFolderExist
                ? pathDirname(path)
                : pathDirname(pathDirname(path)),
            ''
        ).substr(1);

        alert('A new folder was created!\n'
            + `"${folderText}" folder is now located in project "${project.title}" folder.`);
    };

    render() {
        if (isWeb) {
            return '';
        }

        const { disabled } = this.props;

        return (
            <Button
                small
                color={Button.COLOR.NONE}
                disabled={disabled || false}
                onClick={this.handleCreateFolder}
                className={cn(styles.button, styles.left0)}
            >
                <div className={styles.icon}>
                    <img src={icon} alt="Create folder" />
                </div>
                New Folder
            </Button>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => (isWeb ? {} : {
    readProject: projectId => dispatch(readUserDoc(projectId)),
    getFolderName: (fileType, project) => dispatch(getFileNewFolderName(fileType, project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionCreateFolder);
