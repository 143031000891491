import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage7.module.css';
import imageExampleSync from './image_example_sync.svg';

const ProjectHelpPage5 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                Then sync the project.
            </h2>
            <img className={styles.imageExampleSync} src={imageExampleSync} alt="actions" />
        </div>
    </TitledPage>
);

export default ProjectHelpPage5;
