import cn from 'classnames';
import React from 'react';
import AbsoluteText from '../OrganizeBackup/Schema/Components/AbsoluteText';
import styles from './OrganizePublish.module.css';
import image from './img/image_organize_publish.svg';

const OrganizePublish = () => (
    <div>
        <div className={styles.schema}>
            <img src={image} alt="diagram" />
            <AbsoluteText level={2} text="Organize" className={cn(styles.title, styles.leftTitle)} />
            <AbsoluteText level={2} text="Publish" className={cn(styles.title, styles.rightTitle)} />
            <AbsoluteText
                level={0}
                text="Store your raw media in folders that are dynamically created dated folders on on your hard drive"
                className={cn(styles.text, styles.leftText)}
            />
            <AbsoluteText
                level={0}
                text="Group finished Audio and Videos into series. Create feeds and push to YouTube"
                className={cn(styles.text, styles.rightText)}
            />
        </div>
        <h1>You create it. You own it. We help.</h1>
    </div>
);

export default OrganizePublish;
