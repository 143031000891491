import React, { useCallback, useRef } from 'react';
import { TDocumentTypeFile } from '../../constants/docs';
import { asyncForEach } from '../../utils/common';
import { getSelectedFile, TSelectedFile } from '../../utils/files/files';
import { ButtonNew } from '../ButtonNew';


type TFileInputButtonProps = {
  fileType: TDocumentTypeFile
  addFiles: (files: TSelectedFile[]) => void
  disabled?: boolean
  className?: string
  label?: string
};


export const FileInputButton: React.FC<TFileInputButtonProps> = (props) => {
  const { disabled = false, fileType, addFiles, className, label } = props;
  const inputRef = useRef<HTMLInputElement>(null); // Weird, `useRef<HTMLInputElement>(null)` does not work

  const handleButtonClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleInputChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('selected files [web]', e.target.files)
    const files: TSelectedFile[] = await asyncForEach<File, TSelectedFile>(
      [...(e.target.files ?? [])],
      async (file) => getSelectedFile(file, fileType)
    )
    addFiles(files);
  }, [fileType, addFiles]);


  return (
    <>
      <div>
        <ButtonNew
          onClick={handleButtonClick}
          disabled={disabled}
          className={className}
        >
          { label ?? 'Add' }
        </ButtonNew>
      </div>
      <input ref={inputRef} onChange={handleInputChange} type="file" multiple style={{ display: 'none' }} />
    </>
  );
};
