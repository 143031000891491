import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_TYPE } from '../../../constants/general';
import styles from './ListPlaceholder.module.css';
import ListPlaceholderProject from './Project/ListPlaceholderProject';
import ListPlaceholderSeries from './Series/ListPlaceholderSeries';

const getPlaceholder = (type) => {
    switch (type) {
    case CONTENT_TYPE.PROJECT:
        return <ListPlaceholderProject />;
    case CONTENT_TYPE.SERIES:
        return <ListPlaceholderSeries />;
    default:
        return '';
    }
};

const ListPlaceholder = ({ type }) => {
    return (
        <div className={styles.container}>
            {getPlaceholder(type)}
        </div>
    );
};

ListPlaceholder.propTypes = {
    type: PropTypes.string.isRequired,
};

export default ListPlaceholder;
