import { apiShareCreateDocument } from '../../modules/share/api';
import { asyncForEach } from '../common';
import { runUploadFile } from '../common/syncRunner';
import { getFileObject } from './filesCommon';

/**
 * Insert file documents into the user DB and upload files to the cloud
 * @param {string} projectId Project Id
 * @param {[{ type: string, file: File }]} files Object with file
 * */
export const addFilesToProject = (projectId, files) => async (dispatch) => {
    if (files && files.length) {
      const fileObjects = await asyncForEach(
        files,
        async (fl) => await getFileObject(fl.type, fl.file, projectId)
      );

      await asyncForEach(fileObjects, async (fo) => {
        await apiShareCreateDocument(fo.doc);
        await dispatch(runUploadFile([fo]));
      }).then();
    }
};
