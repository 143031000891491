import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HelpPages from '../../../../components/HelpPages/HelpPages';
import { WindowContainer } from '../../../../components/WindowContainer';
import { HELP_FLAG_OPEN_FIRST_PROJECT, setUserHelpFlag } from '../../../../utils/helpText';
import ProjectHelpPage1 from './Pages/Page1/ProjectHelpPage1';
import ProjectHelpPage2 from './Pages/Page2/ProjectHelpPage2';
import ProjectHelpPage3 from './Pages/Page3/ProjectHelpPage3';
import ProjectHelpPage4 from './Pages/Page4/ProjectHelpPage4';
import ProjectHelpPage5 from './Pages/Page5/ProjectHelpPage5';
import ProjectHelpPage6 from './Pages/Page6/ProjectHelpPage6';
import ProjectHelpPage7 from './Pages/Page7/ProjectHelpPage7';

class ProjectHelpPages extends Component {
    static propTypes = {
        flag: PropTypes.bool,
        setFlag: PropTypes.func,
    };

    state = { update: false };

    handleDone = async () => {
        const { setFlag } = this.props;
        await setFlag();
        this.setState(state => ({ update: !state.update }));
    };

    render() {
        const { flag } = this.props;
        if (flag) {
            return '';
        }

        return (
            <WindowContainer closeAction={this.handleDone}>
                <HelpPages
                    width="600px"
                    height="523px"
                    pages={[
                        <ProjectHelpPage1 />,
                        <ProjectHelpPage4 title="How It Works:" />,
                        <ProjectHelpPage6 title="How It Works:" />,
                        <ProjectHelpPage2 title="How It Works:" />,
                        <ProjectHelpPage3 title="How It Works:" />,
                        <ProjectHelpPage5 title="How It Works:" />,
                        <ProjectHelpPage7 title="How It Works:" />,
                    ]}
                    callback={this.handleDone}
                />
            </WindowContainer>
        );
    }
}

const mapStateToProps = ({ user: { dbUser: { helpFlags } } }) => ({
    flag: helpFlags ? helpFlags[HELP_FLAG_OPEN_FIRST_PROJECT] === true : true,
});

const mapDispatchToProps = dispatch => ({
    setFlag: () => dispatch(setUserHelpFlag(HELP_FLAG_OPEN_FIRST_PROJECT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHelpPages);
