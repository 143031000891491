import { LIST_OPTIONS } from '../../constants/pouchDB';
import { GENERAL_ACTION_TYPES } from '../general';

export const ACTION_TYPES = {
    list: {
        read: {
            success: 'SERIES_LIST_READ_SUCCESS'
        }
    },
    current: {
        read: {
            success: 'SERIES_CURRENT_READ_SUCCESS'
        }
    },
    deleted: 'SERIES_DELETED'
};

const initialState = {
    ...LIST_OPTIONS.series,
    list: [],
    current: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.list.read.success: {
        return {
            ...state,
            list: action.list
        };
    }
    case ACTION_TYPES.current.read.success: {
        return {
            ...state,
            current: action.current
        };
    }
    case ACTION_TYPES.deleted: {
        return {
            ...state,
            list: state.list.filter(series => series._id !== action.seriesId)
        };
    }

    case GENERAL_ACTION_TYPES.authClear: {
        return { ...initialState };
    }

    default: {
        return state;
    }
    }
};

export default reducer;
