import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../../Button/Button';
import icon from './icon_delete.svg';
import styles from './ActionRemove.module.css';

class ActionRemove extends Component {
    static propTypes = {
        docs: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
        })),
        action: PropTypes.func,
        callback: PropTypes.func,
    };

    handleRemoveFileButton = async () => {
        const { docs } = this.props;
        const answer = window.confirm(`Items (${docs.length}) will be removed.\nContinue?`);

        if (answer) {
            const { action, callback } = this.props;
            if (action) {
                const errors = await action(docs.map(f => f._id));
                if (errors && errors.length) {
                    alert(`Can not delete items:\n${errors.reduce((acc, msg) => `${acc}\n${msg}`)}`);
                } else if (callback) {
                    callback();
                }
            }
        }
    };

    render() {
        const { action, docs } = this.props;
        return action && (
            <Button
                tiny
                color={Button.COLOR.NONE}
                onClick={this.handleRemoveFileButton}
                className={cn(styles.container, styles.left0)}
            >
                <div className={styles.icon}>
                    <img src={icon} alt={`delete item${docs.length && `s (${docs.length})`}`} />
                </div>
                Delete
            </Button>
        );
    }
}

export default ActionRemove;
