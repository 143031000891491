import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../../../components/Icon';
import { USER_EVENT_COLORS } from '../../../constants/globals';
import styles from './UserEventItem.module.css';

const UserEventItem = ({ color, icon, message, title }) => (
    <div className={styles.container} style={{ backgroundColor: color }}>
        {!!icon && (
            <Icon icon={icon} className={styles.icon} />
        )}
        <div className={styles.messageBlock}>
            {!!title && (
                <div className={styles.title}>
                    {title}
                </div>
            )}
            <div className={styles.message}>
                {message}
            </div>
        </div>
    </div>
);

UserEventItem.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
};

UserEventItem.defaultProps = {
    color: USER_EVENT_COLORS.info,
};

export default UserEventItem;
