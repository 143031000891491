import PropTypes from 'prop-types';
import React from 'react';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../constants/docs';
import Spinner from '../../../Spinner/Spinner';
import StatusIcon from '../../../StatusIcons/StatusIcon/StatusIcon';
import styles from './IconProjectHasFileType.module.css';
import iconFinalVideo from './icon_has_finished_video.svg';
import iconFinalAudio from './icon_has_finished_audio.svg';

const getIcon = (fileType) => {
  switch (fileType) {
  case DOC_TYPE_FILE_FINISH_VIDEO:
    return iconFinalVideo;
  case DOC_TYPE_FILE_FINISH_AUDIO:
    return iconFinalAudio;
  default:
    return '';
  }
};

const getCount = (item, fileType) => (
    (item && item._totals && !!item._totals.fileCount)
        ? item._totals.fileCount[fileType]
        : null
);

const IconProjectHasFileType = ({ project, type, onlyNumber = false }) => {
    const count = getCount(project, type);

    if (onlyNumber) {
        return count === null ? <Spinner className={styles.spinnerNumber} /> : count;
    }

    return (
        <StatusIcon
          enabled={!!count}
          icon={getIcon(type)}
          altOn={`has ${count} files`}
          altOff="has no files"
        >
            {count === null && <Spinner className={styles.spinner} />}
            {!!count && <div className={styles.count}>{count}</div>}
        </StatusIcon>
    );
};
IconProjectHasFileType.propTypes = {
    project: PropTypes.shape({}),
    type: PropTypes.string,
    onlyNumber: PropTypes.bool,
};

export default IconProjectHasFileType;
