import React from 'react';
import videoJs from 'video.js';
import 'video.js/dist/video-js.css';
import styles from './VideoJsPlayer.module.css';

const defaultOptions = {
    autoplay: false,
    controls: true,
    width: '500px',
    height: '281.25px'
};

export default class VideoJsPlayer extends React.Component {
    componentDidMount() {
        // instantiate videoJs
        this.player = videoJs(this.videoNode, { ...defaultOptions, ...this.props }, () => {
            // console.log('onPlayerReady', this)
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so video.js won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    // and we can not set className to that div, only style={}
    render() {
        return (
            <div className={styles.container}>
                <div data-vjs-player>
                    <video ref={node => (this.videoNode = node)} className="video-js" />
                </div>
            </div>
        );
    }
}
