import { actionAuthError } from '../modules/general';
import { apiTokenAuth, apiTokenRefresh } from '../utils/user';
import { doCheckToken } from '../utils/webSettings';
import { actionSetToken } from '../modules/user/userActions';


export const doProcessToken = response => async (dispatch) => {
    const result = doCheckToken(response);
    if (result) {
        dispatch(actionSetToken(response.token, response.refreshToken));
    } else {
        dispatch(actionAuthError(response));
    }
};

export const webTokenAuth = user => async (dispatch) => {
    try {
        const authResponse = await apiTokenAuth(user);
        await dispatch(doProcessToken(authResponse));
    } catch (e) {
        await dispatch(doProcessToken(e));
    }
};

export const webRefreshToken = () => async (dispatch) => {
    try {
        const result = await apiTokenRefresh();
        await dispatch(doProcessToken(result));
    } catch (e) {
        await dispatch(doProcessToken(e));
    }
};
