import React, { FC, useCallback, useState } from 'react';
import { ButtonNew } from '../ButtonNew';
import Input from '../Input/Input';
import { IconFilter } from './IconFilter';
import styles from './list-filter.module.scss';


type TListFilterProps = {
  tagSearch: {
    enabled: boolean
    values: string[]
    setValues: (tags: string[]) => void
    add: (tag: string) => void
    remove: (tag: string) => void
    removeValues: () => void
    component?: React.Component
  }
}

type TUseListFilterProps = {
  // quickSearch?: boolean | {
  //   enabled: boolean
  //   component?: React.Component
  // }
  tagSearch?: boolean | {
    enabled: boolean
    values?: string[]
    // component?: React.Component
    onChange?: (tags: string[]) => void
  }
}

export const useListFilter = (props: TUseListFilterProps): TListFilterProps => {
  const { tagSearch } = props;


  const [tags, setTags] = useState<string[]>(typeof tagSearch === 'boolean' ? [] : tagSearch?.values ?? [])
  const handleTagChange = useCallback((tags: string[]) => {
    setTags(tags);
    if (typeof tagSearch !== 'boolean' && tagSearch?.onChange) {
      tagSearch?.onChange(tags);
    }
  }, [tagSearch])
  const handleTagRemove = useCallback((tag: string) => {
    setTags(s => {
      const newS = s.filter(t => t !== tag);
      if (typeof tagSearch !== 'boolean' && tagSearch?.onChange) {
        tagSearch?.onChange(newS);
      }
      return newS
    });
  }, [tagSearch])
  const handleTagAdd = useCallback((tag: string) => {
    setTags(s => {
      const newS = s.includes(tag) ? s : [...s, ...tag.split(',')];
      if (typeof tagSearch !== 'boolean' && tagSearch?.onChange) {
        tagSearch?.onChange(newS);
      }
      return newS
    });
  }, [tagSearch])


  return {
    tagSearch: {
      enabled: typeof tagSearch === 'boolean' ? tagSearch : tagSearch?.enabled ?? false,
      setValues: handleTagChange,
      add: handleTagAdd,
      remove: handleTagRemove,
      removeValues: () => handleTagChange([]),
      values: tags,
      // component: typeof tagSearch === 'boolean' ? undefined : tagSearch?.component,
    }
  };
}


export const ListFilter: FC<TListFilterProps> = (props) => {
  const { tagSearch } = props;

  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = useCallback(() => {
    setShowDetails(s => !s)
  }, []);

  return (
    <div>
      <div className={styles.shortView}>
        <ButtonNew
          color="transparent"
          onClick={toggleDetails}
          iconComponent={<IconFilter />}
          className={styles.filterButton}
        />
        {showDetails && tagSearch.enabled && (
          <Input
            field={{
              name: 'tags',
              value: tagSearch.values,
              onChange: (tags) => {
                tagSearch.setValues(tags);
                toggleDetails();
              },
              onCreate: (tag: string) => {
                tagSearch.add(tag);
                toggleDetails();
              },
            }}
            type={Input.TYPE.MULTI_SELECT}
            className={styles.quickSearch}
            rootClassName={styles.quickSearchRoot}
            spaceBottom={Input.SPACE_BOTTOM.NONE}
            placeholder="SEARCH TAGS"
          />
        )}
        {!showDetails && tagSearch.values.length !== 0 && (
          <div className={styles.appliedFilters}>
            {tagSearch.enabled && (
              <div>
                <span className={styles.label}>FILTERS:</span>
                {tagSearch.values.map(tag => (
                  <ButtonNew key={tag} onClick={() => tagSearch.remove(tag)} className={styles.filterValueButton} noTextTransform>
                    {tag}
                    <span className={styles.buttonCross} aria-hidden="true">×</span>
                  </ButtonNew>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
