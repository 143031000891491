import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionEpisodeUploadToYoutube
  from '../../../../components/ActionEpisodeUploadToYoutube/ActionEpisodeUploadToYoutube';
import EpisodeActionMenu from '../../../../components/Episodes/EpisodeActionMenu/EpisodeActionMenu';
import { WindowContainer } from '../../../../components/WindowContainer';
import { CONTENT_TYPE } from '../../../../constants/general';
import * as episodesActions from '../../../../modules/episodes/actions';
import EpisodeCreate from '../EpisodeCreate/EpisodeCreate';
import ListContainer from '../../../../components/ListContainer/ListContainer';
import SeasonCreate from '../SeasonCreate/SeasonCreate';
import EpisodeListItemDetails from './EpisodeListItemDetails';

const ItemIcons = ({ item, ...rest }) => (
  <>
    <ActionEpisodeUploadToYoutube
      episodeId={item._id}
      seriesId={item.seriesId}
      youtubeId={item.fileDoc && item.fileDoc.youtube && item.fileDoc.youtube.youtubeId}
    />
    <EpisodeActionMenu item={item} {...rest} />
  </>
);
ItemIcons.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    seriesId: PropTypes.string,
    fileDoc: PropTypes.shape({
      youtube: PropTypes.shape({
        youtubeId: PropTypes.string,
      }),
    })
  }),
  removeCallback: PropTypes.func,
};


const EpisodeListItemMiddle = ({item}) => `No. ${item.number ?? ''}`


class EpisodeList extends Component {
  static propTypes = {
    seriesId: PropTypes.string.isRequired,
    seasonId: PropTypes.string,
    episodes: PropTypes.shape({
      list: PropTypes.array,
    }),
    noPadding: PropTypes.bool,
    readEpisodesList: PropTypes.func.isRequired,
  };

  state = {
    ready: false,
    showCreateNewSeason: false,
    showCreateNewEpisode: false,
  };

  componentDidMount() {
    this._mounted = true;
    this.update().then();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { seasonId } = this.props;
    if (prevProps.seasonId !== seasonId) {
      this.update().then();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  update = async () => {
    const { seriesId, seasonId, readEpisodesList } = this.props;
    await readEpisodesList(seriesId, seasonId);
    if (this._mounted) {
      this.setState({ ready: true });
    }
  };

  toggleCreateNewSeason = async () => {
    if (this._mounted) {
      this.setState(state => ({ showCreateNewSeason: !state.showCreateNewSeason }));
    }
  };

  toggleCreateNewEpisode = async () => {
    if (this._mounted) {
      this.setState(state => ({ showCreateNewEpisode: !state.showCreateNewEpisode }));
    }
  };

  render() {
    const { seriesId, seasonId, episodes, noPadding } = this.props;
    const { ready, showCreateNewSeason, showCreateNewEpisode } = this.state;

    return (
      <div style={{ paddingLeft: noPadding ? 0 : '50px' }}>
        <ListContainer
          ready={ready}
          items={episodes.list}
          type={CONTENT_TYPE.EPISODE}
          icons={props => <ItemIcons removeCallback={this.update} {...props} />}
          actions={seasonId
            ? [
              { label: 'Add Episode', onClick: this.toggleCreateNewEpisode },
            ]
            : [
              { label: 'Add Season', onClick: this.toggleCreateNewSeason },
              { label: 'Add Episode', onClick: this.toggleCreateNewEpisode },
            ]
          }
          details={EpisodeListItemDetails}
          middle={EpisodeListItemMiddle}
          title="Episodes"
          titleLevel={3}
          titleClassName="left"
        />
        {showCreateNewSeason && (
          <WindowContainer closeAction={this.toggleCreateNewSeason}>
            <SeasonCreate
              seriesId={seriesId}
              onChange={this.toggleCreateNewSeason}
            />
          </WindowContainer>
        )}
        {showCreateNewEpisode && (
          <WindowContainer closeAction={this.toggleCreateNewEpisode}>
            <EpisodeCreate
              seriesId={seriesId}
              seasonId={seasonId}
              onChange={this.toggleCreateNewEpisode}
            />
          </WindowContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  episodes: state.episodes,
});

const mapDispatchToProps = dispatch => ({
  readEpisodesList: (seriesId, seasonId) => dispatch(episodesActions.readEpisodesList(seriesId, seasonId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeList);
