import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../Button/Button';
import styles from './HelpPages.module.css';

class HelpPages extends Component {
    static propTypes = {
        pages: PropTypes.array.isRequired,
        callback: PropTypes.func,
        width: PropTypes.string,
        height: PropTypes.string,
    };

    state = {
        pageNumber: 0,
    };

    handleNext = () => {
        const { pageNumber } = this.state;
        this.setState({ pageNumber: pageNumber + 1 });
    };

    handlePrevious = () => {
        const { pageNumber } = this.state;
        this.setState({ pageNumber: pageNumber - 1 });
    };

    handleClose = () => {
        const { callback } = this.props;

        if (callback) {
            callback();
        }
    };

    render() {
        const { pageNumber } = this.state;

        const { pages, width, height } = this.props;

        return (
            <div
                className={styles.container}
                style={{ width, height }}
            >
                <div className={styles.page}>
                    {pages[pageNumber]}
                </div>
                <div className={styles.buttons}>
                    <div className={styles.arrowButtons}>
                        {pageNumber !== 0
                        && (
                            <Button
                                color={Button.COLOR.PRIMARY}
                                onClick={this.handlePrevious}
                            >
                                Previous
                            </Button>
                        )}
                        <Button
                            color={Button.COLOR.PRIMARY}
                            onClick={this.handleNext}
                            disabled={!(pageNumber !== (pages.length - 1))}
                        >
                            Next
                        </Button>
                    </div>
                    <div className={styles.endButtons}>
                        <Button
                            color={pageNumber === (pages.length - 1) ? Button.COLOR.PRIMARY : Button.COLOR.GRAY}
                            onClick={this.handleClose}
                        >
                            Okay, I got it
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default HelpPages;
