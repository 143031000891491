import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getDownloadUrlFunc } from '../../utils/cloud/cloud';
import { checkAllowCloud } from '../../utils/permissions';
import Button from '../Button/Button';
import icon from './icon_download_file.svg';
import styles from './ActionDownloadFile.module.css';

const ActionDownloadFile = ({ cloud, canUseCloud }) => {
    const urlFunc = getDownloadUrlFunc(cloud);
    return (
        canUseCloud && (
            <Button
                tiny
                disabled={!cloud || Object.keys(cloud).length === 0 || !urlFunc}
                onClick={async () => {
                    const response = await urlFunc();
                    if (response) {
                        window.location.href = response.url;
                    }
                }}
                color={Button.COLOR.NONE}
                className={cn(styles.container, styles.left0)}
            >
                <div className={styles.icon}>
                    <img src={icon} alt="download file" />
                </div>
                Download
            </Button>
        )
    );
};

ActionDownloadFile.propTypes = {
    cloud: PropTypes.object,
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = dispatch => ({
    canUseCloud: dispatch(checkAllowCloud()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionDownloadFile);
