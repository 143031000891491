import { DOC_TYPE_FILE_PROJECT } from '../constants/docs';
import { SHARE_TYPES } from '../constants/share';
import { readUserDoc, writeUserDocs } from '../handlers/userDB';
import { fetchProjectFiles } from './desktop/new_file_search';

export const getShareProperty = sharing => (!sharing || (sharing instanceof Array) ? sharing || [] : [sharing]);


const modifyDocWithSettings = (doc, type, sharingId) => ({
  ...doc,
  sharing: [
    ...getShareProperty(doc.sharing).filter(sh => sh.type !== type),
    ...(sharingId ? [{ id: sharingId, type }] : [])
  ]
});

/**
 * Save share status in the documents
 * @param {string} docId Document Id
 * @param {string} type Share type
 * @param {string} sharingId Sharing Id
 * */
// eslint-disable-next-line import/prefer-default-export
export const updateSharesDoc = (docId, type, sharingId) => async (dispatch) => {
  const doc = await dispatch(readUserDoc(docId));
  const files = (doc.type === DOC_TYPE_FILE_PROJECT) ? await dispatch(fetchProjectFiles(doc._id)) : [];
  await dispatch(writeUserDocs([
    modifyDocWithSettings(doc, type, sharingId),
    ...files.map(fl => modifyDocWithSettings(fl, type, sharingId)),
  ]));
};

export const isProjectFilesShared = projectId => async (dispatch) => {
  const files = await dispatch(fetchProjectFiles(projectId));
  return files
    .filter(fl => getShareProperty(fl.sharing).filter(sh => sh.type === SHARE_TYPES.project).length)
    .length > 0;
};
