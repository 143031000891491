import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { WindowContainer } from '../../../components/WindowContainer';
import { CONTENT_TYPE, ROUTE } from '../../../constants/general';
import { readSeriesList } from '../../../modules/series/actions';
import SeriesCreate from '../Series/SeriesCreate/SeriesCreate';
import ListContainer from '../../../components/ListContainer/ListContainer';
import SeriesListItemDetails from './SeriesListItemDetails';

class SeriesList extends Component {
  static propTypes = {
    readList: PropTypes.func.isRequired,
    series: PropTypes.array.isRequired,
  };

  state = { ready: false };

  componentDidMount() {
    this._mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  init = async () => {
    const { readList } = this.props;
    await readList();
    if (this._mounted) {
      this.setState({ ready: true });
    }
  };

  render() {
    const { series } = this.props;
    const { ready } = this.state;

    return (
      <>
        <ListContainer
          ready={ready}
          title="Series"
          items={series}
          actions={[
            { label: 'Add Series', link: ROUTE.series.create() }
          ]}
          details={SeriesListItemDetails}
          type={CONTENT_TYPE.SERIES}
        />
        <Route
          exact
          path={ROUTE.series.create()}
          component={() => (
            <WindowContainer closeAction={() => { this.props.history.goBack() }}>
              <SeriesCreate history={this.props.history} />
            </WindowContainer>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  series: state.series.list
});

const mapDispatchToProps = dispatch => ({
  readList: () => dispatch(readSeriesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesList);
