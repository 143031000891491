import React, { useEffect } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import { useUsersStats } from '../../../../helpers/use-stats-hook';
import { PageContainer } from '../../PageContainer';
import styles from './user-stats.module.scss';


type TRowTotalsProps = {
  val: string
  title: string
}


const RowTotals: React.FC<TRowTotalsProps> = ({ val, title }) => (
  <div className={styles.rowTotal}>
    <div>{val}</div>
    <div>{title}</div>
  </div>
)


export const UserStats: React.FC = (props) => {
  const { userStats, readUserStats } = useUsersStats({ autoRead: false });

  useEffect(() => {
    void readUserStats()
  }, [readUserStats]);


  return (
    <PageContainer title="Stats" titleLevel={2}>
      {!userStats.length && (
        <div className={styles.spinner}>
          <Spinner darkBackground={true} />
        </div>
      )}
      {userStats.map(row => <RowTotals key={row.key} val={row.val} title={row.title} />)}
    </PageContainer>
  );
}
