import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';


const ReadOnlyArea = ({ maxChars, className, value, ...props }) => (
    <div {...props} className={cn(styles.textarea, !!maxChars && styles.limitedTextarea, className)}>
        {value || (<span style={{ color: '#999' }}>{props.placeholder}</span>)}
    </div>
);

ReadOnlyArea.propTypes = {
    maxChars: PropTypes.number,
    value: PropTypes.any,
    className: PropTypes.string,
};

const EditableArea = ({ maxChars, className, ...props }) => (
    <>
        <textarea {...props} className={cn(styles.textarea, !!maxChars && styles.limitedTextarea, className)} />
        {
            !!maxChars
            && (
                <div className={styles.charCounter}>
                    <div>{maxChars - String(props.value).length}</div>
                </div>
            )
        }
    </>
);

EditableArea.propTypes = {
    maxChars: PropTypes.number,
    value: PropTypes.string,
    className: PropTypes.string,
};


const Textarea = (props) => {
    if (props.readOnly) {
        return <ReadOnlyArea {...props} />;
    }
    return <EditableArea {...props} />;
};

Textarea.propTypes = {
    readOnly: PropTypes.bool,
};

export default Textarea;
