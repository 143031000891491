import { ENDPOINTS, ROUTE } from '../../constants/general';
import { api, emitUserError } from '../../constants/globals';
import { frontEndLocation } from '../../constants/system';
import { closeDBs } from '../../new/dbCloseUtils';
import { actionSetLocalSettings } from './userActions';
import { doReadLocalSettings } from '../../new/authUtils';
import { doDBLogin } from '../../new/loginUtils';
import { wait } from '../../utils/common';
import { findDataFolder, findDbFolder, getDbPathLatest, getProjectFolderLatest } from '../../utils/desktop/files';
import { isFolderEmpty } from '../../utils/desktop/fileSystem';
import { fsEnsureDirSync, fsExistsSync, fsMoveSync } from '../../utils/desktop/fse';
import { pathDirname } from '../../utils/desktop/path';
import { getUserId } from '../../utils/webSettings';
import { GENERAL_ACTION_TYPES } from '../general';

export const ACTIONS = {
    clear: () => ({ type: GENERAL_ACTION_TYPES.authClear }),
    error: (type, message, unknownMessage) => ({
        type,
        message: !!message
            ? typeof (message) === 'string'
                ? message
                : message.message
                    ? message.message
                    : JSON.stringify(message)
            : unknownMessage || 'Login unknown error'
    }),
    auth: {
        error: message => ACTIONS.error(GENERAL_ACTION_TYPES.authClear, message),
    },
};

/**
 * @param {{user_id?: string, name: string, login: string, email: string, roles?: string[]}}
 * @param {string[]} roles
 */
export const updateUserProperties = async ({ user_id, name, login, email, roles }) => {
  await api.apiPost(ENDPOINTS.user.updateUserProperties, {
    user_id,
    name,
    login,
    email,
    roles
  });
};


export const webRegister = async ({ name, login, password, email }) => {
    try {
        // const response =
        await api.apiPost(ENDPOINTS.user.register, {
            name,
            login,
            password,
            email,
            confirmation_url: `${frontEndLocation}${ROUTE.registerConfirmed}`,
        });
        // console.log('Register response:', response);
        // if (response) {
        //     if (response.message === 'User was created') {
        //         result = '';
        //     } else if (response.message) {
        //         result = response.message;
        //         emitUserEvent(response.message, 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
        //         throw new Error(response.message);
        //     } else {
        //         emitUserEvent(response, 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
        //         throw new Error(response);
        //     }
        // } else {
        //     emitUserEvent('', 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
        //     result = 'Response message is empty';
        // }
    } catch (e) {
        emitUserError(e.message, 'Unable to register');
        throw e;
    }
};

export const submitNewProjectPath = (projectPath, setInfoCallback = undefined) => async (dispatch, getState) => {
    if (projectPath) {
        const moveFolders = [];

        const userId = getUserId();

        // Check DB path
        let moveDB = false;
        const oldDbPath = findDbFolder(userId);
        const newDbPath = getDbPathLatest(userId);
        if (oldDbPath && fsExistsSync(oldDbPath)) {
            if (oldDbPath.toLowerCase() !== newDbPath.toLowerCase()) {
                moveFolders.push({ src: pathDirname(oldDbPath), dest: pathDirname(newDbPath) });
                moveDB = true;
            }
        } else {
            fsEnsureDirSync(newDbPath);
        }

        // Check project path
        const oldProjectPath = await findDataFolder(userId) || '';
        const newProjectPath = getProjectFolderLatest(projectPath);
        if (oldProjectPath && fsExistsSync(oldProjectPath)) {
            if (oldProjectPath.toLowerCase() !== newProjectPath.toLowerCase()) {
                moveFolders.push({ src: oldProjectPath, dest: newProjectPath });
            }
        } else {
            fsEnsureDirSync(newProjectPath);
        }

        if (moveDB) {
            await dispatch(closeDBs());
            await wait(1000);
        }

        moveFolders.forEach(({ src, dest }) => {
            const errorTemplate = `Can not move folder "${src}" to "${dest}".`;
            if (dest.toLowerCase().includes(src.toLowerCase())) {
                throw new Error(`The new folder (${dest}) should not be in the old (${src})`);
            } else if (!fsExistsSync(dest) || isFolderEmpty(dest)) {
                try {
                    if (setInfoCallback) {
                        setInfoCallback(`Moving from "${src}" to "${dest}" ...`);
                    }
                    fsEnsureDirSync(pathDirname(dest));
                    fsMoveSync(src, dest);
                } catch (e) {
                    throw new Error(`${errorTemplate}\n${e.message}`);
                }
            } else {
                throw new Error(`${errorTemplate}\nThe folder ${dest} already exists.`);
            }
        });

        dispatch(actionSetLocalSettings({ projectPath }));
        if (await dispatch(doReadLocalSettings(userId))) {
            if (moveDB) {
                await dispatch(doDBLogin());
            }
        }
    }
};

export const logout = () => (dispatch) => {
    dispatch(ACTIONS.clear());
    // TODO: Maybe we need to clean all Redux state
};

export const updateUserPassword = ({ newPassword, password }) => async (dispatch) => {
    await api.apiPost(ENDPOINTS.user.changeUserPassword, {
        new_password: newPassword,
        password
    });
};

export const apiDeactivateUser = userId => async (dispatch) => {
    await api.apiPost(ENDPOINTS.user.deactivateUser, { user_id: userId });
    if (userId === getUserId()) {
        dispatch(logout());
    }
};

export const apiActivateUser = userId => async (dispatch) => {
    await api.apiPost(ENDPOINTS.user.activateUser, { user_id: userId });
    // alert('User deactivated');
    // dispatch(logout());
};

export const apiResetPassword = async email => api.apiPost(ENDPOINTS.user.resetPassword, {
    email,
    reset_password_url: `${frontEndLocation}${ROUTE.applyResetPassword}`
});

export const apiResetPasswordApply = async (token, password) => api.apiPost(ENDPOINTS.user.resetPasswordApply, {
    token,
    password,
});
