import React from 'react';
import styles from './LoadingPlaceholder.module.css';

const LoadingPlaceholder = () => (
    <div className={styles.container}>
        Loading ...
    </div>
);

export default LoadingPlaceholder;
