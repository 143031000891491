import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage4.module.css';
import imageExample from './image_example.svg';
import imageHand1 from './image_hand_1.png';

const ProjectHelpPage4 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                When you add media:
            </h2>
            <h2 className={styles.text}>
                1) A folder is created on
                your local drive&nbsp;<span><img className={styles.imageHand1} src={imageHand1} alt="like" /></span>
            </h2>
            <img src={imageExample} alt="example" className={styles.imageExample} />
        </div>
    </TitledPage>
);

export default ProjectHelpPage4;
