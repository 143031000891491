import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage1.module.css';
import imageArrow from './image_arrow.svg';
import configs from '../../../../../../constants/configs';

const ProjectHelpPage1 = () => (
    <TitledPage>
        <div>
            <h2 className={styles.text}>You did it!</h2>
            <h2 className={styles.text}>This is your project page.</h2>
            <h2 className={styles.text}>
                Check out your
                {` ${configs.appName} `}
                folder! You’ll see a project folder was automatically created.
            </h2>
            <img className={styles.imageArrow} src={imageArrow} alt="arrow" />
        </div>
    </TitledPage>
);

export default ProjectHelpPage1;
