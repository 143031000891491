export const errorMessage = (msg) => {
    let message = msg;

    if (typeof msg !== 'string') {
        if (typeof msg === 'object' && msg.message) {
            message = `${msg.message}`;
        }
        try {
            message = JSON.stringify(msg);
        } catch (e) {
            try {
                message = msg.toString();
            } catch (err) {
                message = `Can not parse message ${msg}`;
            }
        }
    }
    return { message };
};
export const errorResponse = response => ({ message: errorMessage(response) });
