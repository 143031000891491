import { DB_ACTION_TYPES } from './reducer';

export const actionSetLocalDB = userDB => ({
    type: DB_ACTION_TYPES.setLocalDB,
    userDB
});

export const actionSetRemoteDB = remoteDB => ({
    type: DB_ACTION_TYPES.setRemoteDB,
    remoteDB
});

export const actionCloseLocalDB = userDB => ({
    type: DB_ACTION_TYPES.closeLocalDB,
    userDB
});

export const actionCloseRemoteDB = remoteDB => ({
    type: DB_ACTION_TYPES.closeRemoteDB,
    remoteDB
});

export const actionSetSyncHandler = syncHandler => ({
    type: DB_ACTION_TYPES.setSyncHandler,
    syncHandler
});

export const actionClearSyncHandler = () => ({
    type: DB_ACTION_TYPES.clearSyncHandler
});
