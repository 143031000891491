import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import WindowContainer from '../../../components/WindowContainer/temp/WindowContainer';
import { ROUTE } from '../../../constants/general';
import { isWeb } from '../../../constants/modules';
import { r } from '../../../constants/routes';
import { TStore } from '../../../helpers/types-store';
import { AllProviders } from '../../../Providers';
import { isSharing } from '../../../utils/webSettings';
import LocalSettings from '../../Desktop/LocalSettings/LocalSettings';
import SharedApp from '../../Shared/App/SharedApp';
import Home from '../../Site/Home';
import { Route, Switch } from 'react-router-dom';
import LoginLayout from '../Login/LoginLayout';
import SyncLayout from '../SyncLayout/SyncLayout';
import UserEventLayout from '../UserEventLayout/UserEventLayout';
import { LoggedApp } from './LoggedApp';


type TFlags = {
  isLoginProcess: boolean
  isLoggedIn: boolean
  isLocalConfigured: boolean
  isDbConnected: boolean
}

export const App: FC = () => {
  const { isDbConnected, isLocalConfigured, isLoggedIn, isLoginProcess } = useSelector<TStore, TFlags>(
    ({ user, db }) => ({
      isLoginProcess: user.isLoginProcess,
      isLoggedIn: user.isLoggedIn,
      isLocalConfigured: user.isLocalConfigured,
      isDbConnected: db.isDbConnected,
    })
  );


  const routes = useMemo(() => {
    return [
      ...(isWeb
        ? [<Route key="home" exact path={r.home.path} component={Home} />]
        : []),
      ...(isSharing()
        ? [<Route key="sharing" path={ROUTE.share.id()} component={SharedApp} />]
        : []),
      ...(!isLoginProcess && isLoggedIn
        ? (isLocalConfigured && isDbConnected
          ? [<Route key="LoggedApp">
            <AllProviders>
              <LoggedApp />
            </AllProviders>
          </Route>]
          : [<Route key="LocalSettings">
            <WindowContainer width={800} height={680} contentOnly hideCloseButton>
              <LocalSettings />
            </WindowContainer>
          </Route>])
        : [<Route key="LoginLayout" component={LoginLayout} />]),
    ];

  }, [isDbConnected, isLocalConfigured, isLoggedIn, isLoginProcess]);


  return (
    <>
      <UserEventLayout />
      <Switch>
        {routes}
      </Switch>
      <SyncLayout />
    </>
  );
}
