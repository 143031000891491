import React from 'react';

const k = 4.1574 / 2.9104;

export const IconSeriesNav: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 17, fill = 'white', ...rest } = props;

  const aspectWidth = (height as number) * k;
  return (
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 4.1574 2.9104" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="a">
          <path d="m0 792h612v-792h-612z"/>
        </clipPath>
      </defs>
      <g transform="matrix(.5522 0 0 -.5522 -5.4102 339.42)" clipPath="url(#a)" fill={fill}>
        <g transform="translate(10.55 612.41)">
          <path d="m0 0h-0.376c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h0.376c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
        <g transform="translate(10.55 614.67)">
          <path d="m0 0h-0.376c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h0.376c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
        <g transform="translate(10.55 610.15)">
          <path d="m0 0h-0.376c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h0.376c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
        <g transform="translate(16.95 612.41)">
          <path d="m0 0h-4.894c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h4.894c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
        <g transform="translate(16.95 614.67)">
          <path d="m0 0h-4.894c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h4.894c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
        <g transform="translate(16.95 610.15)">
          <path d="m0 0h-4.894c-0.208 0-0.377-0.168-0.377-0.376s0.169-0.377 0.377-0.377h4.894c0.208 0 0.376 0.169 0.376 0.377s-0.168 0.376-0.376 0.376"/>
        </g>
      </g>
    </svg>
  );
};
