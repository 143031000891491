import { useCallback, useContext, useEffect, useState } from 'react';
import { TDocument } from '../constants/docs';
import { emitUserError } from '../constants/globals';
import { UserDbContext } from '../Providers/UserDB';
import { TDocPut } from './types-db';


type TUseDocumentProps = {
  id: string
}
type TUseDocumentResult<T extends TDocument> = {
  doc: T
  readDoc: () => Promise<void>
  writeDoc: (values: TDocPut<T>) => Promise<void>
}


export const useDocument = <T extends TDocument>({ id }: TUseDocumentProps): TUseDocumentResult<T> => {
  const { writeUserDoc, readUserDoc } = useContext(UserDbContext);

  const [doc, setDoc] = useState<T>({
    _id: '',
    _rev: '',
    // type: 'project',
    created: '',
    updated: ''
  } as T);


  const readDoc = useCallback(async () => {
    try {
      setDoc(await readUserDoc(id) as T);
    } catch (e) {
      emitUserError((e as Error).message, 'Reading a document');
    }
  }, [id, readUserDoc]);

  const writeDoc = useCallback(async (values: TDocPut<T>) => {
    try {
      setDoc(await writeUserDoc(values) as T);
    } catch (e) {
      emitUserError((e as Error).message, 'Writing a document');
    }
  }, [writeUserDoc]);


  useEffect(() => {
    if (id) {
      void readDoc()
    }
  }, [id, readDoc]);


  return {
    doc,
    readDoc,
    writeDoc,
  };
}
