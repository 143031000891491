import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';


export const useSearch = () => {
    const location = useLocation();

    return useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }) || {}, [location.search]);
};
