import { USER_ACTION_TYPES } from './reducer';


export const actionSetLoginProcess = isLoginProcess => ({
    type: USER_ACTION_TYPES.setLoginProcess,
    isLoginProcess,
});

export const actionSetToken = (token, refreshToken) => ({
    type: USER_ACTION_TYPES.setToken,
    token,
    refreshToken,
});

export const actionSetUserId = id => ({
    type: USER_ACTION_TYPES.setUserId,
    id,
});

export const actionSetUserProperties = properties => ({
    type: USER_ACTION_TYPES.setProperties,
    properties,
});

export const actionSetLocalSettings = local => ({
    type: USER_ACTION_TYPES.setLocalSettings,
    local,
});

export const actionSetDbUser = dbUser => ({
    type: USER_ACTION_TYPES.setDbUser,
    dbUser,
});
