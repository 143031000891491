import React from 'react';
import TitledPage from '../TitledPage/TitledPage';
import styles from './ProjectHelpPage5.module.css';
import imageExampleAction from './image_example_action.svg';

const ProjectHelpPage5 = ({ title }) => (
    <TitledPage title={title}>
        <div className={styles.container}>
            <h2 className={styles.text}>
                You can create folders to save
                to.
            </h2>
            <img className={styles.imageExampleActions} src={imageExampleAction} alt="actions" />
        </div>
    </TitledPage>
);

export default ProjectHelpPage5;
