import cn from 'classnames';
import React from 'react';
import styles from './AbsoluteText.module.css';

const AbsoluteText = ({ text, level, className }) => (
    <div className={cn(styles.text, styles[`level-${level}`], className)}>
        {text}
    </div>
);

export default AbsoluteText;
