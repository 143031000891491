import { getUserId } from '../webSettings';
import {
    apiAbortMultipartUpload,
    apiCompleteMultipartUpload,
    apiCreateMultipartUpload,
    apiListParts,
    apiPrepareUploadPart, getS3ProjectKey
} from '../cloud/cloudS3';
import { createFileName } from '../desktop/files';

const getS3Key = uppyFile => {
    const name = uppyFile.meta.doc?.filename || uppyFile.meta.name;

    if (uppyFile.data.isRawUpload) {
        const userId = getUserId();
        return `${userId}/uploads/${Date.now()}_${name}`;
    }

    const { projectId, _id: fileId, type } = uppyFile.meta.doc;

    if (!projectId) {
        throw new Error(`Unable to determine project Id from doc\n${JSON.stringify(uppyFile.meta.doc)}`);
    }
    if (!fileId) {
        throw new Error(`Unable to determine file Id from doc\n${JSON.stringify(uppyFile.meta.doc)}`);
    }
    if (!type) {
        throw new Error(`Unable to determine file type from doc\n${JSON.stringify(uppyFile.meta.doc)}`);
    }

    return `${getS3ProjectKey(projectId)}/${type}/${createFileName(name, fileId)}`;
};

export const getAwsS3MultipartOptions = () => ({
    // limit: 1,
    // companionUrl: 'https://uploader.meedi-io.aws.cpcs.ws/',
    createMultipartUpload: async (file) => {
        // console.log('createMultipartUpload', { file });
        const key = getS3Key(file);
        try {
            return await apiCreateMultipartUpload(key);
        } catch (e) {
            return { message: e.message };
        }
    },
    listParts: async (file, { uploadId, key }) => {
        // console.log('listParts', {uploadId, key});
        try {
            return await apiListParts(key, uploadId);
        } catch (e) {
            return { message: e.message };
        }
    },
    prepareUploadPart: async (file, partData) => {
        // console.log('prepareUploadPart', {file, partData});
        try {
            return await apiPrepareUploadPart(partData.key, partData.uploadId, partData.number);
        } catch (e) {
            return { message: e.message };
        }
    },
    completeMultipartUpload: async (file, { uploadId, key, parts }) => {
        // console.log('completeMultipartUpload', {uploadId, key, parts});
        try {
            return await apiCompleteMultipartUpload(key, uploadId, parts);
        } catch (e) {
            return { message: e.message };
        }
    },
    abortMultipartUpload: async (file, { uploadId, key }) => {
        // console.log('abortMultipartUpload', {uploadId, key});
        try {
            return await apiAbortMultipartUpload(key, uploadId);
        } catch (e) {
            throw e;
        }
    }
});
