// permissions: {
// DesktopAndWebApp: 'Desktop and webapp',
// ManagementMediaLocal: 'Management media, local machine',
// ManagementAccount: 'Management account',
// S3UploadAudioAndVideo: 'Ability upload finished audio and video to S3',
// AbilityPublicMedia: 'Ability push media out there YouTube/ podcast feed',
//     AbilitySharePagesWithOthers: 'User can share pages with others',
//     AbilityShareMeediUsers: 'Ability share with users signed up to application',
//     AbilityUseOwnCloudStorage: 'Ability use own cloud storage',
//     AbilityUseGlacierAwsStorage: 'Media is stored in Glacier AWS (deep storage)',
//     AbilityUploadByCustomWay: 'Ability upload their folder existing folders their way',
//     AbilityToSeeAllUsersProjects: 'Ability to see ALL user’s projectsy',
//     AbilityBackupProjects: 'Ability use backup project mechanism'
// }
import { isSharing } from './webSettings';

export const PERMISSIONS = {
    desktopAndWebApp: 'DesktopAndWebApp',
    managementMediaLocal: 'ManagementMediaLocal',
    managementAccount: 'ManagementAccount',
    UserManagement: 'UserManagement',
    UserManagementRead: 'UserManagementRead',
    allowCloud: 'S3UploadAudioAndVideo',
    publishMedia: 'AbilityPublicMedia',
    abilitySharePagesWithOthers: 'AbilitySharePagesWithOthers',
    abilityShareMeediUsers: 'AbilityShareMeediUsers',
    abilityUseOwnCloudStorage: 'AbilityUseOwnCloudStorage',
    abilityUseGlacierAwsStorage: 'AbilityUseGlacierAwsStorage',
    abilityUploadByCustomWay: 'AbilityUploadByCustomWay',
    abilityToSeeAllUsersProjects: 'AbilityToSeeAllUsersProjects',
    abilityBackupProjects: 'AbilityBackupProjects',
};

const hasPermission = permission => (dispatch, getState) => {
    if (isSharing()) {
        return true;
    }

    const { user: { web: { properties: { permissions } } } } = getState();
    return permissions.includes(permission);
};

// A user will have access to desktop application and web app.
export const checkAllowApp = () => dispatch => dispatch(hasPermission(PERMISSIONS.desktopAndWebApp));

// User can use the desktop version to manage media, generate UUIDs and create folders on their local machine
export const checkManagementMediaLocal = () => dispatch => dispatch(hasPermission(PERMISSIONS.managementMediaLocal));

// The user is able to manage their own account
export const checkManagementAccount = () => dispatch => dispatch(hasPermission(PERMISSIONS.managementAccount));

// Ability to manage users accounts
export const checkUserManagement = () => dispatch => dispatch(hasPermission(PERMISSIONS.UserManagement));

// Ability to read users accounts
export const checkUserManagementRead = () => dispatch => dispatch(hasPermission(PERMISSIONS.UserManagementRead));

// User can upload finished audio and video to S3
export const checkAllowCloud = () => dispatch => dispatch(hasPermission(PERMISSIONS.allowCloud));

// Users can push stuff out there YT push/ audio podcast feed
export const checkPublishMedia = () => dispatch => dispatch(hasPermission(PERMISSIONS.publishMedia));

// User can share pages with others (Permissions):
//     Read only (ie view, watch and listen)
//     View, Upload, edit all fields
//     View, Upload, edit all fields, delete (Giving ownership to your channel)
export const checkSharePagesWithOthers = () => dispatch => dispatch(
    hasPermission(PERMISSIONS.abilitySharePagesWithOthers)
);

// User can share with users signed up to app
//     Either through direct link or login/PW
export const checkShareMeediUsers = () => dispatch => dispatch(hasPermission(PERMISSIONS.abilityShareMeediUsers));

// User can user their own cloud storage
export const checkUseOwnCloudStorage = () => dispatch => dispatch(hasPermission(PERMISSIONS.abilityUseOwnCloudStorage));

// User can upload media
//     Media is stored in Glacier AWS (deep storage)
export const checkUseGlacierAwsStorage = () => dispatch => dispatch(
    hasPermission(PERMISSIONS.abilityUseGlacierAwsStorage)
);

// User can upload their folder existing folders their way
//     This would replace media with a link to a folder of whatever is in that folder.
//     We’d give that folder an ID
//     All items in the folder are kept as is
//     That folder is synced back to user `as-is`
export const checkUploadByCustomWay = () => dispatch => dispatch(hasPermission(PERMISSIONS.abilityUploadByCustomWay));

// User has ability to see ALL user’s projects
export const checkSeeAllUsersProjects = () => dispatch => dispatch(
    hasPermission(PERMISSIONS.abilityToSeeAllUsersProjects)
);

export const checkBackupProjects = () => dispatch => dispatch(hasPermission(PERMISSIONS.abilityBackupProjects));

