import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../constants/docs';
import { ROUTE } from '../../../constants/general';
import { isFinishFile } from '../../../constants/pouchDB';
import { SHARE_TYPES } from '../../../constants/share';
import { ActionFileMenu } from '../../ActionFileMenu';
import { ShareSettingsButton } from '../../ShareSettingsButton';
import IconUploadedToCloud from '../../IconUploadedToCloud/IconUploadedToCloud';
import S3img from '../../S3img/S3img';
import IconFileOnLocalDrive from '../../StatusIcons/IconFileOnLocalDrive/IconFileOnLocalDrive';
import IconFileUploadToYoutube from '../../StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';
import styles from './ProjectListItemFile.module.css';


const FILE_ROUTES = {
  [DOC_TYPE_FILE_FINISH_VIDEO]: ROUTE.videos,
  [DOC_TYPE_FILE_FINISH_AUDIO]: ROUTE.audios,
};


const ProjectListItemFile = ({ sharingId, projectProps, file, removeFile, saveFile, callback }) => (
  <div className={cn(styles.mediaItem, styles.videoItem)} key={file._id}>
    {isFinishFile(file.type) && <S3img src={file.image} className={styles.previewImage} />}
    <div className={styles.nameGroup}>
      <span className={styles.mediaName}>
        {isFinishFile(file.type)
          ? (
            <Link
              to={!sharingId
                ? FILE_ROUTES[file.type].pages.metadata(file._id)
                : ROUTE.share.file.metadata(sharingId, file._id)
              }
            >
              <h4>{file.title}</h4>
            </Link>
          )
          : file.title
        }
        {isFinishFile(file.type) && (
          <p>
            Created:
            {' '}
            {new Date(file.created).toLocaleDateString()}
          </p>
        )}
        {!!file.description && (
          <p className={styles.description}>
            {file.description}
          </p>
        )}
      </span>
      {!isFinishFile(file.type) && (
        <span className={styles.date}>{new Date(file.created).toLocaleDateString()}</span>
      )}
    </div>
    <span className={styles.mediaId}>{file._id}</span>
    <span className={styles.mediaStatus}>
      <IconUploadedToCloud cloud={file.cloud} />
      <IconFileOnLocalDrive projectId={file.projectId} fileId={file._id} />
      {file.type === DOC_TYPE_FILE_FINISH_VIDEO
        && <IconFileUploadToYoutube youtubeId={file.youtube && file.youtube.youtubeId} />}
      {isFinishFile(file.type) && <ShareSettingsButton onlyIcon sharing={file.sharing} type={SHARE_TYPES.file} />}
    </span>
    <ActionFileMenu
      file={file}
      project={projectProps}
      removeFileAction={removeFile}
      removeFileCallback={callback}
      saveFileAction={saveFile}
      editFileCallback={callback}
    />
  </div>
);

ProjectListItemFile.propTypes = {
  sharingId: PropTypes.string,
  file: PropTypes.object,
  removeFile: PropTypes.func,
  callback: PropTypes.func,
};

export default ProjectListItemFile;
